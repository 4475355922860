import "./SegmentationLabeling.scss";
import React, { useCallback, useMemo, useState } from "react";
import * as R from "ramda";
import SegmentationLabelingTable from "./SegmentationLabelingTable";
import { CampaignRow } from "@blisspointmedia/bpm-types/dist/CampaignLabelingTool";
import { MiscLambdaFetch, pollS3 } from "../utils/fetch-utils";
import { useCompanyInfo } from "../redux/company";
import { useSetError } from "../redux/modals";
import { RouteComponentProps } from "@reach/router";
import {
  FullPageSpinner,
  OldFilterBar,
  PendingChangesControls,
  TextToggleButton,
} from "../Components";
import { useStateFunction } from "../utils/hooks/useData";
import PendingSegmentationChanges from "./PendingSegmentationChanges";
import { CustomSegmentsData } from "./SegmentationMapping";
import IncompleteUpdatesModal from "./IncompleteUpdatesModal";
import pako from "pako";

export interface SegmentRow extends CampaignRow {
  ad_group_id?: number;
  ad_group_name?: string;
  ad_id?: number;
  ad_name?: string;
  raw_row_ids?: string;
  ingested_seg_id?: string;
  pg_seg_id?: string;
  Channel?: string;
  Platform?: string;
  channel_id?: number;
  platform_id?: number;
}

export interface SegmentEditsMap {
  [campaign_id: string]: SegmentRow;
}

interface SegmentationLabelingProps {
  data: any[] | null;
  customSegments: CustomSegmentsData[] | undefined;
  fetchRawData: () => void;
  dataGranularity: "ad" | "ad_group" | "campaign";
}

enum LabeledToggle {
  LABELED = "Labeled",
  UNLABELED = "Unlabeled",
}

interface UpdatedRow {
  rawRowIds: string | number;
  segmentMap: Record<string, number>;
}

const SegmentationLabeling = ({
  data,
  customSegments,
  fetchRawData,
  dataGranularity,
}: SegmentationLabelingProps & RouteComponentProps): JSX.Element => {
  const { cid } = useCompanyInfo();
  const setError = useSetError();

  const [labeledEditsMap, setlabeledEditsMap] = useState<SegmentEditsMap>({});
  const [unlabeledEditsMap, setUnlabeledEditsMap] = useState<SegmentEditsMap>({});
  const [selectedRows, setSelectedRows] = useState<Record<string, SegmentRow>>({});
  const [toggleValue, setToggleValue] = useState<string>(LabeledToggle.UNLABELED);
  const [showIncompleteUpdatesModal, setShowIncompleteUpdatesModal] = useState(false);

  const filterBarOptions = useMemo(() => {
    const uniqueSegmentOptions =
      customSegments?.map(segment => ({
        name: segment.segmentName,
        label: segment.segmentName,
      })) ?? [];

    const options = [
      ...uniqueSegmentOptions,
      { name: "account_id", label: "Account ID" },
      { name: "account_name", label: "Account Name" },
      { name: "campaign_id", label: "Campaign ID" },
      { name: "campaign_name", label: "Campaign" },
    ];

    if (dataGranularity === "ad_group" || dataGranularity === "ad") {
      options.push({ name: "ad_group_id", label: "Ad Group ID" });
      options.push({ name: "ad_group_name", label: "Ad Group" });
    }

    if (dataGranularity === "ad") {
      options.push({ name: "ad_id", label: "Ad ID" });
      options.push({ name: "ad_name", label: "Ad" });
    }
    return options;
  }, [customSegments, dataGranularity]);

  const unlabeledData = useMemo(() => {
    return data?.filter(row => !(row.pg_seg_id || row.ingested_seg_id));
  }, [data]);

  const labeledData = useMemo(() => {
    return data?.filter(row => row.pg_seg_id || row.ingested_seg_id);
  }, [data]);

  const incompleteEditsMap = useMemo(() => {
    if (!customSegments) {
      return {};
    }

    const unlabeledEditsMapCopy = { ...unlabeledEditsMap };
    const segmentKeys = Object.keys(unlabeledEditsMap);

    for (const key of segmentKeys) {
      const value = unlabeledEditsMapCopy[key];

      let hasAllSegments = true;
      for (const customSegment of customSegments) {
        hasAllSegments = hasAllSegments && value.edits && value.edits[customSegment.segmentName];
      }

      if (hasAllSegments) {
        delete unlabeledEditsMapCopy[key];
      }
    }

    return unlabeledEditsMapCopy;
  }, [customSegments, unlabeledEditsMap]);

  const completeUnlabeledEditsMap = useMemo(() => {
    if (!customSegments) {
      return {};
    }

    const unlabeledSegmentEditsMapCopy = { ...unlabeledEditsMap };
    const segmentKeys = Object.keys(unlabeledEditsMap);

    for (const key of segmentKeys) {
      const value = unlabeledEditsMap[key];

      let hasAllSegments = true;
      for (const customSegment of customSegments) {
        hasAllSegments = hasAllSegments && value.edits && value.edits[customSegment.segmentName];
      }

      if (!hasAllSegments) {
        delete unlabeledSegmentEditsMapCopy[key];
      }
    }

    return unlabeledSegmentEditsMapCopy;
  }, [customSegments, unlabeledEditsMap]);

  const dataToUse = useMemo(() => {
    return toggleValue === LabeledToggle.UNLABELED
      ? { data: unlabeledData, editsMap: unlabeledEditsMap, editsMapSetter: setUnlabeledEditsMap }
      : { data: labeledData, editsMap: labeledEditsMap, editsMapSetter: setlabeledEditsMap };
  }, [labeledData, labeledEditsMap, toggleValue, unlabeledData, unlabeledEditsMap]);

  const [filter, setFilter] = useStateFunction<(line) => boolean>(() => true);

  const filteredData = useMemo(() => {
    return dataToUse.data?.filter(filter) || [];
  }, [filter, dataToUse]);

  const [showPendingChanges, setShowPendingChanges] = useState<boolean>(false);
  const hasPendingChanges = useMemo(() => {
    return !R.isEmpty(unlabeledEditsMap) || !R.isEmpty(labeledEditsMap);
  }, [labeledEditsMap, unlabeledEditsMap]);

  const clearAllChanges = () => {
    setUnlabeledEditsMap({});
    setlabeledEditsMap({});
    setShowPendingChanges(false);
  };

  const [saving, setSaving] = useState(false);

  const save = useCallback(async () => {
    if (!customSegments) {
      return;
    }

    setSaving(true);

    try {
      const combinedValues = [
        ...Object.values(completeUnlabeledEditsMap ?? {}),
        ...Object.values(labeledEditsMap),
      ];
      const updatedRows = combinedValues.map(edit => {
        const segmentMap: Record<string, number> = {};
        customSegments.forEach(segment => {
          if (!edit.edits) {
            return;
          }

          segmentMap[segment.segmentId] = edit.edits[segment.segmentName]?.value;
        });

        return { rawRowIds: edit.raw_row_ids ?? edit.ad_id ?? "", segmentMap };
      });

      const sendToLambda = async (rows: UpdatedRow[]) => {
        const compressedPayload = pako.gzip(JSON.stringify(rows), { to: "string" });
        const base64Payload = btoa(compressedPayload);
        const lambdaArgs = {
          company: cid,
          updatedRows: base64Payload,
        };

        const result = await MiscLambdaFetch("/kickOffLambda", {
          method: "POST",
          body: {
            fileType: "txt",
            lambdaArgs,
            lambdaName: "segmentationmapping-updateManualSegmentationMaps",
          },
        });

        const uuid = await result.json();
        const content = await pollS3({
          autoDownload: false,
          bucket: "bpm-cache",
          filename: `${uuid}.txt`,
          mimeType: "text/plain",
        });
        const textContent = await content.text();

        if (result.status !== 200) {
          throw new Error(textContent);
        }
      };

      const batchRows: UpdatedRow[] = [];
      let byteSizeNum = 0;
      for (const updatedRow of updatedRows) {
        const compressedPayload = pako.gzip(JSON.stringify(updatedRow), { to: "string" });
        const base64Payload = btoa(compressedPayload);
        const byteSize = new TextEncoder().encode(base64Payload).length;
        if (byteSizeNum + byteSize > 155000 && batchRows.length > 0) {
          await sendToLambda(batchRows);
          batchRows.length = 0;
          byteSizeNum = 0;
        }

        batchRows.push(updatedRow);
        byteSizeNum += byteSize;
      }

      if (batchRows.length > 0) {
        await sendToLambda(batchRows);
      }

      await fetchRawData();
      setUnlabeledEditsMap(incompleteEditsMap);
      setlabeledEditsMap({});
      setSelectedRows({});
      setSaving(false);
    } catch (e) {
      setSaving(false);
      const reportError = e as Error;
      setError({ message: reportError.message, reportError });
    }
  }, [
    customSegments,
    completeUnlabeledEditsMap,
    labeledEditsMap,
    fetchRawData,
    incompleteEditsMap,
    cid,
    setError,
  ]);

  const validChangesCount = useMemo(() => {
    return Object.keys(completeUnlabeledEditsMap).length + Object.keys(labeledEditsMap).length;
  }, [completeUnlabeledEditsMap, labeledEditsMap]);

  return (
    <div className="segmentationLabeling">
      <IncompleteUpdatesModal
        show={showIncompleteUpdatesModal}
        handleClose={() => setShowIncompleteUpdatesModal(false)}
        saveChanges={save}
        incompleteEditsMap={incompleteEditsMap}
        customSegments={customSegments}
        validChangesCount={validChangesCount}
        dataGranularity={dataGranularity}
      />
      <div className="segmentationLabelingControls">
        <TextToggleButton
          options={[LabeledToggle.UNLABELED, LabeledToggle.LABELED]}
          selectedOption={toggleValue}
          onChange={value => {
            setToggleValue(value);
            setSelectedRows({});
          }}
        />
        <PendingChangesControls
          hasPendingChanges={hasPendingChanges}
          setShowPendingChanges={setShowPendingChanges}
          saveChanges={
            Object.keys(incompleteEditsMap).length > 0
              ? () => {
                  setShowIncompleteUpdatesModal(true);
                }
              : save
          }
          isSaving={saving}
          clearAllChanges={clearAllChanges}
        />
      </div>
      {dataToUse.data ? (
        <div className="labelingTableContainer">
          <div className="filterBarContainer">
            <OldFilterBar
              options={filterBarOptions}
              lines={dataToUse.data || []}
              onFilter={setFilter}
            />
          </div>
          <SegmentationLabelingTable
            data={filteredData}
            customSegments={customSegments}
            editsMap={dataToUse.editsMap}
            selectedRows={selectedRows}
            setEditsMap={dataToUse.editsMapSetter}
            setSelectedRows={setSelectedRows}
            dataGranularity={dataGranularity}
          />
        </div>
      ) : (
        <FullPageSpinner />
      )}
      {showPendingChanges && hasPendingChanges && (
        <PendingSegmentationChanges
          incompleteUnlabeledEditsMap={incompleteEditsMap}
          completeUnlabeledEditsMap={completeUnlabeledEditsMap}
          labeledEditsMap={labeledEditsMap}
          setUnlabeledEditsMap={setUnlabeledEditsMap}
          setMappedSegmentEditsMap={setlabeledEditsMap}
          setShowPendingChanges={setShowPendingChanges}
          customSegments={customSegments}
          dataGranularity={dataGranularity}
        />
      )}
    </div>
  );
};

export default SegmentationLabeling;
