import "./TextToggleButton.scss";
import React, { useMemo } from "react";
import { ButtonGroup } from "react-bootstrap";
import { ButtonFramework, ButtonFrameworkVariant } from "../ButtonFramework";
import { typedReactMemo } from "../../utils/types";

interface Option<T> {
  key: T;
  label?: string;
}

interface Props<T> {
  options: readonly (Option<T> | T)[];
  selectedOption: T;
  onChange: (key: T) => void;
  design?: "primary" | "secondary";
  className?: string;
  toolTipText?: string[];
}

export const TextToggleButton = typedReactMemo(
  <T extends string>({
    className = "",
    design = "primary",
    options,
    selectedOption,
    onChange,
    toolTipText,
  }: Props<T>): JSX.Element => {
    const buttonGroupClassName = useMemo(() => {
      let classes = ["toggle-button"];
      if (design === "primary") {
        classes.push("lg");
      } else {
        classes.push("sm");
      }

      if (className) {
        classes.push(className);
      }

      return classes.join(" ");
    }, [className, design]);

    return (
      <ButtonGroup toggle className={buttonGroupClassName}>
        {options.map((option, i) => {
          let key: T;
          let label: string | undefined;
          if (typeof option === "string") {
            key = option;
            label = option;
          } else {
            ({ key } = option);
            label = option.label || option.key;
          }
          let active = selectedOption === key;

          if (design === "primary") {
            return (
              <ButtonFramework
                className={`toggle-item ${design} ${active ? "selected" : "unselected"}`}
                key={key}
                variant={ButtonFrameworkVariant.NO_ICON}
                size="lg"
                onClick={() => {
                  if (!active) {
                    onChange(key);
                  }
                }}
                tooltipText={toolTipText && toolTipText[i] ? toolTipText[i] : undefined}
              >
                {label}
              </ButtonFramework>
            );
          } else {
            return (
              <ButtonFramework
                className={`toggle-item ${design} ${active ? "selected" : "unselected"}`}
                key={key}
                variant={ButtonFrameworkVariant.NO_ICON}
                size="sm"
                onClick={() => {
                  if (!active) {
                    onChange(key);
                  }
                }}
              >
                {label}
              </ButtonFramework>
            );
          }
        })}
      </ButtonGroup>
    );
  }
);
