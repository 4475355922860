import React, { useMemo } from "react";

import * as R from "ramda";

import { StagedTagData } from "./utils";
import { OverlayTrigger, PieChart, Spinner, Tooltip } from "../../Components";
import { StateSetter } from "../../utils/types";
import { useCreativeMap } from "../../redux/creative";
import { Button } from "react-bootstrap";
import { MdDelete, MdEdit } from "react-icons/md";

import "./StagedTag.scss";

interface StagedTagProps {
  tag: StagedTagData;
  tagIndex: number;
  openTagCreationModal: any;
  setStagedTags: StateSetter<any[]>;
}

const StagedTag = ({
  tag,
  tagIndex,
  openTagCreationModal,
  setStagedTags,
}: StagedTagProps): JSX.Element => {
  const { company } = tag;
  const { creativeMap, colorMap } = useCreativeMap({
    company,
    mediaTypes: ["streaming", "audio"],
  });

  const rotationData = useMemo(() => {
    if (tag.creativeAllocationMap && creativeMap && R.keys(creativeMap).length) {
      return Object.keys(tag.creativeAllocationMap).map(isci => {
        const percentage = tag.creativeAllocationMap ? tag.creativeAllocationMap[isci] : null;
        const clickthroughUrl = tag.clickthroughUrlMap ? tag.clickthroughUrlMap[isci] : null;
        const audioCompanion = tag.audioCompanionMap ? tag.audioCompanionMap[isci]?.adid : null;
        return {
          name: isci,
          value: parseInt(percentage),
          color: colorMap[isci] || "white",
          creativeName: creativeMap[isci].name || "",
          clickthroughUrl,
          audioCompanion,
        };
      });
    }
  }, [colorMap, creativeMap, tag]);

  return (
    <div className="stagedTag">
      <div className="propertiesDiv">
        <div className="propertyDiv">
          <div className="propertyTitle">Name:</div>
          <div className="propertyValue">{tag.name}</div>
        </div>
        <div className="propertyDiv" hidden={!tag.vendor}>
          <div className="propertyTitle">Vendor:</div>
          <div className="propertyValue">{tag.vendor}</div>
        </div>
        <div className="propertyDiv" hidden={!tag.transcodeVendor}>
          <div className="propertyTitle">Transcode Vendor:</div>
          <div className="propertyValue">{tag.vendor}</div>
        </div>
        <div className="propertyDiv" hidden={!tag.adServer}>
          <div className="propertyTitle">Ad Server:</div>
          <div className="propertyValue">{tag.adServer}</div>
        </div>
        <div className="propertyDiv" hidden={!tag.clickthroughUrl}>
          <div className="propertyTitle">Placement-Level Clickthrough URL:</div>
          <div className="propertyValue">{tag.clickthroughUrl}</div>
        </div>
      </div>
      {rotationData ? (
        <div className="creativesDiv">
          {/* This is angry because we're calling a .jsx component from a .tsx one.  Ignore the error, it works fine! */}
          {/*// @ts-ignore */}
          <PieChart data={rotationData} size={100} />
          <div className="legend">
            {rotationData.map(
              ({ name, value, color, creativeName, clickthroughUrl, audioCompanion }) => (
                <OverlayTrigger
                  key={name}
                  placement={OverlayTrigger.PLACEMENTS.TOP.CENTER}
                  overlay={
                    !!clickthroughUrl || !!audioCompanion ? (
                      <Tooltip>
                        <div className="overlayMetadata">
                          {clickthroughUrl && (
                            <div>
                              <b>Clickthrough URL:</b> {clickthroughUrl}
                            </div>
                          )}
                          {audioCompanion && (
                            <div>
                              <b>Audio Companion:</b> {audioCompanion}
                            </div>
                          )}
                        </div>
                      </Tooltip>
                    ) : (
                      <div></div>
                    )
                  }
                >
                  <div className="swatchRow" key={name}>
                    <div className="swatch" style={{ backgroundColor: color }} />
                    <div className="swatchCreativeName">{creativeName}</div>
                    <small className="swatchIsciName">({name})</small>
                    <div className="swatchValue"> - {value}%</div>
                  </div>
                </OverlayTrigger>
              )
            )}
          </div>
        </div>
      ) : (
        <Spinner></Spinner>
      )}
      <div className="controlsDiv">
        <Button
          className="editButton"
          size="sm"
          variant="outline-primary"
          onClick={() => {
            openTagCreationModal(tag, true /*=isEditMode*/);
            setStagedTags(R.remove(tagIndex, 1));
          }}
        >
          <MdEdit />
        </Button>
        <Button
          size="sm"
          variant="outline-primary"
          onClick={() => {
            setStagedTags(R.remove(tagIndex, 1));
          }}
        >
          <MdDelete />
        </Button>
      </div>
    </div>
  );
};

export default StagedTag;
