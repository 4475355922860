import "./SegmentationLabelingTable.scss";
import React, { useState, useCallback, useMemo } from "react";
import * as R from "ramda";
import {
  CellRenderer,
  CheckBox,
  CornerLocation,
  FrozenColumnsTable,
  Header,
  SelfFocusingSelect,
} from "../Components";
import { CampaignRowEdit } from "@blisspointmedia/bpm-types/dist/CampaignLabelingTool";
import { StateSetter } from "../utils/types";
import { Md123 } from "react-icons/md";
import { useMap } from "../utils/hooks/useData";
import { CustomSegmentsData } from "./SegmentationMapping";
import { SegmentEditsMap, SegmentRow } from "./SegmentationLabeling";

interface SegmentationLabelingTableProps {
  data: any[];
  customSegments: CustomSegmentsData[] | undefined;
  editsMap: SegmentEditsMap;
  selectedRows: Record<string, SegmentRow>;
  setEditsMap: StateSetter<SegmentEditsMap>;
  setSelectedRows: StateSetter<Record<string, SegmentRow>>;
  dataGranularity: "ad" | "ad_group" | "campaign";
}

export interface SegmentRowEdit extends CampaignRowEdit {
  Channel?: { label: string; value: number };
  Platform?: { label: string; value: number };
}

const SegmentationLabelingTable: React.FC<SegmentationLabelingTableProps> = ({
  data,
  customSegments,
  editsMap,
  selectedRows,
  setEditsMap,
  setSelectedRows,
  dataGranularity,
}) => {
  const [selectAll, setSelectAll] = useState(false);
  const [focusedCell, setFocusedCell] = useState("");

  // Where does SegmentationLabelingTable get the values for each dropdown?
  const editRow = useCallback(
    (row: SegmentRow, edits: SegmentRowEdit, key: string, field?: string) => {
      setEditsMap(current => {
        const initialValues: SegmentRowEdit = {};
        if (customSegments) {
          for (const customSegment of customSegments) {
            if (row[customSegment.segmentName]) {
              const match = customSegment.values.find(
                val => val.valueName === row[customSegment.segmentName]
              );
              if (match) {
                initialValues[customSegment.segmentName] = {
                  label: row[customSegment.segmentName],
                  value: match.valueId,
                };
              }
            }
          }
        }

        // If there are no changes to the values, make sure the edits key is not present
        if (R.equals({ ...initialValues, ...edits }, initialValues)) {
          delete current[key];
          return { ...current };
        }

        let currentEdits: SegmentRowEdit | undefined = R.path([key, "edits"], current);
        if (field && !currentEdits) {
          // If field is included (deleting edits) and there are no current edits then we are doing nothing.
          return { ...current };
        } else if (field && currentEdits) {
          // If field is included then we are deleting the edit for that field.
          delete currentEdits[field];
          // If there are no more edits for the key then delete the key.
          if (R.isEmpty(currentEdits)) {
            delete current[key];
            return { ...current };
          }
          return {
            ...current,
            [key]: { ...row, edits: currentEdits },
          };
        } else {
          return {
            ...current,
            [key]: {
              ...row,
              edits: { ...initialValues, ...R.path([key, "edits"], current), ...edits },
            },
          };
        }
      });
    },
    [customSegments, setEditsMap]
  );

  const selectedRowsSet = useMemo(() => new Set(Object.keys(selectedRows)), [selectedRows]);

  const selectRow = useCallback(
    (row: SegmentRow) => {
      const { campaign_key } = row;
      if (R.has(campaign_key, selectedRows)) {
        setSelectedRows(current => R.omit([campaign_key], current));
      } else {
        setSelectedRows(current => ({ ...current, [campaign_key]: row }));
      }
    },
    [selectedRows, setSelectedRows]
  );

  const dataWithEdits = useMemo(() => {
    return data.map(row => {
      let rowToUse = row;
      if (editsMap[row.campaign_key]) {
        rowToUse = editsMap[row.campaign_key];
      }

      return rowToUse;
    });
  }, [editsMap, data]);

  const dataMap = useMemo(() => {
    let output = {};
    data.forEach(row => {
      output[row.campaign_key] = row;
    });
    return output;
  }, [data]);

  const [showAccountID, setShowAccountID] = useMap<string, boolean>({});
  const [showCampaignID, setShowCampaignID] = useMap<string, boolean>({});
  const [showAdGroupID, setShowAdGroupID] = useMap<string, boolean>({});
  const [showAdID, setShowAdID] = useMap<string, boolean>({});

  const rightTableHeaders: Header[] = useMemo(() => {
    return (customSegments || []).map(segment => {
      return {
        label: segment.segmentName,
        name: segment.segmentName,
        width: 200,
        renderer: (row: SegmentRow) => {
          if (row) {
            const rowToUse = editsMap[row.campaign_key] || row;
            const { campaign_key } = rowToUse;

            const val =
              (R.has(segment.segmentName, rowToUse.edits)
                ? (rowToUse.edits as SegmentRowEdit)[segment.segmentName].label
                : rowToUse[segment.segmentName]) ||
              // platform and channel will be lowercased from redshift
              rowToUse[segment.segmentName.toLowerCase()];

            return (
              <>
                {focusedCell === `${segment.segmentName}_${campaign_key}` ? (
                  <SelfFocusingSelect
                    menuPortalTarget={document.body}
                    className="selectContainer"
                    value={
                      val
                        ? {
                            label: val,
                            value: val,
                          }
                        : null
                    }
                    placeholder="Select label"
                    onChange={selection => {
                      if (selectedRowsSet.has(campaign_key)) {
                        selectedRowsSet.forEach(key => {
                          selection
                            ? editRow(selectedRows[key], { [segment.segmentName]: selection }, key)
                            : editRow(selectedRows[key], {}, key, segment.segmentName);
                        });
                      } else {
                        selection
                          ? editRow(rowToUse, { [segment.segmentName]: selection }, campaign_key)
                          : editRow(rowToUse, {}, campaign_key, segment.segmentName);
                      }
                    }}
                    onBlur={() => setFocusedCell("")}
                    options={segment.values.map(value => {
                      return { label: value.valueName, value: value.valueId };
                    })}
                    isClearable
                    defaultMenuIsOpen
                  ></SelfFocusingSelect>
                ) : (
                  <div
                    className="editableCell"
                    onClick={() => setFocusedCell(`${segment.segmentName}_${campaign_key}`)}
                  >
                    <span className="cellText">{val || "-"}</span>
                  </div>
                )}
              </>
            );
          } else {
            return <div></div>;
          }
        },
      };
    });
  }, [customSegments, editRow, editsMap, focusedCell, selectedRows, selectedRowsSet]);

  // Making this its own thing to prevent rerenders that happened when using rightTableHeaders.
  const rightHeaderNames = useMemo(() => {
    return (rightTableHeaders || []).map(header => header.name || "");
  }, [rightTableHeaders]);

  const customLeftRenderer: CellRenderer<any> = useMemo(
    () => ({ data, style = {}, classes = [] }) => {
      const {
        campaign_key,
        account_name,
        account_id,
        campaign_name,
        campaign_id,
        ad_group_name,
        ad_group_id,
        ad_name,
        ad_id,
      } = data;

      return (
        <div style={style} className={[...classes, "leftCell"].join(" ")}>
          <div className="checkBoxContainer">
            <CheckBox
              className="checkbox"
              checked={R.has(campaign_key, selectedRows)}
              onCheck={() => selectRow(dataMap[campaign_key])}
            />
          </div>
          <div className="leftCellDiv account">
            <span className="cellText">
              {showAccountID[campaign_key] ? account_id : account_name}
            </span>
            <div
              className="digitsIcon"
              onMouseEnter={() => setShowAccountID(campaign_key, true)}
              onMouseLeave={() => setShowAccountID(campaign_key, false)}
              onClick={() => navigator.clipboard.writeText(account_id)}
            >
              <Md123 />
            </div>
          </div>
          <div className="leftCellDiv campaign">
            <span className="cellText">
              {showCampaignID[campaign_key] ? campaign_id : campaign_name}
            </span>
            <div
              className="digitsIcon"
              onMouseEnter={() => setShowCampaignID(campaign_key, true)}
              onMouseLeave={() => setShowCampaignID(campaign_key, false)}
              onClick={() => navigator.clipboard.writeText(campaign_id)}
            >
              <Md123 />
            </div>
          </div>
          {(dataGranularity === "ad_group" || dataGranularity === "ad") && (
            <div className="leftCellDiv ad_group">
              <span className="cellText">
                {showAdGroupID[campaign_key] ? ad_group_id : ad_group_name}
              </span>
              <div
                className="digitsIcon"
                onMouseEnter={() => setShowAdGroupID(campaign_key, true)}
                onMouseLeave={() => setShowAdGroupID(campaign_key, false)}
                onClick={() => navigator.clipboard.writeText(ad_group_id)}
              >
                <Md123 />
              </div>
            </div>
          )}
          {dataGranularity === "ad" && (
            <div className="leftCellDiv ad">
              <span className="cellText">{showAdID[campaign_key] ? ad_id : ad_name}</span>
              <div
                className="digitsIcon"
                onMouseEnter={() => setShowAdID(campaign_key, true)}
                onMouseLeave={() => setShowAdID(campaign_key, false)}
                onClick={() => navigator.clipboard.writeText(ad_id)}
              >
                <Md123 />
              </div>
            </div>
          )}
        </div>
      );
    },
    [
      dataGranularity,
      dataMap,
      selectRow,
      selectedRows,
      setShowAccountID,
      setShowAdGroupID,
      setShowAdID,
      setShowCampaignID,
      showAccountID,
      showAdGroupID,
      showAdID,
      showCampaignID,
    ]
  );

  const [rightData, setRightData] = useState<any[][]>([]);

  const sortRightData = useCallback(
    (columnName: string, direction: string) => {
      let tempData = [...dataWithEdits];

      if (direction === "up") {
        tempData.sort((a, b) => {
          const valA = a[columnName] || "";
          const valB = b[columnName] || "";
          return valA.localeCompare(valB);
        });
      }
      if (direction === "down") {
        tempData.sort((a, b) => {
          const valA = a[columnName] || "";
          const valB = b[columnName] || "";
          return valB.localeCompare(valA);
        });
      }

      const originalIndexMap = {};
      for (let i = 0; i < dataWithEdits.length; i++) {
        originalIndexMap[dataWithEdits[i].campaign_key] = i;
      }

      let data: any[][] = [];
      for (let i = 0; i < tempData.length; ++i) {
        let obj = tempData[i];
        let row: any[] = [];
        for (let { name } of rightTableHeaders) {
          let content = obj[R.defaultTo("undefined", name)];
          row.push({
            originalRowIndex: originalIndexMap[obj.campaign_key],
            content: R.isNil(content) ? "" : content,
          });
        }
        data.push(row);
      }
      setRightData(data);
    },
    [dataWithEdits, rightTableHeaders]
  );

  const [sortMap, setSortMapValue, setSortMap] = useMap<string, string>({});

  const updateSortingMap = useMemo(() => {
    return { off: "up", up: "down", down: "off" };
  }, []);

  const customCornerRenderer = useMemo(
    () => (corner: CornerLocation) => {
      if (corner === "nw") {
        return (
          <div
            className="stickyTableCell"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <div className="checkBoxContainer">
              <CheckBox
                checked={selectAll}
                onCheck={() => {
                  if (selectAll) {
                    setSelectedRows({});
                  } else {
                    let selectAllMap = {};
                    if (data) {
                      for (let row of data) {
                        selectAllMap[row.campaign_key] = row;
                      }
                    }

                    setSelectedRows(current => ({ ...current, ...selectAllMap }));
                  }

                  setSelectAll(!selectAll);
                }}
              />
            </div>
            <div
              className={`includeSortHeaderCaret account ${sortMap.account_name}`}
              onClick={() => {
                setSortMap({});
                setSortMapValue("account_name", updateSortingMap[sortMap.account_name || "off"]);
                sortRightData("account_name", updateSortingMap[sortMap.account_name || "off"]);
              }}
            >
              Account Name
            </div>
            <div
              className={`includeSortHeaderCaret campaign ${sortMap.campaign_name}`}
              onClick={() => {
                setSortMap({});
                setSortMapValue("campaign_name", updateSortingMap[sortMap.campaign_name || "off"]);
                sortRightData("campaign_name", updateSortingMap[sortMap.campaign_name || "off"]);
              }}
            >
              Campaign Name
            </div>
            {(dataGranularity === "ad_group" || dataGranularity === "ad") && (
              <div
                className={`includeSortHeaderCaret ad_group ${sortMap.ad_group_name}`}
                onClick={() => {
                  setSortMap({});
                  setSortMapValue(
                    "ad_group_name",
                    updateSortingMap[sortMap.ad_group_name || "off"]
                  );
                  sortRightData("ad_group_name", updateSortingMap[sortMap.ad_group_name || "off"]);
                }}
              >
                Ad Group Name
              </div>
            )}
            {dataGranularity === "ad" && (
              <div
                className={`includeSortHeaderCaret ad ${sortMap.ad_name}`}
                onClick={() => {
                  setSortMap({});
                  setSortMapValue("ad_name", updateSortingMap[sortMap.ad_name || "off"]);
                  sortRightData("ad_name", updateSortingMap[sortMap.ad_name || "off"]);
                }}
              >
                Ad Name
              </div>
            )}
          </div>
        );
      } else {
        return <div />;
      }
    },
    [
      selectAll,
      sortMap.account_name,
      sortMap.campaign_name,
      sortMap.ad_group_name,
      sortMap.ad_name,
      dataGranularity,
      setSelectedRows,
      data,
      setSortMap,
      setSortMapValue,
      updateSortingMap,
      sortRightData,
    ]
  );

  const leftDataFields = useMemo(() => {
    let leftFields = ["campaign_key", "account_name", "account_id", "campaign_name", "campaign_id"];
    if (dataGranularity === "ad_group") {
      leftFields = leftFields.concat(["ad_group_name", "ad_group_id"]);
    }
    if (dataGranularity === "ad") {
      leftFields = leftFields.concat(["ad_group_name", "ad_group_id", "ad_name", "ad_id"]);
    }

    return leftFields;
  }, [dataGranularity]);

  const noRowsRenderer = useCallback(() => {
    return data.length === 0 ? <div className="noRows">No rows to display.</div> : <div />;
  }, [data.length]);

  return (
    <div className="segmentationLabelingTable">
      <FrozenColumnsTable
        leftWidth={850}
        leftRenderer={customLeftRenderer}
        cornerRenderer={customCornerRenderer}
        rightTableHeaders={rightTableHeaders}
        rightTableHeaderNames={rightHeaderNames}
        originalRightData={data}
        leftDataFields={leftDataFields}
        rightData={rightData}
        setRightData={setRightData}
        noRowsRenderer={noRowsRenderer}
      />
    </div>
  );
};

export default SegmentationLabelingTable;
