import React, { useState, useEffect, useCallback, useMemo } from "react";
import * as R from "ramda";
import { useSetError } from "../redux/modals";
import { AdminLambdaFetch, UsersLambdaFetch, awaitJSON } from "../utils/fetch-utils";
import {
  Page,
  Skeleton,
  TableSkeleton,
  ModalEditTable,
  SelectorOption,
  Header,
  PendingChangesPanel,
  PendingChangesControls,
} from "../Components";
import "./UserAdmin.scss";
import {
  User,
  GetUserAdminResponse,
  UpdateUserAdminParams,
} from "@blisspointmedia/bpm-types/dist/UserAdmin";
import { useSelector } from "react-redux";
import * as UserRedux from "../redux/user";
export const roleMap = {
  0: "Admin",
  1: "BPM/PPM User",
  2: "Tinuiti User",
  3: "NOC",
  4: "Tinuiti Client Demo",
  10: "External User",
};

const UserAdmin: React.FC = () => {
  const setError = useSetError();
  const [deletedRows, setDeletedRows] = useState<User[]>([]);
  const [tableData, setTableData] = useState<User[]>();
  const [saving, setSaving] = useState(false);
  const [userData, setUserData] = useState<GetUserAdminResponse>();
  const [originalUserData, setOriginalUserData] = useState<Record<string, User>>();
  const [newUsers, setNewUsers] = useState<User[]>([]);
  const [updatedUsers, setUpdatedUsers] = useState<User[]>([]);
  const [deletedUsers, setDeletedUsers] = useState<User[]>([]);
  const [showPendingChanges, setShowPendingChanges] = useState<boolean>(false);
  const [userModalInputErrorMessage, setUserModalInputErrorMessage] = useState<string>("");
  const role = useSelector(UserRedux.roleSelector);

  const validateModalUserInput: (user) => boolean = user => {
    let convertedUser = { ...user };
    for (let key of R.keys(roleMap)) {
      if (roleMap[key] === convertedUser.role) {
        convertedUser.role = key;
      }
    }

    let roleElevationCheck = convertedUser.role >= role;

    if (!roleElevationCheck) {
      setUserModalInputErrorMessage(
        "Invalid Input: You do not have permissions to grant or modify users with privileges greater than your own"
      );
    }

    if (!convertedUser.companies || convertedUser.companies.length === 0) {
      setUserModalInputErrorMessage(
        "Invalid Input: You need to assign a user to at least one company. If you want to assign a user to all companies, select 'All Companies' from the dropdown."
      );
    }

    return roleElevationCheck;
  };

  const getFreshUserData = useCallback(async () => {
    try {
      let res = await UsersLambdaFetch("/userAdmin/getUsers");
      let userDataLambdaResult = await awaitJSON<GetUserAdminResponse>(res);
      for (let user of userDataLambdaResult.users) {
        user.role = roleMap[user.role];
      }
      let userData: User[] = userDataLambdaResult.users;
      let originalUserData: Record<string, User> = {};
      for (let user of userData) {
        originalUserData[user.id] = user;
      }
      setOriginalUserData(originalUserData);
      setTableData(userData);
      setUserData(userDataLambdaResult);
      setDeletedRows([]);
    } catch (e) {
      let error: Error = e as Error;
      setError({
        message: `Failed to get user admin data ${error.message}`,
        reportError: error,
      });
    }
  }, [setError]);

  const clearAllChanges = useCallback(() => {
    setTableData(userData?.users);
    setDeletedRows([]);
    setNewUsers([]);
    setUpdatedUsers([]);
    setDeletedUsers([]);
  }, [userData]);

  useEffect(() => {
    if (!navigator.cookieEnabled) {
      let error: Error = {
        name: "500",
        message: "You need to have cookies enabled to use this page!",
      };
      setError({
        message: error.message,
        reportError: error,
      });
    }

    if (!tableData) {
      (async () => {
        await getFreshUserData();
      })();
    }
  }, [setError, tableData, getFreshUserData]);

  const selectorOptions: Record<string, SelectorOption[]> = useMemo(() => {
    if (userData) {
      return {
        role: R.defaultTo(
          [],
          R.map(e => ({ label: e, value: e }), Object.values(roleMap))
        ),
        company: R.concat(
          [{ label: "All Companies", value: "all" }],
          R.defaultTo(
            [],
            R.map(e => ({ label: e.cid, value: e.cid }), userData.companies)
          )
        ),
      } as Record<string, SelectorOption[]>;
    } else {
      return {};
    }
  }, [userData]);

  useEffect(() => {
    if (tableData && originalUserData) {
      let newUpdatedUsers = R.filter(row => {
        if (originalUserData[row.id]) {
          return (
            (row.email !== originalUserData[row.id].email ||
              row.companies !== originalUserData[row.id].companies ||
              row.role !== originalUserData[row.id].role ||
              row.send_linear_buys !== originalUserData[row.id].send_linear_buys ||
              row.full_name !== originalUserData[row.id].full_name) &&
            row.id !== null
          );
        }
        return false;
      }, tableData);
      setUpdatedUsers(newUpdatedUsers);

      let newNewUsers = R.filter(row => {
        if (row.id) {
          // If we have a new user, they won't have a id field
          return false;
        }
        return true;
      }, tableData);
      setNewUsers(newNewUsers);

      let newDeletedUsers = R.filter(row => {
        return !!row.id;
      }, deletedRows);
      setDeletedUsers(newDeletedUsers);
    }
  }, [tableData, originalUserData, deletedRows]);

  const hasPendingChanges: boolean = useMemo(() => {
    const hasPendingChangesResult =
      !!newUsers.length || !!updatedUsers.length || !!deletedUsers.length;

    if (!hasPendingChangesResult) {
      // if there are no pending changes force the pending pane to
      // close
      setShowPendingChanges(false);
    }

    return hasPendingChangesResult;
  }, [deletedUsers, newUsers, updatedUsers]);

  const cleanUpUser = (users: User[]) => {
    return R.map(user => {
      let convertedUser = { ...user };
      for (let key of R.keys(roleMap)) {
        if (roleMap[key] === convertedUser.role) {
          convertedUser.role = key;
        }
      }
      if (convertedUser.companies && convertedUser.companies.includes("all")) {
        convertedUser.companies = ["all"];
      }
      return convertedUser;
    }, users);
  };

  const save = useCallback(async () => {
    try {
      const convertedNewUsers: User[] = cleanUpUser(newUsers);
      const convertedUpdatedUsers: User[] = cleanUpUser(updatedUsers);
      const convertedDeletedUsers: User[] = cleanUpUser(deletedUsers);
      setSaving(true);
      await UsersLambdaFetch<UpdateUserAdminParams>("/userAdmin/updateUsers", {
        method: "POST",
        body: {
          insert: convertedNewUsers,
          update: convertedUpdatedUsers,
          delete: convertedDeletedUsers,
        },
      });
      await getFreshUserData();
      for (const key of ["users", "getUsers", "getApprovedEmails"]) {
        await AdminLambdaFetch("/clearRedis", {
          method: "DELETE",
          params: {
            key,
          },
        });
      }
      setSaving(false);
    } catch (e) {
      let error: Error = e as Error;
      setSaving(false);
      let userMessage = `Failed to set user access data: ${error.message}`;
      if (
        error.message ===
        'duplicate key value violates unique constraint "expected_bookings_week_company_media_key".'
      ) {
        userMessage = `${userMessage} At least some of the bookings you entered are already in the database (week, company, mediatype)`;
      }
      setError({
        message: userMessage,
        reportError: error,
      });
    }
  }, [newUsers, updatedUsers, deletedUsers, getFreshUserData, setError]);

  const resendInvitation = useCallback(
    async data => {
      if (data.email) {
        let res = await UsersLambdaFetch("/resendInvitation", {
          method: "POST",
          body: {
            email: data.email,
          },
        });
        if (res.status === 200) {
          setError({
            title: "Success",
            variant: "success",
            message: "Invitation was resent!",
          });
        }
      } else {
        let error = new Error("Do not have a valid email to resend invitation to.");
        setError({
          message: error.message,
          reportError: error,
        });
      }
    },
    [setError]
  );

  const resendInvitationButton = {
    label: "Resend Invitation",
    variant: "primary",
    onClick: resendInvitation,
  };

  const userAdminHeader = useMemo(() => {
    const headers = [
      {
        label: "Email",
        field: "email",
        type: "text",
        flex: 3,
        modalRow: 0,
        modalFlex: 1,
      },
      {
        label: "Companies",
        field: "companies",
        type: "select",
        isMulti: true,
        options: "company",
        flex: 2,
        modalRow: 0,
        modalFlex: 1,
        renderer: value => {
          const { companies } = value;
          return <div className="companiesList">{companies?.join(", ") || "-"}</div>;
        },
      },
      {
        label: "Agencies",
        field: "agencies",
        type: "select",
        isMulti: true,
        options: "agency",
        flex: 1,
        modalRow: 1,
        modalFlex: 1,
        renderer: value => {
          const { agencies } = value;
          return <div className="agenciesList">{R.defaultTo([], agencies).join(", ") || "-"}</div>;
        },
        uneditable: true,
      },
      {
        label: "Role",
        field: "role",
        type: "select",
        options: "role",
        flex: 1,
        modalRow: 1,
        modalFlex: 1,
        infoTextParagraphs: [
          "User Roles:",
          "Admin - Can access all pages",
          "BPM/PPM - Can access all BPM or PPM Pages",
          "External User - Only able to access pages specific to their client",
        ],
      },
      {
        label: "Send Linear Buys?",
        field: "send_linear_buys",
        type: "checkbox",
        flex: 1,
        modalRow: 1,
        modalFlex: 1,
      },
      {
        label: "Full Name",
        field: "full_name",
        type: "text",
        flex: 2,
        modalRow: 1,
        modalFlex: 1,
      },
    ];

    return R.filter(elem => {
      const { field } = elem as Header;
      return role === 0 || (field !== "send_linear_buys" && field !== "full_name");
    }, headers);
  }, [role]);

  return (
    <Page
      title="User Admin"
      pageType="User Admin"
      minHeight="600px"
      actions={
        <PendingChangesControls
          hasPendingChanges={hasPendingChanges}
          setShowPendingChanges={setShowPendingChanges}
          saveChanges={save}
          isSaving={saving}
          clearAllChanges={clearAllChanges}
        />
      }
    >
      <div className="userAdminPageContainer">
        {tableData ? (
          <ModalEditTable<Omit<User, "role"> & { role: number | string }>
            className="userAdminTable"
            headers={userAdminHeader}
            tableData={tableData}
            setTableData={setTableData}
            selectorOptions={selectorOptions}
            filterBar
            deletedRows={deletedRows}
            showModalOnAdd={true}
            setDeletedRows={setDeletedRows}
            checkIsValid={user => validateModalUserInput(user)}
            invalidText={userModalInputErrorMessage}
            defaultNewRow={{ role: "External User" }}
            customFooterButtons={[resendInvitationButton]}
          />
        ) : (
          <Skeleton>
            <TableSkeleton />
          </Skeleton>
        )}
        {showPendingChanges && (
          <PendingChangesPanel
            originalData={originalUserData || {}}
            pendingChanges={{
              editedRows: updatedUsers,
              newRows: newUsers,
              deletedRows: deletedUsers,
            }}
            showPendingChanges={showPendingChanges}
            setShowPendingChanges={setShowPendingChanges}
            headers={userAdminHeader}
          />
        )}
      </div>
    </Page>
  );
};

export default UserAdmin;
