import "./KpiVolume.scss";
import { useState, useMemo, useCallback, useContext, useEffect } from "react";
import { CrossChannelContext } from "../CrossChannel";
import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import { MdOutlineTableRows, MdShowChart, MdArrowForwardIos } from "react-icons/md";
import AutoSizer from "react-virtualized-auto-sizer";
import { XAxis, YAxis, Tooltip, CartesianGrid, Area, Line, ComposedChart } from "recharts";
import {
  Dropdown,
  DownloadDropdown,
  DropdownToggleType,
  IconToggleButton,
  BPMTable,
  ToggleSwitch,
} from "../../Components";
import ChartContainer from "../../Components/ChartContainer";
import {
  CARTESIAN_GRID_STROKE,
  CARTESIAN_GRID_STROKE_WIDTH,
  TICK_STYLE,
  DATE_GROUPING_OPTIONS,
  DateGroupingKey,
  INACTIVE_COLOR,
} from "../../TVADCrossChannel/homePageConstants";
import { abbreviateNumber } from "../../utils/data";
import { Brand60, Brand30, Brand20, Brand10 } from "../../utils/colors";
import {
  formatDateLabel,
  getGroupingValue,
  metricFormatter,
  PERCENTAGE_FORMATTER,
  VOLUME_FORMATTER,
} from "../../TVADCrossChannel/homePageUtils";
import { KpiData, KpiPreset } from "../crossChannelUtils";
import { exportToExcel, downloadPNG } from "../../utils/download-utils";
import { StateSetter } from "../../utils/types";
import ChartTooltipV2 from "../../Components/Charts/ChartTooltip/ChartTooltipV2";

const X_AXIS_DATE_FORMAT = "M/dd/yy";

const AREA_COLORS = [Brand60, Brand30, Brand20, Brand10];

enum KpiView {
  VOLUME = "volume",
  CONVERSION_RATE = "conversionRate",
}

const METRIC_OPTIONS = [
  { label: "KPI Volume", value: KpiView.VOLUME },
  { label: "KPI Conversion Rate", value: KpiView.CONVERSION_RATE },
] as const;

export enum Filtering {
  DIRECT = "direct",
  PAID = "paid",
  ALL = "all",
}

export const FILTERING_OPTIONS = [
  { label: "Direct Traffic", value: Filtering.DIRECT },
  { label: "Paid Traffic", value: Filtering.PAID },
  { label: "All Traffic", value: Filtering.ALL },
] as const;

interface KpiVolumeProps {
  data: KpiData[];
  funnelPresets: KpiPreset[];
  kpiLabels: Record<string, string>;
  showMarketingInputs: boolean;
  setShowMarketingInputs: StateSetter<boolean>;
  defaultFiltering?: Filtering;
  defaultFunnel?: string;
  selectedChannels: Record<string, boolean>;
  footer?: string | React.ReactNode;
}

const getDataByDateGrouping = (
  data: KpiData[],
  kpis: string[],
  dateGrouping: string,
  filtering: string,
  metric: KpiView,
  logScale: boolean,
  focusedKpi: string,
  kpiConversionRateLabels: Record<string, string[]>,
  selectedChannels: Record<string, boolean>
) => {
  let totalsByDate: Record<string, Record<string, number>> = {};
  for (let item of data) {
    const { date, kpi, count, isOrganic, channel } = item;
    // todo: remove empty string option once data is cleaned
    if (channel === "" || selectedChannels[channel]) {
      if (
        (filtering === Filtering.DIRECT && !isOrganic) ||
        (filtering === Filtering.PAID && isOrganic)
      ) {
        continue;
      }

      if (focusedKpi && kpi !== focusedKpi && metric === KpiView.VOLUME) {
        continue;
      }

      const dateToUse = getGroupingValue(date, dateGrouping as DateGroupingKey);

      totalsByDate = R.mergeDeepRight(totalsByDate, {
        [dateToUse]: {
          [kpi]: R.pathOr(0, [dateToUse, kpi], totalsByDate) + count,
        },
      });
    }
  }

  if (metric === KpiView.CONVERSION_RATE) {
    const rows = Object.entries(totalsByDate)
      .map(([date, totals]) => {
        let conversionRates: Record<string, number> = {};
        for (let [label, kpis] of Object.entries(kpiConversionRateLabels)) {
          const numerator = totals[kpis[1]];
          const denominator = totals[kpis[0]];
          conversionRates[label] = numerator / denominator;
        }
        return { date, ...conversionRates };
      })
      .sort((a, b) => a.date.localeCompare(b.date));

    return { rows, kpisToUse: R.keys(kpiConversionRateLabels) };
  }

  const rows = Object.entries(totalsByDate)
    .map(([date, totals]) => {
      if (logScale) {
        const logValues = R.keys(totals).reduce(
          (prev, curr) => ({ ...prev, [curr]: Math.log10(totals[curr] || 0) }),
          {}
        );
        return { date, ...logValues };
      } else {
        return { date, ...totals };
      }
    })
    .sort((a, b) => a.date.localeCompare(b.date));

  return { rows, kpisToUse: kpis };
};

const KpiVolume: React.FC<KpiVolumeProps> = ({
  data,
  funnelPresets,
  kpiLabels,
  showMarketingInputs,
  setShowMarketingInputs,
  defaultFiltering = Filtering.ALL,
  defaultFunnel = "",
  selectedChannels,
  footer,
}) => {
  const [dateGrouping, setDateGrouping] = useState(DATE_GROUPING_OPTIONS[0].value);
  const [metric, setMetric] = useState<KpiView>(KpiView.VOLUME);
  const [filtering, setFiltering] = useState(defaultFiltering);
  const [graphView, setGraphView] = useState(true);
  const [logScale, setLogScale] = useState(false);
  const [focusedKpi, setFocusedKpi] = useState("");
  const [hoverKpi, setHoverKpi] = useState("");
  const [preset, setPreset] = useState(defaultFunnel);

  const { company, kpi, dates } = useContext(CrossChannelContext);

  const presetMap = useMemo(() => R.indexBy(R.prop("name"), funnelPresets), [funnelPresets]);
  const kpis = useMemo(() => presetMap[preset]?.kpis || [], [preset, presetMap]);

  useEffect(() => {
    if (defaultFunnel === "") {
      const defaultPreset = R.find(row => row.is_default, funnelPresets);
      setPreset(defaultPreset?.name || funnelPresets[0]?.name || "");
    }
  }, [funnelPresets, defaultFunnel]);

  const presetOptions = useMemo(
    () => funnelPresets.map(preset => ({ label: preset.name, value: preset.name })),
    [funnelPresets]
  );

  // Make new labels, i.e. "Visits -> Cart Adds", "Cart Adds -> Orders"
  const kpiConversionRateLabels = useMemo(() => {
    let labelToKpis: Record<string, string[]> = {};
    const presetData = presetMap[preset] || {};
    const kpis = presetData.kpis || [];
    for (let i = 0; i < kpis.length - 1; i++) {
      const firstKpi = kpis[i];
      const secondKpi = kpis[i + 1];
      const firstLabel = kpiLabels[firstKpi] || firstKpi;
      const secondLabel = kpiLabels[secondKpi] || secondKpi;
      const label = `${firstLabel} \u2192 ${secondLabel}`;
      labelToKpis[label] = [firstKpi, secondKpi];
    }
    return labelToKpis;
  }, [preset, presetMap, kpiLabels]);

  // kpisToUse is either the KPIs from the preset or the conversion rate labels, depending on what view you have toggled.
  const { rows, kpisToUse } = useMemo(
    () =>
      getDataByDateGrouping(
        data,
        kpis,
        dateGrouping,
        filtering,
        metric,
        logScale,
        focusedKpi,
        kpiConversionRateLabels,
        selectedChannels
      ),
    [
      data,
      kpis,
      dateGrouping,
      filtering,
      metric,
      logScale,
      focusedKpi,
      kpiConversionRateLabels,
      selectedChannels,
    ]
  );

  const excelDownload = useCallback(() => {
    exportToExcel(data, `${company}_kpi_volume`);
  }, [data, company]);

  const pngDownload = useCallback(async () => {
    const label = `KPI: ${kpi}`;
    const date =
      DATE_GROUPING_OPTIONS.find(item => item.value === dateGrouping)?.label || dateGrouping;
    const prettyNameMetric = METRIC_OPTIONS.find(item => item.value === metric)?.label || metric;
    const prettyFilterName =
      FILTERING_OPTIONS.find(item => item.value === filtering)?.label || filtering;
    const fileNameIdentifiers = [
      "CrossChannel",
      date,
      prettyNameMetric,
      preset || "",
      prettyFilterName,
      `${dates.start}–${dates.end}`,
    ];
    await downloadPNG(".kpiVolumeContainer", fileNameIdentifiers, label, [
      ".cl-download",
      ".icon-toggle-button",
      ".showInputsToggle",
    ]);
  }, [kpi, dateGrouping, metric, preset, filtering, dates]);

  const headers = useMemo(() => {
    let headers: any[] = [
      {
        label: "Date",
        name: "date",
        width: 60,
        nonInteractive: true,
        renderer: (item: any) => formatDateLabel(item.date, "", true) || "-",
      },
    ];

    kpisToUse.forEach(kpi => {
      headers.push({
        label: kpiLabels[kpi] || kpi,
        name: kpi,
        flex: 1,
        renderer: (item: any) =>
          metricFormatter(metric === KpiView.VOLUME ? "volume" : "percentage")(item[kpi] || 0),
      });
    });

    return headers;
  }, [kpisToUse, kpiLabels, metric]);

  const totals = useMemo(() => {
    let totals: any = {
      date: "",
    };
    kpisToUse.forEach(kpi => {
      let value;
      if (metric === KpiView.VOLUME) {
        value = R.sum(rows.map(row => row[kpi])) || 0;
      } else if (metric === KpiView.CONVERSION_RATE) {
        value = R.mean(rows.map(row => row[kpi])) || 0;
      }
      totals[kpi] = metricFormatter(metric === KpiView.VOLUME ? "volume" : "percentage")(value);
    });
    return totals;
  }, [kpisToUse, rows, metric]);

  const handleKpiChange = useCallback(
    kpi => setFocusedKpi(current => (current === kpi ? "" : kpi)),
    []
  );

  return (
    <div className="kpiVolumeContainer">
      <ChartContainer
        enableHoverDesign
        title={
          <>
            <Dropdown
              type={DropdownToggleType.WIDGET_TITLE}
              value={dateGrouping}
              options={DATE_GROUPING_OPTIONS}
              onChange={option => setDateGrouping(option as DateGroupingKey)}
            />
            <Dropdown
              type={DropdownToggleType.WIDGET_TITLE}
              value={metric}
              options={METRIC_OPTIONS}
              onChange={option => {
                setMetric(option);
                setFocusedKpi("");
              }}
            />
          </>
        }
        rightActions={
          <>
            {metric === KpiView.VOLUME && (
              <ToggleSwitch label="Log Scale" checked={logScale} onChange={setLogScale} />
            )}
            <Dropdown
              size="sm"
              type={DropdownToggleType.FILLED}
              design="secondary"
              value={preset || ""}
              options={presetOptions}
              onChange={name => setPreset(name)}
            />
            <Dropdown
              size="sm"
              type={DropdownToggleType.FILLED}
              design="secondary"
              value={filtering}
              options={FILTERING_OPTIONS}
              onChange={option => setFiltering(option)}
            />
            <IconToggleButton
              size="sm"
              options={[
                { key: "showTable", icon: <MdOutlineTableRows />, label: "table view" },
                { key: "showGraph", icon: <MdShowChart />, label: "graph view" },
              ]}
              selectedOption={graphView ? "showGraph" : "showTable"}
              onChange={() => setGraphView(prev => !prev)}
            />
            <DownloadDropdown
              size="sm"
              onClickOptions={[excelDownload, pngDownload]}
              disabledMenuOptions={
                graphView ? { XLSX: false, PNG: false } : { XLSX: false, PNG: true }
              }
              disabled={!data}
            />
          </>
        }
        footerContent={footer}
      >
        {R.isEmpty(rows) ? (
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              fontSize: "26px",
            }}
          >
            No KPI data to show
          </div>
        ) : graphView ? (
          <div className="kpiVolumeGraphContents">
            <div id="kpiVolumeGraph" className="kpiVolumeGraph">
              <AutoSizer>
                {({ width, height }) => (
                  <ComposedChart
                    width={width}
                    height={height}
                    data={rows}
                    margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                  >
                    <CartesianGrid
                      vertical={false}
                      stroke={CARTESIAN_GRID_STROKE}
                      strokeWidth={CARTESIAN_GRID_STROKE_WIDTH}
                    />
                    {kpisToUse.map((kpi, i) => {
                      if (metric === KpiView.CONVERSION_RATE) {
                        return (
                          <Line
                            strokeWidth={3}
                            dataKey={kpi}
                            dot={false}
                            isAnimationActive={false}
                            key={kpi}
                            stroke={
                              hoverKpi === "" || hoverKpi === kpi ? AREA_COLORS[i] : INACTIVE_COLOR
                            }
                            type="monotone"
                          />
                        );
                      } else {
                        return (
                          <Area
                            type="monotone"
                            dataKey={kpi}
                            stackId="1"
                            key={i}
                            activeDot={false}
                            fillOpacity={1}
                            strokeWidth={0}
                            fill={
                              hoverKpi === "" || hoverKpi === kpi ? AREA_COLORS[i] : INACTIVE_COLOR
                            }
                            isAnimationActive={false}
                            stroke={AREA_COLORS[i]}
                          />
                        );
                      }
                    })}
                    <XAxis
                      dataKey="date"
                      tick={TICK_STYLE}
                      tickFormatter={date => {
                        const parsed = Dfns.parseISO(date);
                        const formatted = Dfns.format(X_AXIS_DATE_FORMAT, parsed);
                        return formatted;
                      }}
                      interval="equidistantPreserveStart"
                    />
                    <YAxis
                      tickLine={false}
                      tick={TICK_STYLE}
                      axisLine={false}
                      tickFormatter={number =>
                        metric === KpiView.VOLUME
                          ? `${abbreviateNumber(number)}`
                          : `${Math.round(number * 100).toFixed(0)}%`
                      }
                    />
                    <Tooltip
                      content={({ label, payload }) => {
                        const valueFormatter =
                          metric === KpiView.VOLUME ? VOLUME_FORMATTER : PERCENTAGE_FORMATTER;
                        const formattedHeaderLabel = formatDateLabel(label, dateGrouping, true);
                        let items = R.sortBy(
                          (item: any) => -Math.abs(item.value),
                          payload?.map(item => {
                            let { name, value, stroke } = item;
                            name = R.defaultTo("", name);
                            value = R.defaultTo(0, value as number);
                            stroke = R.defaultTo("", stroke);
                            return {
                              label: kpiLabels[name] || name,
                              // If log scale, convert back to actual number for tooltip
                              value: logScale
                                ? VOLUME_FORMATTER(Math.round(10 ** value))
                                : valueFormatter(value),
                              color: stroke,
                            };
                          }) || []
                        );

                        const totalsFunction = metric === KpiView.VOLUME ? R.sum : R.mean;
                        const totals = valueFormatter(
                          totalsFunction(payload?.map(item => item.value as number) || [])
                        );

                        const footerLabel = metric === KpiView.VOLUME ? "Total:" : "Avg:";

                        return (
                          <ChartTooltipV2
                            headers={[formattedHeaderLabel]}
                            items={items}
                            footerLabels={focusedKpi === "" ? [footerLabel] : undefined}
                            footerValues={focusedKpi === "" ? [totals] : undefined}
                            itemKeyShape="square"
                          />
                        );
                      }}
                      isAnimationActive={false}
                    />
                  </ComposedChart>
                )}
              </AutoSizer>
            </div>
            <div className="rightOfChart">
              <div className="kpiVolumeLegend">
                {kpisToUse.map((kpi, i) => {
                  const resolvedColor =
                    focusedKpi === ""
                      ? hoverKpi === "" || hoverKpi === kpi
                        ? AREA_COLORS[i]
                        : INACTIVE_COLOR
                      : focusedKpi === kpi
                      ? AREA_COLORS[i]
                      : INACTIVE_COLOR;
                  return (
                    <div
                      className="legendItem"
                      key={kpi}
                      onClick={() => {
                        handleKpiChange(kpi);
                        setHoverKpi("");
                      }}
                      onMouseEnter={() => {
                        if (focusedKpi === "") {
                          setHoverKpi(kpi);
                        }
                      }}
                      onMouseLeave={() => {
                        if (focusedKpi === "") {
                          setHoverKpi("");
                        }
                      }}
                    >
                      <div
                        className="square"
                        style={{
                          backgroundColor: resolvedColor,
                        }}
                      />
                      <div
                        className={`label ${focusedKpi && focusedKpi !== kpi ? "unselected" : ""}`}
                      >
                        {kpiLabels[kpi] || kpi}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div
                className="showInputsToggle"
                onClick={() => setShowMarketingInputs(curr => !curr)}
              >
                <span className="text">{showMarketingInputs ? "Hide" : "Show"} inputs</span>
                <span>
                  <MdArrowForwardIos
                    className={`dropdownArrow ${showMarketingInputs ? "up" : "down"}`}
                  />
                </span>
              </div>
            </div>
          </div>
        ) : (
          <BPMTable
            headers={headers}
            data={rows}
            totals={totals}
            filterBar={false}
            headerHeight={30}
            bottomRowHeight={30}
          />
        )}
      </ChartContainer>
    </div>
  );
};

export default KpiVolume;
