import React, { useCallback, useMemo } from "react";
import "./ButtonFramework.scss";
import { OverlayTrigger, Placement } from "../OverlayTrigger";
import { Tooltip } from "react-bootstrap";

export enum ButtonFrameworkVariant {
  NO_ICON = "bf-no-icon",
  LEADING_ICON = "bf-leading-icon",
  TRAILING_ICON = "bf-trailing-icon",
  ICON_ONLY = "bf-icon-only",
}

export interface ButtonFrameworkProps {
  className?: string;
  variant: ButtonFrameworkVariant;
  size?: "lg" | "sm";
  icon?: React.ReactElement;
  tooltipText?: string;
  tooltipPlacement?: Placement;
  [passthroughProp: string]: any;
}

export const ButtonFramework: React.FC<ButtonFrameworkProps> = React.memo(
  ({
    className,
    variant,
    size = "lg",
    icon,
    tooltipText,
    tooltipPlacement = "bottom center",
    children,
    ...passthrough
  }) => {
    const resolvedClassName: string = useMemo(() => {
      return ["button-framework", variant, size, className].join(" ");
    }, [className, size, variant]);

    const resolvedOutput = useCallback(
      children => {
        return tooltipText ? (
          <OverlayTrigger
            placement={tooltipPlacement}
            overlay={<Tooltip id={`id:${tooltipText}`}>{tooltipText}</Tooltip>}
          >
            {children}
          </OverlayTrigger>
        ) : (
          children
        );
      },
      [tooltipPlacement, tooltipText]
    );

    if (icon) {
      if (variant === ButtonFrameworkVariant.LEADING_ICON) {
        return resolvedOutput(
          <button className={resolvedClassName} {...passthrough}>
            <div className="bf-icon">{icon}</div>
            <div className="bf-label">{children}</div>
          </button>
        );
      } else if (variant === ButtonFrameworkVariant.TRAILING_ICON) {
        return resolvedOutput(
          <button className={resolvedClassName} {...passthrough}>
            <div className="bf-label">{children}</div>
            <div className="bf-icon">{icon}</div>
          </button>
        );
      } else {
        return resolvedOutput(
          <button className={resolvedClassName} {...passthrough}>
            <div className="bf-icon">{icon}</div>
          </button>
        );
      }
    } else {
      return resolvedOutput(
        <button className={resolvedClassName} {...passthrough}>
          <div className="bf-label">{children}</div>
        </button>
      );
    }
  }
);
