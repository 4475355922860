import "./InputTable.scss";
import React, { useMemo, useState } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { BPMTable, Button, ButtonType } from "../../Components";
import { Header } from "../../Components/StickyTable/BPMTable";
import TableInputField from "./TableInputField";
import { EntryEditsMap, buildBlankEntries } from "./OfflineInputsUtils";
import { DeleteConfirmationDialog } from "./OfflineInputsDialogs";
import {
  OfflineInputsMediaEntry,
  OfflineInputsMediaTable,
} from "@blisspointmedia/bpm-types/dist/OfflineInputs";
import { format, isBefore } from "date-fns";
import { SetError } from "../../redux/modals";

const buildInputHeader = (
  entries: OfflineInputsMediaEntry[],
  onPaste: (
    e: React.ClipboardEvent<HTMLInputElement>,
    startRow: number,
    startCol: number,
    columnsOrder,
    setError,
    sortParamList
  ) => void,
  columnIndex: number,
  isEdit: boolean,
  columnsOrder: string[],
  inputName: string,
  inputLabel: string,
  edits: EntryEditsMap,
  setEdits: (edits: EntryEditsMap) => void,
  setError,
  sortParamList
) => {
  return {
    label: inputLabel,
    name: inputName,
    flex: 1,
    renderer: (data): JSX.Element => {
      const rowIndex = entries.indexOf(data);
      const editData = edits[data.date];

      return isEdit ? (
        <TableInputField
          value={editData ? editData[inputName] : data[inputName]}
          onChange={e => {
            const newValue = e.target.value === "" ? undefined : e.target.value;
            const editData = edits[data.date];

            if (editData) {
              edits[data.date] = { ...editData, [inputName]: newValue };
            } else {
              edits[data.date] = { ...data, [inputName]: newValue };
            }

            setEdits(edits);
          }}
          onPaste={e => onPaste(e, rowIndex, columnIndex, columnsOrder, setError, sortParamList)}
        />
      ) : (
        <span>{data[inputName]}</span>
      );
    },
  };
};

const buildTableHeaders = (
  table: OfflineInputsMediaTable,
  entries: OfflineInputsMediaEntry[],
  edits: EntryEditsMap,
  setEdits: (edits: EntryEditsMap) => void,
  setError: SetError,
  isEdit = false,
  sortParamList: { ascending: boolean; index: number }[] = []
) => {
  const { isPaid, hasImpressions, hasRedemptions, otherUnitLabel } = table;

  const onPaste = (
    e: React.ClipboardEvent,
    startRow: number,
    startCol: number,
    columnsOrder: string[],
    setError: SetError,
    sortParamList: { ascending: boolean; index: number }[] = []
  ) => {
    e.preventDefault();

    // Catch bogus inputs and early exit
    if (startRow < 0 || startCol < 0) {
      return;
    }

    // We will only handle onPaste if the table is sorted by date
    let ascending = false;
    if (sortParamList.length > 0) {
      if (sortParamList[0].index !== 0) {
        setError({ message: "Pasting only supported when table is sorted by Date." });
        return;
      }

      ({ ascending } = sortParamList[0]);
    }

    const clipboardData = e.clipboardData.getData("text/plain");
    const pastedRows: String[][] = clipboardData.split("\n").map(row => row.split("\t"));

    for (
      let i = 0;
      (ascending ? startRow - i >= 0 : startRow + i < entries.length) && i < pastedRows.length;
      i++
    ) {
      const tableRow = entries[ascending ? startRow - i : startRow + i];
      let editRow = edits[tableRow.date];
      const pastedRow = pastedRows[i];

      if (!editRow) {
        edits[tableRow.date] = tableRow;
        editRow = edits[tableRow.date];
      }

      for (let j = 0; j + startCol < columnsOrder.length && j < pastedRow.length; j++) {
        // Pasted blank cells become 0s
        if (pastedRow[j] === "") {
          editRow[columnsOrder[j + startCol]] = 0;
        }

        // [^\d.] matches any character that is not a digit or .
        const filteredInput = pastedRow[j].replaceAll(/[^\d.]/g, "");
        if (!isNaN(Number(filteredInput))) {
          editRow[columnsOrder[j + startCol]] = Number(filteredInput);
        }

        // Do nothing with non-numeric, non-blank inputs
      }
    }

    setEdits({ ...edits });
  };

  const tableHeaders: Header[] = [];
  let columnsOrder: string[] = [];

  const buildInputHeaderWithDefaults = (name, label) => {
    return buildInputHeader(
      entries,
      onPaste,
      tableHeaders.length,
      isEdit,
      columnsOrder,
      name,
      label,
      edits,
      setEdits,
      setError,
      sortParamList
    );
  };

  if (isPaid) {
    tableHeaders.push(buildInputHeaderWithDefaults("spend", "Dollars"));
    columnsOrder.push("spend");
  }

  if (hasImpressions) {
    tableHeaders.push(buildInputHeaderWithDefaults("impressions", "Imps (OOO)"));
    columnsOrder.push("impressions");
  }

  if (otherUnitLabel) {
    const otherUnitLabelCapitalized = `${otherUnitLabel
      .charAt(0)
      .toUpperCase()}${otherUnitLabel.slice(1)}`;

    tableHeaders.push(buildInputHeaderWithDefaults("otherUnit", otherUnitLabelCapitalized));
    columnsOrder.push("otherUnit");
  }

  if (hasRedemptions) {
    tableHeaders.push(buildInputHeaderWithDefaults("redemptions", "Redemptions"));
    columnsOrder.push("redemptions");
  }

  tableHeaders.unshift({
    label: "Date (Day)",
    name: "date",
    flex: 1,
    renderer: (data): JSX.Element => {
      return (
        <span id={`${table.tableId}_${data.date.split("T")[0]}`}>
          {format(new Date(data.date), "MM/dd/yyyy")}
        </span>
      );
    },
  });

  return tableHeaders;
};

interface InputTableProps {
  table: OfflineInputsMediaTable;
  unitsLabel?: string;
  deleteTable: (tableId: string) => void;
  tablesInEditMode: string[];
  startEditingTable: (tableId: string, blanks: OfflineInputsMediaEntry[]) => void;
  editingUnlocked: boolean;
  edits: EntryEditsMap;
  setEdits: (edits: EntryEditsMap) => void;
  newEntries: OfflineInputsMediaEntry[];
  submitting: boolean;
  setError: SetError;
  filter?: any;
}

const InputTable: React.FC<InputTableProps> = ({
  table,
  deleteTable,
  tablesInEditMode,
  startEditingTable,
  editingUnlocked,
  edits,
  setEdits,
  newEntries,
  submitting,
  setError,
  filter,
}) => {
  const { tableId, platform, tactic, channel, brand, entries } = table;
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [sortParamList, setSortParamList] = useState<{ ascending: boolean; index: number }[]>([
    { ascending: true, index: 0 },
  ]);
  const isEdit = tablesInEditMode.includes(tableId);

  const entriesToUse = useMemo(() => {
    const combinedEntries = [...newEntries, ...entries].map(entry => {
      const edit = edits[entry.date];
      if (edit) {
        return {
          ...entry,
          ...edit,
        };
      }
      return entry;
    });

    if (filter) {
      return combinedEntries.filter(entry => {
        const formattedEntry = { ...entry, date: format(new Date(entry.date), "MM/dd/yyyy") };
        return filter(formattedEntry);
      });
    }

    return combinedEntries;
  }, [edits, entries, filter, newEntries]);

  const tableHeaders = useMemo(
    () => buildTableHeaders(table, entriesToUse, edits, setEdits, setError, isEdit, sortParamList),
    [table, entriesToUse, edits, setEdits, setError, isEdit, sortParamList]
  );

  const buildBlankEntriesWithRecent = () => {
    // Safeguard against empty tables
    if (entries.length < 1) {
      return [];
    }

    const latestEntry = entries.reduce((acc, entry) => {
      const dateFromEntry = new Date(entry.date);
      return isBefore(dateFromEntry, acc) ? acc : dateFromEntry;
    }, new Date(entries[0]?.date));

    latestEntry.setDate(latestEntry.getDate() + 1);
    const blankEntries = buildBlankEntries(latestEntry);

    return blankEntries;
  };

  const tacticBrandLine = useMemo(() => {
    if (!brand || brand === "N/A") {
      return tactic;
    }
    if (!tactic) {
      return brand;
    }

    return `${tactic}, ${brand}`;
  }, [brand, tactic]);

  return (
    <>
      <DeleteConfirmationDialog
        show={showDeleteDialog}
        onDelete={() => deleteTable(tableId)}
        onHide={() => setShowDeleteDialog(false)}
      />
      <BPMTable
        data={entriesToUse}
        filterBar={false}
        headers={tableHeaders}
        superHeaderHeight={72}
        noRowsRenderer={() => <div>No data to show</div>}
        additionalControls={
          <div className="superHeader">
            <div className="headerControls">
              {editingUnlocked && !isEdit && (
                <Button
                  type={ButtonType.FILLED}
                  design="secondary"
                  disabled={submitting}
                  onClick={() => startEditingTable(tableId, buildBlankEntriesWithRecent())}
                >
                  <MdEdit />
                </Button>
              )}
              {editingUnlocked && (
                <Button
                  type={ButtonType.FILLED}
                  design="secondary"
                  disabled={submitting}
                  onClick={() => {
                    setShowDeleteDialog(true);
                  }}
                >
                  <MdDelete />
                </Button>
              )}
            </div>
            <div className="headerLabels">
              <div>
                <strong>{channel}</strong>
              </div>
              <div>{platform}</div>
              <div>{tacticBrandLine}</div>
            </div>
          </div>
        }
        onUpdateSortParamList={sortParamList => setSortParamList(sortParamList)}
        overrideSortParamList={sortParamList}
      />
    </>
  );
};

export default InputTable;
