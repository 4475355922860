import React, { useCallback, useContext } from "react";
import * as R from "ramda";
import { Dropdown, DropdownToggleType } from "../Components";
import { KpiMappingsContext } from "./KpiMapping";
import {
  CombinedCrossChannelKpis,
  HandleKpiEditInput,
  HandleNewRowInput,
  HandleNewSourceKpiInput,
  makeNewSourceKpiKey,
} from "./EditKpiMapping";
import {
  EditsMap,
  NewSourceKpis,
  SourceMapping,
  AccountInfo,
} from "@blisspointmedia/bpm-types/dist/KpiMapping";

interface SourceColumnProps {
  combinedKpiMappings: any;
  source: string;
  crossChannelKpis: CombinedCrossChannelKpis;
  editsMap: EditsMap;
  newSourceKpis: NewSourceKpis;
  accounts: AccountInfo[];
  handleKpiEdit: (input: HandleKpiEditInput) => void;
  handleNewSourceKpi: (input: HandleNewSourceKpiInput) => void;
  handleNewRow: (input: HandleNewRowInput) => void;
}
const SourceColumn: React.FC<SourceColumnProps> = React.memo(
  ({
    combinedKpiMappings,
    source,
    crossChannelKpis,
    editsMap,
    newSourceKpis,
    accounts,
    handleKpiEdit,
    handleNewSourceKpi,
    handleNewRow,
  }) => {
    const { sourceIdMap, kpiOptions } = useContext(KpiMappingsContext);

    const handleOnChange = useCallback(
      ({
        newVal,
        source,
        mappingId,
        crossChannelKpiId,
        accountId,
        isCrossChannelKpi,
        isNewRow,
      }) => {
        if (isNewRow) {
          handleNewRow({
            crossChannelKpiId,
            kpiLabel: newVal,
            sourceLabel: source,
            accountId,
            isCrossChannelKpi,
          });
        } else if (mappingId) {
          handleKpiEdit({
            kpiId: mappingId,
            kpiLabel: newVal,
            isCrossChannelKpi,
          });
        } else {
          handleNewSourceKpi({
            crossChannelKpiId,
            source,
            sourceId: sourceIdMap[source],
            kpiLabel: newVal,
            accountId,
          });
        }
      },
      [handleKpiEdit, handleNewRow, handleNewSourceKpi, sourceIdMap]
    );

    let accountsWithFill = accounts;
    // For cases like TVAD, account ID will be null so this will be empty but we need a placeholder
    if (accounts.length === 0) {
      //@ts-ignore
      accountsWithFill = [{ accountId: null, accountName: null }];
    }

    return (
      <div className="sourceColumn">
        <div className="columnHeader">{source}</div>
        <div className="accountIdColumns">
          {accountsWithFill.map(accountInfo => {
            const { accountId, accountName } = accountInfo;
            return (
              <div className="accountIdColumn">
                <div className="accountIdLabel">
                  <div className="labelPrefix">Account:</div>
                  <div className="labelValue">{accountName || accountId || "N/A"}</div>
                </div>
                {crossChannelKpis.map(({ crossChannelKpiLabel, crossChannelKpiId, isNewRow }) => {
                  const kpiMappingsKey = isNewRow ? crossChannelKpiId : crossChannelKpiLabel; // If this is existing row, the key is the crossChannelKpiLabel. If it's new row, the key is the crossChannelKpiId (which is a UUID)
                  const mappings = R.pathOr(
                    [] as SourceMapping[],
                    [kpiMappingsKey, "mappings", source],
                    combinedKpiMappings
                  );
                  const mapping = R.find(R.propEq("accountId", accountId), mappings);
                  const { id, kpi } = mapping || ({} as SourceMapping);
                  return (
                    <div key={crossChannelKpiId} className="sourceItem">
                      <div className="sourceKpiDropdown">
                        <Dropdown
                          className="kpiSelectorDropdown"
                          type={DropdownToggleType.OUTLINED}
                          value={
                            R.pathOr(
                              "",
                              [makeNewSourceKpiKey(crossChannelKpiId, source, accountId), "newKpi"],
                              newSourceKpis
                            ) ||
                            R.pathOr("", [id, "newKpi"], editsMap) ||
                            kpi
                          }
                          options={(kpiOptions[source] || []).map(option => {
                            return { label: option, value: option };
                          })}
                          onChange={newVal =>
                            handleOnChange({
                              newVal,
                              source,
                              mappingId: id,
                              crossChannelKpiId,
                              accountId,
                              isNewRow,
                              isCrossChannelKpi: false,
                            })
                          }
                          hasFilterBar
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
);

export default SourceColumn;
