import React, { useState, useMemo } from "react";

import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import * as uuid from "uuid";

import { Form, Modal, ListGroup } from "react-bootstrap";

import { BPMButton, BPMTable, Page, SingleDatePicker, Spinner } from "../Components";

import {
  LinearBuyingLambdaFetch,
  S3Put,
  pollS3,
  awaitJSON,
  DagLambdaFetch,
} from "../utils/fetch-utils";

import "./ProcessLogs.scss";

const DATE_FORMAT = "yyyy-MM-dd";
const CUTOFF_DATE = R.pipe(
  Dfns.startOfISOWeek,
  Dfns.addWeeks(1),
  Dfns.format(DATE_FORMAT)
)(new Date());
const S3_BUCKET_LOCATION = "bpm-reconcile";

const DEFAULT_WEEK = () => {
  return R.pipe(Dfns.startOfISOWeek, Dfns.subWeeks(1), Dfns.format(DATE_FORMAT))(new Date());
};

const ProcessLogs = () => {
  const [week, setWeek] = useState(DEFAULT_WEEK);
  const [fileData, setFileData] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [processedTableData, setProcessedTableData] = useState();
  const [acceptModal, setAcceptModal] = useState(false);
  const [accepting, setAccepting] = useState(false);
  const [s3id, setS3id] = useState();
  const [companyFormName, setCompanyFormName] = useState();
  const [downloadingLog, setDownloadingLog] = useState(false);

  // Upload Post Log to S3
  const uploadToS3 = (file, id) => {
    const fileReader = new FileReader();

    fileReader.onload = event => {
      let data = event.target.result;

      let splitString = data.split(",");
      data = splitString[1];

      S3Put(S3_BUCKET_LOCATION, `${id}/input/${file.name}`, data, {
        bufferEncoding: "base64",
        contentType: file.type,
      });
    };

    fileReader.readAsDataURL(file);
  };

  // Upload All Post Logs to S3
  const uploadAll = async () => {
    setUploading(true);
    let id = uuid.v4();
    setS3id(id);

    // Upload each log
    for (let file of fileData) {
      uploadToS3(file, id);
    }

    // API to process logs in S3
    await DagLambdaFetch("/start_ingestion_job", {
      method: "POST",
      body: {
        taskBody: {
          params: {
            job_name: "parsePostLogs",
            queue: "dag-ondemand-queue",
            streamingType: "streaming",
            input_branch: "v2",
            memory: "58880",
            extra: `${id},${week}`,
          },
          kpi: "none",
          startScript: "./scripts/ingest-streaming-job.sh",
          codeDirectory: "blisspointmedia",
          jobDefinition: "streaming-job-java-21",
        },
      },
    });

    // Poll S3 until processed logs are ready
    let postlogsJSON = await pollS3({
      bucket: "bpm-reconcile",
      filename: `${id}/output/postlogs.json`,
      mimeType: "application/json",
      autoDownload: false,
      noTimeout: true,
    });

    let parsedJSON = await awaitJSON(postlogsJSON);

    let tableObj = {};

    for (let row of parsedJSON) {
      let key = `${row.company}_${row.network}_${row.satelliteNetwork}_${row.length}`;

      tableObj[key] = {
        company: row.company,
        network: row.network,
        satelliteNetwork: row.satelliteNetwork,
        length: row.length,
        count: (R.has("count", tableObj[key]) ? tableObj[key].count : 0) + 1,
        spend: (R.has("spend", tableObj[key]) ? tableObj[key].spend : 0) + row.cost,
      };
    }

    let tableArray = [];

    for (let key of R.keys(tableObj)) {
      tableArray.push(tableObj[key]);
    }

    tableArray = R.sortBy(R.prop("company"), tableArray);

    setProcessedTableData(tableArray);
    setFileData([]);

    setUploading(false);
  };

  // Accept Network
  const acceptNetwork = async (company, week, uuid) => {
    setAccepting(true);

    await LinearBuyingLambdaFetch("/reconcile_accept", {
      method: "POST",
      body: {
        build_id: uuid,
        company,
        week,
      },
    });

    setProcessedTableData(current => {
      let filteredTableData = current.filter(row => row.company !== company);
      return filteredTableData;
    });
    setAccepting(false);
    setAcceptModal(false);
  };

  // Remove Log
  const removeLog = name => {
    setFileData(current => {
      let filteredFileData = current.filter(row => row.name !== name);
      return filteredFileData;
    });
  };

  // Download Log
  const downloadLog = async company => {
    setDownloadingLog({ [company]: true });
    if (company === "excel") {
      await pollS3({
        bucket: "bpm-reconcile",
        filename: `${s3id}/output/postlogs.xlsx`,
        mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
    } else if (company === "csv") {
      await pollS3({
        bucket: "bpm-reconcile",
        filename: `${s3id}/output/postlogs.csv`,
        mimeType: "text/csv",
      });
    } else {
      await pollS3({
        bucket: "bpm-reconcile",
        filename: `${s3id}/output/${company}_${week}_postlogs.csv`,
        mimeType: "text/csv",
      });
    }
    setDownloadingLog(false);
  };

  // Totals Row
  const finalRow = useMemo(() => {
    if (!processedTableData) {
      return;
    }
    let countTotal = R.pipe(R.pluck("count"), R.sum())(processedTableData);
    let spendTotal = R.pipe(R.pluck("spend"), R.sum())(processedTableData);

    spendTotal = `$${spendTotal.toLocaleString()}`;
    countTotal = countTotal.toLocaleString();

    let row = {
      company: "",
      network: "",
      length: "",
      count: countTotal,
      spend: spendTotal,
    };
    return row;
  }, [processedTableData]);

  // Totals Renderer
  const totalsRenderer = ({ data, style = {}, classes = [] }) => {
    return (
      <div style={style} className={[...classes, "grandTotalCell"].join(" ")}>
        {data}
      </div>
    );
  };

  return (
    <Page
      title="Process Post Logs"
      pageType="Process Post Logs"
      actions={
        <div className="processLogsActions">
          <div>
            <BPMButton
              onClick={uploadAll}
              disabled={uploading || R.isEmpty(fileData)}
              className="actionButton upload"
              variant="success"
            >
              {uploading ? <Spinner color="white" /> : "Upload and Process"}
            </BPMButton>
          </div>

          <div>
            <BPMButton
              onClick={() => setFileData([])}
              disabled={R.isEmpty(fileData)}
              className="actionButton clear"
              variant="danger"
            >
              Clear
            </BPMButton>
          </div>

          {processedTableData && (
            <div>
              <BPMButton
                onClick={() => setAcceptModal(true)}
                className="actionButton accept"
                variant="success"
              >
                Accept
              </BPMButton>
            </div>
          )}

          <SingleDatePicker
            mondayOnly
            date={week}
            isOutsideRange={date => date > CUTOFF_DATE}
            onChange={date => {
              setWeek(date);
            }}
          />
        </div>
      }
    >
      <div className="processLogsContainer">
        <div className="leftPane">
          <div className={`uploadTable${processedTableData ? "" : " summaryNotActive"}`}>
            <BPMTable
              data={fileData}
              filterBar={false}
              headers={[
                {
                  label: "Name",
                  name: "name",
                  flex: 1,
                  nonInteractive: true,
                },
                {
                  label: "Size",
                  name: "size",
                  width: 250,
                  nonInteractive: true,
                  renderer: data => {
                    let sizeInMb = data.size / 1000000;
                    return `${sizeInMb.toLocaleString()} MB`;
                  },
                },
                {
                  label: "Actions",
                  width: 250,
                  nonInteractive: true,
                  renderer: data => {
                    return (
                      <BPMButton
                        className="actionButton clear"
                        onClick={() => removeLog(data.name)}
                        variant="danger"
                      >
                        Remove
                      </BPMButton>
                    );
                  },
                },
              ]}
            />
          </div>
          <div className={`summaryTable${processedTableData ? " active" : ""}`}>
            {processedTableData && (
              <BPMTable
                data={processedTableData}
                totals={finalRow}
                totalsRenderer={totalsRenderer}
                filterBar={false}
                headers={[
                  {
                    label: "Company",
                    name: "company",
                    flex: 1,
                    nonInteractive: true,
                  },
                  {
                    label: "Network",
                    name: "network",
                    width: 150,
                    nonInteractive: true,
                  },
                  {
                    label: "Satellite Network",
                    name: "satelliteNetwork",
                    width: 150,
                    nonInteractive: true,
                  },
                  {
                    label: "Length",
                    name: "length",
                    width: 150,
                    nonInteractive: true,
                  },
                  {
                    label: "Count",
                    name: "count",
                    width: 150,
                    nonInteractive: true,
                  },
                  {
                    label: "Spend",
                    name: "spend",
                    width: 150,
                    nonInteractive: true,
                    renderer: data => `$${data.spend.toLocaleString()}`,
                  },
                ]}
              />
            )}
          </div>
        </div>

        <div className="rightPane">
          <div className="upload-btn-wrapper">
            <button className="file-btn">Upload file(s)</button>
            <input
              type="file"
              multiple
              name="myfile"
              onChange={e => {
                let localFiles = e.target.files;

                setFileData(current => [...current, ...localFiles]);
              }}
            />
          </div>

          {processedTableData && (
            <div className="logDownloadContainer">
              <div className="logDownloadList">
                <ListGroup>
                  <ListGroup.Item className="title">Post-Logs</ListGroup.Item>
                  <ListGroup.Item className="listItem">
                    <BPMButton className="logDownloadButton" onClick={() => downloadLog("excel")}>
                      {downloadingLog.excel ? <Spinner /> : "Excel"}
                    </BPMButton>
                  </ListGroup.Item>
                  <ListGroup.Item className="listItem">
                    <BPMButton className="logDownloadButton" onClick={() => downloadLog("csv")}>
                      {downloadingLog.csv ? <Spinner /> : "CSV"}
                    </BPMButton>
                  </ListGroup.Item>
                  {R.pipe(
                    R.pluck("company"),
                    R.uniq(),
                    R.map(company => (
                      <ListGroup.Item className="listItem">
                        <BPMButton
                          className="logDownloadButton"
                          onClick={() => downloadLog(company)}
                        >
                          {downloadingLog[company] ? <Spinner /> : `${company}`}
                        </BPMButton>
                      </ListGroup.Item>
                    ))
                  )(processedTableData)}
                </ListGroup>
              </div>
            </div>
          )}
        </div>

        {acceptModal && (
          <Modal show onHide={() => setAcceptModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Accept Spots for Company</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Master Company</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter company name"
                  onChange={e => setCompanyFormName(e.target.value)}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <BPMButton
                variant="primary"
                onClick={() => {
                  acceptNetwork(companyFormName, week, s3id);
                }}
              >
                {accepting ? <Spinner /> : "Accept"}
              </BPMButton>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </Page>
  );
};

export default ProcessLogs;
