import "./EditPagePresetModal.scss";
import { DateRange, StateSetter } from "../../utils/types";
import { Filtering, FILTERING_OPTIONS } from "../../CrossChannel/Components/KpiVolume";
import { FilterPreset } from "@blisspointmedia/bpm-types/dist/pgTables/FilterPreset";
import { Form, Modal } from "react-bootstrap";
import { KpiPreset } from "../../CrossChannel/crossChannelUtils";
import { makeDropdownOptions } from "../../CrossChannel/Components/DeliverySnapshot";
import { MdAdd, MdClose, MdEdit, MdOutlineToggleOff, MdToggleOn } from "react-icons/md";
import { MetricsPagePreset } from "@blisspointmedia/bpm-types/dist/MetricsPage";
import { PresetIDGroups as TablePresetIDGroups } from "@blisspointmedia/bpm-types/dist/MetricsTable";
import { STANDARD_GROUP_NAME } from "../../Performance/performanceUtils";
import * as R from "ramda";
import React, { useMemo, useState } from "react";
import {
  DELIVERY_METRIC_OPTIONS,
  METRIC_OPTIONS,
  Metric,
  PERFORMANCE_METRIC_OPTIONS,
} from "../../CrossChannel/crossChannelConstants";
import {
  Button,
  ButtonType,
  DatePicker,
  Dropdown,
  DropdownToggleType,
  TextToggleButton,
  ToggleSwitch,
} from "../../Components";
import {
  DEFAULT_DELIVERY_METRICS_OPTIONS,
  DeliveryMetricOption,
} from "../Delivery/SingleChannelDeliveryChart";

const SINGLE_CHANNEL_MENU_TABS = [
  "Dates",
  "KPI, etc.",
  "Filters",
  "Overview",
  "Delivery",
  "Metrics Table",
  "Preset Group",
];

interface GlobalOptionsPickers {
  audience?: string[] | undefined;
  kpi?: { options: string[]; nameKpiMap; kpiNameMap };
  lag?: string[] | undefined;
}

interface EditPagePresetModalProps {
  dateIncrementOptions?: ("daily" | "weekly" | "monthly" | "quarterly" | "yearly")[];
  deliveryDimensionMap?: Record<string, string>;
  deliveryMetricOptions?: DeliveryMetricOption[];
  filterPresets: FilterPreset[] | undefined;
  funnelPresets?: KpiPreset[];
  globalOptionsPickers: GlobalOptionsPickers;
  groups?: string[];
  mediatype?: string;
  menuTabs?: string[];
  onHide?: () => void;
  pagePresetChanges: MetricsPagePreset;
  saveAndApplyOnClick: (pagePresetChanges: MetricsPagePreset) => void;
  setPagePresetChanges: StateSetter<MetricsPagePreset>;
  sparkChartOptions?: Metric[];
  tablePresets: TablePresetIDGroups[];
}

const DEFAULT_SPARKCHARTS = ["spend", "impressions", "volume", "roas"];

const EditPagePresetModal = ({
  dateIncrementOptions,
  deliveryDimensionMap = {},
  deliveryMetricOptions = DEFAULT_DELIVERY_METRICS_OPTIONS,
  filterPresets = [],
  funnelPresets = [],
  globalOptionsPickers = {},
  groups = [],
  menuTabs = SINGLE_CHANNEL_MENU_TABS,
  onHide = () => {},
  pagePresetChanges,
  saveAndApplyOnClick,
  setPagePresetChanges,
  sparkChartOptions = [],
  tablePresets = [],
}: EditPagePresetModalProps): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState("Dates");
  const [newName, setNewName] = useState<string | undefined>();
  const [hasNewGroupName, setHasNewGroupName] = useState<boolean>();
  const [showGroupsDropdownMenu, setShowGroupsDropdownMenu] = useState<boolean | undefined>(
    undefined
  );
  const [internalOnly, setInternalOnly] = useState(
    pagePresetChanges && pagePresetChanges.globalOptions && pagePresetChanges.globalOptions.role
      ? pagePresetChanges.globalOptions.role >= 2
      : false
  );

  const dateIncrementsOptions = useMemo(() => {
    const options = [
      { label: "Daily", value: "daily" },
      { label: "Weekly", value: "weekly" },
      { label: "Monthly", value: "monthly" },
      { label: "Quarterly", value: "quarterly" },
      { label: "Yearly", value: "yearly" },
    ];
    if (!R.isNil(dateIncrementOptions)) {
      const newOptions = R.filter(
        option => dateIncrementOptions.includes(option.value as any),
        options
      );
      if (
        pagePresetChanges &&
        pagePresetChanges.dateInfo &&
        pagePresetChanges.dateInfo.increments &&
        !newOptions.map(option => option.value).includes(pagePresetChanges.dateInfo.increments)
      ) {
        setPagePresetChanges(pagePresetChanges => ({
          ...pagePresetChanges,
          dateInfo: {
            ...pagePresetChanges.dateInfo,
            increments: newOptions[0].value as
              | "daily"
              | "weekly"
              | "monthly"
              | "quarterly"
              | "yearly",
          },
        }));
      }
      return newOptions;
    }
    return options;
  }, [dateIncrementOptions, pagePresetChanges, setPagePresetChanges]);

  const sparkCharts = useMemo(() => {
    let sparkCharts: string[] = [];
    if (pagePresetChanges && pagePresetChanges.globalOptions) {
      sparkCharts = R.defaultTo([], pagePresetChanges.globalOptions.sparkCharts);
    } else {
      sparkCharts = menuTabs.includes("Cross-Channel Overview")
        ? DEFAULT_SPARKCHARTS.slice(0, 3)
        : DEFAULT_SPARKCHARTS;
    }
    let resetCharts = false;
    for (let i = 0; i < DEFAULT_SPARKCHARTS.length; i++) {
      if (!sparkCharts[i]) {
        resetCharts = true;
        sparkCharts[i] = DEFAULT_SPARKCHARTS[i];
      }
    }
    if (resetCharts) {
      setPagePresetChanges(pagePresetChanges => ({
        ...pagePresetChanges,
        globalOptions: {
          ...pagePresetChanges.globalOptions,
          sparkCharts,
        },
      }));
    }
    return sparkCharts;
  }, [menuTabs, pagePresetChanges, setPagePresetChanges]);

  const { kpi, audience, lag } =
    pagePresetChanges && pagePresetChanges.globalOptions
      ? pagePresetChanges.globalOptions
      : { kpi: undefined, audience: "HH", lag: undefined };

  const kpiPicker = globalOptionsPickers.kpi &&
    globalOptionsPickers.kpi.kpiNameMap &&
    globalOptionsPickers.kpi.nameKpiMap &&
    globalOptionsPickers.kpi.options && (
      <Dropdown
        label="KPI"
        onChange={option => {
          if (globalOptionsPickers.kpi) {
            setPagePresetChanges({
              ...pagePresetChanges,
              globalOptions: {
                ...pagePresetChanges.globalOptions,
                kpi: globalOptionsPickers.kpi.kpiNameMap[option].id,
              },
            });
          }
        }}
        options={globalOptionsPickers.kpi.options}
        size="sm"
        type={DropdownToggleType.OUTLINED}
        value={
          globalOptionsPickers.kpi.nameKpiMap &&
          kpi &&
          globalOptionsPickers.kpi.nameKpiMap[kpi] &&
          globalOptionsPickers.kpi.nameKpiMap[kpi].name
            ? globalOptionsPickers.kpi.nameKpiMap[kpi].name
            : globalOptionsPickers.kpi.options[0]
        }
      />
    );
  const lagPicker = globalOptionsPickers.lag && (
    <Dropdown
      label="Lag"
      onChange={option =>
        setPagePresetChanges({
          ...pagePresetChanges,
          globalOptions: {
            ...pagePresetChanges.globalOptions,
            lag: option as any,
          },
        })
      }
      options={globalOptionsPickers.lag}
      size="sm"
      type={DropdownToggleType.OUTLINED}
      value={R.defaultTo("7d", lag)}
    />
  );
  const audiencePicker = globalOptionsPickers.audience && (
    <Dropdown
      label="Audience"
      onChange={option =>
        setPagePresetChanges({
          ...pagePresetChanges,
          globalOptions: {
            ...pagePresetChanges.globalOptions,
            audience: option as any,
          },
        })
      }
      options={globalOptionsPickers.audience}
      size="sm"
      type={DropdownToggleType.OUTLINED}
      value={R.defaultTo("HH", audience)}
    />
  );

  const modalHeader = (
    <Modal.Header>
      <div className="titleContainer">
        <Modal.Title>Page Preset:</Modal.Title>
        <div className="pagePresetNameContainer">
          {!R.isNil(newName) ? (
            <Form.Control
              className="newNameInput"
              onChange={e => setNewName(e.target.value)}
              placeholder={"New Preset Name"}
              value={newName}
            />
          ) : (
            <div className="pagePresetName">
              {pagePresetChanges.temporary ? "Temporary Preset" : pagePresetChanges.name}
            </div>
          )}
          {pagePresetChanges &&
            pagePresetChanges.presetGroup !== STANDARD_GROUP_NAME &&
            (R.isNil(newName) ? (
              <div
                className="editPagePresetNameIcon"
                onClick={() => {
                  setNewName(pagePresetChanges.name);
                }}
              >
                <MdEdit />
              </div>
            ) : (
              <div
                className="editPagePresetNameIcon"
                onClick={() => {
                  setNewName(undefined);
                }}
              >
                <MdClose />
              </div>
            ))}
        </div>
      </div>
      <div className="internalOnlyContainer" onClick={() => setInternalOnly(!internalOnly)}>
        <div className="internalOnlyLabel">Internal Only</div>
        <div className="internalOnlyToggle">
          {internalOnly ? <MdToggleOn /> : <MdOutlineToggleOff />}
        </div>
      </div>
    </Modal.Header>
  );

  const tabSelector = (
    <div className="tabSelector">
      {R.map(
        tab => (
          <div
            className={selectedTab === tab ? "active tab" : "tab"}
            onClick={() => setSelectedTab(tab)}
          >
            {tab === "Cross-Channel Overview" ? "Overview" : tab}
          </div>
        ),
        R.filter(
          tab => tab !== "Preset Group" || pagePresetChanges.presetGroup !== STANDARD_GROUP_NAME,
          menuTabs
        ) as string[]
      )}
    </div>
  );

  const metricOptions = useMemo(() => {
    if (sparkChartOptions) {
      const options: { label: string; value: Metric }[] = [];
      for (const metric of sparkChartOptions) {
        const metricOption = METRIC_OPTIONS.find(option => option.value === metric);
        if (!R.isNil(metricOption)) {
          options.push(metricOption);
        }
      }
      return options;
    } else {
      return METRIC_OPTIONS;
    }
  }, [sparkChartOptions]);

  const datesContainer = (
    <div className="datesContainer">
      <div className="dateIncrementsContainer">
        <div className="dateIncrementsLabel">Date Increments</div>
        <div className="dateIncrementsDropdownContainer">
          <Dropdown
            size="sm"
            className="dateIncrementsDropdown"
            type={DropdownToggleType.OUTLINED}
            value={
              pagePresetChanges &&
              pagePresetChanges.dateInfo &&
              pagePresetChanges.dateInfo.increments
                ? pagePresetChanges.dateInfo.increments
                : "daily"
            }
            options={dateIncrementsOptions}
            onChange={value =>
              setPagePresetChanges({
                ...pagePresetChanges,
                dateInfo: {
                  ...pagePresetChanges.dateInfo,
                  increments: value as "daily" | "weekly" | "monthly" | "quarterly" | "yearly",
                },
              })
            }
          />
        </div>
      </div>
      <div className="datePickerContainer">
        <div className="datePickerLabel">Primary Date Range</div>
        <div className="datePickerContainer">
          <DatePicker
            fullWeeksOnly={
              pagePresetChanges.dateInfo && pagePresetChanges.dateInfo.increments === "weekly"
            }
            range={{
              start: pagePresetChanges.dateInfo.start,
              end: pagePresetChanges.dateInfo.end,
            }}
            onChange={(newDates: DateRange) =>
              setPagePresetChanges({
                ...pagePresetChanges,
                dateInfo: {
                  ...pagePresetChanges.dateInfo,
                  start: newDates.start,
                  end: newDates.end,
                },
              })
            }
          />
        </div>
      </div>
      <div className="datePickerContainer">
        <div className="datePickerLabel">Comparison Date Range</div>
        <div className="datePickerContainer">
          <DatePicker
            fullWeeksOnly={
              pagePresetChanges.dateInfo && pagePresetChanges.dateInfo.increments === "weekly"
            }
            range={{
              start: pagePresetChanges.dateInfo.comparison_start
                ? pagePresetChanges.dateInfo.comparison_start
                : pagePresetChanges.dateInfo.start,
              end: pagePresetChanges.dateInfo.comparison_end
                ? pagePresetChanges.dateInfo.comparison_end
                : pagePresetChanges.dateInfo.end,
            }}
            onChange={(newDates: DateRange) =>
              setPagePresetChanges({
                ...pagePresetChanges,
                dateInfo: {
                  ...pagePresetChanges.dateInfo,
                  comparison_start: newDates.start,
                  comparison_end: newDates.end,
                },
              })
            }
          />
        </div>
      </div>
    </div>
  );
  const globalOptionsContainer = (
    <div className="globalOptionsContainer">
      {kpiPicker && (
        <div className="globalOptionsDropdownContainer">
          <div className="globalOptionsLabel">KPI</div>
          <div className="globalOptionsDropdown">{kpiPicker}</div>
        </div>
      )}
      {lagPicker && (
        <div className="globalOptionsDropdownContainer">
          <div className="globalOptionsLabel">Lag Window</div>
          <div className="globalOptionsDropdown">{lagPicker}</div>
        </div>
      )}
      {audiencePicker && (
        <div className="globalOptionsDropdownContainer">
          <div className="globalOptionsLabel">Audience</div>
          <div className="globalOptionsDropdown">{audiencePicker}</div>
        </div>
      )}
    </div>
  );
  const filtersContainer = (
    <div className="filtersContainer">
      <div className="filterPresetContainer">
        <div className="filterPresetLabel">Filter Preset</div>
        <div className="filterPresetDropdownContainer">
          <Dropdown
            size="sm"
            className="filterPresetDropdown"
            type={DropdownToggleType.OUTLINED}
            value={
              pagePresetChanges && pagePresetChanges.filterInfo.filterID
                ? (`${pagePresetChanges.filterInfo.filterID}` as string)
                : "None"
            }
            options={[
              { label: "None", value: "None" },
              ...R.map(
                filterPreset => ({ label: filterPreset.name, value: `${filterPreset.id}` }),
                filterPresets
              ),
            ]}
            onChange={value => {
              if (value === "None") {
                setPagePresetChanges({
                  ...pagePresetChanges,
                  filterInfo: {},
                });
              } else {
                setPagePresetChanges({
                  ...pagePresetChanges,
                  filterInfo: { filterID: parseInt(value) },
                });
              }
            }}
          />
        </div>
      </div>
    </div>
  );
  const groupsContainer = (
    <div className="groupsContainer">
      <div className="groupContainer">
        <div className="groupLabel">Preset Group</div>
        <div className="groupSelectionContainer">
          <Dropdown
            value={
              hasNewGroupName
                ? "New Group"
                : pagePresetChanges && pagePresetChanges.presetGroup
                ? pagePresetChanges.presetGroup
                : ""
            }
            options={[
              ...(hasNewGroupName ? [{ label: "New Group", value: "New Group" }] : []),
              ...groups,
            ]}
            type={DropdownToggleType.OUTLINED}
            size="sm"
            show={showGroupsDropdownMenu}
            onChange={value => {
              setPagePresetChanges(presetChanges => ({
                ...presetChanges,
                presetGroup: value,
              }));
              setHasNewGroupName(false);
            }}
            onToggle={() => {
              setShowGroupsDropdownMenu(!showGroupsDropdownMenu);
            }}
            footer={
              !hasNewGroupName && (
                <>
                  <MdAdd />
                  <div className="label">Create New Group</div>
                </>
              )
            }
            footerOnClick={() => {
              setHasNewGroupName(true);
              setShowGroupsDropdownMenu(false);
            }}
          />
          {hasNewGroupName && (
            <div className="groupFormContainer">
              <Form.Control
                className="newNameInput"
                onChange={e => {
                  const newGroupName = e && e.target ? e.target.value : undefined;
                  if (!R.isNil(newGroupName)) {
                    setPagePresetChanges(presetChanges => ({
                      ...presetChanges,
                      presetGroup: newGroupName,
                    }));
                  }
                }}
                placeholder={"None"}
                value={
                  pagePresetChanges && pagePresetChanges.presetGroup
                    ? pagePresetChanges.presetGroup
                    : ""
                }
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
  // Both the single channel and cross channel overview modals use the same compare metrics subcontainer
  const compareMetricsSubContainer = (
    <div className="cmContainer">
      <div className="cmLabel">Compare Metrics</div>
      <div className="cmDropdownsContainer">
        <div className="toggle">
          <ToggleSwitch
            design={"secondary"}
            label={"Compare Period"}
            leadingLabel={true}
            checked={R.defaultTo(
              false,
              pagePresetChanges?.globalOptions?.compareMetricsIncludeOtherDates
            )}
            onChange={() =>
              setPagePresetChanges({
                ...pagePresetChanges,
                globalOptions: {
                  ...pagePresetChanges.globalOptions,
                  compareMetricsIncludeOtherDates: !R.defaultTo(
                    false,
                    pagePresetChanges?.globalOptions?.compareMetricsIncludeOtherDates
                  ),
                },
              })
            }
          />
        </div>
        {R.map(
          index => (
            <div className="cmDropdownContainer">
              <div className="label">{`Metric ${index + 1}`}</div>
              <Dropdown
                size="sm"
                value={pagePresetChanges?.globalOptions?.compareMetrics?.[index]}
                type={DropdownToggleType.OUTLINED}
                options={[...METRIC_OPTIONS, { label: "None", value: undefined }] as any}
                onChange={value => {
                  const newCompareMetrics = R.defaultTo(
                    [undefined, undefined, undefined, undefined],
                    pagePresetChanges?.globalOptions?.compareMetrics
                  );
                  newCompareMetrics[index] = value as Metric;
                  setPagePresetChanges({
                    ...pagePresetChanges,
                    globalOptions: {
                      ...pagePresetChanges.globalOptions,
                      compareMetrics: newCompareMetrics,
                    },
                  });
                }}
              />
            </div>
          ),
          R.range(0, 4)
        )}
      </div>
    </div>
  );
  const overviewContainer = (
    <div className="overviewContainer">
      <div className="sparkChartsContainer">
        <div className="sparkChartsLabel">Spark Charts</div>
        <div className="sparkChartSelectorsContainer">
          {sparkCharts.map((chart, index) => (
            <div className="sparkChartSelector">
              <div className="label">{`Chart ${index + 1}`}</div>
              {!(
                chart.toLowerCase() === "roas" ||
                (chart.length === 3 && chart.toLowerCase().startsWith("cp"))
              ) && (
                <TextToggleButton
                  design="secondary"
                  options={["Total", "Avg"]}
                  selectedOption={
                    pagePresetChanges.globalOptions.sparkChartTypes &&
                    pagePresetChanges.globalOptions.sparkChartTypes[index]
                      ? pagePresetChanges.globalOptions.sparkChartTypes[index]
                      : false
                  }
                  onChange={value => {
                    let newSparkChartTypes = pagePresetChanges.globalOptions.sparkChartTypes
                      ? [...pagePresetChanges.globalOptions.sparkChartTypes]
                      : [false, false, false, false];
                    newSparkChartTypes[index] = value as Metric;
                    setPagePresetChanges(changes => ({
                      ...changes,
                      globalOptions: {
                        ...changes.globalOptions,
                        sparkChartTypes: newSparkChartTypes,
                      },
                    }));
                  }}
                />
              )}
              <Dropdown
                size="sm"
                options={metricOptions}
                type={DropdownToggleType.OUTLINED}
                value={chart}
                onChange={value => {
                  let newSparkCharts = [...sparkCharts];
                  newSparkCharts[index] = value as Metric;
                  setPagePresetChanges(changes => ({
                    ...changes,
                    globalOptions: {
                      ...changes.globalOptions,
                      sparkCharts: newSparkCharts,
                    },
                  }));
                }}
              />
            </div>
          ))}
        </div>
      </div>
      {compareMetricsSubContainer}
    </div>
  );
  const crossChannelOverviewContainer = (
    <div className="overviewContainer">
      <div className="kpiVolumeContainer">
        <div className="kpiVolumeLabel">KPI Volume</div>
        <div className="kpiVolumeDropdownsContainer">
          <div className="kpiVolumeDropdownContainer">
            <div className="label">Funnel</div>
            <Dropdown
              size="sm"
              value={
                pagePresetChanges &&
                pagePresetChanges.globalOptions &&
                pagePresetChanges.globalOptions.trafficFunnelName
                  ? pagePresetChanges.globalOptions.trafficFunnelName
                  : funnelPresets[0]?.name
              }
              type={DropdownToggleType.OUTLINED}
              options={R.map(
                preset => ({
                  label: preset.name,
                  value: preset.name,
                }),
                funnelPresets
              )}
              onChange={value =>
                setPagePresetChanges({
                  ...pagePresetChanges,
                  globalOptions: { ...pagePresetChanges.globalOptions, trafficFunnelName: value },
                })
              }
            />
          </div>
          <div className="kpiVolumeDropdownContainer">
            <div className="label">Traffic</div>
            <Dropdown
              size="sm"
              value={
                pagePresetChanges &&
                pagePresetChanges.globalOptions &&
                pagePresetChanges.globalOptions.traffic
                  ? pagePresetChanges.globalOptions.traffic
                  : Filtering.ALL
              }
              type={DropdownToggleType.OUTLINED}
              options={FILTERING_OPTIONS as any}
              onChange={value =>
                setPagePresetChanges({
                  ...pagePresetChanges,
                  globalOptions: { ...pagePresetChanges.globalOptions, traffic: value },
                })
              }
            />
          </div>
        </div>
      </div>
      <div className="funnelDynamicsContainer">
        <div className="funnelDynamicsLabel">Funnel Dynamics</div>
        <div className="funnelDynamicsDropdownsContainer">
          <div className="funnelDynamicsDropdownContainer">
            <div className="label">Funnel</div>
            <Dropdown
              size="sm"
              value={
                pagePresetChanges &&
                pagePresetChanges.globalOptions &&
                pagePresetChanges.globalOptions.funnelDynamicsName
                  ? pagePresetChanges.globalOptions.funnelDynamicsName
                  : funnelPresets[0]?.name
              }
              type={DropdownToggleType.OUTLINED}
              options={R.map(
                preset => ({
                  label: preset.name,
                  value: preset.name,
                }),
                funnelPresets
              )}
              onChange={value =>
                setPagePresetChanges({
                  ...pagePresetChanges,
                  globalOptions: { ...pagePresetChanges.globalOptions, funnelDynamicsName: value },
                })
              }
            />
          </div>
        </div>
      </div>
      <div className="sparkChartsContainer">
        <div className="sparkChartsLabel">Spark Charts</div>
        <div className="sparkChartSelectorsContainer">
          {sparkCharts.map((chart, index) => (
            <div className="sparkChartSelector">
              <div className="label">{`Chart ${index + 1}`}</div>
              {!(
                chart.toLowerCase() === "roas" ||
                (chart.length === 3 && chart.toLowerCase().startsWith("cp"))
              ) && (
                <TextToggleButton
                  design="secondary"
                  options={["Total", "Avg"]}
                  selectedOption={
                    pagePresetChanges.globalOptions.sparkChartTypes &&
                    pagePresetChanges.globalOptions.sparkChartTypes[index]
                      ? pagePresetChanges.globalOptions.sparkChartTypes[index]
                      : false
                  }
                  onChange={value => {
                    let newSparkChartTypes = pagePresetChanges.globalOptions.sparkChartTypes
                      ? [...pagePresetChanges.globalOptions.sparkChartTypes]
                      : [false, false, false, false];
                    newSparkChartTypes[index] = value as Metric;
                    setPagePresetChanges(changes => ({
                      ...changes,
                      globalOptions: {
                        ...changes.globalOptions,
                        sparkChartTypes: newSparkChartTypes,
                      },
                    }));
                  }}
                />
              )}
              <Dropdown
                size="sm"
                options={metricOptions}
                type={DropdownToggleType.OUTLINED}
                value={chart}
                onChange={value => {
                  let newSparkCharts = [...sparkCharts];
                  newSparkCharts[index] = value as Metric;
                  setPagePresetChanges(changes => ({
                    ...changes,
                    globalOptions: {
                      ...changes.globalOptions,
                      sparkCharts: newSparkCharts,
                    },
                  }));
                }}
              />
            </div>
          ))}
        </div>
      </div>
      {compareMetricsSubContainer}
    </div>
  );
  const metricsTableContainer = (
    <div className="metricsTableContainer">
      <div className="tablePresetContainer">
        <div className="tablePresetLabel">Table Preset</div>
        <div className="tablePresetDropdownContainer">
          <Dropdown
            size="sm"
            className="tablePresetDropdown"
            type={DropdownToggleType.OUTLINED}
            value={`${pagePresetChanges.globalOptions.tableID}` as string}
            options={R.map(
              tablePreset => ({ label: tablePreset.name, value: `${tablePreset.id}` }),
              tablePresets
            )}
            onChange={value =>
              setPagePresetChanges({
                ...pagePresetChanges,
                globalOptions: { ...pagePresetChanges.globalOptions, tableID: parseInt(value) },
              })
            }
          />
        </div>
      </div>
    </div>
  );
  const deliveryContainer = (
    <div className="deliveryContainer">
      <div className="deliveryChartContainer">
        <div className="deliveryLabel">Delivery Options</div>
        <div className="deliveryOptionsContainer">
          <Dropdown
            size="sm"
            options={R.map(
              option => ({
                label: option.label,
                value: option.label,
              }),
              deliveryMetricOptions
            )}
            value={R.defaultTo(
              deliveryMetricOptions[0].label,
              pagePresetChanges.globalOptions.deliveryMetric
            )}
            type={DropdownToggleType.OUTLINED}
            onChange={value =>
              setPagePresetChanges({
                ...pagePresetChanges,
                globalOptions: {
                  ...pagePresetChanges.globalOptions,
                  deliveryMetric: value as string,
                },
              })
            }
          />
          <div className="deliveryOption">
            <div className="label">Delivery by</div>
            <Dropdown
              size="sm"
              options={R.map(key => ({ label: key, value: key }), R.keys(deliveryDimensionMap))}
              type={DropdownToggleType.OUTLINED}
              value={R.defaultTo(
                R.keys(deliveryDimensionMap)[0],
                pagePresetChanges.globalOptions.deliveryDimension
              )}
              onChange={value =>
                setPagePresetChanges({
                  ...pagePresetChanges,
                  globalOptions: {
                    ...pagePresetChanges.globalOptions,
                    deliveryDimension: value as string,
                  },
                })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
  const deliveryAndPerformanceContainer = (
    <div className="dpContainer">
      <div className="dpItemContainer">
        <div className="dpLabel">Spend vs Budget Snapshot</div>
        <div className="dpItemDropdownsContainer">
          <div className="dpItemDropdownContainer">
            <div className="label">Top</div>
            <Dropdown
              size="sm"
              value={pagePresetChanges?.globalOptions?.sbSnapshotTop}
              type={DropdownToggleType.OUTLINED}
              options={makeDropdownOptions().topDropdownOptions as any}
              onChange={value =>
                setPagePresetChanges({
                  ...pagePresetChanges,
                  globalOptions: {
                    ...pagePresetChanges.globalOptions,
                    sbSnapshotTop: value,
                  },
                })
              }
            />
          </div>
          <div className="dpItemDropdownContainer">
            <div className="label">Bottom</div>
            <Dropdown
              size="sm"
              value={pagePresetChanges?.globalOptions?.sbSnapshotBottom}
              type={DropdownToggleType.OUTLINED}
              options={makeDropdownOptions().bottomDropdownOptions as any}
              onChange={value =>
                setPagePresetChanges({
                  ...pagePresetChanges,
                  globalOptions: {
                    ...pagePresetChanges.globalOptions,
                    sbSnapshotBottom: value,
                  },
                })
              }
            />
          </div>
        </div>
      </div>
      <div className="dpItemContainer">
        <div className="dpLabel">Delivery Over Time</div>
        <div className="dpItemDropdownsContainer">
          <div className="toggle">
            <ToggleSwitch
              design={"secondary"}
              label={"Compare Period"}
              leadingLabel={true}
              checked={R.defaultTo(
                false,
                pagePresetChanges?.globalOptions?.crossChannelDeliveryIncludeOtherDates
              )}
              id={"switch1"}
              onChange={() =>
                setPagePresetChanges({
                  ...pagePresetChanges,
                  globalOptions: {
                    ...pagePresetChanges.globalOptions,
                    crossChannelDeliveryIncludeOtherDates: !R.defaultTo(
                      false,
                      pagePresetChanges?.globalOptions?.crossChannelDeliveryIncludeOtherDates
                    ),
                  },
                })
              }
            />
          </div>
          <div className="dpItemDropdownContainer">
            <div className="label">Metric</div>
            <Dropdown
              size="sm"
              value={pagePresetChanges?.globalOptions?.crossChannelDeliveryMetric}
              type={DropdownToggleType.OUTLINED}
              options={DELIVERY_METRIC_OPTIONS as any}
              onChange={value =>
                setPagePresetChanges({
                  ...pagePresetChanges,
                  globalOptions: {
                    ...pagePresetChanges.globalOptions,
                    crossChannelDeliveryMetric: value,
                  },
                })
              }
            />
          </div>
        </div>
      </div>
      <div className="dpItemContainer">
        <div className="dpLabel">Performance Snapshot</div>
        <div className="dpItemDropdownsContainer">
          <div className="dpItemDropdownContainer">
            <div className="label">Metric</div>
            <Dropdown
              size="sm"
              value={pagePresetChanges?.globalOptions?.crossChannelPerformanceSnapshotMetric}
              type={DropdownToggleType.OUTLINED}
              options={PERFORMANCE_METRIC_OPTIONS as any}
              onChange={value =>
                setPagePresetChanges({
                  ...pagePresetChanges,
                  globalOptions: {
                    ...pagePresetChanges.globalOptions,
                    crossChannelPerformanceSnapshotMetric: value,
                  },
                })
              }
            />
          </div>
        </div>
      </div>
      <div className="dpItemContainer">
        <div className="dpLabel">Performance Over Time</div>
        <div className="dpItemDropdownsContainer">
          <div className="toggle">
            <ToggleSwitch
              design={"secondary"}
              label={"Compare Period"}
              leadingLabel={true}
              id={"switch2"}
              checked={R.defaultTo(
                false,
                pagePresetChanges?.globalOptions?.crossChannelPerformanceIncludeOtherDates
              )}
              onChange={() =>
                setPagePresetChanges({
                  ...pagePresetChanges,
                  globalOptions: {
                    ...pagePresetChanges.globalOptions,
                    crossChannelPerformanceIncludeOtherDates: !R.defaultTo(
                      false,
                      pagePresetChanges?.globalOptions?.crossChannelPerformanceIncludeOtherDates
                    ),
                  },
                })
              }
            />
          </div>
          <div className="dpItemDropdownContainer">
            <div className="label">Metric</div>
            <Dropdown
              size="sm"
              value={pagePresetChanges?.globalOptions?.crossChannelPerformanceMetric}
              type={DropdownToggleType.OUTLINED}
              options={PERFORMANCE_METRIC_OPTIONS as any}
              onChange={value =>
                setPagePresetChanges({
                  ...pagePresetChanges,
                  globalOptions: {
                    ...pagePresetChanges.globalOptions,
                    crossChannelPerformanceMetric: value,
                  },
                })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );

  const modalBody = (
    <Modal.Body>
      {tabSelector}
      {selectedTab === "Cross-Channel Overview" && crossChannelOverviewContainer}
      {selectedTab === "Dates" && datesContainer}
      {selectedTab === "Delivery & Performance" && deliveryAndPerformanceContainer}
      {selectedTab === "Delivery" && deliveryContainer}
      {selectedTab === "Filters" && filtersContainer}
      {selectedTab === "KPI, etc." && globalOptionsContainer}
      {selectedTab === "Metrics Table" && metricsTableContainer}
      {selectedTab === "Overview" && overviewContainer}
      {selectedTab === "Preset Group" && groupsContainer}
    </Modal.Body>
  );

  const modalFooter = (
    <Modal.Footer>
      <Button
        className="cancelButton"
        design="secondary"
        onClick={() => onHide()}
        size={"sm"}
        type={ButtonType.EMPTY}
      >
        Cancel
      </Button>
      <Button
        className="previewButton"
        design="secondary"
        onClick={() => saveAndApplyOnClick({ ...pagePresetChanges, temporary: true })}
        size={"sm"}
        type={ButtonType.OUTLINED}
      >
        Temp Preview
      </Button>
      <Button
        className="saveAndApplyButton"
        onClick={() =>
          saveAndApplyOnClick({
            ...pagePresetChanges,
            name: R.defaultTo(pagePresetChanges.name, newName),
          })
        }
        size={"sm"}
        type={ButtonType.FILLED}
      >
        Save and Apply
      </Button>
    </Modal.Footer>
  );

  return (
    <Modal centered className="editPagePresetModal" onHide={() => onHide()} show size="lg">
      {modalHeader}
      {modalBody}
      {modalFooter}
    </Modal>
  );
};

export default EditPagePresetModal;
