export enum Metric {
  CLICKS = "clicks",
  CPC = "cpc",
  CPM = "cpm",
  CPX = "cpx",
  IMPRESSIONS = "impressions",
  REVENUE = "revenue",
  ROAS = "roas",
  SPEND = "spend",
  VOLUME = "volume",
}

const DELIVERY_METRICS = [Metric.CPM, Metric.IMPRESSIONS, Metric.SPEND];
const PERFORMANCE_METRICS = [
  Metric.CLICKS,
  Metric.CPC,
  Metric.CPX,
  Metric.REVENUE,
  Metric.ROAS,
  Metric.VOLUME,
];

export const METRIC_TO_PRETTY_NAME = {
  [Metric.CLICKS]: "Clicks",
  [Metric.CPC]: "CPC",
  [Metric.CPM]: "CPM",
  [Metric.CPX]: "CPX",
  [Metric.IMPRESSIONS]: "Impressions",
  [Metric.REVENUE]: "Revenue",
  [Metric.ROAS]: "ROAS",
  [Metric.SPEND]: "Spend",
  [Metric.VOLUME]: "KPI Volume",
} as const;

export const METRIC_OPTIONS = [
  { label: METRIC_TO_PRETTY_NAME[Metric.CLICKS], value: Metric.CLICKS },
  { label: METRIC_TO_PRETTY_NAME[Metric.CPC], value: Metric.CPC },
  { label: METRIC_TO_PRETTY_NAME[Metric.CPM], value: Metric.CPM },
  { label: METRIC_TO_PRETTY_NAME[Metric.CPX], value: Metric.CPX },
  { label: METRIC_TO_PRETTY_NAME[Metric.IMPRESSIONS], value: Metric.IMPRESSIONS },
  { label: METRIC_TO_PRETTY_NAME[Metric.REVENUE], value: Metric.REVENUE },
  { label: METRIC_TO_PRETTY_NAME[Metric.ROAS], value: Metric.ROAS },
  { label: METRIC_TO_PRETTY_NAME[Metric.SPEND], value: Metric.SPEND },
  { label: METRIC_TO_PRETTY_NAME[Metric.VOLUME], value: Metric.VOLUME },
] as const;

export const DELIVERY_METRIC_OPTIONS = METRIC_OPTIONS.filter(metric =>
  DELIVERY_METRICS.includes(metric.value)
);
export const PERFORMANCE_METRIC_OPTIONS = METRIC_OPTIONS.filter(metric =>
  PERFORMANCE_METRICS.includes(metric.value)
);

export const MEDIA_TYPE_TO_PRETTY_NAME = {
  audio: "Audio",
  display: "Display",
  streaming: "Streaming",
  tv: "Linear TV",
  social: "Social",
  search: "Search",
} as const;

export const GRAY_BAR_COLOR = "#E6E6E6";
export const GRAY_TEXT_COLOR = "#ADADAD";

export const DESCRIPTIVE_METRICS = [Metric.CPM, Metric.CLICKS, Metric.IMPRESSIONS, Metric.SPEND];
