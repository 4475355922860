import "./FunnelDynamics.scss";
import React, { useState, useEffect, useCallback, useMemo, useContext } from "react";
import * as R from "ramda";
import FunnelGraph from "funnel-graph-js";
import ChartContainer from "../../Components/ChartContainer";
import { Dropdown, DropdownToggleType, DownloadDropdown } from "../../Components";
import { KpiData, KpiPreset } from "../crossChannelUtils";
import { exportToExcel, downloadPNG } from "../../utils/download-utils";
import { CrossChannelContext } from "../CrossChannel";

interface FunnelDynamicsData {
  labels: string[];
  subLabels: string[];
  colors: string[];
  values: number[][];
}

interface FunnelDynamicsProps {
  data: KpiData[];
  funnelPresets: KpiPreset[];
  kpiLabels: Record<string, string>;
  defaultPreset?: string;
  selectedChannels: Record<string, boolean>;
}

const SUB_LABELS = ["Organic", "Paid"];
const COLORS = ["#8254FF", "#390094"];

const processKpiData = (
  data: KpiData[],
  kpiPreset: KpiPreset,
  kpiLabels: Record<string, string>,
  selectedChannels: Record<string, boolean>
): FunnelDynamicsData => {
  const totalsByKpiOrganic = {};
  const totalsByKpiPaid = {};

  for (let item of data) {
    const { kpi, count, isOrganic, channel } = item;
    // todo: remove empty string option once data is cleaned
    if (channel === "" || selectedChannels[channel]) {
      if (!kpiPreset.kpis.includes(kpi)) {
        continue;
      }

      if (isOrganic) {
        totalsByKpiOrganic[kpi] = (totalsByKpiOrganic[kpi] || 0) + count;
      } else {
        totalsByKpiPaid[kpi] = (totalsByKpiPaid[kpi] || 0) + count;
      }
    }
  }

  const labels = kpiPreset.kpis.map(kpi => kpiLabels[kpi] || kpi);

  const values = kpiPreset.kpis.map(kpi => [
    Math.round(totalsByKpiOrganic[kpi] || 0),
    Math.round(totalsByKpiPaid[kpi] || 0),
  ]);

  return {
    labels,
    subLabels: SUB_LABELS,
    colors: COLORS,
    values,
  };
};

const FunnelDynamics: React.FC<FunnelDynamicsProps> = React.memo(
  ({ data, funnelPresets, kpiLabels, defaultPreset = "", selectedChannels }) => {
    const [preset, setPreset] = useState(defaultPreset);
    const [drawn, setDrawn] = useState(false);
    const [graph, setGraph] = useState<any>();

    const { company, kpi, dates } = useContext(CrossChannelContext);

    const presetMap = useMemo(() => R.indexBy(R.prop("name"), funnelPresets), [funnelPresets]);

    useEffect(() => {
      if (defaultPreset === "") {
        const defaultPresetFromTable = R.find(row => row.is_default, funnelPresets);
        setPreset(defaultPresetFromTable?.name || funnelPresets[0]?.name || "");
      }
    }, [funnelPresets, defaultPreset]);

    const presetOptions = useMemo(
      () => funnelPresets.map(preset => ({ label: preset.name, value: preset.name })),
      [funnelPresets]
    );

    const processedData = useMemo(() => {
      if (!R.isNil(data) && !R.isEmpty(data) && preset) {
        return processKpiData(data, presetMap[preset], kpiLabels, selectedChannels);
      }
    }, [data, preset, presetMap, kpiLabels, selectedChannels]);

    useEffect(() => {
      if (drawn || !processedData) {
        return;
      }
      const graph = new FunnelGraph({
        container: ".funnelSVG",
        data: processedData,
        displayPercent: true,
        direction: "vertical",
        width: 350,
        height: 700,
      });

      graph.draw();
      setDrawn(true);
      setGraph(graph);
    }, [data, processedData, drawn]);

    const updateGraph = useCallback(
      (presetName: string) => {
        setPreset(presetName);

        if (graph && presetMap[presetName]) {
          const newData = processKpiData(data, presetMap[presetName], kpiLabels, selectedChannels);
          graph.updateData(newData);
        }
      },
      [data, presetMap, graph, kpiLabels, selectedChannels]
    );

    useEffect(() => {
      if (drawn && processedData) {
        updateGraph(preset || "");
      }
    }, [processedData, drawn, updateGraph, preset]);

    const excelDownload = useCallback(() => {
      exportToExcel(data, `${company}_funnel_dynamics`);
    }, [data, company]);

    const pngDownload = useCallback(async () => {
      const label = `KPI: ${kpi}`;
      const fileNameIdentifiers = [
        "CrossChannel",
        "FunnelDynamics",
        preset || "",
        `${dates.start}–${dates.end}`,
      ];
      await downloadPNG(".ccKpiFunnel", fileNameIdentifiers, label, [".cl-download"]);
    }, [kpi, preset, dates]);

    return (
      <ChartContainer
        title="Funnel Dynamics"
        enableHoverDesign
        rightActions={
          <>
            <Dropdown
              size="sm"
              type={DropdownToggleType.FILLED}
              design="secondary"
              value={preset || ""}
              options={presetOptions}
              onChange={updateGraph}
            />
            <DownloadDropdown
              size="sm"
              onClickOptions={[excelDownload, pngDownload]}
              disabled={!data}
            />
          </>
        }
      >
        {R.isNil(processedData) ? (
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              fontSize: "26px",
              minHeight: "700px",
            }}
          >
            No KPI data to show
          </div>
        ) : (
          <div className="kpiFunnelDynamics">
            <div className="funnelSVG" />
          </div>
        )}
      </ChartContainer>
    );
  }
);

export default FunnelDynamics;
