import React, { useState, useMemo, useCallback } from "react";
import * as R from "ramda";
import { Button, ButtonGroup, ListGroup } from "react-bootstrap";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { useStateFunction } from "../utils/hooks/useData";
import { Page, /*Img, */ Video, Audio, OldFilterBar, FullPageSpinner } from "../Components";
import { useCreativeMap } from "../redux/creative";
import useLocation from "../utils/hooks/useLocation";
import { MdHeadset } from "react-icons/md";
import "./CreativeView.scss";

const FILTER_BAR_OPTIONS = [
  { name: "isci", label: "ISCI" },
  { name: "name", label: "Name" },
  { name: "length", label: "Length" },
  { name: "liveLinear", label: "Live Linear" },
  { name: "liveStreaming", label: "Live Streaming" },
];

const MediaPlayer = ({ selectedCreative }) => {
  if (R.includes("audio", selectedCreative.media_types)) {
    return (
      <div className="audioContainer">
        <Audio
          controls
          className="audioPlayer"
          src={`https://cdn.blisspointmedia.com/creatives/${selectedCreative.file}.mp3`}
          unloader={<h3>No Audio File</h3>}
        />
      </div>
    );
  } else {
    return (
      <div className="videoContainer">
        <Video
          src={`https://cdn.blisspointmedia.com/creatives/${selectedCreative.file}.mp4`}
          poster={`https://cdn.blisspointmedia.com/creatives/${selectedCreative.file}.png`}
          controls
          className="videoPlayer"
          unloader={<h3>No Video File</h3>}
        />
      </div>
    );
  }
};

const selectedData = ({ live, creativeMap }) => {
  if (!R.keys(creativeMap).length) {
    return [];
  }

  let creativeList = [];

  for (let creative of R.keys(creativeMap)) {
    if (creativeMap[creative].avail === "N" && creativeMap[creative].hd) {
      if (!(live ^ (creativeMap[creative].liveStreaming || creativeMap[creative].liveLinear))) {
        creativeList.push(creativeMap[creative]);
      }
    }
  }

  return creativeList;
};

const CreativeView = () => {
  const { company } = useLocation();
  const { creativeMap } = useCreativeMap({
    company,
    mediaTypes: ["streaming", "audio", "linear"],
  });
  const [live, setLive] = useState(true);
  const creativeList = selectedData({ live, creativeMap });
  const [filter, setFilter] = useStateFunction(() => true);

  const filterBarLines = useMemo(
    () => R.project(["isci", "name", "length", "liveLinear", "liveStreaming"], creativeList || []),
    [creativeList]
  );

  const filteredCreativeList = useMemo(() => {
    if (creativeList) {
      return R.pipe(R.filter(filter), R.sortBy(R.prop("name")))(creativeList);
    }
  }, [creativeList, filter]);

  const [selectedCreative, setSelectedCreative] = useState();

  const ListRow = useCallback(
    ({ index, style }) => {
      let creative = filteredCreativeList[index];
      return (
        <ListGroup.Item
          title={creative.name}
          key={creative.isci}
          className="creativeRow"
          onClick={() => setSelectedCreative(creative)}
          active={(selectedCreative || {}).isci === creative.isci}
          style={style}
        >
          <div className="thumbnailContainer">
            {R.includes("audio", creative.media_types) ? (
              <div className="audioThumbnail">
                <MdHeadset size={25} />
                <div>{creative.isci}</div>
              </div>
            ) : (
              /*<Img
                className="videoThumbnail"
                src={`https://cdn.blisspointmedia.com/creatives/${creative.isci}.png`}
                unloader={<div className="?Unloader">{creative.isci}</div>}
              />*/ <div></div>
            )}
          </div>
          <div className="descriptions">
            <div className="title">{creative.name}</div>
            <div className="isci">{creative.isci}</div>
            <div className="length">{`${creative.length}s`}</div>
          </div>
        </ListGroup.Item>
      );
    },
    [filteredCreativeList, selectedCreative]
  );

  return (
    <Page title="Creatives" pageType="Creatives">
      {filteredCreativeList ? (
        <div className="creativeViewPage">
          <div className="filterBarContainer">
            <OldFilterBar
              options={FILTER_BAR_OPTIONS}
              lines={filterBarLines}
              onFilter={setFilter}
            />
          </div>
          <div className="creativeViewContent">
            <div className="leftPane">
              <ButtonGroup toggle className="toggleButtons">
                <Button active={live} onClick={() => setLive(true)}>
                  Live
                </Button>
                <Button active={!live} onClick={() => setLive(false)}>
                  Archived
                </Button>
              </ButtonGroup>
              <ListGroup className="creativeList">
                <AutoSizer>
                  {({ width, height }) => (
                    <List
                      height={height}
                      width={width}
                      itemCount={filteredCreativeList.length}
                      itemSize={120}
                      className="virtualizedCreativeList"
                    >
                      {ListRow}
                    </List>
                  )}
                </AutoSizer>
              </ListGroup>
            </div>
            <div className="rightPane">
              {selectedCreative && (
                <MediaPlayer selectedCreative={selectedCreative} key={selectedCreative.file} />
              )}
            </div>
          </div>
        </div>
      ) : (
        <FullPageSpinner />
      )}
    </Page>
  );
};

export default CreativeView;
