// Experiment for placement specs in streaming buying page.  If enabled, users will be able
// to use new streaming buying page which has additional inputs for orders.

const placementSpecsCompanies: string[] = [
  // "blisspoint",
  // Add companies here!
];

export const shouldEnablePlacementSpecs = (company: string): boolean => {
  return placementSpecsCompanies.includes(company);
};
