// Experiment that displays toggle which pulls cross channel data from platform table instead of analytics table

const platformVsAnalyticsCompanies: string[] = [
  "tula",
  "instacart",
  // Add companies here!
];

export const shouldEnablePlatformVsAnalytics = (company: string): boolean => {
  return platformVsAnalyticsCompanies.includes(company);
};
