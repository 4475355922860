import "./PaidMediaImpact.scss";
import React, { useCallback, useEffect, useState } from "react";
import WidgetContainer from "../../Components/WidgetContainer";
import ChartContainer from "../../Components/ChartContainer";
import { S3SignedUrlFetch } from "../../utils/fetch-utils";
import Papa from "papaparse";
import AreaChart from "../../Components/Charts/AreaChart";
import * as R from "ramda";
import { exportToExcel, downloadPNG } from "../../utils/download-utils";
import * as Dfns from "date-fns/fp";
import { DownloadDropdown, TextToggleButton } from "../../Components";
import MoreInfo from "../../MMM/MoreInfo";
import { Bar, BarChart, Legend, ResponsiveContainer, XAxis } from "recharts";
import { currFormat, percFormat, standardizeMetricName } from "./BrandImpactUtils";
import { MdArrowForward } from "react-icons/md";

interface PaidMediaImpactProps {
  company: string;
  groupBy: string;
  groupByMetric: string;
  setGroupByOptions: (options: any) => void;
}

export const GROUP_BY_OPTIONS_BRAND_HEALTH_METRIC = [
  { label: "Ad Awareness", value: "ad_awareness" },
  { label: "Awareness", value: "awareness" },
  { label: "Buzz", value: "buzz" },
  { label: "Consideration", value: "consideration" },
];

interface BrandHealthMetricMap {
  [brandHealthMetric: string]: {
    data: AggregatedPaidMediaData;
  };
}

interface AggregatedPaidMediaData {
  Channel: string;
  PaidMedia: number;
  OtherFactors: number;
}

interface ImplicationData {
  metric: string;
  additionalSpend: number;
}

type MetricOptionToolTipMap = {
  [key: string]: string;
};

const GROUP_BY_METRIC_MAP: MetricOptionToolTipMap = {
  ad_awareness:
    "Respondents are asked to select from a list of brands which they have seen or heard advertising for recently. If they select your brand, that counts as a positive response.",
  awareness:
    "Respondents are asked to select from a list of brands which they have ever heard of. If they select your brand, that counts as a positive response.",
  buzz:
    "Respondents are asked to select from a list of brands which they have heard positive references to recently. If they select your brand, that counts as a positive response.",
  consideration:
    "Respondents are asked to select from a list of brands which they would consider purchasing whenever they’re next in the market. If they select your brand, that counts as a positive response.",
};

const capitalizeWords = (s: string): string => {
  return s
    .replace(/_/g, " ")
    .toLowerCase()
    .replace(/\b./g, a => a.toUpperCase());
};

const renderCustomLabel = props => {
  const { x, y, width, value } = props;
  const percentage = `${(value * 100).toFixed(2)}%`;

  return (
    <text x={x + width / 2} y={y} fill="#000" textAnchor="middle" dy={-6}>
      {percentage}
    </text>
  );
};

export const PaidMediaImpact: React.FC<PaidMediaImpactProps> = ({ groupByMetric }) => {
  const [implicationsData, setImplicationsData] = useState<ImplicationData[]>([]);
  const [selectedToggleOptionWAA, setSelectedToggleOptionWAA] = useState<string>("Over time");
  const [timeSeriesData, setTimeSeriesData] = useState<any[]>([]);
  const [barChartData, setBarChartData] = useState<any[]>([]);

  useEffect(() => {
    const fetchBarChartData = async () => {
      try {
        const brandHealthMetricDataMap: BrandHealthMetricMap = {};
        for (let brandHealthMetric of GROUP_BY_OPTIONS_BRAND_HEALTH_METRIC) {
          let brandHealthMetricValue = brandHealthMetric.value;
          let barChartResponse = await S3SignedUrlFetch(
            `bpm-miguel-test/bh_robyn_b1_main_demo/out/${brandHealthMetricValue}_p/time_series_decomp.csv`
          );
          const barChartText = await barChartResponse.text();
          const { data: barChartParsed }: Papa.ParseResult<any> = Papa.parse(barChartText, {
            header: true,
            skipEmptyLines: true,
            dynamicTyping: true,
          });

          const sums = barChartParsed.reduce(
            (acc, data) => {
              if (data.dep_var !== 0) {
                acc.PaidMedia += data.pm_value;
                acc.OtherFactors += data.other_value;
              }
              return acc;
            },
            { Channel: brandHealthMetricValue, PaidMedia: 0, OtherFactors: 0 }
          );
          brandHealthMetricDataMap[brandHealthMetricValue] = { data: sums };
        }
        let finalBarChartData: any[] = [];
        for (const [key] of Object.entries(brandHealthMetricDataMap)) {
          let paidMedia = brandHealthMetricDataMap[key].data.PaidMedia;
          let otherFactors = brandHealthMetricDataMap[key].data.OtherFactors;
          let total = paidMedia + otherFactors;
          let totalMediaEffectPercent = paidMedia / total;
          let otherEffectPercent = otherFactors / total;
          finalBarChartData.push({
            key: key,
            paidMedia: totalMediaEffectPercent,
            otherFactors: otherEffectPercent,
          });
        }
        setBarChartData(finalBarChartData);
      } catch (error) {
        console.error("Failed to fetch or parse data:", error);
      }
    };

    const fetchTimeSeriesAndImplicationsData = async () => {
      try {
        let implicationsResponse = await S3SignedUrlFetch(
          "bpm-miguel-test/bh_robyn_b1_main_demo/out/stage_1_topline.csv"
        );
        const implicationsText = await implicationsResponse.text();
        const { data: implicationsParsed }: Papa.ParseResult<ImplicationData> = Papa.parse(
          implicationsText,
          {
            header: true,
            skipEmptyLines: true,
            dynamicTyping: true,
          }
        );

        const implicationsData: ImplicationData[] = implicationsParsed.map((item: any) => ({
          metric: item.metric,
          additionalSpend: item.additional_spend_per_1_perc,
        }));
        setImplicationsData(implicationsData);

        let timeSeriesResponse = await S3SignedUrlFetch(
          `bpm-miguel-test/bh_robyn_b1_main_demo/out/${groupByMetric}_p/time_series_decomp.csv`
        );
        const timeSeriesText = await timeSeriesResponse.text();

        const { data: timeSeriesParsed }: Papa.ParseResult<any> = Papa.parse(timeSeriesText, {
          header: true,
          skipEmptyLines: true,
          dynamicTyping: true,
        });

        const dataFixed = timeSeriesParsed.map((item: any) => ({
          date: item.date,
          PaidMedia: item.pm_value,
          OtherFactors: item.other_value,
        }));

        setTimeSeriesData(dataFixed);
      } catch (error) {
        console.error("Failed to fetch or parse data:", error);
      }
    };

    fetchTimeSeriesAndImplicationsData();
    fetchBarChartData();
  }, [groupByMetric]);

  const excelDownloadWeeklyAdAwarenessAreaChart = useCallback(() => {
    exportToExcel(timeSeriesData, "weekly_ad_awareness_area_chart");
  }, [timeSeriesData]);

  const pngDownloadWeeklyAdAwarenessAreaGraph = useCallback(async () => {
    await downloadPNG(".leftLeft .chartContainer .contents", "weekly_ad_awareness_area_graph");
  }, []);

  const excelDownloadWeeklyAdAwarenessBarChart = useCallback(() => {
    exportToExcel(barChartData, "weekly_ad_awareness_bar_chart");
  }, [barChartData]);

  const pngDownloadWeeklyAdAwarenessBarChart = useCallback(async () => {
    await downloadPNG(".leftLeft .chartContainer .contents", "weekly_ad_awareness_bar_chart");
  }, []);

  return (
    <WidgetContainer
      collapsible
      header={
        <>
          How does our paid media impact{" "}
          <span style={{ fontWeight: 600 }}>{standardizeMetricName(groupByMetric)}</span>?
        </>
      }
      subHeader={
        <>
          {`Attribution of ${standardizeMetricName(
            groupByMetric
          )} to media investment based on model parameter estimates.`}
          <MoreInfo rightLabel="More info" size="sm">
            {`Calculated for each point in time as (${groupByMetric} at that point in time) - (level of media investments at that point in time) x (model parameter estimates for those media inputs)`}
          </MoreInfo>
        </>
      }
    >
      <div className="left">
        <div className="leftLeft">
          <ChartContainer
            enableHoverDesign
            rightActions={
              <div className="paidMediaImpactRightActions">
                <TextToggleButton
                  design="secondary"
                  options={["Total", "Over time"]}
                  selectedOption={selectedToggleOptionWAA}
                  onChange={setSelectedToggleOptionWAA}
                ></TextToggleButton>
                <DownloadDropdown
                  size="sm"
                  onClickOptions={[
                    selectedToggleOptionWAA === "Over time"
                      ? excelDownloadWeeklyAdAwarenessAreaChart
                      : excelDownloadWeeklyAdAwarenessBarChart,
                    selectedToggleOptionWAA === "Over time"
                      ? pngDownloadWeeklyAdAwarenessAreaGraph
                      : pngDownloadWeeklyAdAwarenessBarChart,
                  ]}
                />
              </div>
            }
            title={`Weekly ${standardizeMetricName(groupByMetric)}`}
            titleAfterDashText="12/20/20 – 12/24/23"
            beforeTooltipText="Positive Response"
            tooltipText="Positive response indicates the percentage of survey respondents expressing favorable perceptions or experiences related to each brand health metric."
          >
            {!R.isEmpty(timeSeriesData) && selectedToggleOptionWAA === "Over time" && (
              <AreaChart
                data={timeSeriesData}
                xAxisDataKey="date"
                xAxisTickFormatter={val => Dfns.format("M/dd/yy", new Date(`${val}`))}
                dateGrouping="Week"
                yAxisTickFormatter={value => `${value}%`}
                yAxisWidth={150}
                colorOverrides={{
                  [`${standardizeMetricName(groupByMetric)} From Paid Media`]: "#8254FF",
                  [`${standardizeMetricName(groupByMetric)} From Other Factors`]: "#E5E9F2",
                }}
                areas={[
                  {
                    name: `${standardizeMetricName(groupByMetric)} From Other Factors`,
                    dataKey: "OtherFactors",
                    toolTip: GROUP_BY_METRIC_MAP[groupByMetric],
                  },
                  {
                    name: `${standardizeMetricName(groupByMetric)} From Paid Media`,
                    dataKey: "PaidMedia",
                  },
                ]}
                tooltipFormatter={val => {
                  if (!val) {
                    return val;
                  }
                  return percFormat(val);
                }}
                reverseToolTipItems={true}
                reverseLegend={true}
              ></AreaChart>
            )}
            {!R.isEmpty(barChartData) &&
              selectedToggleOptionWAA === "Total" &&
              barChartData.map((item, index) => (
                <div key={index}>
                  <ResponsiveContainer width="100%" height="100%" minWidth={100}>
                    <BarChart
                      width={500}
                      height={300}
                      data={[item]}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <XAxis
                        dataKey="key"
                        orientation="top"
                        axisLine={false}
                        tickLine={false}
                        tickFormatter={capitalizeWords}
                        tick={
                          groupByMetric === item.key
                            ? {
                                dy: -15,
                                fill: "#1F003F",
                                fontWeight: 600,
                              }
                            : {
                                dy: -15,
                                fill: "#1F003F",
                                fontWeight: 400,
                              }
                        }
                      />
                      <Legend iconSize={0} layout="horizontal" />
                      <Bar
                        dataKey="paidMedia"
                        fill={groupByMetric === item.key ? "#946DFF" : "#F1F3F9"}
                        name="Paid Media"
                        label={renderCustomLabel}
                      />
                      <Bar
                        dataKey="otherFactors"
                        fill={groupByMetric === item.key ? "#A8A8A8" : "#F1F3F9"}
                        name="Other Factors"
                        label={renderCustomLabel}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              ))}
          </ChartContainer>
        </div>
        <div className="leftRight">
          <ChartContainer title={"Implications"} enableHoverDesign>
            <div className="brandEquityImplication">
              <div className="titleRowStyle">
                <div className="titleStyleLeft">Investment Increase</div>
                <div className="titleStyleRight">Brand Health Impact</div>
              </div>
              {implicationsData.map((item, index) => (
                <div key={index} className="rowStyle">
                  <div
                    className={
                      standardizeMetricName(item.metric) === standardizeMetricName(groupByMetric)
                        ? "spendText rowStyleColorSpendText"
                        : "spendText"
                    }
                  >
                    {`+ ${currFormat(implicationsData[index].additionalSpend, 0)}`}
                    <span className="cadenceText">{" per week"}</span>
                  </div>
                  <div
                    className={
                      standardizeMetricName(item.metric) === standardizeMetricName(groupByMetric)
                        ? "arrowIcon rowStyleColorArrowIcon"
                        : "arrowIcon"
                    }
                  >
                    <MdArrowForward></MdArrowForward>
                  </div>
                  <div
                    className={
                      standardizeMetricName(item.metric) === standardizeMetricName(groupByMetric)
                        ? "metricText rowStyleColorMetricText"
                        : "metricText"
                    }
                  >
                    {" "}
                    {`+1% ${standardizeMetricName(implicationsData[index].metric)}`}
                  </div>
                </div>
              ))}
              <div className="endTextRow">
                <div className="endTextRowStyle">
                  The estimates of required investment — in each case to raise a brand health metric
                  by 1% — assume a continuation of the current investment composition.
                </div>
              </div>
            </div>
          </ChartContainer>
        </div>
      </div>
    </WidgetContainer>
  );
};

export default PaidMediaImpact;
