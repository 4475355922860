import "./SegmentationIdCreation.scss";
import React, { useState, useMemo, useCallback, useEffect } from "react";
import { RouteComponentProps } from "@reach/router";
import * as R from "ramda";
import { MdAdd, MdClose, MdContentCopy, MdSave } from "react-icons/md";
import { useSetError } from "../redux/modals";
import {
  BPMButton,
  BPMTable,
  DownloadDropdown,
  FullPageSpinner,
  Header,
  SelfFocusingSelect,
  Spinner,
  TextToggleButton,
} from "../Components";
import { awaitJSON, SegmentationMappingLambdaFetch } from "../utils/fetch-utils";
import { useCompanyInfo } from "../redux/company";
import { CustomSegmentsData } from "./SegmentationMapping";
import { downloadPNG, exportToExcel } from "../utils/download-utils";
import { Button } from "react-bootstrap";

enum TabView {
  CreateNewIDs = "Create new ID",
  ExistingIDs = "Existing IDs",
}

interface SegmentationIdCreationProps extends RouteComponentProps {
  customSegments: CustomSegmentsData[] | undefined;
  setCustomSegments: (value: React.SetStateAction<CustomSegmentsData[] | undefined>) => void;
}

interface CustomSegmentMapFromNumberToName {
  [key: number]: string;
}

const areAllSegmentValuesNotNull = (
  tableRows: Record<string, any>[],
  uniqueCustomSegmentsNames: string[]
) => {
  const allValuesFilled = tableRows.every(row =>
    uniqueCustomSegmentsNames.every(
      segmentName => row[segmentName] !== null && row[segmentName] !== undefined
    )
  );
  if (!allValuesFilled) {
    return false;
  }
  const uniqueRows = new Set(
    tableRows.map(row => uniqueCustomSegmentsNames.map(segmentName => row[segmentName]).join("|"))
  );

  const noDuplicates = uniqueRows.size === tableRows.length;

  return allValuesFilled && noDuplicates && tableRows.length > 0;
};

const SegmentationIdCreation = ({
  customSegments,
  setCustomSegments,
}: SegmentationIdCreationProps): JSX.Element => {
  const setError = useSetError();
  const { cid } = useCompanyInfo();
  const [newRow, setNewRow] = useState<Record<string, any>>({});
  const [tableRows, setTableRows] = useState<Record<string, any>[]>([]);
  const [focusedCell, setFocusedCell] = useState("");
  const [saving, setSaving] = useState(false);
  const [tabView, setTabView] = useState(TabView.CreateNewIDs);
  const [newId, setNewId] = useState<GenerateOrFetchSegmentationId[]>([]);
  const [uniqueCustomSegmentsName, setUniqueCustomSegmentsName] = useState<string[]>([]);
  const [segmentationIds, setSegmentationIds] = useState();
  const [tableHeight, setTableHeight] = useState("");
  useEffect(() => {
    (async () => {
      try {
        const res = await SegmentationMappingLambdaFetch("/getSegmentationIds", {
          params: { company: cid },
        });
        const data = await awaitJSON(res);
        setSegmentationIds(data);
      } catch (e) {
        const err = e as Error;
        setError({
          message: `Failed to get segmentation IDs. Error: ${err.message}`,
          reportError: err,
        });
      }
    })();
  }, [cid, setError]);
  const [
    customSegmentNameMapWordToNumber,
    setCustomSegmentNameMapWordToNumber,
  ] = useState<CustomSegmentMapFromNumberToName>({});
  const [
    customSegmentValueMapNumberToWord,
    setCustomSegmentValueMapNumberToWord,
  ] = useState<CustomSegmentMapFromNumberToName>({});

  // Set unique custom segments names
  useEffect(() => {
    if (customSegments) {
      const segmentNames = customSegments.map(segment => segment.segmentName);
      setUniqueCustomSegmentsName(segmentNames);
    }
  }, [customSegments]);

  useEffect(() => {
    if (customSegments) {
      const initNewRow = customSegments.reduce(
        (acc, curr) => ({ ...acc, [curr.segmentName]: null }),
        {}
      );

      const segmentNamesMapWordToNumber = customSegments.reduce((acc, curr) => {
        return { ...acc, [curr.segmentName]: curr.segmentId };
      }, {} as CustomSegmentMapFromNumberToName);

      const segmentValuesMapNumberToWord = customSegments.reduce((acc, curr) => {
        const valuesMap = curr.values.reduce((map, { valueId, valueName }) => {
          map[valueId] = valueName;
          return map;
        }, {} as CustomSegmentMapFromNumberToName);
        return { ...acc, ...valuesMap };
      }, {} as CustomSegmentMapFromNumberToName);

      setCustomSegmentNameMapWordToNumber(segmentNamesMapWordToNumber);
      setCustomSegmentValueMapNumberToWord(segmentValuesMapNumberToWord);
      setTableRows([initNewRow]);
      setNewRow(initNewRow);
    }
  }, [setError, cid, customSegments]);

  const excelDownloadSegmentationIds = useCallback(() => {
    exportToExcel(segmentationIds || [], `${cid}_segmentation_ids`);
  }, [segmentationIds, cid]);

  const pngDownloadSegmentationIds = useCallback(async () => {
    await downloadPNG(".BPMTableContainer .stickyTableOuter", `${cid}_segmentation_ids`);
  }, [cid]);

  const dropdownOptions = useMemo(() => {
    if (!customSegments) {
      return {};
    }
    let options: Record<string, any[]> = {};
    for (let segment of customSegments) {
      options[segment.segmentName] = segment.values.map((value: any) => ({
        label: value.valueName,
        value: value.valueId,
      }));
    }
    return options;
  }, [customSegments]);

  const segmentValueIdToName = useMemo(() => {
    if (!customSegments) {
      return {};
    }
    let valueIdToName: Record<string, string> = {};
    for (let segment of customSegments) {
      for (let value of segment.values) {
        valueIdToName[value.valueId] = value.valueName;
      }
    }
    return valueIdToName;
  }, [customSegments]);

  const makeTableHeader = useCallback(
    segment => {
      const { segmentName } = segment;
      return {
        label: segmentName,
        name: segmentName,
        flex: 1,
        renderer: (row: any, i: number) => {
          const value = row[segmentName] || "";
          const label = segmentValueIdToName[value];
          const focusedCellKey = `${segmentName}-${i}`;
          return (
            <>
              {focusedCell === focusedCellKey ? (
                <SelfFocusingSelect
                  className="selectContainer"
                  menuPortalTarget={document.body}
                  value={{
                    label,
                    value,
                  }}
                  onBlur={() => setFocusedCell("")}
                  placeholder="Select value"
                  onChange={selection =>
                    setTableRows(prevRows =>
                      prevRows.map((r, index) =>
                        index === i ? { ...r, [segmentName]: selection?.value } : r
                      )
                    )
                  }
                  options={dropdownOptions[segmentName]}
                  isClearable={false}
                  defaultMenuIsOpen
                />
              ) : (
                <div className="editableCell" onClick={() => setFocusedCell(focusedCellKey)}>
                  <span className="cellText">{label || "--"}</span>
                </div>
              )}
            </>
          );
        },
      };
    },
    [dropdownOptions, focusedCell, segmentValueIdToName]
  );

  const tableHeaders: Header[] = useMemo(() => {
    if (!customSegments) {
      return [];
    } else {
      return customSegments.map((segment: any) => makeTableHeader(segment));
    }
  }, [makeTableHeader, customSegments]);

  interface GenerateOrFetchSegmentationId {
    segmentationId: string;
    isNew: boolean;
    segmentMap: Record<string, number | string>;
  }

  const saveChanges = useCallback(async () => {
    try {
      setSaving(true);
      const newIds: GenerateOrFetchSegmentationId[] = [];
      setNewId([]);
      for (const row of tableRows) {
        const filteredNulls: Record<string, any> = R.pickBy(R.complement(R.isNil), row);
        const transformedObject = Object.keys(filteredNulls).reduce((acc, key) => {
          const newKey = customSegmentNameMapWordToNumber[key];
          if (newKey !== undefined) {
            acc[newKey] = filteredNulls[key];
          }
          return acc;
        }, {} as Record<string, any>);
        const res = await SegmentationMappingLambdaFetch("/generateOrFetchSegmentationID", {
          method: "POST",
          body: { company: cid, segmentMap: transformedObject },
        });
        const { segmentationId, isNew }: GenerateOrFetchSegmentationId = await awaitJSON(res);
        const segmentMapWithNames = Object.entries(filteredNulls).reduce((acc, [key, value]) => {
          const segmentName: string = key;
          const segmentValue: string = customSegmentValueMapNumberToWord[value];

          return {
            ...acc,
            [segmentName]: segmentValue,
          };
        }, {} as Record<string, string>);
        newIds.push({ segmentationId, isNew, segmentMap: segmentMapWithNames });
      }

      setSaving(false);
      setNewId(newIds);
      const initNewRow = (customSegments || []).reduce(
        (acc, curr) => ({ ...acc, [curr.segmentName]: null }),
        {}
      );
      setNewRow(initNewRow);
      setTableRows([initNewRow]);
    } catch (e) {
      const err = e as Error;
      setError({
        message: `Failed to save new segmentation ID. Error: ${err.message}`,
        reportError: err,
      });
      setSaving(false);
    }
  }, [
    customSegments,
    customSegmentNameMapWordToNumber,
    tableRows,
    cid,
    customSegmentValueMapNumberToWord,
    setError,
  ]);

  useEffect(() => {
    const startHeight = 50;
    const rowHeight = 50;
    const maxRows = 8;
    const newHeight =
      tableRows.length > maxRows
        ? `${maxRows * rowHeight + startHeight}px`
        : `${tableRows.length * rowHeight + startHeight}px`;
    setTableHeight(newHeight);
  }, [tableRows]);

  const newIds = newId.filter(item => item.isNew);
  const existingIds = newId.filter(item => !item.isNew);

  let renderItem: JSX.Element;
  switch (tabView) {
    case TabView.CreateNewIDs:
      renderItem = (
        <div className="createNewIdView">
          <div className="explainerRow">
            <div className="explainer">
              Use this to create new Segmentation IDs that are derived from selecting a unique
              combination of segment values below.
            </div>
            <div>
              <Button
                onClick={() => setTableRows(prevRows => [...prevRows, newRow])}
                size="sm"
                variant="outline-primary"
              >
                <MdAdd />
              </Button>
              <Button
                onClick={() =>
                  setTableRows(prevRows => {
                    const lastRow = prevRows[prevRows.length - 1];
                    return lastRow ? [...prevRows, { ...lastRow }] : prevRows;
                  })
                }
                size="sm"
                variant="outline-primary"
              >
                <MdContentCopy />
              </Button>
              <Button
                size="sm"
                variant="outline-primary"
                onClick={() => setTableRows(prevRows => prevRows.slice(0, -1))}
              >
                <MdClose />
              </Button>
            </div>
          </div>
          <div className="newSegmentationIDstable" style={{ height: tableHeight }}>
            <BPMTable data={tableRows} headers={tableHeaders} filterBar={false} />
          </div>
          {newId.length >= 1 && (
            <div className="newId">
              {newIds.length > 0 && (
                <div className="spacingBelow">
                  Your new segmentation {newIds.length > 1 ? "IDs are" : "ID is"}{" "}
                  <strong>{newIds.map(item => item.segmentationId).join(", ")}</strong>. In the
                  relevant advertising platforms, paste this ID where you'd normally define a
                  Campaign, Ad Group, Ad, or equivalent. This will automatically tag the media with
                  the segment values that you selected. You can view all IDs on the "Existing IDs"
                  tab.
                </div>
              )}

              {existingIds.length > 0 &&
                existingIds.map(item => (
                  <div key={item.segmentationId} className="spacingBelow">
                    The following id, <strong>{item.segmentationId}</strong>, already exists with
                    the following segments:
                    {Object.entries(item.segmentMap).map(([key, value]) => (
                      <div key={key}>
                        {key}: {value}
                      </div>
                    ))}
                  </div>
                ))}
            </div>
          )}
        </div>
      );
      break;
    case TabView.ExistingIDs:
      renderItem = (
        <ExistingIds
          segments={(customSegments || []).map(row => row.segmentName)}
          segmentationIds={segmentationIds}
          setError={setError}
        />
      );
      break;
    default:
      renderItem = (
        <BPMTable
          noRowsRenderer={() => <div className="noRows">Add new segmentation IDs.</div>}
          data={[newRow]}
          headers={tableHeaders}
          filterBar={false}
        />
      );
  }

  return (
    <div className="segmentationIdCreation">
      <div className="controls">
        <TextToggleButton
          options={[TabView.CreateNewIDs, TabView.ExistingIDs]}
          selectedOption={tabView}
          onChange={setTabView}
        />
        <BPMButton
          className="saveIdButton"
          onClick={saveChanges}
          variant="success"
          size="sm"
          disabled={!areAllSegmentValuesNotNull(tableRows, uniqueCustomSegmentsName)}
        >
          {saving ? <Spinner /> : <MdSave />}
        </BPMButton>
        {tabView === TabView.ExistingIDs && (
          <DownloadDropdown
            size="sm"
            onClickOptions={[excelDownloadSegmentationIds, pngDownloadSegmentationIds]}
          />
        )}
      </div>
      {R.isEmpty(newRow) ? <FullPageSpinner /> : renderItem}
    </div>
  );
};

interface ExistingIdProps {
  segments: string[];
  segmentationIds: any;
  setError: (error: { message: string; reportError: Error }) => void;
}

const ExistingIds: React.FC<ExistingIdProps> = ({ segments, segmentationIds, setError }) => {
  const tableHeaders: Header[] = useMemo(() => {
    if (!segments) {
      return [];
    } else {
      let headers: Header[] = [
        {
          label: "Segmentation ID",
          name: "segmentationId",
          flex: 1,
          renderer: data => <div style={{ fontWeight: 500 }}>{data.segmentationId}</div>,
        },
      ];
      segments.forEach(segment =>
        headers.push({
          label: segment,
          name: segment,
          flex: 1,
        })
      );
      return headers;
    }
  }, [segments]);
  return (
    <React.Fragment>
      {segmentationIds ? (
        <BPMTable data={segmentationIds} headers={tableHeaders} />
      ) : (
        <FullPageSpinner />
      )}
    </React.Fragment>
  );
};

export default SegmentationIdCreation;
