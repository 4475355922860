import React, { useMemo } from "react";
import "./DateInputField.scss";

const MAX_DATE = {
  1: 31,
  2: 29,
  3: 31,
  4: 30,
  5: 31,
  6: 30,
  7: 31,
  8: 31,
  9: 30,
  10: 31,
  11: 30,
  12: 31,
};

const checkMonth = (month: string) => {
  const parsedMonth = +month;
  if (month.length === 1) {
    return parsedMonth <= 1;
  }
  return parsedMonth > 0 && parsedMonth <= 12;
};

const checkDay = (month: string, day: string) => {
  const parsedMonth = +month;
  const parsedDay = +day;
  if (day.length === 1) {
    return parsedDay <= MAX_DATE[parsedMonth] / 10;
  }
  return parsedDay <= MAX_DATE[parsedMonth];
};

interface DateInputFieldProps {
  label: string;
  value: string;
  setValue?: React.Dispatch<React.SetStateAction<string>>;
  customValidation?: {
    isValid: (value: string) => boolean;
    errorMessage: string;
  };
  infoMessage?: string;
}

export const DateInputField: React.FC<DateInputFieldProps> = React.memo(
  ({ label, value, setValue, customValidation, infoMessage }) => {
    // Check if the date is valid as user types.
    const isValid = useMemo(() => {
      if (value.length === 1) {
        return checkMonth(value) && value.match(/^[0-9]+$/) !== null;
      } else if (value.length === 2) {
        return checkMonth(value) && value.match(/^[0-9][0-9]+$/) !== null;
      } else if (value.length === 3) {
        return checkMonth(value.substring(0, 2)) && value.match(/^[0-9][0-9][/]+$/) !== null;
      } else if (value.length === 4) {
        const month = value.substring(0, 2);
        const day = value.substring(3);
        return (
          checkMonth(month) && checkDay(month, day) && value.match(/^[0-9][0-9][/][0-9]+$/) !== null
        );
      } else if (value.length === 5) {
        const month = value.substring(0, 2);
        const day = value.substring(3);
        return (
          checkMonth(month) &&
          checkDay(month, day) &&
          value.match(/^[0-9][0-9][/][0-9][0-9]+$/) !== null
        );
      } else if (value.length === 6) {
        const month = value.substring(0, 2);
        const day = value.substring(3, 5);
        return (
          checkMonth(month) &&
          checkDay(month, day) &&
          value.match(/^[0-9][0-9][/][0-9][0-9][/]+$/) !== null
        );
      } else if (value.length === 7) {
        const month = value.substring(0, 2);
        const day = value.substring(3, 5);
        return (
          checkMonth(month) &&
          checkDay(month, day) &&
          value.match(/^[0-9][0-9][/][0-9][0-9][/][0-9]+$/) !== null
        );
      } else if (value.length === 8) {
        const month = value.substring(0, 2);
        const day = value.substring(3, 5);
        return (
          checkMonth(month) &&
          checkDay(month, day) &&
          value.match(/^[0-9][0-9][/][0-9][0-9][/][0-9][0-9]+$/) !== null
        );
      } else if (value.length === 9) {
        const month = value.substring(0, 2);
        const day = value.substring(3, 5);
        return (
          checkMonth(month) &&
          checkDay(month, day) &&
          value.match(/^[0-9][0-9][/][0-9][0-9][/][0-9][0-9][0-9]+$/) !== null
        );
      } else if (value.length === 10) {
        const month = value.substring(0, 2);
        const day = value.substring(3, 5);
        return (
          checkMonth(month) &&
          checkDay(month, day) &&
          value.match(/^[0-9][0-9][/][0-9][0-9][/][0-9][0-9][0-9][0-9]+$/) !== null
        );
      } else if (value.length > 10) {
        return false;
      } else {
        return true;
      }
    }, [value]);

    const customIsValid = useMemo(() => {
      if (value.length > 0 && isValid && customValidation) {
        return customValidation.isValid(value);
      }

      return true;
    }, [customValidation, isValid, value]);

    return (
      <div className="dateInputFieldContainer">
        <div>
          <div className="dateInputFieldLabel">{label}</div>
          <input
            className={`dateInputFieldValue${isValid ? "" : " invalid"}`}
            type="text"
            placeholder="mm/dd/yyyy"
            value={value}
            onChange={event => {
              const newValue = event.target.value;
              if (setValue) {
                setValue(newValue);
              }
            }}
          ></input>
        </div>
        {infoMessage && <div className="dateInputFieldInfo">{infoMessage}</div>}
        {!isValid && <div className={`dateInputFieldError ${label}`}>Invalid date format</div>}
        {!customIsValid && (
          <div className={`dateInputFieldError ${label}`}>{customValidation?.errorMessage}</div>
        )}
      </div>
    );
  }
);

export default DateInputField;
