// Experiment for new graph Streaming performance page.

const ipPerformanceCompanies: string[] = [
  "blacktux",
  "blinkist",
  "condenast",
  "equifax",
  "figure",
  "flamingo",
  "halocollar",
  "honest",
  "jackpocket",
  "meandem",
  "movado",
  "quince",
  "renttherunway",
  "sandlandsleep",
  "sorare",
  "talkiatry",
  "talkspace",
  "tile",
  "zenbusiness",
  "zola",
];

export const shouldEnableGraphPerformance = (company: string): boolean => {
  return !ipPerformanceCompanies.includes(company) && !company.includes("instacart");
};

const audioIpPerformanceCompanies: string[] = [
  "afterpay",
  "blacktux",
  "blinkist",
  "condenast",
  "equifax",
  "figure",
  "flamingo",
  "halocollar",
  "homechef",
  "honest",
  "jackpocket",
  "meandem",
  "misfitsmarket",
  "movado",
  "quince",
  "renttherunway",
  "sandlandsleep",
  "sorare",
  "talkiatry",
  "talkspace",
  "tile",
  "zenbusiness",
  "zola",
];

export const shouldEnableAudioGraphPerformance = (company: string): boolean => {
  return !audioIpPerformanceCompanies.includes(company) && !company.includes("instacart");
};

const displayGraphPerformanceCompanies: string[] = [];

export const shouldEnableDisplayGraphPerformance = (company: string): boolean => {
  return displayGraphPerformanceCompanies.includes(company);
};
