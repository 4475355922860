import * as S from "@blisspointmedia/bpm-types/dist/StreamingPerformance";
import * as P from "@blisspointmedia/bpm-types/dist/Performance";

import { DateRange } from "../../utils/types";
import {
  ColumnMetaData,
  DimensionColumnMetaData,
  OverviewConfigItem,
  PercentageDisplayMode,
  toPretty1000sInteger,
  toPrettyNumber,
  toPrettySpend,
} from "../performanceUtils";

export const BETWEEN_OPTIONS: S.ImpressionCountSelector[] = [
  "Imp's Served Between",
  "Response Between",
];

export const DIMENSION_COLUMN_METADATA_MAP: Record<
  S.PerformanceDimensionColumnType,
  DimensionColumnMetaData
> = {
  "Network Logo": {
    minWidth: 73,
    dimension: "Network",
    label: "\u00a0",
  },
  Description: {
    minWidth: 150,
    dimension: "Network",
    label: "Description",
  },
  "Network Name": {
    minWidth: 100,
    dimension: "Network",
    label: "Network",
  },
  "Derived ID": {
    minWidth: 150,
    dimension: "Network",
    defaultAdmin: true,
  },
  Creative: {
    dimension: "Creative",
    minWidth: 200,
  },
  "Creative Thumbnail": {
    dimension: "Creative",
    minWidth: 100,
    label: "\u00a0",
  },
  Length: {
    dimension: "Length",
    minWidth: 73,
  },
  Size: {
    dimension: "Size",
    minWidth: 73,
  },
  Device: {
    dimension: "DeviceOS",
    minWidth: 100,
    label: "Device",
  },
  OS: {
    dimension: "DeviceOS",
    minWidth: 100,
    label: "OS",
  },
  "Device Logo": {
    dimension: "DeviceOS",
    minWidth: 73,
    label: "Device",
    centerHeader: true,
  },
  "OS Logo": {
    dimension: "DeviceOS",
    minWidth: 73,
    label: "OS",
    centerHeader: true,
  },
  "Network Group Name": {
    dimension: "Network Group",
    minWidth: 100,
    label: "Network Group",
    centerHeader: true,
  },
  "Network Group Logo": {
    dimension: "Network Group",
    minWidth: 100,
    label: "Network Group",
    centerHeader: true,
  },
};

export const getColumnMetadataMap = (
  isGraph = false
): Partial<Record<S.ColumnType, ColumnMetaData>> => {
  let columnMetadataMap: Partial<Record<S.ColumnType, ColumnMetaData>> = {
    avgFreq: {
      formatter: toPrettyNumber,
      minWidth: 100,
      prettyName: isGraph ? "Average Frequency" : "Avg. Freq.",
      defaultLabel: isGraph ? "Avg. Freq." : "Avg.",
      decimals: 1,
      percentageDisplayMode: PercentageDisplayMode.DISABLE,
      category: isGraph ? P.StreamingColumnCategory.DIRECT : undefined,
    },
    completionsRate: {
      formatter: toPrettyNumber,
      minWidth: 100,
      decimals: 2,
      prettyName: "Completion Rate",
      percentageDisplayMode: PercentageDisplayMode.DISABLE,
      contentReplacement: {
        replacementString: "-",
        threshold: 0,
      },
      category: isGraph ? P.StreamingColumnCategory.DIRECT : undefined,
    },
    cpm: {
      formatter: toPrettySpend,
      minWidth: 100,
      prettyName: "eCPM",
      decimals: 2,
      minIsBest: true,
      percentageDisplayMode: PercentageDisplayMode.DISABLE,
      category: isGraph ? P.StreamingColumnCategory.DIRECT : undefined,
    },
    imps: {
      formatter: toPretty1000sInteger,
      minWidth: 100,
      prettyName: "Imp's ('000s)",
      percentageDisplayMode: PercentageDisplayMode.SHOW,
      category: isGraph ? P.StreamingColumnCategory.DIRECT : undefined,
    },
    residImps: {
      formatter: toPretty1000sInteger,
      minWidth: 100,
      prettyName: isGraph ? "Matchable Imp's (000s)" : "Resid. Imp's (000s)",
      percentageDisplayMode: PercentageDisplayMode.SHOW,
      category: isGraph ? P.StreamingColumnCategory.DIRECT : undefined,
    },
    respImps: {
      formatter: toPrettyNumber,
      minWidth: 100,
      prettyName: "Resp. Imp's",
      percentageDisplayMode: PercentageDisplayMode.SHOW,
      category: isGraph ? P.StreamingColumnCategory.DIRECT : undefined,
    },
    respIndex: {
      formatter: toPrettyNumber,
      minWidth: 100,
      prettyName: "Resp. Index",
      decimals: 2,
      percentageDisplayMode: PercentageDisplayMode.DISABLE,
      category: isGraph ? P.StreamingColumnCategory.DIRECT : undefined,
    },
    medianFreq: {
      formatter: toPrettyNumber,
      minWidth: 100,
      prettyName: "Median Freq.",
      defaultLabel: isGraph ? "Median Freq." : "Median",
      percentageDisplayMode: PercentageDisplayMode.DISABLE,
      category: isGraph ? P.StreamingColumnCategory.DIRECT : undefined,
    },
    reachFreqPerIp: {
      formatter: toPrettyNumber,
      minWidth: 100,
      prettyName: "Reach Frequency Per IP ('000s)",
      defaultLabel: "Reach Freq. ('000s)",
      decimals: 2,
      percentageDisplayMode: PercentageDisplayMode.DISABLE,
      category: isGraph ? P.StreamingColumnCategory.DIRECT : undefined,
    },
    residResps: {
      formatter: toPrettyNumber,
      minWidth: 100,
      prettyName: isGraph ? "Matchable Resp's" : "Resid. Resp's",
      percentageDisplayMode: PercentageDisplayMode.SHOW,
      category: isGraph ? P.StreamingColumnCategory.DIRECT : undefined,
    },
    resps: {
      formatter: toPrettyNumber,
      minWidth: 100,
      prettyName: "Resp's",
      percentageDisplayMode: PercentageDisplayMode.SHOW,
      category: isGraph ? P.StreamingColumnCategory.DIRECT : undefined,
    },
    spend: {
      formatter: toPrettySpend,
      minWidth: 100,
      prettyName: "Spend",
      percentageDisplayMode: PercentageDisplayMode.SHOW,
      category: isGraph ? P.StreamingColumnCategory.DIRECT : undefined,
    },
    stddevFreqPerIp: {
      formatter: toPrettyNumber,
      minWidth: 100,
      prettyName: "Standard Deviation of Frequency Per IP",
      defaultLabel: "Standard Dev of Freq/IP",
      decimals: 2,
      percentageDisplayMode: PercentageDisplayMode.DISABLE,
      category: isGraph ? P.StreamingColumnCategory.DIRECT : undefined,
    },
    avgRevenueDirect: {
      formatter: toPrettySpend,
      minWidth: 100,
      prettyName: isGraph ? "Average Revenue" : "Avg Rev., Direct",
      defaultLabel: isGraph ? "AOV" : "Avg. Rev.",
      percentageDisplayMode: PercentageDisplayMode.DISABLE,
      category: isGraph ? P.StreamingColumnCategory.DIRECT : undefined,
    },
    cpxDirect: {
      formatter: toPrettySpend,
      minWidth: 100,
      prettyName: isGraph ? "CPX" : "CPX, Direct",
      defaultLabel: "CPX",
      minIsBest: true,
      contentReplacement: {
        replacementString: "-",
        threshold: 0,
      },
      zerosSortHigh: true,
      percentageDisplayMode: PercentageDisplayMode.DISABLE,
      category: isGraph ? P.StreamingColumnCategory.DIRECT : undefined,
    },
    cpxDirectRaw: {
      formatter: toPrettySpend,
      minWidth: 100,
      prettyName: isGraph ? "CPX" : "CPX, Direct (unadj.)",
      defaultLabel: "CPX",
      minIsBest: true,
      contentReplacement: {
        replacementString: "-",
        threshold: 0,
      },
      zerosSortHigh: true,
      percentageDisplayMode: PercentageDisplayMode.DISABLE,
      category: isGraph ? P.StreamingColumnCategory.DIRECT_UNADJ : undefined,
    },
    revenueDirect: {
      formatter: toPrettySpend,
      minWidth: 100,
      prettyName: isGraph ? "Total Revenue" : "Revenue, Direct",
      defaultLabel: isGraph ? "Total Revenue" : "Revenue",
      percentageDisplayMode: PercentageDisplayMode.HIDE,
      category: isGraph ? P.StreamingColumnCategory.DIRECT : undefined,
    },
    revenueDirectRaw: {
      formatter: toPrettySpend,
      minWidth: 100,
      prettyName: isGraph ? "Total Revenue" : "Revenue, Direct (unadj.)",
      defaultLabel: isGraph ? "Total Revenue" : "Revenue (unadj.)",
      percentageDisplayMode: PercentageDisplayMode.HIDE,
      category: isGraph ? P.StreamingColumnCategory.DIRECT_UNADJ : undefined,
    },
    roasDirect: {
      formatter: toPrettyNumber,
      minWidth: 100,
      prettyName: isGraph ? "ROAS" : "ROAS, Direct",
      defaultLabel: "ROAS",
      decimals: 2,
      percentageDisplayMode: PercentageDisplayMode.DISABLE,
      category: isGraph ? P.StreamingColumnCategory.DIRECT : undefined,
    },
    roasDirectRaw: {
      formatter: toPrettyNumber,
      minWidth: 100,
      prettyName: isGraph ? "ROAS" : "ROAS, Direct (unadj.)",
      defaultLabel: isGraph ? "ROAS" : "ROAS (unadj.)",
      decimals: 2,
      percentageDisplayMode: PercentageDisplayMode.DISABLE,
      category: isGraph ? P.StreamingColumnCategory.DIRECT_UNADJ : undefined,
    },
    volumeDirect: {
      formatter: toPrettyNumber,
      minWidth: 100,
      prettyName: "Volume",
      decimals: 0,
      percentageDisplayMode: PercentageDisplayMode.SHOW,
      category: isGraph ? P.StreamingColumnCategory.DIRECT : undefined,
    },
    volumeDirectRaw: {
      formatter: toPrettyNumber,
      minWidth: 100,
      prettyName: isGraph ? "Volume" : "Volume (unadj.)",
      percentageDisplayMode: PercentageDisplayMode.SHOW,
      category: isGraph ? P.StreamingColumnCategory.DIRECT_UNADJ : undefined,
    },
  };

  if (!isGraph) {
    columnMetadataMap = {
      ...columnMetadataMap,

      avgRevenueFractional: {
        formatter: toPrettySpend,
        minWidth: 100,
        prettyName: "Avg Rev., +Frac",
        defaultLabel: "Avg. Rev.",
        percentageDisplayMode: PercentageDisplayMode.DISABLE,
      },
      avgRevenueHalo: {
        formatter: toPrettySpend,
        minWidth: 100,
        prettyName: "Avg Rev., +Halo",
        defaultLabel: "Avg. Rev.",
        percentageDisplayMode: PercentageDisplayMode.DISABLE,
      },
      cpxFractional: {
        formatter: toPrettySpend,
        minWidth: 100,
        prettyName: "CPX, +Frac",
        defaultLabel: "CPX",
        minIsBest: true,
        contentReplacement: {
          replacementString: "-",
          threshold: 0,
        },
        zerosSortHigh: true,
        percentageDisplayMode: PercentageDisplayMode.DISABLE,
      },
      cpxHalo: {
        formatter: toPrettySpend,
        minWidth: 100,
        prettyName: "CPX, +Halo",
        defaultLabel: "CPX",
        minIsBest: true,
        contentReplacement: {
          replacementString: "-",
          threshold: 0,
        },
        zerosSortHigh: true,
        percentageDisplayMode: PercentageDisplayMode.DISABLE,
      },
      cpxHaloRaw: {
        formatter: toPrettySpend,
        minWidth: 100,
        prettyName: "CPX, Halo (unadj.)",
        defaultLabel: "CPX (unadj.)",
        minIsBest: true,
        contentReplacement: {
          replacementString: "-",
          threshold: 0,
        },
        zerosSortHigh: true,
        percentageDisplayMode: PercentageDisplayMode.DISABLE,
      },
      revenueFractional: {
        formatter: toPrettySpend,
        minWidth: 100,
        prettyName: "Revenue, +Frac",
        defaultLabel: "Revenue",
        percentageDisplayMode: PercentageDisplayMode.HIDE,
      },
      revenueHalo: {
        formatter: toPrettySpend,
        minWidth: 100,
        prettyName: "Revenue, +Halo",
        defaultLabel: "Revenue",
        percentageDisplayMode: PercentageDisplayMode.HIDE,
      },
      revenueHaloRaw: {
        formatter: toPrettySpend,
        minWidth: 100,
        prettyName: "Revenue, +Halo (unadj.)",
        defaultLabel: "Revenue (unadj.)",
        percentageDisplayMode: PercentageDisplayMode.HIDE,
      },
      roasFractional: {
        formatter: toPrettyNumber,
        minWidth: 100,
        prettyName: "ROAS, +Frac",
        defaultLabel: "ROAS",
        decimals: 2,
        percentageDisplayMode: PercentageDisplayMode.DISABLE,
      },
      roasHalo: {
        formatter: toPrettyNumber,
        minWidth: 100,
        prettyName: "ROAS, +Halo",
        defaultLabel: "ROAS",
        decimals: 2,
        percentageDisplayMode: PercentageDisplayMode.DISABLE,
      },
      roasHaloRaw: {
        formatter: toPrettyNumber,
        minWidth: 100,
        prettyName: "ROAS, +Halo (unadj.)",
        defaultLabel: "ROAS (unadj.)",
        decimals: 2,
        percentageDisplayMode: PercentageDisplayMode.DISABLE,
      },
      volumeFractional: {
        formatter: toPrettyNumber,
        minWidth: 100,
        prettyName: "Volume, +Frac",
        defaultLabel: "Volume",
        decimals: 0,
        percentageDisplayMode: PercentageDisplayMode.SHOW,
      },
      volumeHalo: {
        formatter: toPrettyNumber,
        minWidth: 100,
        prettyName: "Volume, +Halo",
        defaultLabel: "Volume",
        decimals: 0,
        percentageDisplayMode: PercentageDisplayMode.SHOW,
      },
      volumeHaloRaw: {
        formatter: toPrettyNumber,
        minWidth: 100,
        prettyName: "Volume, +Halo (unadj.)",
        defaultLabel: "Volume (unadj.)",
        percentageDisplayMode: PercentageDisplayMode.SHOW,
      },
    };
  } else {
    columnMetadataMap = {
      ...columnMetadataMap,
      avgRevenueDirectIncremental: {
        formatter: toPrettySpend,
        minWidth: 100,
        prettyName: "Average Revenue",
        defaultLabel: "AOV",
        percentageDisplayMode: PercentageDisplayMode.DISABLE,
        category: P.StreamingColumnCategory.INCREMENTAL_DIRECT,
      },
      avgRevenueIncremental: {
        formatter: toPrettySpend,
        minWidth: 100,
        prettyName: "Average Revenue",
        defaultLabel: "AOV",
        percentageDisplayMode: PercentageDisplayMode.DISABLE,
        category: P.StreamingColumnCategory.INCREMENTAL_FF,
      },
      avgRevenueFullFunnel: {
        formatter: toPrettySpend,
        minWidth: 100,
        prettyName: "Average Revenue",
        defaultLabel: "AOV",
        percentageDisplayMode: PercentageDisplayMode.DISABLE,
        category: P.StreamingColumnCategory.FULL_FUNNEL,
      },
      cpxDirectIncremental: {
        formatter: toPrettySpend,
        minWidth: 100,
        prettyName: "CPX",
        defaultLabel: "iCPX",
        minIsBest: true,
        contentReplacement: {
          replacementString: "-",
          threshold: 0,
        },
        zerosSortHigh: true,
        percentageDisplayMode: PercentageDisplayMode.DISABLE,
        category: P.StreamingColumnCategory.INCREMENTAL_DIRECT,
      },
      cpxIncremental: {
        formatter: toPrettySpend,
        minWidth: 100,
        prettyName: "CPX",
        defaultLabel: "iCPX",
        minIsBest: true,
        contentReplacement: {
          replacementString: "-",
          threshold: 0,
        },
        zerosSortHigh: true,
        percentageDisplayMode: PercentageDisplayMode.DISABLE,
        category: P.StreamingColumnCategory.INCREMENTAL_FF,
      },
      cpxFullFunnel: {
        formatter: toPrettySpend,
        minWidth: 100,
        prettyName: "CPX",
        minIsBest: true,
        contentReplacement: {
          replacementString: "-",
          threshold: 0,
        },
        zerosSortHigh: true,
        percentageDisplayMode: PercentageDisplayMode.DISABLE,
        category: P.StreamingColumnCategory.FULL_FUNNEL,
      },
      cpxFullFunnelRaw: {
        formatter: toPrettySpend,
        minWidth: 100,
        prettyName: "CPX",
        minIsBest: true,
        contentReplacement: {
          replacementString: "-",
          threshold: 0,
        },
        zerosSortHigh: true,
        percentageDisplayMode: PercentageDisplayMode.DISABLE,
        category: P.StreamingColumnCategory.FULL_FUNNEL_UNADJ,
      },
      revenueDirectIncremental: {
        formatter: toPrettySpend,
        minWidth: 100,
        prettyName: "Total Revenue",
        defaultLabel: "iRevenue",
        percentageDisplayMode: PercentageDisplayMode.HIDE,
        category: P.StreamingColumnCategory.INCREMENTAL_DIRECT,
      },
      revenueIncremental: {
        formatter: toPrettySpend,
        minWidth: 100,
        prettyName: "Total Revenue",
        defaultLabel: "iRevenue",
        percentageDisplayMode: PercentageDisplayMode.HIDE,
        category: P.StreamingColumnCategory.INCREMENTAL_FF,
      },
      revenueFullFunnel: {
        formatter: toPrettySpend,
        minWidth: 100,
        prettyName: "Total Revenue",
        percentageDisplayMode: PercentageDisplayMode.HIDE,
        category: P.StreamingColumnCategory.FULL_FUNNEL,
      },
      revenueFullFunnelRaw: {
        formatter: toPrettySpend,
        minWidth: 100,
        prettyName: "Total Revenue",
        percentageDisplayMode: PercentageDisplayMode.HIDE,
        category: P.StreamingColumnCategory.FULL_FUNNEL_UNADJ,
      },
      roasDirectIncremental: {
        formatter: toPrettyNumber,
        minWidth: 100,
        prettyName: "ROAS",
        defaultLabel: "iROAS",
        decimals: 2,
        percentageDisplayMode: PercentageDisplayMode.DISABLE,
        category: P.StreamingColumnCategory.INCREMENTAL_DIRECT,
      },
      roasIncremental: {
        formatter: toPrettyNumber,
        minWidth: 100,
        prettyName: "ROAS",
        defaultLabel: "iROAS",
        decimals: 2,
        percentageDisplayMode: PercentageDisplayMode.DISABLE,
        category: P.StreamingColumnCategory.INCREMENTAL_FF,
      },
      roasFullFunnel: {
        formatter: toPrettyNumber,
        minWidth: 100,
        prettyName: "ROAS",
        decimals: 2,
        percentageDisplayMode: PercentageDisplayMode.DISABLE,
        category: P.StreamingColumnCategory.FULL_FUNNEL,
      },
      roasFullFunnelRaw: {
        formatter: toPrettyNumber,
        minWidth: 100,
        prettyName: "ROAS",
        decimals: 2,
        percentageDisplayMode: PercentageDisplayMode.DISABLE,
        category: P.StreamingColumnCategory.FULL_FUNNEL_UNADJ,
      },
      volumeDirectIncremental: {
        formatter: toPrettyNumber,
        minWidth: 100,
        prettyName: "Volume",
        defaultLabel: "iVolume",
        decimals: 0,
        percentageDisplayMode: PercentageDisplayMode.SHOW,
        category: P.StreamingColumnCategory.INCREMENTAL_DIRECT,
      },
      volumeIncremental: {
        formatter: toPrettyNumber,
        minWidth: 100,
        prettyName: "Volume",
        defaultLabel: "iVolume",
        decimals: 0,
        percentageDisplayMode: PercentageDisplayMode.SHOW,
        category: P.StreamingColumnCategory.INCREMENTAL_FF,
      },
      volumeFullFunnel: {
        formatter: toPrettyNumber,
        minWidth: 100,
        prettyName: "Volume",
        decimals: 0,
        percentageDisplayMode: PercentageDisplayMode.SHOW,
        category: P.StreamingColumnCategory.FULL_FUNNEL,
      },
      percentDirectIncremental: {
        formatter: toPrettyNumber,
        minWidth: 100,
        prettyName: "% Incremental",
        defaultLabel: "% Incremental",
        decimals: 2,
        percentageDisplayMode: PercentageDisplayMode.DISABLE,
        category: P.StreamingColumnCategory.INCREMENTAL_DIRECT,
      },
      percentIncremental: {
        formatter: toPrettyNumber,
        minWidth: 100,
        prettyName: "% Incremental",
        defaultLabel: "% Incremental",
        decimals: 2,
        percentageDisplayMode: PercentageDisplayMode.DISABLE,
        category: P.StreamingColumnCategory.INCREMENTAL_FF,
      },
      volumeFullFunnelRaw: {
        formatter: toPrettyNumber,
        minWidth: 100,
        prettyName: "Volume",
        percentageDisplayMode: PercentageDisplayMode.SHOW,
        category: P.StreamingColumnCategory.FULL_FUNNEL_UNADJ,
      },
    };
  }

  return columnMetadataMap;
};

export const makeFetchKey = (
  id: number | undefined,
  dates: DateRange,
  branchBuild: string,
  kpi: string,
  lag: S.LagOption | ""
): string => `${id}_${dates.start}_${dates.end}_${branchBuild}_${kpi}_${lag}`;

export type IndexTotalsMap = Record<
  string,
  {
    totalRespondingImps: number;
    totalResidentialImps: number;
  }
>;

export const constructIndexTotalsMap = (
  columns: P.Column[],
  rows: S.PerformanceDataRow[],
  kpi: string,
  lag: S.LagOption
): IndexTotalsMap => {
  let indexTotalsMap: IndexTotalsMap = {};
  for (let column of columns) {
    if (column.type === "respIndex") {
      let fetchKey = S.getFetchKey({ kpi: column.kpi || kpi, lag: column.lag || lag });
      let totalRespondingImps = 0;
      let totalResidentialImps = 0;
      for (let row of rows) {
        totalRespondingImps += row.fetches[fetchKey]?.respImps || 0;
        totalResidentialImps += row.fetches[fetchKey]?.residImps || 0;
      }
      indexTotalsMap[fetchKey] = {
        totalRespondingImps,
        totalResidentialImps,
      };
    }
  }

  return indexTotalsMap;
};

export const performanceRowToTableRow = (
  row: S.PerformanceDataRow,
  columns: P.Column[],
  indexTotalsMap: IndexTotalsMap,
  company: string,
  kpi: string,
  lag: S.LagOption,
  isAdmin: boolean
): number[] => {
  const getRowValue = (column: P.Column): number => {
    let fetchKey = S.getFetchKey({ kpi: column.kpi || kpi, lag: column.lag || lag });
    let columnDataRow = row.fetches[fetchKey];
    if (!columnDataRow) {
      return 0;
    }
    if (
      [
        "imps",
        "spend",
        "residImps",
        "respImps",
        "resps",
        "residResps",
        "avgFreq",
        "medianFreq",
        "stddevFreqPerIp",
        "impsGraph",
        "residImpsGraph",
        "respImpsGraph",
      ].includes(column.type) ||
      column.type.startsWith("volume") ||
      column.type.startsWith("revenue")
    ) {
      return columnDataRow[column.type] || 0;
    }

    if (column.type === "completionsRate") {
      return 100 * S.calculateCompletionsRate(columnDataRow);
    }

    if (column.type === "reachFreqPerIp") {
      return Math.round((columnDataRow[column.type] || 0) / 1000);
    }
    if (column.type === "cpm") {
      return S.calculateCPM(columnDataRow);
    }
    if (column.type === "respIndex") {
      let vals = indexTotalsMap[fetchKey];
      return S.calculateResponseIndex(
        columnDataRow,
        vals.totalRespondingImps,
        vals.totalResidentialImps
      );
    }
    if (column.type.startsWith("cpx")) {
      const category = column.type.replace("cpx", "");
      return S.calculateCPX(columnDataRow, category as S.SpecialColumnCategory);
    }
    if (column.type.startsWith("roas")) {
      const category = column.type.replace("roas", "");
      return S.calculateROAS(columnDataRow, category as S.SpecialColumnCategory, company);
    }
    if (column.type.startsWith("avgRevenue")) {
      const category = column.type.replace("avgRevenue", "");
      return S.calculateAvgRevenue(columnDataRow, category as S.SpecialColumnCategory);
    }
    if (
      column.type.startsWith("percentIncremental") ||
      column.type.startsWith("percentDirectIncremental")
    ) {
      const category = column.type.replace("percent", "");
      return S.calculatePercentIncremental(columnDataRow, category as S.SpecialColumnCategory);
    }
    return 0;
  };

  return columns.reduce<number[]>((columns, column) => {
    if (!isAdmin && column.adminOnly) {
      return columns;
    }
    return [...columns, getRowValue(column)];
  }, []);
};

export interface RowWithTabularData extends S.PerformanceDataRow {
  tabularRow: number[];
}

export type PresetChanges = Omit<
  S.PerformancePreset,
  "filterTokens" | "filterState" | "prefix" | "impsBetween"
>;

export const STREAMING_OVERVIEW_CONFIG: Record<keyof S.OverviewConfig, OverviewConfigItem> = {
  spendInfo: {
    displayName: "Spend Info",
  },
  conversions: {
    displayName: "Conversion / Imps ('000s)",
  },
  revenue: {
    displayName: "Revenue",
  },
  deliveryRates: {
    displayName: "Delivery",
  },
  cppChart: {
    displayName: "CPX Path",
  },
  volumePathChart: {
    displayName: "Volume Path",
    defaultOff: true,
  },
  conversionChart: {
    displayName: "Conversion Latency",
  },
  hideFractional: {
    displayName: "Hide Fractional",
    defaultOff: true,
  },
};

export const GLOSSARY_IP: [string, React.ReactNode][] = [
  [
    "eCPM",
    "Effective Cost Per Thousand Impressions. This is the total spend times 1,000 divided by the number of impressions delivered.",
  ],
  ["Imp's ('000s)", "Number of impressions in the thousands."],
  [
    "Resp. Imp's",
    "Responding Impressions. The number of residential impressions that resulted in a direct KPI response.",
  ],
  [
    "Resp. Index",
    "Response Index. It's the measure of a row's response rate relative to the overall response rate. It's (responding impressions) / (total responding impressions) / (residential impressions) / (total residential impressions).",
  ],
  ["Volume", "KPI Volume. The number of trued-up KPIs attributed to our impressions."],
  ["ROAS", "Return On Ad Spend. It's the total revenue divided by the total spend."],
  [
    "Direct",
    "Direct KPIs. These are people who saw our ad and directly converted without seeing media from other marketing channels.",
  ],
  [
    "Halo",
    "KPIs from people who saw our ad, then saw media from another marketing channel, then converted.",
  ],
  [
    "Fractional",
    <>
      KPI attribution less permissive than Halo meant to model partial contributions from non-direct
      responses. For more detail,{" "}
      <a href="https://cdn.blisspointmedia.com/reference/Fractional%20Attribution%20BPM.pdf">
        see this document
      </a>
      .
    </>,
  ],
  [
    "Res. Del. Rate",
    "Residential Delivery Rate. The ratio of delivered residential impressions to total delivered impressions.",
  ],
  [
    "Res. Resp. Rate",
    "Residential Response Rate. The ratio of residential responses to total responses.",
  ],
  [
    "Resid. Imp's",
    "Residential Impressions. The number of impressions, in the thousands, served to residential IP addresses.",
  ],
  ["Resid. Resp's", "Residential Responses. The number of responses from residential IP addresses"],
  [
    "CPX",
    'Cost per KPI. The average cost for a single KPI. The "X" is usually an abbreviation for the KPI, such as "CPV" for "visits" or "CPO" for "orders".',
  ],
  [
    "Lag",
    "The number of days after an impression a response can occur for it to still be attributable.",
  ],
  [
    "Imp's Served Between",
    "The selected date range is applying to impression delivery date. Responses occurring outside the selected date range are still counted if they are within the lag.",
  ],
  [
    "Response Between",
    "The selected date range is applied to response date. Impressions attributed to the response occurring before the date range are still considered.",
  ],
  [
    "Weekly Frequency",
    "Either the average or the median of the number of the number of times in a week an impression is delivered to the same IP address across all delivered IPs. If the same people are being delivered ads multiple times, these numbers increase.",
  ],
];

export const GLOSSARY_GRAPH: [string, React.ReactNode][] = [
  [
    "Full Funnel Volume",
    "The volume of conversions with a deterministic link between ad exposure and conversion.",
  ],
  [
    "% Incremental",
    "The percentage of campaign conversions that are considered to be causal, i.e. the conversion occurred because of the ad exposure and would not have occurred in its absence. Incrementality Coefficient = (Treatment Response Rate - Control Response Rate) / (Treatment Response Rate)",
  ],
  [
    "iVolume",
    "Incremental KPI volume. The number of incremental conversions causally attributed to our campaign.",
  ],
  [
    "iCPX",
    'Cost per incremental conversion. The "X" is usually an abbreviation for the KPI, such as "CPV" for "visits" or "CPO" for "orders".',
  ],
  [
    "Direct",
    "Direct KPIs. This refers to the subset of conversions that take a high-intent response path (typically, direct or brand search) as opposed to clicking through from a paid marketing source (e.g. Facebook).",
  ],
  ["Imp's ('000s)", "Number of impressions, in thousands."],
  [
    "Imp's Served Between",
    "The selected date range considers campaign performance based on when impressions are served; responses occurring outside the selected date range are still counted if they are within the specified lag window.",
  ],
  [
    "Lag",
    "The maximum number of days following an impression that a conversion is eligible for attribution to that impression.",
  ],
  [
    "iROAS",
    "Incremental Return On Ad Spend. The revenue generated by a campaign divided by the investment in that campaign.",
  ],
  [
    "Matchable Delivery Rate",
    "The fraction of delivered impressions that are resolvable to an individual user/household.",
  ],
  [
    "Matchable Response Rate",
    "The fraction of conversions that are resolvable to an individual user/household.",
  ],
  [
    "Matchable Imp's",
    "Matchable Impressions. The number of impressions, in the thousands, served to resolvable users/households.",
  ],
  [
    "Matchable Resp's",
    "Matchable Responses. The number of responses from resolvable users/households.",
  ],
  [
    "Resp. Imp's",
    "Responding Impressions. The number of matchable impressions that resulted in a direct KPI response.",
  ],
  [
    "Resp. Index",
    "Response Index. The ratio of a row's response rate to the overall campaign response rate.  It's (responding impressions) / (total responding impressions) / (matchable impressions) / (total matchable impressions).",
  ],
  [
    "Response Between",
    "The selected date range considers campaign performance based on when conversions occur.",
  ],
  [
    "Weekly Frequency",
    "Either the average or the median number of times in a week an impression is delivered to the same user/household. If the same people are being delivered ads multiple times, these numbers increase.",
  ],
  [
    "eCPM",
    "Effective cost per thousand impressions. This is the total spend times 1,000 divided by the number of impressions delivered.",
  ],
];
