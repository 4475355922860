import "./AddNewClient.scss";
import {
  AdminLambdaFetch,
  awaitJSON,
  DagLambdaFetch,
  SheetsLambdaFetch,
} from "../utils/fetch-utils";
import { BPMButton, Page, Spinner } from "../Components";
import { Button, Col, DropdownButton, Dropdown, Form } from "react-bootstrap";
import { MdRefresh } from "react-icons/md";
import { useCompanyInfo } from "../redux/company";
import { useEffect, useMemo, useState } from "react";
import { useSetAreYouSure, useSetError } from "../redux/modals";
import * as R from "ramda";
import Select from "react-select";

const AGENCY = "tinuiti";
const DISPLAY_TIMEZONES = ["UTC", "Pacific", "Mountain", "Central", "Eastern"];
const LOWERCASE_REGEXP = /[a-z]/;
const NUMBERS_REGEX = /[0-9]/;
const PATH_REGEXP = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b\/([-a-zA-Z0-9@:%_+.~#()?&//=])/;
const PROTOCOL_REGEXP = /http(s)?:\/\/[-a-zA-Z0-9@:%._+~#=]?/;
const SPECIAL_CHARACTERS = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
const UPPERCASE_REGEXP = /[A-Z]/;
const VALID_URL_REGEXP = /^http(s)?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#()?&//=]*)/;

const IAB_CATEGORY_MAP = {
  IAB1: "IAB1 Arts & Entertainment",
  IAB2: "IAB2 Automotive",
  IAB3: "IAB3 Business",
  IAB4: "IAB4 Careers",
  IAB5: "IAB5 Education",
  IAB6: "IAB6 Family & Parenting",
  IAB7: "IAB7 Health & Fitness",
  IAB8: "IAB8 Food & Drink",
  IAB9: "IAB9 Hobbies & Interests",
  IAB10: "IAB10 Home & Garden",
  IAB11: "IAB11 Law, Gov't & Politics",
  IAB12: "IAB12 News",
  IAB13: "IAB13 Personal Finance",
  IAB14: "IAB14 Society",
  IAB15: "IAB15 Science",
  IAB16: "IAB16 Pets",
  IAB17: "IAB17 Sports",
  IAB18: "IAB18 Style & Fashion",
  IAB19: "IAB19 Technology & Computing",
  IAB20: "IAB20 Travel",
  IAB21: "IAB21 Real Estate",
  IAB22: "IAB22 Shopping",
  IAB23: "IAB23 Religion & Spirituality",
  IAB24: "IAB24 Uncategorized",
};
const IAB_CATEGORIES = R.values(IAB_CATEGORY_MAP);

interface AddNewClientParams {
  agency: string;
  beeswaxDefaultClick: string;
  beeswaxDomain: string;
  beeswaxIABCategory: string;
  companyId: string;
  companyName: string;
  companyNameAbbreviation: string;
  kpiTimezone: string;
  kpiTimezoneDisplay: string;
  mediaTypes: string[];
  streamingTypes: string[];
}

interface EditClientBody {
  company: string;
  mediaTypes: string[];
}

interface ClientSetupInputs {
  existingClient: boolean;
}

const getKpiTimezoneValue = (kpiTimezoneDisplay: string): string => {
  if (kpiTimezoneDisplay === "Eastern") {
    return "America/New_York";
  } else if (kpiTimezoneDisplay === "UTC") {
    return "Etc/UTC";
  } else if (kpiTimezoneDisplay === "Central") {
    return "America/Chicago";
  } else if (kpiTimezoneDisplay === "Mountain") {
    return "America/Denver";
  } else if (kpiTimezoneDisplay === "Pacific") {
    return "America/Los_Angeles";
  }
  return "invalid";
};

const AddNewClient: React.FC<ClientSetupInputs> = ({ existingClient }): JSX.Element => {
  // Params
  const [company, setCompany] = useState<string>("");
  const [companyAbbreviation, setCompanyAbbreviation] = useState<string>("");
  const [companyDomain, setCompanyDomain] = useState<string>("");
  const [companyHomePage, setCompanyHomePage] = useState<string>("");
  const [companyID, setCompanyID] = useState<string>("");
  const [iabCategory, setIABCategory] = useState<string>("IAB24"); // Default to IAB24
  const [kpiTimezone, setKpiTimezone] = useState<string>(
    DISPLAY_TIMEZONES[DISPLAY_TIMEZONES.length - 1] // Default to Eastern
  );
  const [mediaMap, setMediaMap] = useState<Record<string, boolean>>();
  // ------
  const [initialMediaMap, setInitialMediaMap] = useState<Record<string, boolean>>({});
  const [options, setOptions] = useState<string[]>();
  const [refreshing, setRefreshing] = useState(false);
  const [saving, setSaving] = useState<boolean>(false);
  const companyInfo = useCompanyInfo();
  const setAreYouSure = useSetAreYouSure(true);
  const setError = useSetError();
  const useCompanyOptions = (): string[] => {
    useEffect(() => {
      if (!options || !options.length) {
        (async () => {
          try {
            let res = await SheetsLambdaFetch("/get_company_ids");
            let jsonResponse = await awaitJSON<{ ids: string[] }>(res);
            setOptions(jsonResponse.ids);
            setRefreshing(false);
          } catch {
            setError({ title: "Internal Error", message: "Could not fetch existing company ids" });
            setOptions([]);
            return options;
          }
        })();
      }
    });
    return options || [];
  };
  const companyOptions = useCompanyOptions();

  const saveChanges = async () => {
    setSaving(false);
    const message = `Successfully ${existingClient ? "updated" : "added"} ${company}.`;
    const finalMediaMap: Record<string, boolean> = R.defaultTo({}, mediaMap);
    let isNoMediaTypeEnabled = true;
    for (const mediaType of R.keys(finalMediaMap)) {
      if (finalMediaMap[mediaType]) {
        isNoMediaTypeEnabled = false;
        break;
      }
    }
    if (isNoMediaTypeEnabled) {
      setError({
        message: "Please select at least one campaign type",
        title: "Error: Campaign Types",
      });
      return;
    }

    if (existingClient) {
      try {
        setSaving(true);
        await setAreYouSure({
          title: "Please review the information below",
          message: reviewInformation(),
          okayText: existingClient ? "Edit Client" : "Add New Client",
        });
        try {
          const body = {
            company: companyInfo.cid,
            mediaTypes: R.filter(
              mediaType => finalMediaMap[mediaType] === true,
              R.keys(finalMediaMap)
            ),
            beeswaxDomain: companyDomain,
            beeswaxDefaultClick: companyHomePage,
          };
          await DagLambdaFetch<EditClientBody>("/edit_client", {
            method: "POST",
            body,
          });
          await setError({
            variant: "success",
            title: "Success!",
            message,
          });
          await AdminLambdaFetch("/clearRedis", {
            method: "DELETE",
            params: {
              key: "companyInfo",
            },
          });
          setSaving(false);
        } catch {
          setSaving(false);
          setError({ title: "Internal Error", message: "Client was not updated" });
        }
      } catch {
        setSaving(false);
      }
    } else {
      let streamingTypes: string[] = [];
      let mediaTypes: string[] = [];

      if (!company) {
        setError({ message: "Please enter a company name", title: "Error: Company Name" });
        return;
      }

      if (!companyID) {
        setError({ message: "Please enter a company id", title: "Error: Company ID" });
        return;
      } else {
        if (SPECIAL_CHARACTERS.test(companyID)) {
          setError({
            message: "Company id cannot contain spaces or special characters",
            title: "Error: Company ID",
          });
          return;
        }
        if (UPPERCASE_REGEXP.test(companyID)) {
          setError({
            message: "Company id must contain only lowercase letters",
            title: "Error: Company ID",
          });
          return;
        }
      }

      if (!companyAbbreviation) {
        setError({
          message: "Please enter a company name abbreviation",
          title: "Error: Company Name Abbreviation",
        });
        return;
      } else {
        if (companyAbbreviation.length !== 2) {
          setError({
            message: "Company name abbreviation must be two letters",
            title: "Error: Company Name Abbreviation",
          });
          return;
        }
        if (
          SPECIAL_CHARACTERS.test(companyAbbreviation) ||
          LOWERCASE_REGEXP.test(companyAbbreviation) ||
          NUMBERS_REGEX.test(companyAbbreviation)
        ) {
          setError({
            message: "Company name abbreviation can only contain uppercase letters",
            title: "Error: Company Name Abbreviation",
          });
          return;
        }
      }

      if (!companyDomain) {
        setError({
          message: "Please enter the company domain ",
          title: "Error: Company Domain",
        });
        return;
      } else {
        if (!PROTOCOL_REGEXP.test(companyDomain)) {
          setError({
            message: "Please begin with the url protocol https://",
            title: "Error: Company Domain",
          });
          return;
        }
        if (PATH_REGEXP.test(companyDomain) || companyDomain[companyDomain.length - 1] === "/") {
          setError({
            message:
              "Please do not include any characters following the top level domain (ie nothing after .com)",
            title: "Error: Company Domain",
          });
          return;
        }

        if (!VALID_URL_REGEXP.test(companyDomain)) {
          setError({
            message: "Company domain not valid",
            title: "Error: Company Domain",
          });
          return;
        }
      }

      if (!companyHomePage) {
        setError({
          message: "Please enter the company home page",
          title: "Error: Company Home Page",
        });
        return;
      } else {
        if (!PROTOCOL_REGEXP.test(companyHomePage)) {
          setError({
            message: "Please begin with the url protocol https://",
            title: "Error: Company Home Page",
          });
          return;
        }
        if (companyHomePage[companyHomePage.length - 1] === "/") {
          setError({
            message: "Home Page cannot end in with a / character",
            title: "Error: Company Domain",
          });
          return;
        }
        if (!VALID_URL_REGEXP.test(companyHomePage)) {
          setError({
            message: "Company home page url not valid",
            title: "Error: Company Home Page",
          });
          return;
        }
      }
      const finalMediaMap: Record<string, boolean> = R.defaultTo({}, mediaMap);

      for (let type of Object.keys(finalMediaMap)) {
        if (finalMediaMap[type] !== undefined) {
          if (
            ["audio", "streaming"].includes(type) &&
            !streamingTypes.includes(type.toLowerCase())
          ) {
            streamingTypes.push(type.toLowerCase());
          }
          if (!mediaTypes.includes(type.toLowerCase())) {
            mediaTypes.push(type.toLowerCase());
          }
        }
      }

      try {
        setSaving(true);
        try {
          let res = await SheetsLambdaFetch("/get_company_ids");
          let jsonResponse = await awaitJSON<{ ids: string[] }>(res);
          if (jsonResponse.ids.includes(companyID)) {
            setError({
              message: "Company id already taken, please enter a different one",
              title: "Error: Company ID",
            });
            setSaving(false);
            return;
          }
        } catch {
          setSaving(false);
          setError({ title: "Internal Error", message: "Could not fetch existing company ids" });
        }
        await setAreYouSure({
          title: "Please review the information below",
          message: reviewInformation(),
          okayText: existingClient ? "Edit Client" : "Add New Client",
        });
        try {
          setKpiTimezone("");

          const params: AddNewClientParams = {
            agency: AGENCY,
            beeswaxDefaultClick: companyHomePage,
            beeswaxDomain: companyDomain,
            beeswaxIABCategory: iabCategory,
            companyId: companyID,
            companyName: company,
            companyNameAbbreviation: companyAbbreviation,
            kpiTimezone: getKpiTimezoneValue(kpiTimezone),
            kpiTimezoneDisplay: kpiTimezone,
            mediaTypes,
            streamingTypes,
          };

          await SheetsLambdaFetch<{ params: AddNewClientParams }>("/add_new_client", {
            method: "POST",
            body: { params },
          });
          await setError({
            variant: "success",
            title: "Success!",
            message: "Successfully added client",
          });
          await AdminLambdaFetch("/clearRedis", {
            method: "DELETE",
            params: {
              key: "companyInfo",
            },
          });
          setSaving(false);
          window.location.reload();
        } catch {
          setSaving(false);
          setError({ title: "Internal Error", message: "Client was not added" });
        }
      } catch {
        setSaving(false);
      }
    }
  };

  const reviewInformation = (): string => {
    const finalMediaMap: Record<string, boolean> = R.defaultTo({}, mediaMap);
    let message = `Company Name: ${company}
    Company ID: ${companyID}
    Company Name Abbreviation: ${companyAbbreviation}
    Agency: ${AGENCY}
    IAB Category: ${IAB_CATEGORY_MAP[R.defaultTo("", iabCategory)]}
    Company Domain: ${companyDomain}
    Company Home Page: ${companyHomePage}
    Campaign Types:`;
    for (let mediaType of R.keys(finalMediaMap)) {
      if (finalMediaMap[mediaType] === true) {
        const capitalizedMediaType =
          mediaType === "tv"
            ? "Linear"
            : `${mediaType.charAt(0).toUpperCase()}${mediaType.slice(1)}`;
        message = `${message}\n${capitalizedMediaType}`;
      }
    }
    return message;
  };

  useEffect(() => {
    const initialMediaMap = {};
    if (existingClient && companyInfo) {
      const { media_types } = companyInfo;
      const mediaTypes = R.defaultTo([], media_types);
      if (R.isNil(mediaMap)) {
        for (const mediaType of mediaTypes) {
          initialMediaMap[mediaType] = true;
        }
        setInitialMediaMap(initialMediaMap);
        setMediaMap(initialMediaMap);
        setCompany(companyInfo.name);
        setCompanyAbbreviation(R.defaultTo("", companyInfo.name_abbrev));
        setCompanyDomain(R.defaultTo("", companyInfo.beeswax_advertiser_domain));
        setCompanyHomePage(R.defaultTo("", companyInfo.beeswax_default_click));
        setCompanyID(R.defaultTo("", companyInfo.cid));
        setIABCategory(R.defaultTo("", companyInfo.beeswax_iab_category));
      }
    } else if (R.isNil(mediaMap)) {
      setInitialMediaMap(initialMediaMap);
      setMediaMap(initialMediaMap);
    }
  }, [companyInfo, existingClient, mediaMap]);

  const optionList = useMemo(() => companyOptions.map(value => ({ value, label: value })), [
    companyOptions,
  ]);

  return (
    <Page
      title={existingClient ? "Edit Client" : "Add New Client"}
      pageType={existingClient ? "Edit Client" : "Add New Client"}
      actions={
        <div className="companyPicker">
          <Select
            className="select"
            placeholder="Search for existing companies..."
            isSearchable
            value={company}
            options={[{ label: "Add New Client ", value: "new" }, ...optionList]}
            onChange={option => {
              window.location.href =
                option.value === "new"
                  ? `${window.location.origin}/add_new_client`
                  : `${window.location.origin}/${option.value}/edit_client`;
            }}
          />
          {!existingClient && (
            <Button
              className="refresh"
              onClick={() => {
                setOptions(undefined);
                setRefreshing(true);
              }}
            >
              {refreshing ? <Spinner /> : <MdRefresh />}
            </Button>
          )}
        </div>
      }
    >
      <Form className="form">
        <div className="companyInfo">
          <Form.Group as={Col} className="companyName">
            <Form.Label>Company Name</Form.Label>
            <Form.Control
              type="text"
              value={company}
              disabled={existingClient}
              placeholder="eg. Company Name"
              onChange={e => {
                setCompany(e.currentTarget.value);
              }}
            ></Form.Control>
          </Form.Group>

          <Form.Group as={Col} className="companyId">
            <Form.Label>Company ID</Form.Label>
            <Form.Control
              type="text"
              value={companyID}
              disabled={existingClient}
              placeholder="eg. companyname"
              onChange={e => {
                setCompanyID(e.currentTarget.value);
              }}
            ></Form.Control>
          </Form.Group>
        </div>
        <div className="websites">
          <Form.Group as={Col} className="companyDomain">
            <Form.Label>Company Domain</Form.Label>
            <Form.Control
              type="url"
              value={companyDomain}
              placeholder="https://www.companyname.com"
              onChange={e => {
                setCompanyDomain(e.currentTarget.value);
              }}
            ></Form.Control>
          </Form.Group>
          <Form.Group as={Col} className="companyHomePage">
            <Form.Label>Company Home Page</Form.Label>
            <Form.Control
              type="url"
              value={companyHomePage}
              placeholder="https://www.companyname.com/us"
              onChange={e => {
                setCompanyHomePage(e.currentTarget.value);
              }}
            ></Form.Control>
          </Form.Group>
        </div>
        <div className="row2">
          <Form.Group as={Col} className="companyAbbreviation">
            <Form.Label>Company Name Abbreviation</Form.Label>
            <Form.Control
              maxLength={2}
              value={companyAbbreviation}
              disabled={existingClient}
              placeholder="eg. CN"
              onChange={e => {
                setCompanyAbbreviation(e.currentTarget.value);
              }}
            ></Form.Control>
            <Form.Text>Required, two uppercase letters only</Form.Text>
          </Form.Group>
          <Form.Group className="IABCategory">
            <Form.Label>IAB Category</Form.Label>
            <DropdownButton
              drop="down"
              title={IAB_CATEGORY_MAP[R.defaultTo("", iabCategory)]}
              disabled={existingClient}
            >
              {IAB_CATEGORIES.map(category => (
                <Dropdown.Item
                  key={category}
                  eventKey={category}
                  onSelect={category => {
                    const newCategory = R.defaultTo("IAB24 Uncategorized", category)
                      .slice(0, 5)
                      .trim();
                    setIABCategory(newCategory as string);
                  }}
                >
                  {category}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </Form.Group>
        </div>
        {mediaMap && (
          <div className="row3">
            {!existingClient && (
              <Form.Group as={Col} className="kpiTimezone">
                <Form.Label>KPI Timezone</Form.Label>
                <DropdownButton
                  title={kpiTimezone}
                  onSelect={value => setKpiTimezone(R.defaultTo("", value))}
                >
                  {DISPLAY_TIMEZONES.map(timezone => (
                    <Dropdown.Item key={timezone} eventKey={timezone}>
                      {timezone}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Form.Group>
            )}
            <Form.Group as={Col} className="campaignTypes">
              <Form.Label>Campaign Types</Form.Label>
              <div className="mediaTypes">
                <Form.Check
                  inline
                  label="Linear"
                  type="checkbox"
                  checked={
                    mediaMap && !R.isNil(mediaMap.tv)
                      ? mediaMap.tv
                      : !R.isNil(initialMediaMap.tv)
                      ? initialMediaMap.tv
                      : false
                  }
                  onChange={() =>
                    setMediaMap(map => ({
                      ...map,
                      tv:
                        mediaMap && !R.isNil(mediaMap.tv)
                          ? !mediaMap.tv
                          : !R.isNil(initialMediaMap.tv)
                          ? !initialMediaMap.tv
                          : true,
                    }))
                  }
                ></Form.Check>
                <Form.Check
                  inline
                  label="Streaming"
                  type="checkbox"
                  checked={
                    mediaMap && !R.isNil(mediaMap.streaming)
                      ? mediaMap.streaming
                      : !R.isNil(initialMediaMap.streaming)
                      ? initialMediaMap.streaming
                      : false
                  }
                  onChange={() =>
                    setMediaMap(map => ({
                      ...map,
                      streaming:
                        mediaMap && !R.isNil(mediaMap.streaming)
                          ? !mediaMap.streaming
                          : !R.isNil(initialMediaMap.streaming)
                          ? !initialMediaMap.streaming
                          : true,
                    }))
                  }
                ></Form.Check>
                <Form.Check
                  inline
                  label="Audio"
                  type="checkbox"
                  checked={
                    mediaMap && !R.isNil(mediaMap.audio)
                      ? mediaMap.audio
                      : !R.isNil(initialMediaMap.audio)
                      ? initialMediaMap.audio
                      : false
                  }
                  onChange={() =>
                    setMediaMap(map => ({
                      ...map,
                      audio:
                        mediaMap && !R.isNil(mediaMap.audio)
                          ? !mediaMap.audio
                          : !R.isNil(initialMediaMap.audio)
                          ? !initialMediaMap.audio
                          : true,
                    }))
                  }
                ></Form.Check>
                <Form.Check
                  inline
                  label="Commerce"
                  type="checkbox"
                  checked={
                    mediaMap && !R.isNil(mediaMap.commerce)
                      ? mediaMap.commerce
                      : !R.isNil(initialMediaMap.commerce)
                      ? initialMediaMap.commerce
                      : false
                  }
                  onChange={() =>
                    setMediaMap(map => ({
                      ...map,
                      commerce:
                        mediaMap && !R.isNil(mediaMap.commerce)
                          ? !mediaMap.commerce
                          : !R.isNil(initialMediaMap.commerce)
                          ? !initialMediaMap.commerce
                          : true,
                    }))
                  }
                ></Form.Check>
                <Form.Check
                  inline
                  label="Display"
                  type="checkbox"
                  checked={
                    mediaMap && !R.isNil(mediaMap.display)
                      ? mediaMap.display
                      : !R.isNil(initialMediaMap.display)
                      ? initialMediaMap.display
                      : false
                  }
                  onChange={() =>
                    setMediaMap(map => ({
                      ...map,
                      display:
                        mediaMap && !R.isNil(mediaMap.display)
                          ? !mediaMap.display
                          : !R.isNil(initialMediaMap.display)
                          ? !initialMediaMap.display
                          : true,
                    }))
                  }
                ></Form.Check>
                <Form.Check
                  inline
                  label="Radio"
                  type="checkbox"
                  checked={
                    mediaMap && !R.isNil(mediaMap.radio)
                      ? mediaMap.radio
                      : !R.isNil(initialMediaMap.radio)
                      ? initialMediaMap.radio
                      : false
                  }
                  onChange={() =>
                    setMediaMap(map => ({
                      ...map,
                      radio:
                        mediaMap && !R.isNil(mediaMap.radio)
                          ? !mediaMap.radio
                          : !R.isNil(initialMediaMap.radio)
                          ? !initialMediaMap.radio
                          : true,
                    }))
                  }
                ></Form.Check>
                <Form.Check
                  inline
                  label="Search"
                  type="checkbox"
                  checked={
                    mediaMap && !R.isNil(mediaMap.search)
                      ? mediaMap.search
                      : !R.isNil(initialMediaMap.search)
                      ? initialMediaMap.search
                      : false
                  }
                  onChange={() =>
                    setMediaMap(map => ({
                      ...map,
                      search:
                        mediaMap && !R.isNil(mediaMap.search)
                          ? !mediaMap.search
                          : !R.isNil(initialMediaMap.search)
                          ? !initialMediaMap.search
                          : true,
                    }))
                  }
                ></Form.Check>
                <Form.Check
                  inline
                  label="Shopping"
                  type="checkbox"
                  checked={
                    mediaMap && !R.isNil(mediaMap.shopping)
                      ? mediaMap.shopping
                      : !R.isNil(initialMediaMap.shopping)
                      ? initialMediaMap.shopping
                      : false
                  }
                  onChange={() =>
                    setMediaMap(map => ({
                      ...map,
                      shopping:
                        mediaMap && !R.isNil(mediaMap.shopping)
                          ? !mediaMap.shopping
                          : !R.isNil(initialMediaMap.shopping)
                          ? !initialMediaMap.shopping
                          : true,
                    }))
                  }
                ></Form.Check>
                <Form.Check
                  inline
                  label="Social"
                  type="checkbox"
                  checked={
                    mediaMap && !R.isNil(mediaMap.social)
                      ? mediaMap.social
                      : !R.isNil(initialMediaMap.social)
                      ? initialMediaMap.social
                      : false
                  }
                  onChange={() =>
                    setMediaMap(map => ({
                      ...map,
                      social:
                        mediaMap && !R.isNil(mediaMap.social)
                          ? !mediaMap.social
                          : !R.isNil(initialMediaMap.social)
                          ? !initialMediaMap.social
                          : true,
                    }))
                  }
                ></Form.Check>
                <Form.Check
                  inline
                  label="YouTube"
                  type="checkbox"
                  checked={
                    mediaMap && !R.isNil(mediaMap.youtube)
                      ? mediaMap.youtube
                      : !R.isNil(initialMediaMap.youtube)
                      ? initialMediaMap.youtube
                      : false
                  }
                  onChange={() =>
                    setMediaMap(map => ({
                      ...map,
                      youtube:
                        mediaMap && !R.isNil(mediaMap.youtube)
                          ? !mediaMap.youtube
                          : !R.isNil(initialMediaMap.youtube)
                          ? !initialMediaMap.youtube
                          : true,
                    }))
                  }
                ></Form.Check>
              </div>
            </Form.Group>
          </div>
        )}
        <Form.Group as={Col} className="saveButton">
          <BPMButton variant="success" onClick={() => saveChanges()}>
            {saving ? <Spinner /> : existingClient ? "Edit Client" : "Add New Client"}
          </BPMButton>
        </Form.Group>
      </Form>
    </Page>
  );
};
export default AddNewClient;
