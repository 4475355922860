import "./MMM.scss";
import React, { useMemo, useState, useEffect, useCallback } from "react";
import { Redirect, RouteComponentProps, Router } from "@reach/router";
import { useTabbedNav } from "../utils/hooks/useNav";
import { Dropdown, Page, Button, CheckBox, ButtonType, TextToggleButton } from "../Components";
import { MetricsLambdaFetch, awaitJSON } from "../utils/fetch-utils";
import * as R from "ramda";
import { useSetError, useSetAreYouSure } from "../redux/modals";
import useLocation from "../utils/hooks/useLocation";
import { useExperimentFlag } from "../utils/experiments/experiment-utils";

import ModelResults from "./ModelResults/ModelResults";
import OfflineInputs from "./OfflineInputs/OfflineInputs";
import FAQ from "./FAQ/FAQ";
import { ButtonFrameworkVariant } from "../Components/ButtonFramework";
import { useSelector } from "react-redux";
import { isTinuitiUserSelector } from "../redux/user";

const enum TabKey {
  MODEL_RESULTS = "model-results",
  BUDGET_OPTIMIZATION = "budget-optimization",
  SCENARIO_PLANNING = "scenario-planning",
  FAQ = "FAQ",
  OFFLINE_INPUTS = "offline-inputs",
}

const NAVS = [
  { label: "Model Results", key: TabKey.MODEL_RESULTS },
  { label: "FAQ", key: TabKey.FAQ },
];

interface DateOption {
  date: string;
  client_facing: boolean;
  kpi: string;
  branch: string;
}

interface FormattedDateOptions {
  date: string;
  client_facing: boolean;
  kpis: string[];
  path: {
    [key: string]: string;
  };
}

interface MapValues {
  [key: string]: {
    client_facing: boolean;
    kpis: string[];
    path: {
      [key: string]: string;
    };
  };
}

const MMM: React.FC = ({ navigate }: RouteComponentProps) => {
  const setError = useSetError();
  const setAreYouSure = useSetAreYouSure(true);
  const { company } = useLocation();
  const [kpi, setKpi] = useState<string>("");
  const [groupBy, setGroupBy] = useState("channel");
  const [groupByOptions, setGroupByOptions] = useState<Array<{ label: string; value: string }>>([]);
  const disabledPlatform = useMemo(() => {
    if (!R.isEmpty(groupByOptions)) {
      return groupByOptions.filter(option => option.value === "platform").length === 0;
    }
  }, [groupByOptions]);
  const [kpiOptions, setKpiOptions] = useState<string[]>();
  const [pageActions, setPageActions] = useState<JSX.Element | undefined>(<></>);
  const [pageArchiveActions, setPageArchiveActions] = useState<JSX.Element | undefined>(<></>);
  const [modelRunDate, setModelRunDate] = useState<string>("");
  const isTinuitiUser = useSelector(isTinuitiUserSelector);
  const [modelDateOptions, setModelDateOptions] = useState<FormattedDateOptions[]>([]);
  const [effectiveOrRaw, setEffectiveOrRaw] = useState<"Effective" | "Raw">("Effective");
  const stageTwo = useExperimentFlag("enablestageTwoMMM");

  const dateMap = useMemo(
    () =>
      modelDateOptions.reduce((acc, cv): MapValues => {
        acc[cv.date] = {
          client_facing: cv.client_facing,
          kpis: cv.kpis,
          path: cv.path,
        };
        return acc;
      }, {}),
    [modelDateOptions]
  );

  const { tab, goToTab } = useTabbedNav({
    navigate,
    baseURL: "mmm",
    defaultKey: TabKey.MODEL_RESULTS,
  });

  const toggleMMMRun = useCallback(
    async (dateBranchStr: string, checked: boolean) => {
      try {
        setModelDateOptions(m =>
          m.map(el => {
            if (el.date === dateBranchStr) {
              el.client_facing = checked;
            }
            return { ...el };
          })
        );
        await MetricsLambdaFetch("/toggleMMMRun", {
          method: "POST",
          body: {
            company,
            date: dateBranchStr.slice(0, 10),
            client_facing: checked,
            branch: dateBranchStr.slice(11),
          },
        });
      } catch (e) {
        const reportError = e as Error;
        setError({
          message: reportError.message,
          reportError,
        });
      }
    },
    [company, setError]
  );

  useEffect(() => {
    const getDateOptions = async () => {
      const filterDateRuns = (options: DateOption[]) => {
        const formatted = options
          .sort((a, b) => Date.parse(b.date) - Date.parse(a.date))
          .reduce((acc: FormattedDateOptions[], cv, i) => {
            if (i > 0 && acc[acc.length - 1].date === `${cv.date.slice(0, 10)}:${cv.branch}`) {
              acc[acc.length - 1].client_facing =
                acc[acc.length - 1].client_facing && cv.client_facing;
              if (!acc[acc.length - 1].kpis.includes(cv.kpi)) {
                acc[acc.length - 1].kpis.push(cv.kpi);
              }
              acc[acc.length - 1].path[cv.kpi] = cv.date;
            } else {
              acc.push({
                date: `${cv.date.slice(0, 10)}:${cv.branch}`,
                client_facing: cv.client_facing,
                kpis: [cv.kpi],
                path: { [cv.kpi]: cv.date },
              });
            }
            return acc;
          }, []);

        return isTinuitiUser ? formatted : formatted.filter(obj => obj.client_facing);
      };
      try {
        const res = await MetricsLambdaFetch("/dateOptions", {
          params: {
            company,
          },
        });
        const dateOptions = await awaitJSON<DateOption[]>(res);
        if (dateOptions.length < 1) {
          setError({
            message: "There are no MMM runs for this client",
          });
          return;
        }
        const dateOptionsFormated = filterDateRuns(dateOptions);
        if (dateOptionsFormated.length < 1) {
          setError({
            message: "There are no released MMM runs for this client",
          });
          return;
        }
        const initialRun =
          dateOptionsFormated.find(run => run.client_facing === true) ||
          dateOptionsFormated[dateOptionsFormated.length - 1];
        setModelDateOptions(dateOptionsFormated);
        setModelRunDate(initialRun.date);
        setKpi(initialRun.kpis[0]);
        setKpiOptions(initialRun.kpis);
      } catch (e) {
        const reportError = e as Error;
        setError({
          message: reportError.message,
          reportError,
        });
      }
    };
    getDateOptions();
  }, [company, setError, isTinuitiUser]);

  useEffect(() => {
    if (isTinuitiUser) {
      NAVS.push(
        { label: "Budget Optimization", key: TabKey.BUDGET_OPTIMIZATION },
        { label: "Scenario Planning", key: TabKey.SCENARIO_PLANNING },
        { label: "Offline Inputs", key: TabKey.OFFLINE_INPUTS }
      );
    }
  }, [isTinuitiUser]);

  useEffect(() => {
    switch (tab) {
      case TabKey.FAQ:
      case TabKey.OFFLINE_INPUTS:
        setPageActions(undefined);
        setPageArchiveActions(undefined);
        break;
      default:
        setPageActions(
          <div id="mmmHeaderSubActions">
            <Dropdown
              design="primary"
              className="mmmKpiDropdown"
              onChange={setKpi}
              options={R.map(kpi => {
                return { label: kpi, value: kpi };
              }, kpiOptions || [])}
              value={kpi || ""}
            />
            <Dropdown
              design="primary"
              className="mmmGroupByDropdown"
              onChange={setGroupBy}
              options={groupByOptions}
              value={groupBy}
            />
          </div>
        );
        setPageArchiveActions(
          <div id="mmmArchiveActions">
            <Dropdown
              section={isTinuitiUser ? ["Client-Facing", "Admin-Only"] : ["Client Facing"]}
              background="dark"
              className={isTinuitiUser ? "mmmArchiveDropdown internal" : "mmmArchiveDropdown"}
              dropdownMenuClassName="align-right"
              onChange={value => {
                setModelRunDate(value);
                setKpiOptions(dateMap[value].kpis);
                setKpi(k => (dateMap[value].kpis.includes(k) ? k : dateMap[value].kpis[0]));
              }}
              options={modelDateOptions.map(el => ({
                label: `${isTinuitiUser ? `[Branch: ${el.date.slice(11)}] ` : ""}${new Date(
                  `${el.date.slice(0, 10)}T00:00:00`
                ).toLocaleDateString("en-US", {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                })}`,
                value: el.date,
                section: el.client_facing ? "Client-Facing" : "Admin-Only",
                statusPill: el.date === modelDateOptions[0].date ? "Newest" : "Archived",
                className: "archiveOptions",
              }))}
              value={modelRunDate}
            />
            {isTinuitiUser && modelRunDate && (
              <Button
                className="archiveToggleButton"
                background="dark"
                type={ButtonType.OUTLINED}
                variant={ButtonFrameworkVariant.TRAILING_ICON}
                onClick={() => {
                  const visibility = dateMap[modelRunDate].client_facing;
                  setAreYouSure({
                    title: visibility ? "Hide run from clients?" : "Show run to clients?",
                    message: `You're about to make this run ${
                      visibility ? "hidden from" : "visible to"
                    } clients. Are you sure you want to continue?`,
                    cancelText: "Cancel",
                    okayText: "Continue",
                    onOkay: () => {
                      toggleMMMRun(modelRunDate, !visibility);
                    },
                  });
                }}
                icon={
                  <CheckBox checked={dateMap[modelRunDate].client_facing} id="secondaryDesign" />
                }
              >
                Client-Facing
              </Button>
            )}
          </div>
        );
    }
  }, [
    groupBy,
    groupByOptions,
    kpi,
    kpiOptions,
    tab,
    isTinuitiUser,
    modelRunDate,
    modelDateOptions,
    toggleMMMRun,
    setAreYouSure,
    dateMap,
  ]);

  return (
    <Page
      actions={
        <div className="primaryActionsContainerMMM">
          {stageTwo && (
            <TextToggleButton
              options={["Effective", "Raw"]}
              selectedOption={effectiveOrRaw}
              onChange={() => setEffectiveOrRaw(e => (e === "Raw" ? "Effective" : "Raw"))}
              className="mmmEffectiveRawToggle"
            />
          )}
          {pageArchiveActions}
        </div>
      }
      app2Redesign
      navs={NAVS}
      onNav={goToTab}
      pageType="MMM"
      selectedNav={tab}
      title="MMM"
      subHeader={pageActions}
    >
      <Router className="fullPageRouter mmmPage">
        {groupByOptions &&
          kpiOptions &&
          kpi.length &&
          modelRunDate.length &&
          dateMap[modelRunDate] &&
          kpi && (
            <ModelResults
              effectiveOrRaw={effectiveOrRaw}
              disabledPlatform={disabledPlatform}
              groupBy={groupBy}
              setGroupByOptions={setGroupByOptions}
              kpi={kpi}
              path={"/"}
              date={dateMap[modelRunDate].path[kpi]}
              branch={modelRunDate.slice(11)}
            />
          )}
        <FAQ path={TabKey.FAQ} />
        <OfflineInputs path={TabKey.OFFLINE_INPUTS} />
        <Redirect noThrow from="/offlineinputs" to={`../${TabKey.OFFLINE_INPUTS}`} />
        <Redirect noThrow from="/offline_inputs" to={`../${TabKey.OFFLINE_INPUTS}`} />
      </Router>
    </Page>
  );
};

export default MMM;
