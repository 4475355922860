import React, { useState, useMemo, useEffect, useRef, useCallback } from "react";
import ReactDOM from "react-dom";
import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import cn from "classnames";
import { useRenderedPosition } from "../../utils/hooks/useDOMHelpers";
import { DateRange } from "../../utils/types";
import "./DatePicker.scss";
import { DatePickerButton } from "./DatePickerButton";
import { DPCalendar } from "./DPCalendar";
import { Button, ButtonType } from "../Button";

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
] as const;

const DEFAULT_MIN_YEAR = 2017 as const;
const DEFAULT_MAX_YEAR = new Date().getFullYear() + 2;

// Gotten from inspecting the date picker. Note: the height is the maximum height for when the
// calendar has 6 rows.
const CALENDAR_WIDTH = 537;
const CALENDAR_HEIGHT = 303;

const CALENDAR_SPACE = 6;

export const FOCUS_OPTIONS = ["START", "END"] as const;

const PRETTY_DATE_FORMAT = "M/d/yy";

const makePrettyDate = (date?: string) =>
  date ? R.pipe(Dfns.parseISO, Dfns.format(PRETTY_DATE_FORMAT))(date) : "___";

interface DatePickerProps {
  range?: DateRange;
  onChange: (newDates: DateRange) => void;
  minYear?: number;
  maxYear?: number;
  isOutsideRange?: (date: string) => boolean;
  isDayBlocked?: (date: string) => boolean;
  bordered?: boolean;
  currentMonthSecond?: boolean;
  fullWeeksOnly?: boolean; // Used for linear pages in which we only allow selection of entire weeks starting on Mondays.
  maxDate?: string; // Used for brand health page to explain why certain dates are greyed out.
  // [passthroughProp: string]: any;
}

export const DatePicker: React.FC<DatePickerProps> = ({
  range,
  onChange,
  minYear = DEFAULT_MIN_YEAR,
  maxYear = DEFAULT_MAX_YEAR,
  isOutsideRange = () => false,
  isDayBlocked = () => false,
  currentMonthSecond,
  fullWeeksOnly,
  maxDate,
}) => {
  // Years for the year picker
  const years = useMemo(() => R.range(minYear, maxYear + 1), [minYear, maxYear]);

  const { start, end } = range || {};

  const ref = useRef<HTMLDivElement>(null);

  const [position, resetPosition] = useRenderedPosition(ref);

  const resolvedPosition = useMemo(() => {
    // Start off anchored below the input aligned on the right
    let top = position.bottom + CALENDAR_SPACE;
    let left = position.right - CALENDAR_WIDTH;
    // If it spills off the bottom of the page, open up
    if (top + CALENDAR_HEIGHT > window.innerHeight) {
      top = position.top - CALENDAR_HEIGHT - CALENDAR_SPACE;
    }
    // if it spill over the left side of the screen, anchor left
    if (left < 0) {
      ({ left } = position);
    }
    return { top, left };
  }, [position]);

  const [show, setShowRaw] = useState(false);

  const [dateInputs, setDateInputs] = useState<Partial<DateRange>>({
    start,
    end,
  });
  const [focus, setFocus] = useState<typeof FOCUS_OPTIONS[number]>("START");

  const setShow = useCallback(
    (show: boolean, save?: boolean) => {
      resetPosition();
      setShowRaw(show);
      if (!show && dateInputs.start && dateInputs.end) {
        setFocus("START");
        setDateInputs({ start, end });
        if (save && (dateInputs.start !== start || dateInputs.end !== end)) {
          onChange(dateInputs as DateRange);
        }
      }
    },
    [onChange, dateInputs, resetPosition, start, end]
  );

  useEffect(() => {
    setDateInputs(dateInputs => {
      let newDateInputs = { ...dateInputs };
      let changed = false;
      if (dateInputs.start !== start) {
        newDateInputs.start = start;
        changed = true;
      }
      if (dateInputs.end !== end) {
        newDateInputs.end = end;
        changed = true;
      }
      if (changed) {
        return newDateInputs;
      }
      return dateInputs;
    });
  }, [start, end]);

  const onChangeInternal = useCallback(
    (dateRange: Partial<DateRange>, focus: typeof FOCUS_OPTIONS[number]) => {
      setDateInputs(dateRange);
      setFocus(focus);
    },
    []
  );

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setShow(false);
      }
    };
    document.addEventListener("keydown", handler);
    return () => {
      document.removeEventListener("keydown", handler);
    };
  }, [setShow]);

  return (
    <>
      <DatePickerButton
        reference={ref}
        setShow={setShow}
        range={range}
        fullWeeksOnly={fullWeeksOnly}
        setFocus={setFocus}
      ></DatePickerButton>
      {show &&
        ReactDOM.createPortal(
          <div className="DPDateContainer">
            <div className="overlay" onClick={() => setShow(false)} />
            <div className="calendarBox" style={resolvedPosition}>
              <DPCalendar
                start={dateInputs.start}
                end={dateInputs.end}
                focus={focus}
                onChange={onChangeInternal}
                isOutsideRange={isOutsideRange}
                isDayBlocked={isDayBlocked}
                years={years}
                currentMonthSecond={currentMonthSecond}
                maxDate={maxDate}
              />
              <div className="controls">
                <div className="subControls">
                  <div className="dateDisplay">
                    <span
                      className={cn("singleDate", { focused: focus === "START" })}
                      onClick={() => setFocus("START")}
                    >
                      {makePrettyDate(dateInputs.start)}
                    </span>{" "}
                    –{" "}
                    <span
                      className={cn("singleDate", { focused: focus === "END" })}
                      onClick={() => setFocus("END")}
                    >
                      {makePrettyDate(dateInputs.end)}
                    </span>
                  </div>
                  <Button
                    type={ButtonType.OUTLINED}
                    design="secondary"
                    onClick={() => setDateInputs(range || {})}
                  >
                    Reset
                  </Button>
                </div>
                <div className="subControls">
                  <Button
                    type={ButtonType.OUTLINED}
                    design="secondary"
                    onClick={() => setShow(false)}
                  >
                    Cancel
                  </Button>
                  <Button type={ButtonType.FILLED} onClick={() => setShow(false, true)}>
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </div>,
          document.body
        )}
    </>
  );
};
