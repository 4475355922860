import React, { useMemo, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { MdOutlineSdCardAlert } from "react-icons/md";
import { isBefore } from "date-fns";
import { Button, ButtonType, Spinner } from "../../Components";
import DateInputField from "../../Components/DatePicker/DateInputField/DateInputField";
import "./AddMediaInputModal.scss";
import "../../Components/FormCheck.scss";

interface AddMediaInputModalProps {
  show: boolean;
  handleClose: () => void;
  createTable: (
    params: {
      platform: string;
      tactic: string;
      channel: string;
      brand: string;
      hasImpressions: boolean;
      hasRedemptions: boolean;
      isPaid: boolean;
      otherUnitLabel?: string;
    },
    startDate: Date
  ) => void;
  isPaid: boolean;
}

const AddMediaInputModal: React.FC<AddMediaInputModalProps> = ({
  show,
  handleClose,
  createTable,
  isPaid,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [channel, setChannel] = useState("");
  const [platform, setPlatform] = useState("");
  const [tactic, setTactic] = useState("");
  const [brand, setBrand] = useState<"Brand" | "Non-Brand" | "N/A" | "">("");
  const [hasImpressions, setHasImpressions] = useState(false);
  const [otherUnitChecked, setOtherUnitChecked] = useState(false);
  const [otherUnitLabel, setOtherUnitLabel] = useState("");
  const [showSpendWarning, setShowSpendWarning] = useState(false);
  const [hasRedemptions, setHasRedemptions] = useState<"true" | "false" | "unselected">(
    "unselected"
  );
  const [startDateStr, setStartDateStr] = useState("");

  const resetState = () => {
    setChannel("");
    setPlatform("");
    setTactic("");
    setBrand("");
    setHasImpressions(false);
    setOtherUnitChecked(false);
    setOtherUnitLabel("");
    setShowSpendWarning(false);
    setHasRedemptions("unselected");
    setStartDateStr("");
    setIsSubmitting(false);
  };

  const submitDisabled =
    isSubmitting ||
    !channel ||
    (otherUnitChecked && !otherUnitLabel) ||
    (isPaid && hasRedemptions === "unselected") ||
    !startDateStr;

  const channelOptions = useMemo(() => {
    const options: string[][] = [];
    if (!isPaid) {
      options.push(["Email", "SEO", "SMS"]);
    }
    const mainSection = [
      "Audio",
      "Affiliate",
      "Commerce",
      "Display",
      "Influencer",
      "Linear TV",
      "Search",
      "Social",
      "Streaming TV",
      "YouTube",
    ];
    if (isPaid) {
      mainSection.splice(4, 0, "Email");
      mainSection.splice(8, 0, "SMS");
    }
    options.push(mainSection);
    options.push(["Offline Channel"]);
    return options;
  }, [isPaid]);

  const offlineChannelExampleText = isPaid
    ? "Ex. Billboard, Terrestrial Radio, Print, Partnerships, Referral"
    : "Ex: Earned Media, Free Media, Unpaid Press Coverage";

  const platformSectionTitle = isPaid ? (
    <>
      Platform <span className="sectionTitleExtra">(or Agency)</span>
    </>
  ) : (
    "Platform or Title"
  );

  return (
    <Modal className="addMediaInputModal" show={show} onHide={handleClose} size="xl" centered>
      <Modal.Header>
        <h4 className="modalTitle">Add Manual {isPaid ? "Paid" : "Non-Paid"} Media Input</h4>
        <div className="subTitle">*Required fields marked with an asterisk.</div>
      </Modal.Header>
      <Modal.Body>
        <div className="channelInput">
          <div className="sectionTitle">
            Channel<span className="asterisk">*</span>
          </div>
          {channelOptions.map((group, index) => {
            return (
              <div className="channelGroup" key={index}>
                {group.map(channelName => {
                  return (
                    <React.Fragment key={channelName}>
                      <Form.Check
                        type="radio"
                        label={channelName}
                        checked={channel === channelName}
                        id={channelName}
                        onChange={() => setChannel(channelName)}
                      />
                      {channelName === "Offline Channel" && (
                        <div className="inputExplanation">
                          <p>{offlineChannelExampleText}</p>
                          <p>Name this channel in the platform section.</p>
                        </div>
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
            );
          })}
        </div>
        <div className="platformTacticBrandInput">
          <>
            <div className="sectionTitle">{platformSectionTitle}</div>
            <Form.Control
              placeholder="Ex: Klaviyo, Blog Post"
              value={platform}
              onChange={e => setPlatform(e.target.value)}
            />
            <div className="inputExplanation">
              <p>
                If this data comes from a platform (service, site, or method that delivers media),
                enter the platform name.
              </p>
              <p>Otherwise, input a descriptive title like "Blog Post".</p>
            </div>
          </>
          <>
            <div className="sectionTitle">Tactic</div>
            <Form.Control
              placeholder="Ex: Retargeting"
              value={tactic}
              onChange={e => setTactic(e.target.value)}
            />
            <div className="inputExplanation">
              <p>Tactic is the primary objective or audience for your spend.</p>
            </div>
          </>
          <div className="sectionTitle">Brand / Non-Brand</div>
          <Form.Check
            type="radio"
            label="Brand"
            checked={brand === "Brand"}
            id="brand"
            onChange={() => setBrand("Brand")}
          />
          <Form.Check
            type="radio"
            label="Non-Brand"
            checked={brand === "Non-Brand"}
            id="nonbrand"
            onChange={() => setBrand("Non-Brand")}
          />
          <Form.Check
            type="radio"
            label="Not Applicable"
            checked={brand === "N/A"}
            id="na"
            onChange={() => setBrand("N/A")}
          />
        </div>
        <div className="unitsInput">
          <div className="sectionTitle">
            Units<span className="asterisk">*</span>
            <div className="sectionHint">Select all that apply.</div>
          </div>
          <Form.Check
            type="checkbox"
            label="Impressions"
            checked={hasImpressions}
            id="hasImpressions"
            onChange={() => setHasImpressions(!hasImpressions)}
          />
          <Form.Check
            type="checkbox"
            label="Other"
            checked={otherUnitChecked}
            id="otherUnitChecked"
            onChange={() => setOtherUnitChecked(!otherUnitChecked)}
          />
          {otherUnitChecked && (
            <Form.Control
              placeholder="Ex: Delivered, Sends"
              value={otherUnitLabel}
              onChange={e => setOtherUnitLabel(e.target.value)}
            />
          )}
          {isPaid && (
            <>
              <Form.Check
                type="checkbox"
                label="Spend (required)"
                checked={true}
                readOnly
                id="spend"
                onClick={() => setShowSpendWarning(true)}
              />
              {showSpendWarning && (
                <div className="inputExplanation warningExplanation">
                  <MdOutlineSdCardAlert size={18} />
                  <p>
                    If your channel doesn't have spend, please{" "}
                    <b>switch to a non-paid media input.</b>
                  </p>
                </div>
              )}
            </>
          )}
        </div>
        {isPaid && (
          <div className="hasRedemptionsInput">
            <div className="sectionTitle">
              Redemptions<span className="asterisk">*</span>
            </div>
            <Form.Check
              type="radio"
              label="True"
              checked={hasRedemptions === "true"}
              id="true"
              onChange={() => setHasRedemptions("true")}
            />
            <Form.Check
              type="radio"
              label="False"
              checked={hasRedemptions === "false"}
              id="false"
              onChange={() => setHasRedemptions("false")}
            />
            <div className="inputExplanation">
              <p>Select "True" if you track code usage.</p>
            </div>
          </div>
        )}
        <div className="timeFrameInput">
          <div className="sectionTitle">
            Time Frame<span className="asterisk">*</span>
          </div>
          <DateInputField
            label="Start Date"
            value={startDateStr}
            setValue={setStartDateStr}
            customValidation={{
              isValid: value => {
                const parsedDate = new Date(value);
                const today = new Date();

                return isBefore(parsedDate, today);
              },
              errorMessage: "Please select a past date.",
            }}
            infoMessage="mm/dd/yyyy"
          />
          <div className="inputExplanation dateInputExplanation">
            <p>
              Important: Spend should be entered daily whenever possible. If your data has been
              aggregated, please disaggregate it to the daily level.
            </p>
            <p>However, if you only spend on specific days, please do not disaggregate.</p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type={ButtonType.FILLED}
          design="secondary"
          onClick={() => {
            handleClose();
            resetState();
          }}
        >
          Cancel
        </Button>
        <Button
          type={ButtonType.FILLED}
          onClick={async () => {
            setIsSubmitting(true);
            await createTable(
              {
                platform,
                tactic,
                channel,
                brand: brand || "N/A",
                hasImpressions,
                hasRedemptions: hasRedemptions === "true",
                isPaid,
                otherUnitLabel,
              },
              new Date(startDateStr)
            );
            handleClose();
            resetState();
          }}
          disabled={submitDisabled}
        >
          {isSubmitting ? <Spinner /> : "Apply"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddMediaInputModal;
