import {
  CDN,
  CellData,
  ColumnMetaData,
  DimensionMap,
  GlossaryItem,
} from "../SingleChannel/MetricsTable/metricsTableUtils";
import { DimensionColumn } from "@blisspointmedia/bpm-types/dist/MetricsTable";
import { Metric, METRIC_TO_PRETTY_NAME } from "./crossChannelConstants";
import * as CC from "@blisspointmedia/bpm-types/dist/CrossChannelPerformance";
import * as Dfns from "date-fns/fp";
import * as DfnsTZ from "date-fns-tz/fp";
import * as R from "ramda";
import {
  IMP_IN_THOUSANDS_FORMATTER,
  metricFormatter,
  SPEND_FORMATTER,
} from "../TVADCrossChannel/homePageUtils";

const DAY_DATE_LABEL = "M/dd/yy";

export interface KpiData {
  date: string;
  kpi: string;
  count: number;
  isOrganic: boolean;
  revenue: number;
  channel: string;
}

export interface KpiPreset {
  name: string;
  kpis: string[];
  is_default: boolean;
}

export interface KpiInfo {
  kpiData: KpiData[]; // KPI counts
  funnelPresets: KpiPreset[];
  kpiLabels: Record<string, string>; // Raw KPI name to pretty/cross-channel name
}

export interface Metrics {
  clicks: number;
  cpc: number;
  cpm: number;
  cpx: number;
  impressions: number;
  revenue: number;
  roas: number;
  spend: number;
  volume: number;
}

export interface MetricsByChannel {
  [channel: string]: Metrics;
}

export interface RawMetricsData {
  date: string;
  channels: MetricsByChannel;
}

export interface CrossChannelMetrics {
  date: string;
  channel: string;
  platform: string;
  campaign: string;
  metrics: Metrics;
}

export interface MetricTotalsByDate {
  date: string;
  metrics: Metrics;
}

export interface BudgetsData {
  channel: string;
  budget: number;
  dateRange: "primary" | "other";
}

const formatDateLabel = (date: string): string => {
  if (!date) {
    return "";
  }

  const toUTC = DfnsTZ.utcToZonedTime("UTC", new Date(date));
  const isValid = Dfns.isValid(toUTC);

  if (!isValid) {
    return "";
  }

  const formattedDate = Dfns.format(DAY_DATE_LABEL, toUTC);

  return formattedDate;
};

export const makePrettyDateRange = (start: string, end: string): string => {
  return `${formatDateLabel(start)}–${formatDateLabel(end)}`;
};

/**
 * Get default date ranges for date picker.
 */
export const getDefaultDates = (): {
  start: string;
  end: string;
  otherStart: string;
  otherEnd: string;
} => {
  // TODO: Remove after demos.
  const start = "2024-04-01";
  const end = "2024-04-30";
  const otherStart = "2024-03-01";
  const otherEnd = "2024-03-30";
  // const start = R.pipe(Dfns.subDays(31), Dfns.format(DATE_FORMAT))(new Date());
  // const end = R.pipe(Dfns.subDays(1), Dfns.format(DATE_FORMAT))(new Date());
  // const otherStart = R.pipe(Dfns.subDays(61), Dfns.format(DATE_FORMAT))(new Date());
  // const otherEnd = R.pipe(Dfns.subDays(32), Dfns.format(DATE_FORMAT))(new Date());
  return { start, end, otherStart, otherEnd };
};

/**
 * Filter data by selected channels, and aggregate the metric totals by date.
 * For now, not filtering by selected platforms.
 */
export const getMetricTotalsByDate = (
  data: CrossChannelMetrics[],
  selectedChannels: Record<string, boolean>
): MetricTotalsByDate[] => {
  if (R.isNil(data) || R.isEmpty(data)) {
    return [];
  }

  let metricsByDay: Record<string, Metrics> = {};

  for (let item of data) {
    const { date, metrics, channel } = item;
    if (selectedChannels[channel]) {
      metricsByDay[date] = R.mergeWith(R.add, metricsByDay[date] || {}, metrics);

      // Compute running average of CPC, CPM, CPX, and ROAS when aggregating metrics.
      metricsByDay[date][Metric.CPC] =
        metricsByDay[date][Metric.SPEND] / metricsByDay[date][Metric.CLICKS];
      metricsByDay[date][Metric.CPM] =
        (metricsByDay[date][Metric.SPEND] / metricsByDay[date][Metric.IMPRESSIONS]) * 1000;
      metricsByDay[date][Metric.CPX] =
        metricsByDay[date][Metric.SPEND] / metricsByDay[date][Metric.VOLUME];
      metricsByDay[date][Metric.ROAS] =
        metricsByDay[date][Metric.REVENUE] / metricsByDay[date][Metric.SPEND];
    }
  }

  return Object.entries(metricsByDay)
    .map(([date, metrics]) => ({ date, metrics }))
    .sort((a, b) => a.date.localeCompare(b.date));
};

export const getPrettyMetricName = (metric: string): string => {
  return METRIC_TO_PRETTY_NAME[metric] || metric;
};

export const makeColumnMetaData = (
  metric: CC.DimensionColumnType,
  iconStyle?: "logo" | "thumbnail" | undefined
): ColumnMetaData => {
  return {
    contentType: "text",
    dimensionVarName: metric,
    displayName: metric,
    iconStyle: iconStyle || undefined,
  };
};

export const dimensionColumnMetaDataMap: Partial<Record<CC.DimensionColumnType, ColumnMetaData>> = {
  Platform: makeColumnMetaData("Platform", "logo"),
  Channel: makeColumnMetaData("Channel", "logo"),
  "Account Name": makeColumnMetaData("Account Name"),
  "Account ID": makeColumnMetaData("Account ID"),
};

export const columnMetaDataMap: Partial<Record<CC.ColumnType, ColumnMetaData>> = {
  [Metric.CLICKS]: {
    displayName: "Clicks",
    formatValue: metricFormatter("clicks"),
  },
  [Metric.SPEND]: {
    displayName: "Spend",
    formatValue: SPEND_FORMATTER,
  },
  [Metric.IMPRESSIONS]: {
    displayName: "Impressions (000s)",
    formatValue: IMP_IN_THOUSANDS_FORMATTER,
  },
  [Metric.VOLUME]: {
    displayName: "KPI Volume",
    formatValue: metricFormatter("volume"),
  },
  [Metric.REVENUE]: {
    displayName: "Revenue",
    formatValue: metricFormatter("revenue"),
  },
  [Metric.CPC]: {
    displayName: "CPC",
    formatValue: metricFormatter("cpc"),
    aggregator: agg =>
      agg[Metric.CLICKS] && agg[Metric.SPEND]
        ? (agg[Metric.SPEND] as number) / (agg[Metric.CLICKS] as number)
        : "--",
    fetchGetter: fetch =>
      fetch[Metric.CLICKS] && fetch[Metric.SPEND]
        ? (fetch[Metric.SPEND] as number) / (fetch[Metric.CLICKS] as number)
        : "--",
    requiredTotalsColumns: [Metric.CLICKS, Metric.SPEND],
  },
  [Metric.CPM]: {
    displayName: "CPM",
    formatValue: metricFormatter("cpm"),
    aggregator: agg =>
      agg[Metric.IMPRESSIONS] && agg[Metric.SPEND]
        ? ((agg[Metric.SPEND] as number) * 1000) / (agg[Metric.IMPRESSIONS] as number)
        : "--",
    fetchGetter: fetch =>
      fetch[Metric.IMPRESSIONS] && fetch[Metric.SPEND]
        ? ((fetch[Metric.SPEND] as number) * 1000) / (fetch[Metric.IMPRESSIONS] as number)
        : "--",
    requiredTotalsColumns: [Metric.IMPRESSIONS, Metric.SPEND],
  },
  [Metric.ROAS]: {
    displayName: "ROAS",
    formatValue: metricFormatter("roas"),
    aggregator: agg =>
      agg[Metric.REVENUE] && agg[Metric.SPEND]
        ? (agg[Metric.REVENUE] as number) / (agg[Metric.SPEND] as number)
        : "--",
    fetchGetter: fetch =>
      fetch[Metric.REVENUE] && fetch[Metric.SPEND]
        ? (fetch[Metric.REVENUE] as number) / (fetch[Metric.SPEND] as number)
        : 0,
    requiredTotalsColumns: [Metric.REVENUE, Metric.SPEND],
  },
  [Metric.CPX]: {
    displayName: "CPX",
    formatValue: metricFormatter("cpx"),
    aggregator: agg =>
      agg[Metric.VOLUME] && agg[Metric.SPEND]
        ? (agg[Metric.SPEND] as number) / (agg[Metric.VOLUME] as number)
        : "--",
    fetchGetter: fetch =>
      fetch[Metric.VOLUME] && fetch[Metric.SPEND]
        ? (fetch[Metric.SPEND] as number) / (fetch[Metric.VOLUME] as number)
        : "--",
    requiredTotalsColumns: [Metric.VOLUME, Metric.SPEND],
  },
};

export const getCrossChannelDimensionCell = (
  dimensionData: DimensionMap,
  dimensionHeader: DimensionColumn
): CellData => {
  const { dimensionVarName, dimensionTypeName, icon } = dimensionHeader;
  const resolvedDimensionData = dimensionData as Record<string, string>;
  const dimensionValue = resolvedDimensionData[dimensionVarName];
  const defaultCell = {
    label: dimensionValue,
    value: dimensionValue,
  };
  if (dimensionTypeName === "Channel") {
    const parsedDimensionValue = dimensionValue.replace(/\s+/g, "");
    const url = !R.isNil(icon)
      ? `${CDN}/assets/img/channels/${parsedDimensionValue}.png`
      : undefined;
    return {
      ...defaultCell,
      url,
    };
  } else if (dimensionTypeName === "Platform") {
    const channelMap = {
      audio: "Streaming Audio",
      linear: "Linear TV",
      streaming: "Streaming Video",
    };
    const prettyNameMap = {
      audio: "Streaming Audio",
      facebook: "Meta",
      google_ads: "Google Ads",
      linear: "Linear TV",
      streaming: "Streaming Video",
      tiktok: "TikTok",
      twitter: "X",
    };
    const prettyName = prettyNameMap[dimensionValue.toLowerCase()]
      ? prettyNameMap[dimensionValue.toLowerCase()]
      : `${dimensionValue.replace(/_/g, " ").charAt(0).toUpperCase()}${dimensionValue
          .replace(/_/g, " ")
          .slice(1)}`;
    const url = !R.isNil(icon)
      ? `${CDN}/assets/img/${
          channelMap[dimensionValue.toLowerCase()] ? "channels" : "platforms"
        }/${prettyName.replace(/ /g, "")}.png`
      : undefined;
    return {
      label: prettyName,
      value: prettyName,
      url,
    };
  }

  return defaultCell;
};

export const GLOSSARY: GlossaryItem[] = [
  {
    term: "Channel",
    definition: "This is a type of marketing effort.	A type of marketing effort (e.g. Paid Social).",
  },
  {
    term: "Platform",
    definition:
      "This is the system through which the ad impression is being delivered.	The system through which the ad is delivered (e.g. Meta).",
  },
  {
    term: "Surface",
    definition:
      "This is the end point where the ad is being delivered to a potential consumer.	The end point where the ad is delivered to its audience (e.g. Facebook or Instagram). Note: surface isn't always applicable.",
  },
  {
    term: "Clicks",
    definition: "The number of individuals who click on the ad(s).",
  },
  {
    term: "Spend",
    definition: "The total dollars invested.	The number of dollars invested.",
  },
  {
    term: "Impressions",
    definition: "The number of times the ad(s) are delivered.",
  },
  {
    term: "KPI Volume",
    definition:
      "The number of outcome events generated (for the KPI selected in the dropdown at the top of the page).",
  },
  {
    term: "Revenue",
    definition: "The number of dollars generated in return.",
  },
  {
    term: "CPM",
    definition: "Cost per Thousand Impresisons = (spend x 1,000) / impressions",
  },
  {
    term: "ROAS",
    definition: "Return on Ad Spend = revenue / spend",
  },
  {
    term: "CPX",
    definition:
      "Cost per X = total spend / KPI volume (X is a placeholder letter for the KPI selected in the dropdown at the top of the page)",
  },
  {
    term: "CPC",
    definition: "Cost per Click = spend / clicks",
  },
];
