import React from "react";
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Cell, LabelList } from "recharts";
import { Brand100 } from "../utils/colors";
import "./PacingBar.scss";
import { Img } from "../Components";

const formatYValue = (value, marginLeft, numberOfSegments) => {
  return marginLeft === 1 && numberOfSegments.length > 1 ? `${value.trim()} Total` : value.trim();
};

const CustomYAxisTick = ({
  x,
  y,
  payload,
  marginLeft,
  isModal,
  numberOfSegments,
  longestText,
  total,
  currentSegment,
}) => {
  if (isModal) {
    return (
      <foreignObject x={x - 50} y={y - 10} width={200} height={100}>
        <div className="pacingBarText">Total</div>
      </foreignObject>
    );
  }

  const newValue = formatYValue(payload.value, marginLeft, numberOfSegments);
  const segmentsWithLogos = ["Channel", "Platform"];


  return (
    <foreignObject
      x={x - longestText - 40}
      y={marginLeft === 1 ? y - 15 : y - 10}
      width={200}
      height={125}
    >
      <div className="pacingBarYAxisContainer">
        {segmentsWithLogos.includes(currentSegment) && !total && (
          <div className="pacingBarLogos">
            {" "}
            <Img
              src={`https://cdn.blisspointmedia.com/assets/img/${`${currentSegment.toLowerCase()}s`}/${payload.value.replace(
                /\s/g,
                ""
              )}.png`}
              alt="brand health header"
              className="logoImage"
            />
          </div>
        )}
        <div className={`pacingBarText${marginLeft}`}>
          <div>{total ? "Total" : newValue}</div>
        </div>
      </div>
    </foreignObject>
  );
};

const blues = ["#2AE0F6", "#AEF3FC", "#D9F9FD"];

const getColor = (colors: string[], index: number) => {
  return colors[index];
};

let ctx;

export const measureText: any = text => {
  if (!ctx) {
    ctx = document.createElement("canvas").getContext("2d");
    ctx.font = "1rem 'Graphik";
  }

  return ctx.measureText(text).width;
};

const BAR_AXIS_SPACE = 10;

interface PacingBarProps {
  data: {
    totalCleared: number;
    totalProjected: number;
    totalRemainder: number;
    segmentName: string;
  }[];
  segmentName: string;
  keys: string[];
  goalValue: number;
  className?: string;
  colors?: string[];
  marginLeft: number;
  numberOfSegments: string[];
  longestText: number;
  isModal?: boolean;
  total?: boolean;
  currentSegment?: string;
}

const CustomBarWithTarget: any = ({
  fill,
  x,
  y,
  width,
  height,
  index,
  targetValue,
  isModal,
  marginLeft,
}) => {
  const budgetText = `$${(targetValue / 1000).toLocaleString("en-us", {
    maximumFractionDigits: 2,
  })}k Budget`;

  let fontWeight = 600;
  let fontSize = 14;
  if (marginLeft >= 3) {
    fontWeight = 400;
    fontSize = 12;
  }
  return (
    <svg>
      <rect x={x} y={y} width={width} height={height} stroke="none" fill={fill} />
      {index === 2 && (
        <>
          <line
            x1={x + width}
            x2={x + width}
            y1={y}
            y2={y + height}
            stroke="#007F8E"
            strokeWidth={3}
          />
          <text
            x={x + width + 3}
            y={y + height / 2}
            fill="#007F8E"
            dominantBaseline="middle"
            fontWeight={fontWeight}
            fontSize={fontSize}
          >
            {budgetText}
          </text>
        </>
      )}
    </svg>
  );
};

const renderCustomizedLabel: any = (props, index, marginLeft, isModal, goalValue) => {
  if (index > 1) {
    return null;
  }
  const { x, y, value } = props;
  if (value === 0 && index === 1) {
    return null;
  }
  const valueTag = index === 0 ? " Delivered" : " Projected";
  let formattedValue: string;
  if (isModal) {
    formattedValue = `$${(value / 1000).toLocaleString("en-us", {
      maximumFractionDigits: 0,
    })}k/${((value / goalValue) * 100).toFixed(0)}% ${valueTag}`;
  } else {
    formattedValue = `$${(value / 1000).toLocaleString("en-us", {
      maximumFractionDigits: 0,
    })}k ${valueTag}`;
  }
  let yHeight = index === 0 ? y + 10 : y + 38 - marginLeft * 3.5;
  let fontWeight = 500;
  let fontSize = 14;
  let dominantBaseline = "hanging";
  if (marginLeft === 3) {
    fontWeight = 400;
    fontSize = 12;
    yHeight = y + 2;
  } else if (marginLeft === 4) {
    fontWeight = 400;
    fontSize = 12;
    dominantBaseline = "auto";
    yHeight = index === 0 ? y - 2 : y + 47;
  }

  return (
    <text
      x={x + 9.92}
      y={yHeight}
      fill={Brand100}
      textAnchor="start"
      dominantBaseline={dominantBaseline}
      fontSize={fontSize}
      fontFamily="Graphik"
      fontWeight={fontWeight}
    >
      {formattedValue}
    </text>
  );
};

const PacingBar: React.FC<PacingBarProps> = ({
  data,
  segmentName,
  keys,
  goalValue,
  colors = blues,
  className,
  marginLeft,
  numberOfSegments,
  longestText,
  isModal = false,
  total = false,
  currentSegment,
}) => {
  const maxTextWidth = measureText(segmentName);
  let containerHeight = total ? 122 : marginLeft === 1 && numberOfSegments.length > 1 ? 122 : 92;
  let barHeight = total ? 90 : marginLeft === 1 && numberOfSegments.length > 1 ? 90 : 60;
  if (marginLeft === 3) {
    containerHeight = 72;
    barHeight = 36;
  } else if (marginLeft === 4) {
    containerHeight = 48;
    barHeight = 16;
  }
  const barWidth = 100 - marginLeft * 10;

  return (
    <ResponsiveContainer width={`${barWidth}%`} height={containerHeight} debounce={50}>
      <BarChart
        data={data}
        layout="vertical"
        margin={{
          left: isModal ? 35 : longestText,
          right: maxTextWidth + (BAR_AXIS_SPACE - 8),
        }}
      >
        <XAxis hide axisLine={false} type="number" />
        <YAxis
          yAxisId={0}
          dataKey={"segmentName"}
          type="category"
          axisLine={false}
          tickLine={false}
          tick={props => (
            <CustomYAxisTick
              {...props}
              marginLeft={marginLeft}
              isModal={isModal}
              numberOfSegments={numberOfSegments}
              longestText={longestText}
              total={total}
              currentSegment={currentSegment}
            />
          )}
          tickFormatter={value => formatYValue(value, marginLeft, numberOfSegments)}
        />
        {keys.map((key, index) => (
          <Bar
            key={key}
            dataKey={key}
            stackId="a"
            minPointSize={2}
            barSize={barHeight}
            shape={props => (
              <CustomBarWithTarget
                {...props}
                index={index}
                targetValue={goalValue}
                isModal={isModal}
                marginLeft={marginLeft}
              />
            )}
          >
            <LabelList
              dataKey={key}
              content={labelProps =>
                renderCustomizedLabel(labelProps, index, marginLeft, isModal, goalValue)
              }
              fill={"#fde5e5"}
            />
            {data.map((entry, idx) => (
              <Cell key={`cell-${idx}`} fill={getColor(colors, index)} />
            ))}
          </Bar>
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default PacingBar;
