import * as R from "ramda";
import React from "react";
import { MdDeleteForever } from "react-icons/md";
import { Button, ButtonType, OnBlurInput } from "../Components";
import { HandleKpiEditInput, HandleNewRowInput, CombinedCrossChannelKpis } from "./EditKpiMapping";
import { EditsMap } from "@blisspointmedia/bpm-types/dist/KpiMapping";
import { ButtonFrameworkVariant } from "../Components/ButtonFramework";

interface CrossChannelKpiColumnProps {
  crossChannelKpis: CombinedCrossChannelKpis;
  editsMap: EditsMap;
  handleKpiEdit: (input: HandleKpiEditInput) => void;
  handleNewRow: (input: HandleNewRowInput) => void;
  deleteNewRow: (id: string) => void;
}

const CrossChannelKpiColumn: React.FC<CrossChannelKpiColumnProps> = React.memo(
  ({ crossChannelKpis, editsMap, handleKpiEdit, handleNewRow, deleteNewRow }) => {
    return (
      <div className="crossChannelKpiColumn">
        <div className="columnHeader">Cross Channel KPI</div>
        <div>
          {crossChannelKpis.map(({ crossChannelKpiLabel, crossChannelKpiId, isNewRow }) => {
            return (
              <div className="crossChannelKpiItem" key={crossChannelKpiId}>
                {isNewRow && (
                  <Button
                    className="deleteNewKpiButton"
                    type={ButtonType.EMPTY}
                    variant={ButtonFrameworkVariant.ICON_ONLY}
                    icon={<MdDeleteForever style={{ color: "red" }} />}
                    size="sm"
                    onClick={() => deleteNewRow(crossChannelKpiId as string)}
                  />
                )}
                <OnBlurInput
                  value={
                    R.pathOr("", [crossChannelKpiId, "newKpi"], editsMap) || crossChannelKpiLabel
                  }
                  onChange={newVal =>
                    isNewRow
                      ? handleNewRow({
                          crossChannelKpiId: (crossChannelKpiId as unknown) as string,
                          kpiLabel: newVal,
                          sourceLabel: "",
                          isCrossChannelKpi: true,
                        })
                      : handleKpiEdit({
                          kpiId: crossChannelKpiId as number,
                          kpiLabel: newVal,
                          isCrossChannelKpi: true,
                        })
                  }
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
);

export default CrossChannelKpiColumn;
