import React from "react";
import {
  Line,
  LineChart,
  XAxis,
  YAxis,
  BarChart,
  Bar,
  CartesianGrid,
  ReferenceLine,
} from "recharts";
import AutoSizer from "react-virtualized-auto-sizer";
import { Channel4, Neutral300, Brand100 } from "../utils/colors";
import * as Dfns from "date-fns/fp";
import { currencyFormatter } from "../MMM/MMMUtils";
import { useDynamicYAxisWidth } from "../Components/Charts/ChartUtils";
import { calculateCumulativeData } from "./pacingUtils";

interface ModalGraphAndBarChartProps {
  data: any[];
  showGraph: boolean;
  showCumulative: boolean;
  today: string;
}

const ModalGraphAndBarChart: React.FC<ModalGraphAndBarChartProps> = ({
  data,
  showGraph,
  showCumulative,
  today,
}) => {
  const { xAxisHeight, yAxisWidth, setChartRef } = useDynamicYAxisWidth({
    xAxisHeightModifier: x => x + 5,
    yAxisWidthModifier: x => x + 3,
  });

  const formatYAxis = (tickItem, budgetValue) => {
    if (tickItem === budgetValue) {
      return `${currencyFormatter.format(tickItem, 0)} Budget`;
    }
    return `${currencyFormatter.format(tickItem, 0)}`;
  };

  const sumSpendBeforeDate = (data: any[], today: string): number => {
    const todayDate = new Date(today);
    return data
      .filter(item => new Date(item.date) < todayDate)
      .reduce((acc, item) => acc + item.spend, 0);
  };

  const cumulativeData = calculateCumulativeData(data);

  return (
    <AutoSizer>
      {({ width, height }) =>
        showCumulative ? (
          <LineChart data={cumulativeData} height={height} width={width} ref={setChartRef}>
            <ReferenceLine
              x={today}
              label={{
                position: "top",
                value: `Today\n ${(
                  (sumSpendBeforeDate(data, today) / data[0].goalValue) *
                  100
                ).toFixed(0)}% through the time period`,
                fill: Brand100,
                fontWeight: "600",
                fontSize: 16,
                dy: 20,
                dx: 140,
              }}
              stroke="#5F6C84"
            />
            <ReferenceLine
              y={300}
              label={{
                position: "left",
                value: "Budget",
                fill: "#007F8E",
                fontWeight: "400",
                fontSize: 12,
                dy: 20,
                dx: 12,
              }}
              stroke="#007F8E"
              strokeWidth={10}
            />
            <CartesianGrid
              stroke={Neutral300}
              strokeDasharray="0"
              horizontal={true}
              vertical={false}
            />
            <XAxis
              dataKey="date"
              tickFormatter={val => Dfns.format("M/dd", new Date(val))}
              height={xAxisHeight}
              tick={{ fontSize: 12, fontWeight: 400 }}
              axisLine={false}
            />
            <YAxis
              tickFormatter={val => formatYAxis(val, data[0].goalValue)}
              width={yAxisWidth}
              tick={{ fontSize: 12, fontWeight: 400 }}
              tickLine={false}
              axisLine={false}
            />
            <Line
              dot={false}
              type="monotone"
              dataKey="cumulativeClearedSimpleSpend"
              stroke={Channel4}
            />
            <Line
              dot={false}
              type="monotone"
              dataKey="cumulativeProjectedSimpleSpend"
              stroke={Channel4}
              strokeDasharray="5 5"
            />
            <Line
              dot={false}
              type="monotone"
              dataKey="cumulativeClearedSimpleIdealSpend"
              stroke="#7ED600"
            />
            <Line
              dot={false}
              type="monotone"
              dataKey="cumulativeProjectedAdjustedSpend"
              stroke="#7ED600"
              strokeDasharray="5 5"
            />
          </LineChart>
        ) : (
          <BarChart data={data} height={height} width={width}>
            <YAxis
              tickFormatter={val => `${currencyFormatter.format(val, 0)}`}
              tick={{ fontSize: 12, fontWeight: 400 }}
              tickLine={false}
              axisLine={false}
            />
            <XAxis
              dataKey="date"
              tickFormatter={val => Dfns.format("M/dd", new Date(val))}
              tick={{ fontSize: 12, fontWeight: 400 }}
              axisLine={false}
            />
            <ReferenceLine
              x="2024-07-26"
              label={{
                position: "top",
                value: `Today\n ${(
                  (sumSpendBeforeDate(data, "2024-07-26") / data[0].goalValue) *
                  100
                ).toFixed(0)}% through the time period`,
                fill: Brand100,
                fontWeight: "600",
                fontSize: 16,
                dy: 20,
                dx: 140,
              }}
              stroke="#5F6C84"
            />
            <Bar dataKey="cleared.simple.spend" fill={Channel4} />
            <Bar dataKey="projected.simple.spend" fill="#B3F5FB" />
            <Bar dataKey="cleared.simple.idealSpend" fill="#7ED600" />
            <Bar dataKey="projected.adjusted.spend" fill="#D5F0B6" />
          </BarChart>
        )
      }
    </AutoSizer>
  );
};

export default ModalGraphAndBarChart;
