import React, { useMemo, useState } from "react";
import { ButtonFramework, ButtonFrameworkVariant } from "../ButtonFramework";
import "./Button.scss";

export enum ButtonType {
  FILLED = "filled-button",
  OUTLINED = "outlined-button",
  EMPTY = "empty-button",
}

export interface ButtonProps {
  type: ButtonType;
  className?: string;
  variant?: ButtonFrameworkVariant;
  size?: "lg" | "sm";
  design?: "primary" | "secondary";
  background?: "light" | "dark";
  icon?: React.ReactElement;
  disabled?: boolean;
  onClick?: (e: Event) => void;
  [passthroughProp: string]: any;
}

export const Button: React.FC<ButtonProps> = React.memo(
  ({
    type,
    className,
    variant = ButtonFrameworkVariant.NO_ICON,
    size = "lg",
    design = "primary",
    background = "light",
    icon,
    disabled = false,
    onClick = () => {}, // Default to no-op
    ...passthrough
  }) => {
    const [animateOnClick, setAnimateOnClick] = useState<boolean>(false);

    let resolvedClassName = useMemo(() => {
      let classes: string[] = [type, design, background];
      if (animateOnClick) {
        classes.push("animated");
      }
      if (disabled) {
        classes.push("disabled");
      }
      if (className) {
        classes.push(className);
      }
      return classes.join(" ");
    }, [animateOnClick, background, className, design, disabled, type]);

    return (
      <ButtonFramework
        className={resolvedClassName}
        variant={variant}
        size={size}
        icon={icon}
        disabled={disabled}
        onClick={(e: Event) => {
          onClick(e);
          setAnimateOnClick(true);
        }}
        onAnimationEnd={() => setAnimateOnClick(false)}
        {...passthrough}
      />
    );
  }
);
