import {
  checkAuth,
  clearAuthCookies,
  clearRefreshToken,
  initPeriodicRefresh,
  isRefreshNeeded,
} from "../utils/auth";
import { makeReducer } from "./utils";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useCallback, useState, useMemo } from "react";
import { UsersLambdaFetch, awaitJSON } from "../utils/fetch-utils";
import { useSetAreYouSure } from "../redux/modals";
import * as R from "ramda";
import * as Sentry from "@sentry/browser";
import Cookies from "js-cookie";

// Check for a new version of the app every half-hour
const VERSION_CHECK_INTERVAL = 1000 * 60 * 30;
const PAGE_TIMEOUT = 10000; // 10 seconds
const ROOT = "user";

const SET_USER_INFO = `${ROOT}/SET_USER_INFO`;
const SET_IS_AUTHED = `${ROOT}/SET_IS_AUTHED`;
const SET_COMMIT = `${ROOT}/SET_COMMIT`;
const SET_UP_TO_DATE = `${ROOT}/SET_UP_TO_DATE`;
const SET_UP_TO_DATE_DISMISSED = `${ROOT}/SET_UP_TO_DATE_DISMISSED`;

export const agenciesSelector = R.path([ROOT, "agencies"]);
export const appUpToDateDismissedSelector = R.path([ROOT, "appUpToDateDismissed"]);
export const appUpToDateSelector = R.path([ROOT, "appUpToDate"]);
export const commitHashesSelector = R.path([ROOT, "commitHashes"]);
export const companiesSelector = R.path([ROOT, "companies"]);
export const emailSelector = R.path([ROOT, "email"]);
export const fullNameSelector = R.path([ROOT, "fullName"]);
export const isAdminSelector = R.path([ROOT, "isAdmin"]);
export const isAuthedSelector = R.path([ROOT, "isAuthed"]);
export const isInternalSelector = R.path([ROOT, "isInternal"]);
export const isTinuitiUserSelector = user =>
  R.defaultTo(10, R.path([ROOT, "companies", 0, "role"], user)) <= 2;
export const isNOCSelector = user =>
  R.defaultTo(10, R.path([ROOT, "companies", 0, "role"], user)) <= 3;
export const roleSelector = R.path([ROOT, "companies", 0, "role"]);
export const sendingEmailSelector = R.pipe(emailSelector, R.identity);
export const startTimeSelector = R.path([ROOT, "startTime"]);
export const userFetchedSelector = R.path([ROOT, "userFetched"]);

export const useIsAuthed = () => {
  const dispatch = useDispatch();
  const isAuthed = useSelector(isAuthedSelector);
  const setAreYouSure = useSetAreYouSure(true);

  const nilIsAuthedTime = useMemo(() => (R.isNil(isAuthed) ? Date.now() : null), [isAuthed]);
  const [now, setNow] = useState(Date.now());

  const clearTokensAndRefreshPage = useCallback(() => {
    clearAuthCookies();
    document.location.reload();
  }, []);

  const refreshTokens = useCallback(async reload => {
    await initPeriodicRefresh();
    setTimeout(() => {
      if (reload) {
        document.location.reload();
      }
    }, 3000);
  }, []);

  useEffect(() => {
    // If we have been inactive for over 10 seconds ask the user if they want to refresh the page or try
    // sign in again
    if (!R.isNil(nilIsAuthedTime)) {
      if (now - nilIsAuthedTime > PAGE_TIMEOUT) {
        (async () => {
          await setAreYouSure({
            title: "Refresh Credentials",
            message:
              "We are having trouble verifying your login credentials, you can try refreshing the page or signing out and signing in again!",
            okayText: "Refresh",
            cancelText: "Sign Out",
            variant: "warning",
            onCancel: clearTokensAndRefreshPage,
            onOkay: async () => {
              await refreshTokens(true);
            },
          });
        })();
      } else {
        setTimeout(() => {
          setNow(Date.now());
        }, 1000);
      }
    }
  }, [clearTokensAndRefreshPage, nilIsAuthedTime, now, refreshTokens, setAreYouSure]);

  useEffect(() => {
    // If this is null, that means we haven't checked authentication yet.
    if (R.isNil(isAuthed)) {
      (async () => {
        let authCheck;
        try {
          authCheck = await checkAuth();
        } catch (e) {
          clearTokensAndRefreshPage();
          dispatch({
            type: SET_IS_AUTHED,
            isAuthed: false,
          });
        }
        let refreshWait = 0;
        if (isRefreshNeeded) {
          refreshTokens(false);
          refreshWait = 3000;
        }
        setTimeout(async () => {
          // If we're authenticated, call the "me" endpoint. Otherwise, send to the login page
          if (authCheck) {
            try {
              let userRes = await UsersLambdaFetch("/me", {
                retries: 0,
              });
              let userInfo = await awaitJSON(userRes);
              dispatch({
                type: SET_USER_INFO,
                userInfo,
              });
              Sentry.configureScope(scope => {
                scope.setUser({ email: userInfo.email, user: userInfo.email });
              });
            } catch (e) {
              if (R.isNil(isAuthed) && Cookies.get("login_method") === "Google") {
                await setAreYouSure({
                  title: "Refresh Credentials",
                  message:
                    "We are having trouble automatically refreshing your Google Log In Credentials, you can try refreshing the page or signing out and signing in again!",
                  okayText: "Refresh",
                  cancelText: "Sign Out",
                  variant: "warning",
                  onCancel: () => {
                    clearRefreshToken();
                    clearTokensAndRefreshPage();
                  },
                  onOkay: async () => {
                    await refreshTokens(true);
                  },
                });
              }
              console.error(e);
              dispatch({
                type: SET_IS_AUTHED,
                isAuthed: false,
              });
            }
            try {
              await refreshTokens(false);
            } catch (e) {
              console.error(e);
            }
          } else {
            clearAuthCookies();
            dispatch({
              type: SET_IS_AUTHED,
              isAuthed: false,
            });
          }
        }, refreshWait);
      })();
    }
  }, [isAuthed, dispatch, setAreYouSure, clearTokensAndRefreshPage, refreshTokens]);

  return isAuthed;
};

const fetchLatestCommits = async () => {
  let latestCommitRes = await UsersLambdaFetch("/latest_commit", {
    retries: 10,
    params: {
      repo: "bpm-frontend",
    },
  });
  const frontendHash = await awaitJSON(latestCommitRes);
  return {
    frontend: frontendHash.hash,
  };
};

export const useVersionCheck = () => {
  const dispatch = useDispatch();
  const commitHashes = useSelector(commitHashesSelector);
  const isAuthed = useSelector(isAuthedSelector);

  const makeVersionCheckInterval = useCallback(
    hashes => {
      let newFrontend = false;
      const int = setInterval(async () => {
        try {
          const latestHashes = await fetchLatestCommits();
          if (!newFrontend && hashes.frontend !== latestHashes.frontend) {
            console.warn("Newer version of the app available");
            dispatch({
              type: SET_UP_TO_DATE,
              appUpToDate: false,
            });
            newFrontend = true;
          }
          if (newFrontend) {
            clearInterval(int);
          }
        } catch (e) {
          console.error("Failed to fetch latest commits after several retries. Giving up.");
          dispatch({
            type: SET_UP_TO_DATE,
            appUpToDate: false,
          });
          clearInterval(int);
        }
      }, VERSION_CHECK_INTERVAL);
      return int;
    },
    [dispatch]
  );

  useEffect(() => {
    if (isAuthed && !commitHashes) {
      (async () => {
        try {
          let hashes = await fetchLatestCommits();

          Sentry.configureScope(scope => {
            scope.setExtra("commitHash", hashes.frontend);
          });
          // TODO: do we need this in redux? We may actually never use it
          dispatch({
            type: SET_COMMIT,
            commitHashes: hashes,
          });
          if (process.env.NODE_ENV !== "development") {
            makeVersionCheckInterval(hashes);
          }
        } catch (e) {
          const message = `Failed to get latest commit hash on first try. Error: ${e.message}`;
          console.error(message);
          Sentry.captureException(message);
        }
      })();
    }
  }, [isAuthed, commitHashes, dispatch, makeVersionCheckInterval]);
};

export const useUpToDate = () => {
  const dispatch = useDispatch();
  const appUpToDate = useSelector(appUpToDateSelector);
  const dismissed = useSelector(appUpToDateDismissedSelector);

  const dismiss = useCallback(() => {
    dispatch({
      type: SET_UP_TO_DATE_DISMISSED,
      appUpToDateDismissed: true,
    });
  }, [dispatch]);

  return {
    appUpToDate: dismissed || appUpToDate,
    dismiss,
  };
};

const setIsAuthed = (state, { isAuthed }) => ({
  ...state,
  isAuthed,
});

const setUserInfo = (state, { userInfo }) => ({
  ...state,
  ...userInfo,
  isAuthed: true,
  userFetched: true,
});

const setCommit = (state, { hash, repo }) => ({
  ...state,
  commitHashes: {
    ...state.commitHashes,
    [repo]: hash,
  },
});
const setUpToDate = (state, { appUpToDate }) => ({ ...state, appUpToDate });
const setUpToDateDismiss = (state, { appUpToDateDismissed }) => ({
  ...state,
  appUpToDateDismissed,
});

export default makeReducer(
  {
    isAdmin: false,
    isInternal: false,
    email: "",
    userFetched: false,
    appUpToDate: true,
    appUpToDateDismissed: false,
    startTime: Date.now(),
  },
  {
    [SET_IS_AUTHED]: setIsAuthed,
    [SET_USER_INFO]: setUserInfo,
    [SET_COMMIT]: setCommit,
    [SET_UP_TO_DATE]: setUpToDate,
    [SET_UP_TO_DATE_DISMISSED]: setUpToDateDismiss,
  }
);
