import { KpiInfo, MetricTotalsByDate } from "../crossChannelUtils";
import { Metric } from "../crossChannelConstants";
import CompareMetrics from "../Components/CompareMetrics";
import FunnelDynamics from "../Components/FunnelDynamics";
import KpiVolume, { Filtering } from "../Components/KpiVolume";
import MarketingInputs from "../Components/MarketingInputs";
import React, { useState, useContext } from "react";
import SparkChart from "../../Components/SparkChart/SparkChart";
import { CrossChannelContext } from "../CrossChannel";

interface OverviewProps {
  kpis: KpiInfo;
  data: MetricTotalsByDate[];
  otherData: MetricTotalsByDate[];
  marketingInputs: any[];
  channelClassification: Record<string, string>;
  defaultCompareMetrics: Metric[];
  defaultCompareMetricsIncludeOtherDates;
  defaultFunnelDynamicsPreset?: string;
  defaultKpiFiltering?: Filtering;
  defaultKpiFilteringFunnel?: string;
  defaultSparkCharts?: Metric[];
  defaultTotal?: string[];
  selectedChannels: Record<string, boolean>;
}

const DEFAULT_METRICS = [Metric.SPEND, Metric.IMPRESSIONS, Metric.CPM] as const;

const Overview: React.FC<OverviewProps> = ({
  kpis,
  data,
  otherData,
  marketingInputs,
  channelClassification,
  defaultCompareMetrics,
  defaultCompareMetricsIncludeOtherDates = false,
  defaultFunnelDynamicsPreset,
  defaultKpiFiltering,
  defaultKpiFilteringFunnel,
  defaultSparkCharts = DEFAULT_METRICS,
  defaultTotal = [false, false, true],
  selectedChannels,
}) => {
  const [showMarketingInputs, setShowMarketingInputs] = useState(false);
  const { kpiData, funnelPresets, kpiLabels } = kpis;
  const { kpi, showPlatformVsAnalytics } = useContext(CrossChannelContext);
  return (
    <div className="ccOverview">
      <div className="ccTopSection">
        <div className="ccTopSectionLeftSide">
          <div className="ccDailyKpiVolume">
            <KpiVolume
              data={kpiData}
              funnelPresets={funnelPresets}
              kpiLabels={kpiLabels}
              showMarketingInputs={showMarketingInputs}
              setShowMarketingInputs={setShowMarketingInputs}
              defaultFiltering={defaultKpiFiltering}
              defaultFunnel={defaultKpiFilteringFunnel}
              selectedChannels={selectedChannels}
              footer={
                showPlatformVsAnalytics ? (
                  <>
                    <span>KPI picker doesn't apply</span>
                  </>
                ) : undefined
              }
            />
          </div>
          {showMarketingInputs && (
            <div className="ccMarketingInputs">
              <MarketingInputs
                data={marketingInputs}
                channelClassification={channelClassification}
              />
            </div>
          )}
          {!showMarketingInputs && (
            <div className="ccSparkCharts">
              {defaultSparkCharts.map((metric, index) => (
                <SparkChart
                  pngLabel={`KPI: ${kpi}`}
                  pngFileIdentifiers={["CrossChannel"]}
                  data={data}
                  otherData={otherData}
                  defaultMetric={metric}
                  key={metric}
                  defaultTotal={defaultTotal[index] ? defaultTotal[index] === "Total" : false}
                />
              ))}
            </div>
          )}
        </div>
        <div className="ccTopSectionRightSide">
          <div className="ccKpiFunnel">
            <FunnelDynamics
              data={kpiData}
              funnelPresets={funnelPresets}
              kpiLabels={kpiLabels}
              defaultPreset={defaultFunnelDynamicsPreset}
              selectedChannels={selectedChannels}
            />
          </div>
        </div>
      </div>
      <div className="ccRow">
        <div className="ccAgencyNews" style={{ margin: "8px" }}>
          <a href="https://www.tinuiti.com" target="_blank" rel="noopener noreferrer">
            <img
              src={"https://cdn.blisspointmedia.com/agencies/tinuiti/Tinuiti_Agency_News.png"} // This is the placeholder image for now.
              alt="Agency News"
              width={500}
              height={500}
            />
          </a>
        </div>
        <div className="ccMetricComparisonChart">
          <CompareMetrics
            pngDownloadLabel={`KPI: ${kpi}`}
            pngFileIdentifiers={["CrossChannel"]}
            data={data}
            otherData={otherData}
            defaultMetrics={defaultCompareMetrics}
            defaultIncludeOtherDates={defaultCompareMetricsIncludeOtherDates}
            includeFooter={!!showPlatformVsAnalytics}
          />
        </div>
      </div>
    </div>
  );
};

export default Overview;
