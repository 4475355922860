import React, { useCallback, useEffect } from "react";
import * as R from "ramda";
import cn from "classnames";
import * as Sentry from "@sentry/browser";
import { Alert, Modal, Button, Nav, AlertProps } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useModalInfo } from "../redux/modals";
import { isInternalSelector, useUpToDate } from "../redux/user";
import { useCompanyInfo } from "../redux/company";
import ReactGA from "react-ga4";
import { Img } from "./Img";
import "./Page.scss";

export const AlertBar: React.FC = ({ children }) => {
  const { dismiss } = useUpToDate();

  return (
    <div className="newVersionAlertBar">
      <div>{children}</div>
      <Button variant="link" size="sm" onClick={dismiss}>
        Dismiss
      </Button>
    </div>
  );
};

export const NewVersionAlertBar: React.FC = () => {
  const { appUpToDate } = useUpToDate();

  if (appUpToDate) {
    return null;
  }
  return <AlertBar>There is a new version of the app. Please refresh the page.</AlertBar>;
};

export const formatMessage = (message?: React.ReactNode): typeof message => {
  if (message && typeof message === "string") {
    return message.split("\n").map((line, i) => {
      let parts = line.split(/(`.+?`)/);
      return (
        <p key={i}>
          {parts.map((part, i) =>
            part.startsWith("`") ? <code key={`part${i}`}>{part.slice(1, -1)}</code> : part
          )}
        </p>
      );
    });
  }
  return message;
};

class ErrorBoundary extends React.Component<{ children: React.ReactNode }, { hasError: boolean }> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    console.error(error);
    Sentry.captureException(error);
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="errorBoundary">
          An unknown error has occurred. Details have been sent to the engineering team. Please try
          again later.
        </div>
      );
    }
    return this.props.children;
  }
}

export interface PageNav {
  label: string;
  key: string;
}

export interface PageProps {
  title: React.ReactNode;
  pageType: string;
  actions?: React.ReactNode;
  navs?: PageNav[];
  navRenderer?: (label: string) => JSX.Element;
  onNav?: (nav: string) => void;
  selectedNav?: string;
  subHeader?: React.ReactNode;
  minWidth?: string;
  minHeight?: string;
  onlyModals?: boolean;
  app2Redesign?: boolean;
}

export const Page: React.FC<PageProps> = ({
  children,
  title,
  pageType,
  actions,
  navs,
  navRenderer = label => label,
  onNav,
  selectedNav,
  subHeader,
  minWidth = "1220px",
  minHeight = "400px",
  onlyModals = false,
  app2Redesign = false,
}) => {
  const { error, areYouSure } = useModalInfo();
  const ourOnNav = useCallback(
    (eventKey: string | null) => {
      if (onNav && !R.isNil(eventKey)) {
        onNav(eventKey);
      }
    },
    [onNav]
  );

  const { cid } = useCompanyInfo();
  const isInternal = useSelector(isInternalSelector);

  // Used for GA4 tracking of page types.  Simpler than the <Page /> title, since that can
  // sometimes be a JSX element instead of just a string.
  useEffect(() => {
    if (pageType) {
      document.title = pageType || "None";

      ReactGA.initialize("G-LCZYGZVKT2", {
        gtagOptions: {
          role: isInternal ? "internal" : "external",
          company: cid || "None",
        },
      });
      ReactGA.send("pageview");
    }
  }, [pageType, isInternal, cid]);

  const errorModal = error ? (
    <Modal show={true} onHide={error.onOkay} centered={!!error.centered}>
      <Alert variant={error.variant as AlertProps["variant"]} className="BPMModalAlert">
        <Alert.Heading>{error.title}</Alert.Heading>
        {formatMessage(error.message)}
        <hr />
        <div className="modalFooter">
          <Button
            variant={`outline-${error.variant}` as AlertProps["variant"]}
            onClick={error.onOkay}
          >
            {error.okayText}
          </Button>
        </div>
      </Alert>
    </Modal>
  ) : (
    <div />
  );
  const areYouSureModal = areYouSure ? (
    <Modal show={true} onHide={areYouSure.onCancel} centered={!!areYouSure.centered}>
      <Alert variant={areYouSure.variant as AlertProps["variant"]} className="BPMModalAlert">
        <Alert.Heading>{areYouSure.title}</Alert.Heading>
        {formatMessage(areYouSure.message)}
        <hr />
        <div className="modalFooter">
          <Button
            variant={`outline-${areYouSure.variant}` as AlertProps["variant"]}
            onClick={areYouSure.onCancel}
          >
            {areYouSure.cancelText}
          </Button>
          <Button variant={areYouSure.variant} onClick={areYouSure.onOkay}>
            {areYouSure.okayText}
          </Button>
        </div>
      </Alert>
    </Modal>
  ) : (
    <div />
  );
  if (onlyModals) {
    return (
      <div>
        {errorModal}
        {areYouSureModal}
      </div>
    );
  }
  return (
    <div className="bpmPage" style={{ minHeight, minWidth }}>
      <ErrorBoundary>
        <NewVersionAlertBar />
        <div className={cn("header", { app2Redesign })}>
          <div className="titleContainer">
            {app2Redesign && (
              <>
                <Img
                  className="blissPointLogo"
                  alt={"Bliss Point"}
                  src="https://cdn.blisspointmedia.com/agencies/bpm/Tinuiti_Blisspoint_Logo_White.png"
                  unloader={<span>Bliss Point</span>}
                />
                <div className="divider" />
              </>
            )}
            {title && <div className={cn("title", { app2Redesign })}>{title}</div>}
            {navs && (
              <Nav className="navs" variant="pills" activeKey={selectedNav} onSelect={ourOnNav}>
                {navs.map(({ label, key }) => (
                  <Nav.Item key={key}>
                    <Nav.Link {...(app2Redesign ? { bsPrefix: "pageNavItem" } : {})} eventKey={key}>
                      {navRenderer(label)}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            )}
          </div>
          <div className={cn("actions", { app2Redesign })}>{actions}</div>
        </div>
        {subHeader && <div className="pageSubHeader">{subHeader}</div>}
        <div className="body">{children}</div>
        {errorModal}
        {areYouSureModal}
      </ErrorBoundary>
    </div>
  );
};

export default Page;
