import { RelativeDateRange } from "@blisspointmedia/bpm-types/dist/RelativeDatePicker";
import { mixColors, RGB, rgbToHex } from "../utils/colors";
import * as R from "ramda";
import { ColumnMetaData } from "../Performance/performanceUtils";
import { Preset as LinearPreset } from "@blisspointmedia/bpm-types/dist/LinearPerformance";
import { PerformancePreset as StreamingPreset } from "@blisspointmedia/bpm-types/dist/StreamingPerformance";
import { Preset as YoutubePreset } from "@blisspointmedia/bpm-types/dist/YoutubePerformance";

export const dateSort = (arr: any[]): any[] => {
  return R.sort((a, b) => {
    let bDate = new Date(b.date);
    let aDate = new Date(a.date);
    return (
      Date.UTC(aDate.getFullYear(), aDate.getMonth(), aDate.getDate()) -
      Date.UTC(bDate.getFullYear(), bDate.getMonth(), bDate.getDate())
    );
  }, arr);
};

export const sortWithKey = (arr: any[], key: string): any[] => {
  return R.sort((a, b) => {
    let aVal = (a as any)[key];
    let bVal = (b as any)[key];
    if (typeof aVal == "string") {
      return aVal.localeCompare(bVal);
    } else {
      return aVal - bVal;
    }
  }, arr);
};

export const standardColorScheme: Record<"red" | "yellow" | "green", RGB> = {
  red: {
    r: 255,
    g: 99,
    b: 71,
  },
  yellow: {
    r: 255,
    g: 255,
    b: 102,
  },
  green: {
    r: 102,
    g: 255,
    b: 178,
  },
};
export const lightColorScheme: Record<"red" | "yellow" | "green", RGB> = {
  red: {
    r: 255,
    g: 237,
    b: 237,
  },
  yellow: {
    r: 255,
    g: 255,
    b: 220,
  },
  green: {
    r: 229,
    g: 251,
    b: 240,
  },
};
export const heatmapGreen: RGB = {
  r: 116,
  g: 184,
  b: 96,
};

export const WHITE = "#FFFFFF";

export const BLACK = "#000000";

export interface HeatMapData {
  min: number;
  max: number;
  midpoint: number;
}

export const getHeatMapColor = (
  column: number,
  value: number,
  heatMapInfo: (HeatMapData | null)[],
  topData: any[],
  columnMetaDataMap: Record<string, ColumnMetaData>,
  deltas?: boolean
): string => {
  let heatmap = heatMapInfo[column];
  // deltas have their own heatmap data. However, if this column doesn't use heatmapping, we
  // ignore

  let col = topData[column];

  const heatmapMeta = col.heatMapping || {};

  if (!(heatmap && heatmapMeta)) {
    return "";
  }
  let { min, max, midpoint } = heatmap;
  let { colorScheme, excludeZeros } = heatmapMeta;
  let { minIsBest: columnMinIsBest, contentReplacement } = columnMetaDataMap[topData[column].type];
  const presetMinIsBest =
    topData && topData[column] && topData[column].heatMapping
      ? (topData[column].heatMapping as any).minIsBest
      : undefined;
  const minIsBest = R.defaultTo(columnMinIsBest, presetMinIsBest);
  if (
    min === max ||
    (excludeZeros && value === 0) ||
    (contentReplacement && value <= contentReplacement.threshold)
  ) {
    return WHITE;
  }
  let left: RGB, right: RGB;
  let pct = 100;
  if (!deltas && colorScheme === "green") {
    left = {
      r: 255,
      g: 255,
      b: 255,
    };
    right = heatmapGreen;
    pct = (value - min) / (max - min);
  } else {
    let ourColors = deltas || colorScheme === "light" ? lightColorScheme : standardColorScheme;
    let up = ourColors.green;
    let down = ourColors.red;
    if (minIsBest) {
      [up, down] = [down, up];
    }
    left = down;
    right = ourColors.yellow;
    let denom = midpoint - min;
    if (denom !== 0) {
      pct = (value - min) / denom;
    }
    if (value > midpoint) {
      left = ourColors.yellow;
      right = up;
      let denom = max - midpoint;
      if (denom !== 0) {
        pct = 1 - (max - value) / denom;
      } else {
        pct = 100;
      }
    }
  }
  pct = Math.max(0, Math.min(1, pct));
  return rgbToHex(mixColors(left, right, pct));
};

export const lagOptions = ["1d", "3d", "7d", "14d", "30d"] as const;
export type LagOption = typeof lagOptions[number];

export const impCountTypes = ["Response Between", "Imp's Served Between"] as const;
export type ImpCountType = typeof impCountTypes[number];

export const DEFAULT_DATE_SETTING: RelativeDateRange = {
  start: { pivotDate: "monday", adjustment: 7, adjustmentType: "day" },
  end: { pivotDate: "today", adjustmentType: "day", adjustment: 1 },
};

export const emptyLinearPreset: LinearPreset = {
  columns: [],
  headers: [],
  performanceDimensionColumns: [],
  filterTokens: [],
};

export const emptyYoutubePreset = (emptyLinearPreset as any) as YoutubePreset;

export const emptyStreamingPreset: StreamingPreset = {
  columns: [],
  headers: [],
  performanceDimensionColumns: [],
  filterTokens: [],
  impsBetween: true,
  prefix: "streaming",
};

const GOOGLE_SLIDE_WIDTH_INCHES = 13.35;
const GOOGLE_SLIDE_WIDTH_PIXELS = 960;
export const pixelsToInches = (pixels: number): number =>
  (pixels / GOOGLE_SLIDE_WIDTH_PIXELS) * GOOGLE_SLIDE_WIDTH_INCHES;
