import "./SpendAndEffectModal.scss";
import React, { useState, useMemo, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { Sankey } from "recharts";

// components
import ChartContainer from "../ChartContainer";
import WidgetContainer from "../WidgetContainer";
import { Dropdown, DropdownToggleType } from "../Dropdown";
import { DownloadDropdown } from "../DownloadDropdown";
import { ToggleSwitch, ToggleSwitchType } from "../ToggleSwitch";
import { VisualLegendHorizontal } from "../VisualLegendHorizontal";
import { ShadedPattern } from "../../MMM/FillPatterns/ShadedPattern";
import { SpendAndEffectBarChart } from "../../MMM/ModelResults/SpendAndEffectBarChart";

//utils
import { colorMap as colors } from "../../utils/colors";
import { snapShotDataSort, getBarChartContributersData } from "../../MMM/MMMUtils";

interface SpendAndEffectModalProps {
  onClose: () => void;
  data: any[];
  valueFormatter: (value: number) => string;
  sortOptions: { value: string }[];
}

const PRIMARY_SANKEY_SET = new Set([
  "activeChannelEffect2",
  "activeChannelEffect",
  "activeChannelSpend",
  "activeChannelSpend2",
]);

export const SpendAndEffectModal: React.FC<SpendAndEffectModalProps> = ({
  onClose,
  data,
  valueFormatter,
  sortOptions,
}) => {
  const [sortDropdownValue, setSortDropdownValue] = useState<string>("Spend: High to low");
  const [actualValues, setActualValues] = useState<boolean>(true);
  const [activeChannel, setActiveChannel] = useState<string>("All Channels");
  const [barData, setBarData] = useState<any[]>(snapShotDataSort(data, "Spend: High to low"));
  const [xAxisScale, setXAxisScale] = useState<number>();

  const activeChannelData = useMemo(() => {
    return activeChannel === "All Channels" ? {} : data.find(item => item.name === activeChannel);
  }, [data, activeChannel]);

  const colorMap = useMemo(() => {
    if (data) {
      return colors(data);
    }
  }, [data]);

  const isValueAdded = activeChannelData.added || false;

  const activeChannelMap = useMemo(
    () =>
      data.reduce(
        (acc, channel) => {
          acc["All Channels"].add(channel.name);
          acc[channel.name] = channel.contributers;
          return acc;
        },
        { "All Channels": new Set() }
      ),
    [data]
  );

  const [spendSankeyData, effectSankeyData] = useMemo(() => {
    const activeChannelSpendTotal =
      activeChannelData.contributorsSpendSum + activeChannelData.value;
    const activeChannelEffectTotal =
      activeChannelData.contributorsEffectSum + activeChannelData.value2;

    const startingLink = isValueAdded
      ? { source: 1, target: 0, value: 0 }
      : { source: 0, target: 1, value: 0 };
    const initialSpendObj = {
      nodes: [{ name: "activeChannelSpend" }, { name: "activeChannelSpend2" }],
      links: [{ ...startingLink }],
    };
    const initialEffectObj = {
      nodes: [{ name: "activeChannelEffect" }, { name: "activeChannelEffect2" }],
      links: [{ ...startingLink }],
    };
    if (activeChannel === "All Channels") {
      return [initialSpendObj, initialEffectObj];
    }

    return data.reduce(
      ([spend, effect], cv) => {
        if (activeChannel === cv.name) {
          cv.value
            ? (spend.links[0].value = (cv.value / activeChannelSpendTotal) * 100)
            : spend.links.shift();
          cv.value2
            ? (effect.links[0].value = (cv.value2 / activeChannelEffectTotal) * 100)
            : effect.links.shift();
        }
        if (activeChannelMap[activeChannel].has(cv.name)) {
          if (cv[`${activeChannel}:Spend`]) {
            spend.nodes.push({ name: cv.name });
            spend.links.push({
              target: isValueAdded ? 0 : spend.nodes.length - 1,
              source: isValueAdded ? spend.nodes.length - 1 : 0,
              value: (cv[`${activeChannel}:Spend`] / activeChannelSpendTotal) * 100,
            });
          }
          if (cv[`${activeChannel}:Effect`]) {
            effect.nodes.push({ name: cv.name });
            effect.links.push({
              target: isValueAdded ? 0 : effect.nodes.length - 1,
              source: isValueAdded ? effect.nodes.length - 1 : 0,
              value: (cv[`${activeChannel}:Effect`] / activeChannelEffectTotal) * 100,
            });
          }
        }
        return [spend, effect];
      },
      [initialSpendObj, initialEffectObj]
    );
  }, [data, activeChannel, activeChannelMap, isValueAdded, activeChannelData]);

  const CustomLink = useCallback(
    (
      { sourceX, targetX, sourceY, targetY, sourceControlX, targetControlX, linkWidth, payload },
      name
    ) => {
      const isPrimary = isValueAdded
        ? PRIMARY_SANKEY_SET.has(payload.source.name)
        : PRIMARY_SANKEY_SET.has(payload.target.name);
      const resolvedFill = isPrimary
        ? colorMap[activeChannel]
        : colorMap[isValueAdded ? payload.source.name : payload.target.name];
      const resolvePathd = `M${sourceX},${sourceY} C${sourceControlX},${sourceY} ${targetControlX},${targetY} ${targetX},${targetY}`;
      return (
        <g>
          <path
            d={resolvePathd}
            fill="none"
            stroke={resolvedFill}
            strokeOpacity={0.4}
            strokeWidth={linkWidth}
            strokeLinecap="butt"
          />
          <foreignObject
            x={isValueAdded ? sourceX - 280 : sourceX + 20}
            y={isValueAdded ? sourceY - linkWidth / 2 : targetY - linkWidth / 2}
            width={"180px"}
            height={linkWidth}
          >
            <div className="sankeyLabelContainer">
              <div className={`sankeyLabel ${isPrimary ? "primary" : ""}`}>
                {isPrimary
                  ? `${activeChannel} Direct `
                  : `${isValueAdded ? payload.source.name : payload.target.name}: `}
                {`${payload.value.toFixed(2)}%`}
                &nbsp;
              </div>
            </div>
          </foreignObject>
        </g>
      );
    },
    [activeChannel, colorMap, isValueAdded]
  );

  const CustomNode = useCallback(
    ({ width, height, payload, x, y }): React.ReactElement => {
      const resolvedFill = PRIMARY_SANKEY_SET.has(payload.name)
        ? colorMap[activeChannel]
        : `url(#shadedStripe${colorMap[payload.name]})`;

      return (
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          fill={resolvedFill}
          stroke={
            payload.name === "activeChannelEffect" || payload.name === "activeChannelSpend"
              ? "black"
              : undefined
          }
          strokeWidth={2}
        />
      );
    },
    [colorMap, activeChannel]
  );

  return (
    <div>
      <Modal show onHide={onClose} size="xl" className="spendAndEffectModal">
        <WidgetContainer
          header={<div>Spend and Effect Share Details</div>}
          rightActions={
            <Modal.Header closeButton className="spendEffectModalHeader">
              <DownloadDropdown size="sm" onClickOptions={[() => {}, () => {}]} />
            </Modal.Header>
          }
        >
          <Modal.Body className="spendEffectModalBody">
            <ChartContainer
              title={
                <Dropdown
                  type={DropdownToggleType.WIDGET_TITLE}
                  value={activeChannel}
                  options={["All Channels", ...data.map(channel => channel.name)]}
                  onChange={option => {
                    setBarData(getBarChartContributersData(data, activeChannelMap[option]));
                    setActiveChannel(option);
                  }}
                />
              }
              rightActions={
                <div className="groupRightActions">
                  <VisualLegendHorizontal className="inModal" />
                  <ToggleSwitch
                    label="Actual Values"
                    checked={actualValues}
                    onChange={setActualValues}
                    leadingLabel={true}
                    designType={ToggleSwitchType.FILLED}
                    design="secondary"
                    size="sm"
                  />
                  <Dropdown
                    type={DropdownToggleType.FILLED}
                    design="secondary"
                    size="sm"
                    value={sortDropdownValue}
                    onChange={value => {
                      setBarData(d => snapShotDataSort(d, value));
                      setSortDropdownValue(value);
                    }}
                    options={sortOptions}
                  />
                </div>
              }
            >
              <div className="multiBarChartContainer">
                <div className="patternDefinitions">
                  <ShadedPattern color={"grey"} visibleTag={false} />
                  <ShadedPattern
                    color="white"
                    backgroundColor={"grey"}
                    id={"Neggrey"}
                    visibleTag={false}
                  />
                  {data.map(item => (
                    <div key={`modalShadedPatternDefs${item.name}`}>
                      <ShadedPattern color={colorMap[item.name]} visibleTag={false} />
                      <ShadedPattern
                        color="white"
                        backgroundColor={colorMap[item.name]}
                        id={`Neg${colorMap[item.name]}`}
                        visibleTag={false}
                      />
                    </div>
                  ))}
                </div>
                {activeChannel !== "All Channels" && (
                  <SpendAndEffectBarChart
                    expandedModal={true}
                    activeChannel={activeChannel}
                    setActiveChannel={setActiveChannel}
                    barData={data.filter(el => el.name === activeChannel)}
                    setBarData={setBarData}
                    data={data}
                    actualValues={actualValues}
                    valueFormatter={valueFormatter}
                    className="activeChannel"
                    colorMap={colorMap}
                    barSize={24}
                    key={"SpendandEffectMMMModalActiveChannel"}
                    xAxisScale={xAxisScale}
                    setXAxisScale={setXAxisScale}
                  />
                )}
                <SpendAndEffectBarChart
                  expandedModal={true}
                  activeChannel={activeChannel}
                  setActiveChannel={setActiveChannel}
                  barData={barData}
                  setBarData={setBarData}
                  data={data}
                  actualValues={actualValues}
                  valueFormatter={valueFormatter}
                  colorMap={colorMap}
                  barSize={24}
                  key={"SpendandEffectMMMModalAllChannels"}
                  xAxisScale={xAxisScale}
                  setXAxisScale={setXAxisScale}
                />
              </div>
              <div className="secondaryChart">
                <ChartContainer
                  title={
                    activeChannel !== "All Channels"
                      ? "Breakdown by 100%"
                      : "Select a channel to see breakdown"
                  }
                >
                  {activeChannel !== "All Channels" && (
                    <div className="sankeyContainer">
                      <div className="singleSankeyContainer">
                        <div>Spend: </div>
                        <div className={`totalSankeyLabel ${isValueAdded ? "added" : ""}`}>
                          100% Effective Spend
                        </div>
                        <div className={`sankeyChart ${isValueAdded ? "added" : ""}`}>
                          <Sankey
                            height={180}
                            width={150}
                            data={spendSankeyData}
                            node={CustomNode}
                            link={props => CustomLink(props, "Spend")}
                            nodePadding={19}
                          ></Sankey>
                        </div>
                      </div>
                      <div className="singleSankeyContainer">
                        <div>Effect: </div>
                        <div className={`totalSankeyLabel ${isValueAdded ? "added" : ""}`}>
                          100% Effective Effect
                        </div>
                        <div className={`sankeyChart ${isValueAdded ? "added" : ""}`}>
                          <Sankey
                            height={180}
                            width={150}
                            data={effectSankeyData}
                            node={CustomNode}
                            link={props => CustomLink(props, "Effect")}
                            nodePadding={19}
                          ></Sankey>
                        </div>
                      </div>
                    </div>
                  )}
                </ChartContainer>
              </div>
            </ChartContainer>
          </Modal.Body>
        </WidgetContainer>
      </Modal>
    </div>
  );
};
