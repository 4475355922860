import React, { useState, useEffect, useCallback, useMemo } from "react";
import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import { MdCheckCircleOutline } from "react-icons/md";
import { useSetError } from "../redux/modals";
import { awaitJSON, MiscLambdaFetch, JavaLambdaFetch } from "../utils/fetch-utils";
import {
  Page,
  Spinner,
  Skeleton,
  TableSkeleton,
  ModalEditTable,
  PendingChangesControls,
  PendingChangesPanel,
  Button,
  ButtonType,
} from "../Components";
import { useSelector } from "react-redux";
import * as UserRedux from "../redux/user";
import "./DataConnectorTool.scss";

export interface UpdateDataConnectorRowsParams {
  insert: DataConnectorToolRow[];
  update: DataConnectorToolRow[];
  delete: DataConnectorToolRow[];
}
const DATE_FORMAT = "yyyy-MM-dd hh:mma";

const SOURCE_OPTIONS = [
  {
    value: "facebook",
    label: "Facebook",
  },
  {
    value: "ga4",
    label: "Google Analytics 4",
  },
  {
    value: "bing",
    label: "Bing Ads (Microsoft)",
  },
  {
    value: "google_ads",
    label: "Google Ads",
  },
];
const SECRET_OPTIONS = [
  {
    value: "production/google_ads/data-1",
    label: "Google Ads: data-1@",
  },
  {
    value: "production/google_ads/data",
    label: "Google Ads: data@",
  },
  {
    value: "production/google_analytics/data-1",
    label: "Google Analytics 4: data-1@",
  },
  {
    value: "production/google_analytics/data",
    label: "Google Analytics 4: data@",
  },
  {
    value: "production/bing_ads/data",
    label: "Bing Ads: data@",
  },
  {
    value: "production/facebook_ads",
    label: "Facebook: dataservicesplatforms@",
  },
  {
    value: "production/facebook/data",
    label: "Facebook: data@",
  },
];
const selectorOptions = {
  source_options: SOURCE_OPTIONS,
  secret_options: SECRET_OPTIONS,
};

interface DataConnectorToolRow {
  bool?: boolean;
  company: string;
  source: string;
  account_id: string;
  secret_path: string;
  mcc_id: string;
  brand: string;
  created: string;
  lastmodified: string;
  lastuser: string;
}

const DataConnectorTool: React.FC = () => {
  const setError = useSetError();
  const [tableData, setTableData] = useState<DataConnectorToolRow[]>();
  const [originalTableData, setOriginalTableData] = useState<DataConnectorToolRow[]>();
  const [modalInputErrorMessage, setModalInputErrorMessage] = useState<string>("");
  const [newRows, setNewRows] = useState<DataConnectorToolRow[]>([]);
  const [showPendingChanges, setShowPendingChanges] = useState(false);
  const [checkAuthLoading, setCheckAuthLoading] = useState("");
  const [saving, setSaving] = useState(false);
  const isNOC = useSelector(UserRedux.isNOCSelector);

  const pendingChangesHeaders = [
    {
      label: "Company",
      field: "company",
    },
    {
      label: "Source",
      field: "source",
    },
    {
      label: "Account Id",
      field: "account_id",
    },
    {
      label: "Auth Account",
      field: "secret_path",
    },
    {
      label: "MCC Id",
      field: "mcc_id",
    },
  ];

  const headers = [
    {
      label: "Check Auth",
      field: "",
      width: 100,
      uneditable: true,
      renderer: data => (
        <Button
          onClick={async () => await checkMobiusAuth(data)}
          type={ButtonType.FILLED}
          disabled={checkAuthLoading !== ""}
          icon={
            checkAuthLoading === `${data.company}_${data.account_id}_${data.source}` ? (
              <Spinner />
            ) : (
              <MdCheckCircleOutline />
            )
          }
        />
      ),
    },
    {
      label: "Company (lowercase)",
      field: "company",
      modalRow: 0,
      modalWidth: 200,
      width: 150,
    },
    {
      label: "Source",
      field: "source",
      type: "select",
      options: "source_options",
      modalRow: 0,
      modalWidth: 200,
      width: 100,
    },
    {
      label: "Account Id (numeric)",
      field: "account_id",
      modalRow: 0,
      modalWidth: 200,
      width: 200,
    },
    {
      label: "Auth Account",
      field: "secret_path",
      type: "select",
      options: "secret_options",
      modalRow: 1,
      modalWidth: 300,
      width: 250,
    },
    {
      label: "Brand",
      field: "brand",
      uneditable: true,
      width: 250,
    },
    {
      label: "MCC Id (Google Ads only)",
      field: "mcc_id",
      modalRow: 1,
      modalWidth: 200,
      width: 150,
    },
    {
      label: "Last Modified",
      field: "lastmodified",
      uneditable: true,
      width: 200,
      renderer: (data: DataConnectorToolRow) =>
        data.lastmodified && R.pipe(Dfns.parseISO, Dfns.format(DATE_FORMAT))(data.lastmodified),
    },
    {
      label: "Last User",
      field: "lastuser",
      uneditable: true,
      width: 200,
    },
  ];

  const getFreshMobiusDataConnectorData = useCallback(async () => {
    let data;
    try {
      data = await MiscLambdaFetch("/getMobiusDataConnectorConfig");
      data = await awaitJSON(data);
      setTableData(data);
      setOriginalTableData(data);
    } catch (e) {
      const reportError = e as Error;
      setError({
        message: `Failed to get mobius data connector data ${reportError.message}`,
        reportError,
      });
    }
  }, [setError]);

  useEffect(() => {
    // do the actual fetching
    if (!tableData) {
      (async () => {
        await getFreshMobiusDataConnectorData();
      })();
    }
  }, [setError, tableData, getFreshMobiusDataConnectorData]);

  useEffect(() => {
    if (tableData && originalTableData) {
      let newNewRows = R.filter(row => {
        if (
          row.bool ||
          row.company == null ||
          row.source == null ||
          row.account_id == null ||
          row.secret_path == null
        ) {
          return false;
        }
        return true;
      }, tableData);

      setNewRows(newNewRows);
    }
  }, [tableData, originalTableData]);

  const save = useCallback(async () => {
    try {
      const getInternalData = (displayData: DataConnectorToolRow[]): DataConnectorToolRow[] => {
        return R.map(
          row => ({
            bool: false,
            company: row.company,
            source: row.source,
            account_id: row.account_id,
            secret_path: row.secret_path,
            brand: row.brand,
            mcc_id: row.mcc_id,
            created: row.created,
            lastmodified: row.lastmodified,
            lastuser: row.lastuser,
          }),
          displayData
        );
      };

      let convertedNewRows = getInternalData(newRows || []);
      const brandRes = await JavaLambdaFetch("/get_data_connector_account", {
        method: "POST",
        body: convertedNewRows,
      });

      const brandResJSON = await awaitJSON(brandRes);
      if (!brandResJSON.empty) {
        for (let row of brandResJSON.rows) {
          for (let ogRow of convertedNewRows) {
            if (
              row.company === ogRow.company &&
              row.source === ogRow.source &&
              row.accountNumber === ogRow.account_id &&
              row.secretPath === ogRow.secret_path
            ) {
              ogRow.brand = row.brand;
            }
          }
        }
      }

      const internalBody: UpdateDataConnectorRowsParams = {
        insert: convertedNewRows,
        update: [],
        delete: [],
      };

      setSaving(true);
      let res = await MiscLambdaFetch("/putMobiusDataConnectorJob", {
        method: "POST",
        body: internalBody,
      });

      const resJSON = await awaitJSON(res);
      setSaving(false);
      await getFreshMobiusDataConnectorData();

      setError({
        title: "Success",
        variant: "success",
        message: resJSON.message,
      });
    } catch (e) {
      setSaving(false);
      const reportError = e as Error;
      setError({ message: `${reportError.message}` });
    }
  }, [setError, newRows, getFreshMobiusDataConnectorData]);

  const checkAuthInternal = useCallback(async (data: DataConnectorToolRow): Promise<boolean> => {
    try {
      const res = await JavaLambdaFetch("/data_connector_auth_check", {
        method: "POST",
        body: {
          company: data.company,
          source: data.source,
          accountNumber: data.account_id,
          secretPath: data.secret_path,
          mccId: data.mcc_id,
        },
      });
      if (res.status === 200) {
        return true;
      }
      throw new Error("Java lambda did not return 200");
    } catch (e) {
      return false;
    }
  }, []);

  const checkMobiusAuth = useCallback(
    async (data: DataConnectorToolRow) => {
      try {
        setCheckAuthLoading(`${data.company}_${data.account_id}_${data.source}`);
        const authCheck = await checkAuthInternal(data);
        if (authCheck) {
          setCheckAuthLoading("");
          setError({
            title: "Success",
            variant: "success",
            message: `Auth was validated for ${data.company}, ${data.source}, ${data.account_id}, ${data.secret_path}.`,
          });
        } else {
          setCheckAuthLoading("");
          let error = new Error(
            `Auth Failed for ${data.company}, ${data.source}, ${data.account_id}, ${data.secret_path}.`
          );
          setError({
            message: error.message,
            reportError: error,
          });
        }
      } catch (e) {
        setCheckAuthLoading("");
        let error = new Error(
          `Auth Failed for ${data.company}, ${data.source}, ${data.account_id}, ${data.secret_path}.`
        );
        setError({
          message: error.message,
          reportError: error,
        });
      }
    },
    [setError, checkAuthInternal]
  );

  const validateUserInput = useCallback(
    async (data: DataConnectorToolRow): Promise<boolean> => {
      let isError = false;
      let missingFields: string[] = [];
      if (!data.company) {
        missingFields.push("'Company'");
        isError = true;
      }
      if (!data.source) {
        missingFields.push("'Source'");
        isError = true;
      }
      if (!data.account_id) {
        missingFields.push("'Account Id'");
        isError = true;
      }
      if (!data.secret_path) {
        missingFields.push("'Auth Account'");
        isError = true;
      }
      if (data.source === "google_ads" && !data.mcc_id) {
        missingFields.push("'MCC Id'");
        isError = true;
      }
      let mes = `"Please make sure ${missingFields.join(
        ", "
      )} for each row are set for new rows. MCC Id is only required when source is Google Ads."`;
      if (isError) {
        setModalInputErrorMessage(mes);
        return false;
      }

      let isMismatch = false;
      if (data.source === "google_ads") {
        if (!data.secret_path.includes("google_ads")) {
          isMismatch = true;
        }
      } else if (data.source === "ga4") {
        if (!data.secret_path.includes("google_analytics")) {
          isMismatch = true;
        }
      } else if (data.source === "facebook") {
        if (!data.secret_path.includes("facebook")) {
          isMismatch = true;
        }
      } else if (data.source === "bing") {
        if (!data.secret_path.includes("bing")) {
          isMismatch = true;
        }
      }
      if (isMismatch) {
        setModalInputErrorMessage("Source and Auth Account must match.");
        return false;
      }

      if (data.company !== data.company.toLowerCase()) {
        setModalInputErrorMessage("Company must be all lowercase.");
        return false;
      }

      if (originalTableData) {
        let dupRows = R.filter(row => {
          return !!(
            row.company === data.company &&
            row.source === data.source &&
            row.account_id === data.account_id &&
            row.secret_path === data.secret_path
          );
        }, originalTableData);

        if (dupRows.length > 0) {
          let row = dupRows[0];
          let dupRowsMes = `Found duplcate row in mobius_data_connectors: ${row.company}, ${row.source}, ${row.account_id}, ${row.secret_path}.`;
          setModalInputErrorMessage(dupRowsMes);
          return false;
        }
      }
      const authCheck = await checkAuthInternal(data);
      if (!authCheck) {
        setModalInputErrorMessage(
          `Auth failed for ${data.company}, ${data.source}, ${data.account_id}, ${data.secret_path}.`
        );
        return false;
      }
      setModalInputErrorMessage("");
      return true;
    },
    [originalTableData, checkAuthInternal]
  );

  const hasPendingChanges: boolean = useMemo(() => {
    if (isNOC) {
      const hasPendingChangesResult = !!newRows.length;
      if (!hasPendingChangesResult) {
        setShowPendingChanges(false);
      }
      return hasPendingChangesResult;
    }
    setShowPendingChanges(false);
    return false;
  }, [newRows, isNOC]);

  const clearAllChanges = useCallback(() => {
    setTableData(originalTableData);
    setNewRows([]);
  }, [originalTableData]);

  return (
    <Page
      title="Data Connector Tool"
      pageType="Data Connector Tool"
      minHeight="600px"
      actions={
        <PendingChangesControls
          hasPendingChanges={hasPendingChanges}
          setShowPendingChanges={setShowPendingChanges}
          saveChanges={save}
          isSaving={saving}
          clearAllChanges={clearAllChanges}
        />
      }
    >
      <div className="dataConnectorsPageContainer">
        {tableData ? (
          <ModalEditTable
            className="dataConnectorsTable"
            name="Data Connectors"
            headers={headers}
            tableData={tableData || []}
            setTableData={newTableData => setTableData(newTableData)}
            enableAdd={isNOC}
            invalidText={modalInputErrorMessage}
            selectorOptions={selectorOptions}
            checkIsValid={validateUserInput}
            filterBar
            rowHeight={50}
          />
        ) : (
          <Skeleton>
            <TableSkeleton />
          </Skeleton>
        )}
        {showPendingChanges && (
          <PendingChangesPanel
            originalData={{}}
            pendingChanges={{
              newRows: newRows,
            }}
            showPendingChanges={showPendingChanges}
            setShowPendingChanges={setShowPendingChanges}
            headers={pendingChangesHeaders}
          />
        )}
      </div>
    </Page>
  );
};

export default DataConnectorTool;
