import React from "react";
import * as R from "ramda";

import { MdClear, MdFilterList, MdSave } from "react-icons/md";
import { StateSetter } from "../../utils/types";

import "./PendingChangesControls.scss";
import { OverlayTrigger } from "../OverlayTrigger";
import { Tooltip } from "react-bootstrap";
import { ButtonFrameworkVariant } from "../ButtonFramework";
import { Button, ButtonType } from "../Button";
import { Spinner } from "../Spinner";

interface PendingChangesControlsProps {
  hasPendingChanges: boolean;
  setShowPendingChanges: StateSetter<boolean>;
  saveChanges: () => void;
  isSaving: boolean;
  clearAllChanges: () => void;
}

export const PendingChangesControls: React.FC<PendingChangesControlsProps> = React.memo(
  ({
    hasPendingChanges,
    setShowPendingChanges,
    saveChanges,
    isSaving,
    clearAllChanges,
  }: PendingChangesControlsProps): JSX.Element => {
    return (
      <div className="pendingChangesControls">
        <Button
          type={ButtonType.FILLED}
          variant={ButtonFrameworkVariant.LEADING_ICON}
          icon={<MdFilterList />}
          onClick={() => {
            setShowPendingChanges(R.not);
          }}
          disabled={!hasPendingChanges}
        >
          Pending Changes
        </Button>
        <OverlayTrigger
          placement={OverlayTrigger.PLACEMENTS.BOTTOM.CENTER}
          overlay={<Tooltip id="pending-changes-save">Save Changes</Tooltip>}
        >
          <Button
            type={ButtonType.FILLED}
            variant={ButtonFrameworkVariant.ICON_ONLY}
            onClick={saveChanges}
            disabled={isSaving || !hasPendingChanges}
            icon={isSaving ? <Spinner /> : <MdSave />}
          />
        </OverlayTrigger>
        <OverlayTrigger
          placement={OverlayTrigger.PLACEMENTS.BOTTOM.RIGHT}
          overlay={<Tooltip id="pending-changes-clear">Clear Changes</Tooltip>}
        >
          <Button
            type={ButtonType.FILLED}
            variant={ButtonFrameworkVariant.ICON_ONLY}
            onClick={clearAllChanges}
            disabled={!hasPendingChanges}
            icon={<MdClear />}
          />
        </OverlayTrigger>
      </div>
    );
  }
);
