import "./Performance.scss";
import { awaitJSON, StreamingPerformanceLambdaFetch } from "../utils/fetch-utils";
import {
  ClaimSandboxFunction,
  ReleaseSandboxFunction,
  S3PromiseFunction,
  SettingsComponentProps,
  SharedStateFetcher,
  SlideContext,
  SlideType,
} from "./slidesTypes";
import {
  computeResolvedDate,
  DATE_FORMAT,
  RelativeDateRange,
} from "@blisspointmedia/bpm-types/dist/RelativeDatePicker";
import {
  getColumnMetadataMap as getStreamingColumnMetadataMap,
  constructIndexTotalsMap,
  performanceRowToTableRow,
  RowWithTabularData,
} from "../Performance/StreamingPerformance/streamingPerformanceUtils";
import { CreativeMap, CreativeMapItem, useCreativeMap } from "../redux/creative";
import {
  CreativeData,
  DimensionData,
  DimensionValue,
  GetPresetsPreset,
  PerformanceTableData as StreamingPerformanceTableData,
} from "@blisspointmedia/bpm-types/dist/PerformanceSlide";
import { DateRange } from "../utils/types";
import {
  DEFAULT_DATE_SETTING,
  emptyStreamingPreset,
  getHeatMapColor,
  LagOption,
  pixelsToInches,
  WHITE,
} from "./slideUtils";
import { DerivedNetwork, DerivedNetworkMap, useDerivedNetworkMap } from "../redux/networks";
import { CheckBox, OldDropdown, RelativeDatePicker } from "../Components";
import { Form } from "react-bootstrap";
import { getGlobalBrand } from "../Performance/performanceUtils";
import {
  GetKpiMetaDataParams,
  GetKpiMetaDataResponse,
} from "@blisspointmedia/bpm-types/dist/Performance";
import { Provider, useSelector } from "react-redux";
import { reduxStore } from "../redux";
import { SharedState, SlideState } from "./slideTemplateConstants";
import { CompanyInfo, useCompanyInfo } from "../redux/company";
import { SetError } from "../redux/modals";
import * as Dfns from "date-fns/fp";
import * as R from "ramda";
import * as S from "@blisspointmedia/bpm-types/dist/StreamingPerformance";
import * as UserRedux from "../redux/user";
import PerformanceGrid from "../Performance/PerformanceGrid";
import React, { useEffect, useLayoutEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useExperimentFlag } from "../utils/experiments/experiment-utils";

export const makeFetchKey = (kpi: string, lag: LagOption): string => `${kpi}_${lag}`;
const MAX_DATES = 4;
const MAX_FONT_SIZE = 20;
const EMPTY_ROW_DATA = {
  color: WHITE,
  content: "-",
  divider: false,
  value: -1,
};

export const makeDimKey = (dimensionData: DimensionData): string => {
  let dimKey = "";
  for (let key of R.keys(dimensionData)) {
    // Don't want to add type of dimensions just their values
    if (key !== "dimensions") {
      dimKey = `${dimKey}_${dimensionData[key]}`;
    }
  }
  return dimKey;
};

interface SlideStreamingPerformanceNewChartResult {
  clear: boolean;
  streamingPerformanceTableData: StreamingPerformanceTableData | null;
}

interface SlideStreamingPerformanceNewChartOnLoad {
  (results: SlideStreamingPerformanceNewChartResult): void;
}

interface SlideStreamingPerformanceNewChartProps {
  clear: boolean;
  company: string;
  creativeMap: CreativeMap;
  creativeNameMap: Record<string, CreativeMapItem>;
  data: any;
  dataKey: string;
  defaultKPI: string;
  defaultLag: "1d" | "3d" | "7d" | "14d" | "30d";
  derivedNetworkMap: DerivedNetworkMap;
  end: string;
  endLabel: string;
  fetchedPreset: S.PresetRow;
  kpiMetaData: GetKpiMetaDataResponse;
  presetID: number;
  prevKey: string;
  shouldEnableGraphPerformance: boolean;
  start: string;
  startLabel: string;
  onLoad: SlideStreamingPerformanceNewChartOnLoad;
}

interface OnPathLoadInterface {
  bottomData: any;
  heatmapInfo: any;
  leftData: any;
  tableData: any;
  topData: any;
}

const SlideStreamingPerformanceNewChart: React.FC<SlideStreamingPerformanceNewChartProps> = ({
  clear,
  creativeMap,
  creativeNameMap,
  data,
  dataKey,
  defaultKPI,
  defaultLag,
  derivedNetworkMap,
  endLabel,
  fetchedPreset,
  kpiMetaData,
  prevKey,
  shouldEnableGraphPerformance,
  startLabel,
  onLoad,
}) => {
  const { preset } = fetchedPreset;
  const kpi = preset && preset.globalKpi ? preset.globalKpi : defaultKPI;
  const lag = preset && preset.globalLag ? preset.globalLag : defaultLag;
  const [pathLoaded, setPathLoaded] = useState<OnPathLoadInterface | null>(null);
  const [otherDates, setOtherDates] = useState<DateRange>();
  const [filterTerm, setFilterTerm] = useState("");
  const [dataMap, setDataMap] = useState<Record<string, StreamingPerformanceTableData>>({});
  const wait =
    !R.isEmpty(dataKey) &&
    !R.isEmpty(prevKey) &&
    !R.isNil(dataMap[prevKey]) &&
    !R.isNil(dataMap[dataKey]) &&
    dataMap[dataKey] === dataMap[prevKey] &&
    prevKey !== dataKey;
  useLayoutEffect(() => {
    if (!dataKey || wait || (pathLoaded === null && clear)) {
      onLoad({
        streamingPerformanceTableData: {
          headerData: [],
          rowData: [],
          aggregateData: [],
          dimensionData: [],
          start: "",
          end: "",
        },
        clear,
      });
    }
    if (pathLoaded !== null && data.length === 0) {
      onLoad({
        streamingPerformanceTableData: null,
        clear: false,
      });
    } else if (!clear && pathLoaded !== null && !R.isNil(data)) {
      let { topData, tableData, bottomData, heatmapInfo, leftData } = pathLoaded;
      let leftDataCheck = !R.isEmpty(leftData);
      for (let row of leftData) {
        let dims = R.keys(row);
        // Check if the correct type of data has properly loaded
        for (let dimCol of preset.performanceDimensionColumns) {
          if (!dims.includes(dimCol.dimension)) {
            leftDataCheck = false;
            break;
          }
        }
      }
      if (
        !R.isEmpty(topData) &&
        !R.isEmpty(tableData) &&
        !R.isEmpty(bottomData) &&
        !R.isEmpty(heatmapInfo) &&
        leftDataCheck
      ) {
        let rowIndex = 0;
        for (let row of tableData) {
          let colIndex = 0;
          for (let col of heatmapInfo) {
            if (col) {
              let color = getHeatMapColor(colIndex, row[colIndex].value, heatmapInfo, topData, {
                ...getStreamingColumnMetadataMap(false),
                ...getStreamingColumnMetadataMap(true),
              });
              tableData[rowIndex][colIndex].color = color;
            }
            colIndex++;
          }
          rowIndex++;
        }
        if (preset && preset.performanceDimensionColumns) {
          for (let elem of leftData) {
            let dimensions: DimensionValue[] = [];
            for (let dimCol of preset.performanceDimensionColumns) {
              let dimensionValue: DimensionValue = {
                headerLabel: R.defaultTo(dimCol.type, dimCol.label),
                type: dimCol.type,
              };
              if (dimCol.dimension === "Network") {
                let networkData =
                  derivedNetworkMap && derivedNetworkMap[elem.Network]
                    ? (derivedNetworkMap[elem.Network] as DerivedNetwork)
                    : ({} as DerivedNetwork);
                if (dimCol.type === "Derived ID") {
                  dimensionValue.label = elem.Network;
                } else if (dimCol.type === "Description") {
                  dimensionValue.label = networkData.description;
                } else if (dimCol.type === "Network Logo") {
                  dimensionValue.url = networkData
                    ? `https://bpm-cdn.s3.amazonaws.com/networks/${networkData.network}.png`
                    : "";
                } else {
                  dimensionValue.label = networkData.network;
                }
              } else if (dimCol.dimension === "Network Group") {
                if (dimCol.type === "Network Group Name") {
                  dimensionValue.label = elem["Network Group"];
                } else if (dimCol.type === "Network Group Logo") {
                  dimensionValue.url = `https://bpm-cdn.s3.amazonaws.com/networks/${elem[
                    "Network Group"
                  ].toUpperCase()}.png`;
                }
              } else if (dimCol.dimension === "Creative") {
                let creativeData =
                  creativeNameMap && creativeNameMap[elem.Creative]
                    ? ((creativeNameMap[elem.Creative] as any) as CreativeData)
                    : ({} as CreativeData);
                if (dimCol.type === "Creative") {
                  dimensionValue.label = creativeData.name;
                } else if (dimCol.type === "Creative Thumbnail") {
                  dimensionValue.url = creativeData
                    ? `https://bpm-cdn.s3.amazonaws.com/creatives/${creativeData.file}.png`
                    : "";
                }
              } else if (dimCol.dimension === "DeviceOS") {
                let [device, os] = (elem.DeviceOS || "").toUpperCase().split("_");
                const isDevice = dimCol.type === "Device" || dimCol.type === "Device Logo";
                const isLogo = dimCol.type === "Device Logo" || dimCol.type === "OS Logo";
                const datum = ((isDevice ? device : os) || "").toUpperCase();
                if (isLogo) {
                  dimensionValue.url = `https://bpm-cdn.s3.amazonaws.com/networks/${datum}.png`;
                } else {
                  dimensionValue.label = datum;
                }
              } else if (dimCol.dimension === "Length") {
                dimensionValue.label = elem.Length;
              }
              dimensions.push(dimensionValue);
            }
            elem.dimensions = dimensions;
          }
        }
        let streamingPerformanceTableData: StreamingPerformanceTableData | null =
          !!topData && !!tableData && !!bottomData && !!leftData
            ? {
                headerData: topData,
                rowData: tableData,
                aggregateData: bottomData,
                dimensionData: leftData,
                start: startLabel,
                end: endLabel,
              }
            : null;
        if (
          (prevKey !== dataKey && dataMap[prevKey] !== streamingPerformanceTableData) ||
          (prevKey === dataKey && dataMap[prevKey] === streamingPerformanceTableData)
        ) {
          setTimeout(() => {
            onLoad({
              streamingPerformanceTableData,
              clear: false,
            });
          }, 500);
          if (streamingPerformanceTableData !== null) {
            setDataMap(map => {
              map[dataKey] = streamingPerformanceTableData as StreamingPerformanceTableData;
              return map;
            });
          }
        }
      }
    }
  }, [
    clear,
    creativeNameMap,
    data,
    dataKey,
    dataMap,
    derivedNetworkMap,
    endLabel,
    fetchedPreset,
    onLoad,
    pathLoaded,
    preset,
    prevKey,
    shouldEnableGraphPerformance,
    startLabel,
    wait,
  ]);
  if (!data || !creativeNameMap || !preset || !kpiMetaData || clear || wait) {
    return (
      <PerformanceGrid
        preset={emptyStreamingPreset}
        data={[]}
        filterTerm={filterTerm}
        setFilterTerm={setFilterTerm}
        otherDataRowMap={undefined}
        otherDates={otherDates}
        setOtherDates={setOtherDates}
        creativeMap={{}}
        onPathLoad={setPathLoaded}
        isSlides={true}
        slidesKpi={kpi}
        slidesKpiMetaData={kpiMetaData}
        slidesCompany={kpi?.split("_")[0]}
        slidesIsGraph={shouldEnableGraphPerformance}
      />
    );
  } else {
    return (
      <PerformanceGrid
        preset={preset}
        data={data}
        filterTerm={filterTerm}
        setFilterTerm={setFilterTerm}
        otherDataRowMap={undefined}
        otherDates={otherDates}
        setOtherDates={setOtherDates}
        creativeMap={creativeMap}
        onPathLoad={setPathLoaded}
        isSlides={true}
        slidesKpi={kpi}
        slidesKpiMetaData={kpiMetaData}
        slidesCompany={kpi?.split("_")[0]}
        slidesLag={lag}
        slidesIsGraph={shouldEnableGraphPerformance}
      />
    );
  }
};

interface StreamingPerformanceNewSlideData {
  companyColor: string | null;
  customSlideHeader: string;
  customTableColumnWidth: number;
  customTableFontSize: number;
  customTableRowHeight: number;
  footnote: string;
  streamingPerformanceTableData: StreamingPerformanceTableData[] | null;
}

export interface StreamingPerformanceNewSlideState {
  customSlideHeader: string;
  customTableColumnWidth: number;
  customTableFontSize: number;
  customTableRowHeight: number;
  dates: RelativeDateRange[];
  datesSort: boolean[];
  footnote: string;
  isGraph: boolean;
  isInternal: boolean;
  mediatype: string;
  numDates: number;
  numRows: number;
  selectedPreset: GetPresetsPreset | null;

  // Temporary
  graphSlideOverride: boolean;
}
export const strPerformanceNewSharedStateKey = "streamingPerformanceNew" as const;

export interface StreamingPerformanceSharedState {
  companyInfo: CompanyInfo | undefined;
  creativeMap: CreativeMap | undefined;
  derivedNetworkMap: DerivedNetworkMap | undefined;
  globalBrand: string | undefined;
  kpiMetaData: GetKpiMetaDataResponse | undefined;
  presets: GetPresetsPreset[];
  presetMap: Record<string, S.PresetRow>;
}

export interface StreamingPerformanceSharedStateContext {
  presetID: number;
  state: StreamingPerformanceSharedState;
}

export const strPerformanceSharedFetcher: SharedStateFetcher<StreamingPerformanceSharedState> = async (
  key: string,
  setError: SetError,
  context: StreamingPerformanceSharedStateContext
) => {
  const [company, mediaType, isGraphString] = key.split("_");
  const isGraph = isGraphString === "true";
  let res: StreamingPerformanceSharedState = {
    companyInfo: undefined,
    creativeMap: undefined,
    derivedNetworkMap: undefined,
    globalBrand: undefined,
    kpiMetaData: undefined,
    presetMap: {},
    presets: [],
  };
  if (R.isNil(context)) {
    try {
      let params = { company, prefix: mediaType, isGraph };
      let presetRes = await StreamingPerformanceLambdaFetch("/presets", {
        params,
      });
      let presets: GetPresetsPreset[] = await awaitJSON(presetRes);
      res.presets = R.filter(preset => !preset.temporary, presets);
    } catch (e) {
      let error: Error = e as Error;
      setError({
        message: `Could not get valid streaming performance presets. Error: ${error.message}`,
        reportError: error,
      });
    }
    try {
      const kpiRes = await StreamingPerformanceLambdaFetch<GetKpiMetaDataParams>("/kpis", {
        params: {
          company,
        },
      });
      const kpiMetaData = await awaitJSON<GetKpiMetaDataResponse>(kpiRes);
      res.kpiMetaData = kpiMetaData;
    } catch (e) {
      let error = e as Error;
      setError({
        message: `Failed to get streaming performance preset data: ${error.message}`,
        reportError: error,
      });
    }
  } else {
    if (!R.isNil(context.presetID) && R.isNil(context.state.presetMap[context.presetID])) {
      try {
        const fields = {
          company,
          id: context.presetID,
          isGraph,
        };
        const res = await StreamingPerformanceLambdaFetch("/preset", {
          params: { ...fields },
        });
        const fetchedPreset = await awaitJSON(res);
        context.state.presetMap[context.presetID] = fetchedPreset;
      } catch (e) {
        let error = e as Error;
        setError({
          message: `Failed to get streaming performance preset data: ${error.message}`,
          reportError: error,
        });
      }
    }
    res = context.state;
  }
  return res;
};

class StreamingPerformanceNewSlide extends SlideType {
  static typeKey = "streamingPerformanceNew";
  static displayKey = "Streaming Performance New";
  static defaultState: StreamingPerformanceNewSlideState = {
    customSlideHeader: "",
    customTableColumnWidth: 32,
    customTableFontSize: 9,
    customTableRowHeight: 30,
    dates: [DEFAULT_DATE_SETTING],
    datesSort: [true, false, false, false],
    footnote: "",
    isGraph: false,
    isInternal: false,
    mediatype: "streaming",
    numDates: 1,
    numRows: 20,
    selectedPreset: null,

    // Temporary
    graphSlideOverride: false,
  };

  static SettingsComponent: React.FC<
    SettingsComponentProps<StreamingPerformanceNewSlideState>
  > = React.memo(({ slideContext, state, setState, sharedFetch, sharedState }) => {
    const {
      customSlideHeader,
      customTableColumnWidth,
      customTableFontSize,
      customTableRowHeight,
      dates,
      datesSort,
      footnote,
      isGraph,
      isInternal,
      mediatype,
      numDates,
      numRows,
      selectedPreset,

      // Temporary
      graphSlideOverride,
    } = state;
    const [isInternalReady, setIsInternalReady] = useState<boolean>();
    const fetchedIsInternal = useSelector(UserRedux.isInternalSelector);
    const enableAudioGraphPerformance = useExperimentFlag("enableAudioGraphPerformance");
    const enableDisplayGraphPerformance = useExperimentFlag("enableDisplayGraphPerformance");
    const enableGraphPerformance = useExperimentFlag("enableGraphPerformance");
    const fetchedIsGraph =
      (mediatype.toLowerCase().includes("display")
        ? (enableDisplayGraphPerformance as boolean)
        : mediatype.toLowerCase().includes("audio")
        ? (enableAudioGraphPerformance as boolean)
        : (enableGraphPerformance as boolean)) || graphSlideOverride;
    const { company } = slideContext;
    const extremaKey = `${company}_${mediatype}_${fetchedIsGraph}`;
    const { companyInfo, creativeMap, derivedNetworkMap, globalBrand, presetMap, presets } =
      R.isNil(sharedState) ||
      R.isNil(sharedState[strPerformanceNewSharedStateKey]) ||
      R.isNil(sharedState[strPerformanceNewSharedStateKey][extremaKey])
        ? {
            companyInfo: undefined,
            creativeMap: undefined,
            derivedNetworkMap: undefined,
            globalBrand: undefined,
            presetMap: {},
            presets: [],
          }
        : sharedState[strPerformanceNewSharedStateKey][extremaKey];
    const fetchedCompanyInfo = useCompanyInfo();
    const fetchedDerivedNetworkMap = useDerivedNetworkMap(company);
    const fetchedGlobalBrand = companyInfo
      ? getGlobalBrand(companyInfo, companyInfo.streaming_performance_default_kpi)
      : undefined;
    const { creativeMap: fetchedCreativeMap } = useCreativeMap({
      company: globalBrand || company,
      mediaTypes: ["audio", "streaming", "display"],
    });

    // Scenario's
    // Graph Client with graph slide -> isGraph is set to true by flag and graphSlideOverride
    // Graph Client with old IP slide -> old isGraph is overwritten to isGraph by flag
    // IP Client with graph slide -> isGraph is set to true by graphSlideOverride
    // IP Client with old IP slide -> graphSlideOverride is false and isGraph is false so this doesn't
    // change
    useEffect(() => {
      if (isGraph !== fetchedIsGraph) {
        setState({ isGraph: fetchedIsGraph });
      }
    }, [fetchedIsGraph, isGraph, setState]);

    useEffect(() => {
      if (!R.path([strPerformanceNewSharedStateKey, extremaKey], sharedState)) {
        (async () => {
          await sharedFetch(strPerformanceNewSharedStateKey, extremaKey, null);
        })();
      }
    }, [sharedState, sharedFetch, extremaKey]);

    useEffect(() => {
      if (!isInternalReady && !R.isNil(fetchedIsInternal)) {
        setState({ isInternal: fetchedIsInternal });
        setIsInternalReady(true);
      }
    }, [isInternal, fetchedIsInternal, setState, isInternalReady]);

    useEffect(() => {
      if (
        sharedState &&
        sharedState[strPerformanceNewSharedStateKey] &&
        sharedState[strPerformanceNewSharedStateKey][extremaKey]
      ) {
        (async () => {
          if (
            (fetchedCompanyInfo && R.isNil(companyInfo)) ||
            (fetchedCreativeMap && R.isNil(creativeMap)) ||
            (fetchedDerivedNetworkMap && R.isNil(derivedNetworkMap)) ||
            (fetchedGlobalBrand && R.isNil(globalBrand))
          ) {
            await sharedFetch(strPerformanceNewSharedStateKey, extremaKey, {
              state: {
                ...sharedState[strPerformanceNewSharedStateKey][extremaKey],
                companyInfo: fetchedCompanyInfo,
                creativeMap: fetchedCreativeMap,
                derivedNetworkMap: fetchedDerivedNetworkMap,
                globalBrand: fetchedGlobalBrand,
              },
              updateState: true,
            });
          }
          if (!R.isNil(presets) && !R.isEmpty(presets) && !R.isNil(selectedPreset)) {
            if (!R.map(elem => elem.id, presets).includes(selectedPreset.id)) {
              setState({ selectedPreset: null });
            }
          }
          if (selectedPreset && R.isNil(presetMap[selectedPreset.id])) {
            try {
              await sharedFetch(strPerformanceNewSharedStateKey, extremaKey, {
                state: {
                  ...sharedState[strPerformanceNewSharedStateKey][extremaKey],
                  companyInfo: fetchedCompanyInfo,
                  creativeMap: fetchedCreativeMap,
                  derivedNetworkMap: fetchedDerivedNetworkMap,
                  globalBrand: fetchedGlobalBrand,
                },
                presetID: selectedPreset.id,
                updateState: true,
              });
            } catch (e) {
              setState({ selectedPreset: null });
            }
          }
        })();
      }
    }, [
      companyInfo,
      creativeMap,
      derivedNetworkMap,
      extremaKey,
      fetchedCompanyInfo,
      fetchedCreativeMap,
      fetchedDerivedNetworkMap,
      fetchedGlobalBrand,
      globalBrand,
      presetMap,
      presets,
      selectedPreset,
      setState,
      sharedFetch,
      sharedState,
    ]);

    return (
      <div className="settingsBox">
        <div className="wrappingColumn">
          <Form.Group className="options">
            <div className="presetSelector">
              <OldDropdown
                label={"Preset"}
                value={selectedPreset ? selectedPreset.id.toString() : "undefined"}
                options={R.map(preset => {
                  return { label: preset.name, value: preset.id.toString() };
                }, presets)}
                onChange={presetID => {
                  let selectedPreset: GetPresetsPreset | null = null;
                  for (let preset of presets) {
                    if (presetID === preset.id.toString()) {
                      selectedPreset = preset;
                    }
                  }
                  setState({ selectedPreset });
                }}
              />
            </div>
            <div>
              <Form.Label>Font Size</Form.Label>
              <Form.Control
                as="input"
                type="number"
                min={1}
                max={MAX_FONT_SIZE}
                value={customTableFontSize}
                onChange={e => {
                  const size = R.defaultTo(1, parseInt(e.target.value));
                  const sizeInPxs = Math.ceil(size * (1 + 1 / 3));
                  if (customTableColumnWidth < sizeInPxs) {
                    setState({ customTableColumnWidth: sizeInPxs });
                  }
                  if (customTableRowHeight < sizeInPxs) {
                    setState({ customTableRowHeight: sizeInPxs });
                  }
                  setState({ customTableFontSize: size });
                }}
              />
            </div>
            <div>
              <Form.Label>Number of Date Ranges in Table</Form.Label>
              <Form.Control
                as="input"
                type="number"
                min={1}
                max={MAX_DATES}
                value={numDates}
                onChange={e => {
                  let newDatesSort = [false, false, false, false];
                  let sortByIndex = 0;
                  const numDates = R.defaultTo(1, parseInt(e.target.value));
                  let newDates = Array(numDates);
                  for (let i = 0; i < numDates; i++) {
                    newDates[i] = dates[i] && i < dates.length ? dates[i] : DEFAULT_DATE_SETTING;
                    if (datesSort[i]) {
                      sortByIndex = i;
                    }
                  }
                  newDatesSort[sortByIndex] = true;
                  setState({ dates: newDates, datesSort: newDatesSort, numDates: numDates });
                }}
              />
            </div>
            <div>
              <Form.Label>Number of Rows in Table</Form.Label>
              <Form.Control
                as="input"
                type="number"
                min={1}
                value={numRows}
                onChange={e => {
                  setState({ numRows: R.defaultTo(1, parseInt(e.target.value)) });
                }}
              />
            </div>
            <Form.Label className="note">
              NOTE: Remember to sort the page by the relevant column if the number of rows exceeds
              20 and to specify the global kpi and lag being used in the table and if any columns
              have a custom kpi or lag set in the optional footnote!
            </Form.Label>
            <div>
              <Form.Label>
                Custom Table Column Width in Pixels(
                {pixelsToInches(customTableColumnWidth).toFixed(2)} in Inches)
              </Form.Label>
              <Form.Control
                as="input"
                type="number"
                min={32}
                value={customTableColumnWidth}
                onChange={e => {
                  let newWidth;
                  try {
                    newWidth = isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value);
                  } catch {
                    newWidth = customTableColumnWidth;
                  }
                  setState({ customTableColumnWidth: newWidth });
                }}
              />
            </div>
            <div>
              <Form.Label>
                Custom Table Row Height in Pixels({pixelsToInches(customTableRowHeight).toFixed(2)}{" "}
                in Inches)
              </Form.Label>
              <Form.Control
                as="input"
                type="number"
                min={10}
                value={customTableRowHeight}
                onChange={e => {
                  let newHeight;
                  try {
                    newHeight = isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value);
                  } catch {
                    newHeight = customTableRowHeight;
                  }
                  setState({ customTableRowHeight: newHeight });
                }}
              />
            </div>
            <div className="footnoteDiv">
              Optional Footnote:
              <Form.Control
                className="footnoteTextEntry"
                value={footnote}
                onChange={e => {
                  setState({ footnote: e.target.value });
                }}
              />
            </div>
            <div>
              {`${footnote.length ? "Optional Footnote on Streaming Performance Slide:" : ""}`}
            </div>
            <Form.Label className="note">{`${
              footnote.length ? "Note: " : ""
            }${footnote}`}</Form.Label>
          </Form.Group>
          <Form.Group className="datePickers">
            <div className="customSlideHeaderText">
              Optional Custom Slide Header:
              <Form.Control
                className="customSlideHeader"
                value={customSlideHeader ? customSlideHeader : ""}
                onChange={e => {
                  setState({ customSlideHeader: e.target.value });
                }}
              />
            </div>
            <div className="dp">
              {dates.length > 0 &&
                dates.map((date: RelativeDateRange, index: number) => {
                  if (date && date.start && date.end) {
                    return (
                      <div key={`Dates Pickers ${index + 1}`}>
                        <div className="dateRangeLabelAndSortBy">
                          <Form.Label className="dateRangeText">{`Start Date ${
                            index + 1
                          }`}</Form.Label>
                          <div className="dateRangeSortBy">
                            <Form.Label className="dateRangeSortByText">
                              Sort By Date Range?
                            </Form.Label>
                            <CheckBox
                              checked={datesSort[index]}
                              onCheck={e => {
                                let newDatesSort = [false, false, false, false];
                                newDatesSort[index] = e;
                                setState({ datesSort: newDatesSort });
                              }}
                              size="md"
                            />
                          </div>
                        </div>
                        <RelativeDatePicker
                          state={date.start}
                          onChange={start => {
                            let datesUpdate = dates;
                            datesUpdate[index] = { start, end: datesUpdate[index].end };
                            setState({ dates: datesUpdate });
                          }}
                        />
                        <Form.Label className="dateRangeText">{`End Date ${index + 1}`}</Form.Label>
                        <RelativeDatePicker
                          state={date.end}
                          onChange={end => {
                            let datesUpdate = dates;
                            datesUpdate[index] = { start: datesUpdate[index].start, end };
                            setState({ dates: datesUpdate });
                          }}
                        />
                      </div>
                    );
                  } else {
                    return <div></div>;
                  }
                })}
            </div>
          </Form.Group>
        </div>
      </div>
    );
  });

  generate = async (
    context: SlideContext,
    state: SlideState,
    _: SharedState,
    claimSandbox: ClaimSandboxFunction,
    releaseSandbox: ReleaseSandboxFunction,
    addS3Image: S3PromiseFunction
  ): Promise<StreamingPerformanceNewSlideData> => {
    let { company } = context;
    let {
      customSlideHeader,
      customTableColumnWidth,
      customTableFontSize,
      customTableRowHeight,
      dates,
      datesSort,
      isGraph,
      footnote,
      isInternal,
      mediatype,
      numDates,
      numRows,
      selectedPreset,
    } = state as StreamingPerformanceNewSlideState;
    const extremaKey = `${company}_${mediatype}_${isGraph}`;
    const {
      companyInfo,
      creativeMap,
      derivedNetworkMap,
      kpiMetaData,
      presetMap,
    } = _.streamingPerformanceNew[extremaKey];
    if (R.isNil(companyInfo)) {
      throw new Error(`No default kpi or lag found for: ${company}`);
    }
    const {
      color: companyColor = null,
      streaming_performance_default_kpi: defaultKPI,
      streaming_performance_default_lag: defaultLag,
    } = companyInfo;
    const mediatypeLabel =
      mediatype === "display" ? "Display" : mediatype === "audio" ? "Audio" : "Streaming";
    if (R.isNil(selectedPreset)) {
      throw new Error(
        `No preset selected. (${mediatypeLabel} Performance New, ${customSlideHeader}).`
      );
    }
    const fetchedPreset = presetMap[selectedPreset.id];
    if (R.isNil(fetchedPreset)) {
      throw new Error(
        `We are still loading your streaming performance presets, please wait. (${mediatypeLabel} Performance New, ${customSlideHeader}`
      );
    }
    let streamingPerformanceTableData: StreamingPerformanceTableData[] = [];
    let prevKey = "";
    for (let i = 0; i < numDates; i++) {
      let data: StreamingPerformanceTableData;
      const dateObj = dates[i];
      const start = computeResolvedDate(dateObj.start);
      const end = Dfns.format(
        DATE_FORMAT,
        Dfns.addDays(1, new Date(computeResolvedDate(dateObj.end)))
      );
      const startLabel = Dfns.format(
        "MMMM do",
        Dfns.addDays(1, new Date(computeResolvedDate(dateObj.start)))
      );
      const endLabel = Dfns.format(
        "MMMM do",
        Dfns.addDays(1, new Date(computeResolvedDate(dateObj.end)))
      );
      if (fetchedPreset === null || kpiMetaData === null) {
        throw new Error(
          `No preset data (${mediatypeLabel} Performance New, ${customSlideHeader}, ${startLabel}-${endLabel}).`
        );
      }
      const dataKey = `${fetchedPreset.id}_${start}_${end}`;
      const slideName = `${customSlideHeader ? `${customSlideHeader}, ` : ""}Preset: ${
        fetchedPreset.name
      }`;
      let sandbox = await claimSandbox();
      const fields = {
        start,
        end,
        id: selectedPreset ? selectedPreset.id : 0,
        company,
        branch: "v2",
        build: "latest",
        isGraph,
      };
      let fetchedPerformanceData;
      try {
        let res = await StreamingPerformanceLambdaFetch("/", {
          params: { ...fields },
        });
        fetchedPerformanceData = await awaitJSON(res);
      } catch (e) {
        throw new Error(
          `No data available for this date range (${mediatypeLabel} Performance New, ${slideName}, ${startLabel}-${endLabel}).
          If this error is persisting, please check the performance page with this preset and date range and notify the engineering team that data is missing or corrupted!`
        );
      }
      let hasCreative = false;
      if (
        fetchedPreset &&
        fetchedPreset.preset &&
        fetchedPreset.preset.performanceDimensionColumns
      ) {
        for (let i = 0; i < fetchedPreset.preset.performanceDimensionColumns.length; ++i) {
          if (fetchedPreset.preset.performanceDimensionColumns[i].dimension === "Creative") {
            hasCreative = true;
            break;
          }
        }
      }
      if (
        fetchedPreset === null ||
        !fetchedPreset.preset ||
        !fetchedPreset.preset.performanceDimensionColumns ||
        R.isEmpty(fetchedPreset.preset.performanceDimensionColumns)
      ) {
        throw new Error(
          `No preset data (${mediatypeLabel} Performance New, ${slideName}, ${startLabel}-${endLabel}).`
        );
      } else if (kpiMetaData === null) {
        throw new Error(
          `No kpi meta data (${mediatypeLabel} Performance New, ${slideName}, ${startLabel}-${endLabel}).`
        );
      } else if (
        !fetchedPerformanceData ||
        !fetchedPerformanceData.data ||
        R.isEmpty(fetchedPerformanceData.data)
      ) {
        throw new Error(
          `No data available for this date range (${mediatypeLabel} Performance New, ${slideName}, ${startLabel}-${endLabel}).
          If this error is persisting, please check the performance page with this preset and date range and notify the engineering team that data is missing or corrupted!`
        );
      } else if (hasCreative && (creativeMap === null || R.isEmpty(creativeMap))) {
        throw new Error(
          `Error with Creative Map (${mediatypeLabel} Performance New, ${slideName}, ${startLabel}-${endLabel}).
          Verify that streaming and/or audio and/or display is enabled for this client`
        );
      } else {
        let creativeNameMap = {};
        if (creativeMap) {
          for (let key of R.keys(creativeMap)) {
            const { name } = creativeMap[key];
            if (R.isNil(creativeNameMap[name]) || !creativeNameMap[name].file) {
              creativeNameMap[name] = creativeMap[key];
            }
          }
        }
        let rows: RowWithTabularData[] = [];
        const { preset } = fetchedPreset;
        try {
          let { data } = fetchedPerformanceData;
          let indexTotalsMap = constructIndexTotalsMap(
            preset.columns,
            data,
            preset.globalKpi ? preset.globalKpi : defaultKPI,
            preset.globalLag ? preset.globalLag : defaultLag
          );
          let tabularData: RowWithTabularData[] = [];
          for (let row of data) {
            tabularData.push({
              ...row,
              tabularRow: performanceRowToTableRow(
                row,
                preset.columns,
                indexTotalsMap,
                company,
                preset.globalKpi ? preset.globalKpi : defaultKPI,
                preset.globalLag ? preset.globalLag : defaultLag,
                isInternal
              ),
            });
          }

          for (let row of tabularData) {
            for (let dimCol of preset.performanceDimensionColumns) {
              let check: (row: RowWithTabularData) => boolean;
              if (dimCol.dimension === "Network" && dimCol.type !== "Derived ID") {
                check = row =>
                  (
                    (R.defaultTo({}, derivedNetworkMap)[row.dimensions.Network || ""] || {})[
                      dimCol.type === "Description" ? "description" : "network"
                    ] || ""
                  )
                    .toLowerCase()
                    .includes("");
              } else if (dimCol.dimension === "Creative") {
                check = row =>
                  !!R.defaultTo({}, creativeNameMap)
                    [row.dimensions.Creative || ""]?.name.toLowerCase()
                    .includes("");
              } else {
                check = row =>
                  `${row.dimensions[dimCol.dimension] || ""}`.toLowerCase().includes("");
              }
              if (check(row)) {
                rows.push(row);
                break;
              }
            }
          }
        } catch (e) {
          let error = e as Error;
          throw error;
        }
        if (R.isEmpty(rows)) {
          throw new Error(
            `No data available for this date range (${mediatypeLabel} Performance New, ${slideName}, ${startLabel}-${endLabel}).
            If this error is persisting, please check the performance page with this preset and date range and notify the engineering team that data is missing or corrupted!`
          );
        }
        let prevKeyChart = prevKey;
        data = await new Promise((resolve, reject) => {
          setTimeout(() => {
            try {
              //At the start of each loop clear the performance grid
              const clear = true;
              const load = (clear: boolean) => {
                ReactDOM.render(
                  <Provider store={reduxStore}>
                    <SlideStreamingPerformanceNewChart
                      clear={clear}
                      company={company}
                      creativeMap={R.defaultTo({}, creativeMap)}
                      creativeNameMap={creativeNameMap}
                      data={rows}
                      defaultKPI={defaultKPI}
                      defaultLag={defaultLag}
                      derivedNetworkMap={R.defaultTo({}, derivedNetworkMap)}
                      end={end}
                      endLabel={endLabel}
                      fetchedPreset={fetchedPreset as S.PresetRow}
                      dataKey={dataKey}
                      kpiMetaData={kpiMetaData as GetKpiMetaDataResponse}
                      presetID={selectedPreset ? selectedPreset.id : 0}
                      prevKey={prevKeyChart}
                      shouldEnableGraphPerformance={isGraph as boolean}
                      start={start}
                      startLabel={startLabel}
                      onLoad={({ streamingPerformanceTableData, clear }) => {
                        if (clear) {
                          load(false);
                        }
                        if (
                          streamingPerformanceTableData !== null &&
                          R.defaultTo([], streamingPerformanceTableData.headerData).length > 0 &&
                          R.defaultTo([], streamingPerformanceTableData.rowData).length > 0 &&
                          R.defaultTo([], streamingPerformanceTableData.aggregateData).length > 0
                        ) {
                          resolve(streamingPerformanceTableData);
                        } else if (streamingPerformanceTableData === null) {
                          reject(
                            new Error(
                              `No data available for this date range (${mediatypeLabel} Performance New, ${slideName})`
                            )
                          );
                        }
                      }}
                    />
                  </Provider>,
                  sandbox.element
                );
              };
              setTimeout(() => {
                load(clear);
              }, 500);
            } catch (e) {
              reject(e);
            }
          }, 100);
        });
      }
      releaseSandbox(sandbox);
      streamingPerformanceTableData.push(data);
    }
    let sortIndex = 0;
    for (let i = 0; i < datesSort.length; i++) {
      if (datesSort[i]) {
        sortIndex = i;
      }
    }

    let dimensionKeys: Record<string, number> = {};
    let numRowsAdjusted = numRows;
    if (
      streamingPerformanceTableData[sortIndex].dimensionData &&
      streamingPerformanceTableData[sortIndex].dimensionData.length < numRowsAdjusted
    ) {
      numRowsAdjusted = streamingPerformanceTableData[sortIndex].dimensionData.length;
    } else if (
      !streamingPerformanceTableData[sortIndex].dimensionData ||
      !streamingPerformanceTableData[sortIndex].rowData
    ) {
      throw new Error(
        `Failed to get streaming performance data (${mediatypeLabel} Performance New)`
      );
    }

    // Store indexes of dimension data in case subsequent date ranges are not in the same order
    for (let j = 0; j < numRowsAdjusted; j++) {
      let { dimensionData } = streamingPerformanceTableData[sortIndex];
      let dimKey = makeDimKey(dimensionData[j]);
      dimensionKeys[dimKey] = j;
    }

    for (let i = 0; i < numDates; i++) {
      // If data is missing, enter zeros
      let { dimensionData, rowData, headerData } = streamingPerformanceTableData[i];
      let sortedRowData = Array(numRowsAdjusted).fill(
        Array(R.defaultTo([], headerData).length).fill(EMPTY_ROW_DATA)
      );
      if (dimensionData && rowData) {
        if (i === sortIndex) {
          sortedRowData = rowData.slice(0, numRowsAdjusted);
        } else {
          for (let k = 0; k < R.defaultTo(0, rowData.length); k++) {
            let dimKey = makeDimKey(dimensionData[k]);
            // Must check for zero or else this index will be ignored
            if (dimensionKeys[dimKey] || dimensionKeys[dimKey] === 0) {
              sortedRowData[dimensionKeys[dimKey]] = rowData ? rowData[k] : EMPTY_ROW_DATA;
            }
          }
        }
      }
      // Sort data using first date range
      let dimData = streamingPerformanceTableData[sortIndex].dimensionData;
      streamingPerformanceTableData[i].dimensionData = dimData
        ? dimData.slice(0, numRowsAdjusted)
        : dimData;
      streamingPerformanceTableData[i].rowData = sortedRowData;
    }
    footnote = footnote.trim();
    if (footnote) {
      footnote = `Note: ${footnote}`;
    }
    if (!customSlideHeader && mediatype === "audio") {
      customSlideHeader = "Audio Performance";
    }
    if (!customSlideHeader && mediatype === "display") {
      customSlideHeader = "Display Performance";
    }
    return {
      companyColor,
      customSlideHeader,
      customTableColumnWidth,
      customTableFontSize,
      customTableRowHeight,
      footnote,
      streamingPerformanceTableData,
    };
  };
}

export default StreamingPerformanceNewSlide;
