import "./KpiMapping.scss";
import React, { useEffect, useMemo, useState } from "react";
import * as R from "ramda";
import { useCompanyInfo } from "../redux/company";
import { MdEdit } from "react-icons/md";
import { BPMTable, Button, ButtonType, FullPageSpinner, Page } from "../Components";
import EditKpiMapping from "./EditKpiMapping";
import useLocation from "../utils/hooks/useLocation";
import { useSetError } from "../redux/modals";
import { CrossChannelLambdaFetch } from "../utils/fetch-utils";
import { KpiMappings, AccountInfo } from "@blisspointmedia/bpm-types/dist/KpiMapping";
import { ButtonFrameworkVariant } from "../Components/ButtonFramework";
import KpiFunnels from "./KpiFunnels";

interface KpiMappingsContextType {
  sourceIdMap: Record<string, number>;
  kpiOptions: Record<string, string[]>;
  kpiMappings: KpiMappings;
  sourcesWithKpis: string[];
  accountInfoBySource: Record<string, AccountInfo[]>;
}

export const KpiMappingsContext = React.createContext<KpiMappingsContextType>({
  sourceIdMap: {},
  kpiOptions: {},
  kpiMappings: {},
  sourcesWithKpis: [],
  accountInfoBySource: {},
});

enum NAV {
  KPI_MAPPING = "KPI Mapping",
  CROSS_CHANNEL_FUNNEL = "Cross Channel Funnels",
}

const NAVS = [
  { label: NAV.KPI_MAPPING, key: NAV.KPI_MAPPING },
  { label: NAV.CROSS_CHANNEL_FUNNEL, key: NAV.CROSS_CHANNEL_FUNNEL },
];

const KpiMapping = (): JSX.Element => {
  const { company } = useLocation();
  const { cross_channel_kpis: crossChannelKpis } = useCompanyInfo();
  const setError = useSetError();
  const [editMode, setEditMode] = useState(false);
  const [kpiMappings, setKpiMappings] = useState<KpiMappings | undefined>();
  const [kpiOptions, setKpiOptions] = useState<Record<string, string[]>>();
  const [sourceIdMap, setSourceIdMap] = useState<Record<string, number>>();
  const [accountInfoBySource, setAccountInfoBySource] = useState<Record<string, AccountInfo[]>>();
  const [selectedNav, setSelectedNav] = useState(NAV.KPI_MAPPING);
  const [kpiFunnels, setKpiFunnels] = useState<any[]>();

  useEffect(() => {
    if (!kpiMappings) {
      (async () => {
        try {
          const res = await CrossChannelLambdaFetch("/getKpiMappings", {
            params: {
              company,
            },
          });
          const data = await res.json();
          const { kpiMappings, sourceIdMap, accountInfoBySource } = data;
          setKpiMappings(kpiMappings || {});
          setSourceIdMap(sourceIdMap || {});
          setAccountInfoBySource(accountInfoBySource || {});
        } catch (e) {
          setError({
            message: `Failed to fetch KPI mapping data for ${company}. Error: ${e.message}`,
            reportError: e,
          });
        }
      })();
    }
  }, [company, kpiMappings, setError]);

  useEffect(() => {
    (async () => {
      try {
        const res = await CrossChannelLambdaFetch("/getKpiOptions", {
          params: {
            company,
          },
        });
        const data = await res.json();
        setKpiOptions(data || {});
      } catch (e) {
        setError({
          message: `Failed to fetch source KPI options for ${company}. Error: ${e.message}`,
          reportError: e,
        });
      }
    })();
  }, [company, setError]);

  useEffect(() => {
    if (!kpiFunnels) {
      (async () => {
        try {
          const res = await CrossChannelLambdaFetch("/getKpiFunnels", {
            params: {
              company,
            },
          });
          const data = await res.json();
          setKpiFunnels(data || []);
        } catch (e) {
          setError({
            message: `Failed to fetch KPI Funnels for ${company}. Error: ${e.message}`,
            reportError: e,
          });
        }
      })();
    }
  }, [company, kpiFunnels, setError]);

  // Display the sources that we have KPI dropdown options for
  const sourcesWithKpis = useMemo(() => (kpiOptions ? R.keys(kpiOptions).sort() : []), [
    kpiOptions,
  ]);

  const headers = useMemo(() => {
    if (sourcesWithKpis && kpiMappings && accountInfoBySource) {
      const headers = [
        {
          label: "KPI Name",
          name: "crossChannelKpi",
          flex: 1,
          minFlexWidth: 200,
        },
      ];

      for (let source of sourcesWithKpis) {
        let accounts = accountInfoBySource[source];
        if (!accounts || !accounts.length) {
          // @ts-ignore
          accounts = [{ accountId: null, accountName: null }];
        }
        for (let accountInfo of accounts) {
          const { accountId, accountName } = accountInfo;

          headers.push({
            label: `${source} - ${accountName || accountId || "N/A"}`,
            name: `${source} - ${accountId}`,
            flex: 1,
            minFlexWidth: 200,
          });
        }
      }

      return headers;
    } else {
      return [];
    }
  }, [accountInfoBySource, sourcesWithKpis, kpiMappings]);

  const tableData = useMemo(() => {
    if (kpiMappings) {
      return R.values(kpiMappings).map(kpi => {
        const row: { [key: string]: string } = {
          crossChannelKpi: kpi.crossChannelKpiLabel,
        };

        for (let source of sourcesWithKpis) {
          const mappings = R.pathOr([], ["mappings", source], kpi);
          for (let mapping of mappings) {
            const { accountId, kpi } = mapping;
            row[`${source} - ${accountId}`] = kpi || "";
          }
        }

        return row;
      });
    }
  }, [kpiMappings, sourcesWithKpis]);

  return (
    <Page
      title="KPI Mapping"
      pageType="KPI Mapping"
      navs={NAVS}
      selectedNav={selectedNav}
      onNav={val => setSelectedNav(val as NAV)}
    >
      {selectedNav === NAV.CROSS_CHANNEL_FUNNEL ? (
        <KpiFunnels
          funnels={kpiFunnels}
          crossChannelKpis={crossChannelKpis || []}
          company={company}
          setKpiFunnels={setKpiFunnels}
        />
      ) : (
        <div className="kpiMappingPage">
          {!editMode && (
            <div className="kpiMappingMainControls">
              <Button
                className="editModeButton"
                type={ButtonType.FILLED}
                variant={ButtonFrameworkVariant.LEADING_ICON}
                icon={<MdEdit />}
                onClick={() => setEditMode(!editMode)}
              >
                Edit
              </Button>
            </div>
          )}
          {kpiMappings && kpiOptions && sourcesWithKpis ? (
            editMode ? (
              <KpiMappingsContext.Provider
                value={{
                  sourceIdMap: sourceIdMap || {},
                  kpiOptions,
                  kpiMappings,
                  sourcesWithKpis,
                  accountInfoBySource: accountInfoBySource || {},
                }}
              >
                <EditKpiMapping
                  company={company}
                  data={kpiMappings}
                  setEditMode={setEditMode}
                  setKpiMappings={setKpiMappings}
                />
              </KpiMappingsContext.Provider>
            ) : (
              <div className="kpiMappingTableView">
                <BPMTable headers={headers} data={tableData} />
              </div>
            )
          ) : (
            <FullPageSpinner />
          )}
        </div>
      )}
    </Page>
  );
};

export default KpiMapping;
