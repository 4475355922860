import React from "react";
import ReactDOM from "react-dom";

import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";

import { Provider } from "react-redux";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import "react-filter-box-updated/lib/react-filter-box.css";

// This needs to come before the App import so all our sass throughout the app has precedence over
// the bootstrap styles.
import "./styleLoader.js";

import App from "./App";

import "react-dates-temp/initialize";
import "react-dates-temp/lib/css/_datepicker.css";

import { reduxStore, createReduxStore } from "./redux";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_STAGE,
  integrations: [new Integrations.ExtraErrorData(), new Integrations.Dedupe()],
});

Sentry.setTag("amplify_build", process.env.REACT_APP_AWS_JOB_ID || "n/a");

// This is all related to CORS issues from running bpm-web in iframe. We can potentially get rid of
// this once we stop doing that.
if (!document.domain.endsWith("amplifyapp.com")) {
  try {
    document.domain = process.env.REACT_APP_DOMAIN;
  } catch (e) {
    // Setting document.domain seems to fail when loading our app in an iFrame, which I don't care
    // about.
    console.log(e.message);
  }
}

createReduxStore();

ReactDOM.render(
  <Provider store={reduxStore}>
    <DndProvider backend={HTML5Backend}>
      <App />
    </DndProvider>
  </Provider>,
  document.getElementById("root")
);
