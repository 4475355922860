import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { IndividualMonth } from "../IndividualMonth";
import { FOCUS_OPTIONS } from "../DatePicker";
import { DateRange } from "../../../utils/types";
import { useCallback, useMemo, useState } from "react";
import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import { DATE_FORMAT, makeMemoizedGetter } from "../../../utils/data";
import "./DPCalendar.scss";

const TODAY = Dfns.format(DATE_FORMAT, new Date());

// TODO: memoize?
const format = Dfns.format(DATE_FORMAT);

export interface DateInfo {
  date: string;
  display: string;
  span?: boolean;
  selectedLeft?: boolean;
  selectedRight?: boolean;
  lastWasFirst?: boolean;
  nextIsLast?: boolean;
  invalid?: boolean;
}

export interface MonthInfo {
  year: number;
  date: Date;
  dates: (DateInfo | null)[];
}

const getMonthInfo = makeMemoizedGetter({
  calculate: (parsedDate: Date): MonthInfo => {
    const startOfMonth = Dfns.startOfMonth(parsedDate);
    const endOfMonth = Dfns.endOfMonth(parsedDate);
    const dowStart = Dfns.getDay(startOfMonth);
    const dowEnd = Dfns.getDay(endOfMonth);
    const dates = Dfns.eachDayOfInterval({
      start: startOfMonth,
      end: endOfMonth,
    }).map(rawDate => {
      const date = format(rawDate);
      return { date, display: Dfns.format("d", rawDate) };
    });
    // Fill in days from other months with nulls
    dates.unshift(...new Array(dowStart).fill(null));
    dates.push(...new Array(6 - dowEnd).fill(null));
    return {
      year: parseInt(Dfns.format("yyyy", parsedDate)) || 2020,
      dates,
      date: startOfMonth,
    };
  },
  makeKey: Dfns.format("yyyy-MM"),
});

interface DPCalendarProps {
  start?: string;
  end?: string;
  focus?: typeof FOCUS_OPTIONS[number];
  onChange: (dateRange: Partial<DateRange>, focus: typeof FOCUS_OPTIONS[number]) => void;
  isOutsideRange: (date: string) => boolean;
  isDayBlocked: (date: string) => boolean;
  years: number[];
  currentMonthSecond?: boolean;
  maxDate?: string;
}

export const DPCalendar: React.FC<DPCalendarProps> = ({
  start,
  end,
  focus,
  onChange,
  isOutsideRange,
  isDayBlocked,
  years,
  currentMonthSecond,
  maxDate,
}) => {
  const [selectedMonth, setSelectedMonth] = useState(start);
  const monthInfo = useMemo(() => {
    let parsedStart = Dfns.parseISO(selectedMonth || TODAY);
    let nextMonth = Dfns.addMonths(1, parsedStart);
    if (currentMonthSecond) {
      nextMonth = parsedStart;
      parsedStart = Dfns.subMonths(1, parsedStart);
    }
    return [getMonthInfo(parsedStart), getMonthInfo(nextMonth)].map((info: MonthInfo) => ({
      ...info,
      dates: info.dates.map(date => {
        if (!date) {
          return date;
        }
        return {
          ...date,
          invalid: isOutsideRange(date.date) || isDayBlocked(date.date),
        };
      }),
    }));
  }, [selectedMonth, isOutsideRange, isDayBlocked, currentMonthSecond]);
  const adjustSelectedMonth = useCallback(
    (adjustment: number, date = Dfns.parseISO(selectedMonth || TODAY)) => {
      setSelectedMonth(R.pipe(Dfns.addMonths(adjustment), format)(date));
    },
    [selectedMonth]
  );
  const onSelect = useCallback(
    (date: string) => {
      if (focus === "END") {
        let newRange: Partial<DateRange> = {
          end: date,
        };
        if (!start || start <= date) {
          newRange.start = start;
        }
        onChange(newRange, "START");
      } else {
        let newRange: Partial<DateRange> = {
          start: date,
        };
        if (!end || end >= date) {
          newRange.end = end;
        }
        onChange(newRange, "END");
      }
    },
    [start, end, focus, onChange]
  );
  return (
    <div className="DPCalendar">
      {/* TODO: we could probably take a flag and do n months tbh */}
      <IndividualMonth
        monthInfo={monthInfo[0]}
        range={{ start, end }}
        onSelect={onSelect}
        focus={focus}
        setSelectedMonth={month => adjustSelectedMonth(0, month)}
        years={years}
        maxDate={maxDate}
      />
      <IndividualMonth
        monthInfo={monthInfo[1]}
        range={{ start, end }}
        onSelect={onSelect}
        focus={focus}
        setSelectedMonth={month => adjustSelectedMonth(-1, month)}
        years={years}
        maxDate={maxDate}
      />
      <div className="navArrow left" onClick={() => adjustSelectedMonth(-1)}>
        <MdChevronLeft />
      </div>
      <div className="navArrow right" onClick={() => adjustSelectedMonth(1)}>
        <MdChevronRight />
      </div>
    </div>
  );
};

export default DPCalendar;
