import { CreativeInfoItem } from "@blisspointmedia/bpm-types/dist/Creative";
import { Video, Img } from "../Components";

interface CreativeMediaPlayerProps {
  cid: string;
  selectedCreative: CreativeInfoItem;
}

const CreativeMediaPlayer = ({ cid, selectedCreative }: CreativeMediaPlayerProps): JSX.Element => {
  return cid === "tinuititest" ? (
    <Img
      className="videoContainer"
      src={`https://cdn.blisspointmedia.com/creatives/${selectedCreative.file}.png`}
    />
  ) : (
    <div className="videoContainer">
      <Video
        src={`https://cdn.blisspointmedia.com/creatives/${selectedCreative?.file}.mp4`}
        poster={`https://cdn.blisspointmedia.com/creatives/${selectedCreative?.file}.png`}
        controls
        className="videoPlayer"
        unloader={<h3>No Video File</h3>}
      />
    </div>
  );
};

export default CreativeMediaPlayer;
