import "./AMCGraphs.scss";
import * as R from "ramda";
import React from "react";
import {
  Bar,
  BarChart as BarGraph,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

interface BarChartProps {
  data: {}[];
  barKey: string;
  barFillColor?: string;
  yAxisKey: string;
  yAxisLabel: string;
  height?: number;
  fontSize?: number;
}

const BarChart: React.FC<BarChartProps> = ({
  data,
  barKey,
  barFillColor,
  yAxisKey,
  yAxisLabel,
  height,
  fontSize = 16,
}) => {
  // Dynamically configure y-axis width based on length of longest key
  const yAxisWidth =
    (R.filter(entry => !R.isNil(entry[yAxisKey]), data)
      .map(entry => entry[yAxisKey])
      .reduce(
        (accumulator, currentValue) =>
          currentValue.length > accumulator ? currentValue.length : accumulator,
        0
      ) /
      2) *
      fontSize +
    fontSize;

  const renderLegend = props => (
    <div className="legend-wrapper">
      {props.payload.map((entry, index) => (
        <div className="legend" key={index}>
          <div
            className="legend-color"
            style={{ backgroundColor: barFillColor, marginLeft: yAxisWidth }}
          />
          <div>{entry.value}</div>
        </div>
      ))}
    </div>
  );

  return (
    <ResponsiveContainer height={height || 400} width="100%">
      <BarGraph className="amc-horizontal-bar-chart" data={data} layout="vertical">
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" stroke="#000" />
        <YAxis
          type="category"
          stroke="#000"
          dataKey={yAxisKey}
          width={yAxisWidth}
          tick={({ x, y, payload }) => (
            <g transform={`translate(${x},${y})`}>
              <text
                x={0}
                y={fontSize * -0.75}
                dy={fontSize}
                textAnchor="end"
                fontSize={`${fontSize}px`}
              >
                {payload.value}
              </text>
            </g>
          )}
          interval={0}
        />
        <Tooltip />
        <Legend content={renderLegend} verticalAlign="top" />
        <Bar dataKey={barKey} name={yAxisLabel} fill={barFillColor || "#8884d8"} />
      </BarGraph>
    </ResponsiveContainer>
  );
};

export default BarChart;
