import "./FetchFilterBar.scss";
import { Button, ButtonType, DropdownMenu, TextToggleButton } from "../../Components";
import { Category, FilterPaneBasicState } from "@blisspointmedia/bpm-types/dist/FilterPane";
import { MdOutlineArrowBackIos, MdOutlineArrowForwardIos } from "react-icons/md";
import { StateSetter } from "../../utils/types";
import * as R from "ramda";
import React, { useCallback, useEffect, useState } from "react";

export const COMMON_CATEGORY_LABELS: Record<string, Record<string, string>> = {
  Avail: {
    L: "Local",
    N: "National",
  },
};

interface FilterPaneBasicProps {
  categories: Category[];
  initialOpenSection: string;
  readonly?: boolean;
  setState: StateSetter<FilterPaneBasicState>;
  state: FilterPaneBasicState;
}

export const FilterPaneBasic: React.FC<FilterPaneBasicProps> = ({
  categories,
  initialOpenSection,
  readonly,
  setState,
  state,
}) => {
  const [openSection, setOpenSection] = useState(initialOpenSection);
  const { notMap, selectedMap } = state;

  const setNotMap = useCallback(
    (category: string, not: boolean) => {
      setState(
        R.mergeDeepLeft({
          notMap: {
            [category]: not,
          },
        })
      );
    },
    [setState]
  );

  const setCheckBox = useCallback(
    (category: string, key: string, checked: boolean) => {
      setState(
        R.mergeDeepLeft({
          selectedMap: {
            [category]: {
              [key]: checked,
            },
          },
        })
      );
    },
    [setState]
  );

  useEffect(() => {
    const filteredSelectedMap = { ...selectedMap };
    for (const key of R.keys(filteredSelectedMap)) {
      for (const options of R.keys(filteredSelectedMap[key])) {
        if (!filteredSelectedMap[key][options]) {
          delete filteredSelectedMap[key][options];
        }
      }
      if (!R.keys(filteredSelectedMap[key]).length) {
        delete filteredSelectedMap[key];
      }
    }
    if (R.keys(filteredSelectedMap).length !== R.keys(selectedMap).length) {
      setState(state => ({
        ...state,
        selectedMap: filteredSelectedMap,
      }));
    }
  }, [selectedMap, setState]);

  useEffect(() => {
    const filteredNotMap = { ...notMap };
    for (const key of R.keys(filteredNotMap)) {
      if (!filteredNotMap[key]) {
        delete filteredNotMap[key];
      }
    }
    if (R.keys(filteredNotMap).length !== R.keys(notMap).length) {
      setState(state => ({
        ...state,
        notMap: filteredNotMap,
      }));
    }
  }, [notMap, selectedMap, setState]);

  const [subFilterMap, setSubFilterMap] = useState<
    Record<string, ((value: any) => boolean) | undefined>
  >({});

  return (
    <div className="filterPaneBody">
      {R.map(({ label, key: category, options }) => {
        const expanded = openSection === category;
        const not = notMap[category];
        const selectedItemMap = selectedMap[category] || {};
        const selectedOptions: typeof options = [];
        for (const option of options) {
          const key = typeof option === "string" ? option : option.value;
          if (selectedItemMap[key]) {
            selectedOptions.push(option);
          }
        }
        if (readonly && !selectedOptions.length) {
          return null;
        }
        const excludeClasses = ["not"];
        if (not) {
          excludeClasses.push("selected");
        }
        if (readonly) {
          excludeClasses.push("disabled");
        }
        const headerClasses = ["categoryHeader"];
        if (readonly) {
          headerClasses.push("disabled");
        }
        return (
          <div key={category} className="categoryContainer">
            <div
              className={headerClasses.join(" ")}
              onClick={() => !readonly && setOpenSection(expanded ? "" : category)}
            >
              <div className="headerLabel">{label}</div>
              {expanded && (
                <div
                  className="includeExcludeToggleButtonContainer"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <TextToggleButton
                    className={excludeClasses.join(" ")}
                    design="secondary"
                    onChange={option => setNotMap(category, readonly ? not : option === "Exclude")}
                    options={["Include", "Exclude"]}
                    selectedOption={notMap[category] ? "Exclude" : "Include"}
                  />
                </div>
              )}
              {!readonly && (
                <div className="expander">
                  {expanded ? <MdOutlineArrowBackIos /> : <MdOutlineArrowForwardIos />}
                </div>
              )}
            </div>
            {expanded && !readonly && (
              <div className="categoryOptions">
                <div className="selectAllButtons">
                  <Button
                    className="selectAll"
                    size="sm"
                    type={ButtonType.EMPTY}
                    onClick={() => {
                      const checkList: Record<string, true> = {};
                      const mappedOptions = R.map(option => {
                        let value: string;
                        let label = "";
                        if (typeof option === "string") {
                          value = option;
                        } else {
                          ({ value, label } = option);
                        }
                        return {
                          label: label ? label : value ? value : "(none)",
                          value,
                        };
                      }, options);
                      const filter = subFilterMap[category];
                      R.map(option => {
                        if (filter) {
                          if (filter(option)) {
                            checkList[option.value] = true;
                          }
                        } else {
                          checkList[option.value] = true;
                        }
                        return option;
                      }, mappedOptions);
                      setState(
                        R.mergeDeepLeft({
                          selectedMap: {
                            [category]: checkList,
                          },
                        })
                      );
                    }}
                  >
                    Select All
                  </Button>
                  <Button
                    className="selectAll"
                    size="sm"
                    type={ButtonType.EMPTY}
                    onClick={() => {
                      setState(state => ({
                        ...state,
                        selectedMap: { ...state.selectedMap, [category]: {} },
                      }));
                    }}
                  >
                    Clear
                  </Button>
                </div>
                <DropdownMenu
                  applyOnClick={null}
                  cancelOnClick={null}
                  hasFilterBar={options.length > 10}
                  menuItems={R.map(
                    option => {
                      let label: string;
                      let value: string;
                      if (typeof option === "string") {
                        label = option;
                        value = option;
                      } else {
                        ({ label, value } = option);
                      }
                      const checked = R.path([category, value], selectedMap) as boolean;
                      const commonLabel =
                        COMMON_CATEGORY_LABELS &&
                        COMMON_CATEGORY_LABELS[category] &&
                        COMMON_CATEGORY_LABELS[category][value]
                          ? COMMON_CATEGORY_LABELS[category][value]
                          : undefined;
                      return {
                        label: commonLabel ? commonLabel : label ? label : value ? value : "(none)",
                        selected: checked,
                        value,
                      };
                    },

                    options as any
                  )}
                  multiSelect
                  itemOnClick={item => {
                    if (!readonly) {
                      const { value } = item;
                      const checked = R.path([category, value], selectedMap) as boolean;
                      setCheckBox(category, item.value, !checked);
                    }
                  }}
                  setFilters={filter =>
                    setSubFilterMap(subFilterMap => ({ ...subFilterMap, [category]: filter }))
                  }
                  standalone
                />
              </div>
            )}
          </div>
        );
      }, categories)}
    </div>
  );
};

export default FilterPaneBasic;
