import React, { useCallback, useMemo } from "react";
import "./IndividualMonth.scss";
import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import { DateRange } from "../../../utils/types";
import { FOCUS_OPTIONS, MONTHS } from "../DatePicker";
import { OverlayTrigger } from "../../OverlayTrigger";
import { Tooltip } from "react-bootstrap";
import { DateInfo, MonthInfo } from "../DPCalendar";

interface IndividualMonthProps {
  monthInfo: MonthInfo;
  onSelect: (date: string) => void;
  range?: Partial<DateRange>;
  focus?: typeof FOCUS_OPTIONS[number];
  setSelectedMonth: (selectedMonth: Date) => void;
  years: number[];
  maxDate?: string;
}

export const IndividualMonth: React.FC<IndividualMonthProps> = ({
  monthInfo,
  range,
  onSelect,
  focus,
  setSelectedMonth,
  years,
  maxDate,
}) => {
  const dayGrid = useMemo(() => {
    const rows: (DateInfo | null)[][] = [];
    let row: (DateInfo | null)[] = [];
    let lastWasFirst = false;
    for (let dateInfo of monthInfo.dates) {
      if (dateInfo) {
        let info = { ...dateInfo };
        if (lastWasFirst) {
          // This is only necessary when we're on the same row as the start. Thus, if the row has no
          // length, meaning we're the first element, we don't need to set.
          if (row.length) {
            info.lastWasFirst = true;
          }
          lastWasFirst = false;
        }
        if (range) {
          if (info.date === range.start) {
            info.selectedLeft = true;
            lastWasFirst = true;
          } else if (info.date === range.end) {
            info.selectedRight = true;
            const last = row[row.length - 1];
            if (last) {
              last.nextIsLast = true;
            }
          } else if (range.start && range.end && info.date > range.start && info.date < range.end) {
            info.span = true;
          }
        }
        row.push(info);
      } else {
        row.push(null);
      }

      if (row.length === 7) {
        rows.push(row);
        row = [];
      }
    }
    return rows;
  }, [monthInfo, range]);
  const monthValue = useMemo(() => Dfns.getMonth(monthInfo.date), [monthInfo]);
  const onMonthChange = useCallback(
    (monthStr: string) => {
      // The months are 0-delimited
      const monthNum = parseInt(monthStr) + 1;
      const paddedMonthString = `${monthNum}`.padStart(2, "0");
      setSelectedMonth(Dfns.parseISO(`${monthInfo.year}-${paddedMonthString}-01`));
    },
    [setSelectedMonth, monthInfo]
  );
  const onYearChange = useCallback(
    (year: string) => {
      R.pipe(Dfns.setYear(parseInt(year)), setSelectedMonth)(monthInfo.date);
    },
    [setSelectedMonth, monthInfo]
  );
  return (
    <div className="DPMonth">
      <div className="monthHeader">
        <select value={monthValue} onChange={e => onMonthChange(e.target.value)}>
          {MONTHS.map((month, i) => (
            <option key={month} value={i}>
              {month}
            </option>
          ))}
        </select>
        <select value={monthInfo.year} onChange={e => onYearChange(e.target.value)}>
          {years.map(year => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
      <div className="daysOfWeek">
        <div>Su</div>
        <div>Mo</div>
        <div>Tu</div>
        <div>We</div>
        <div>Th</div>
        <div>Fr</div>
        <div>Sa</div>
      </div>
      <div className="dayGrid">
        {dayGrid.map((row, i) => (
          <div key={i}>
            {row.map((day, i) => {
              let classes: string[] = [];
              let display = "";
              let key = `${i}`;
              if (day) {
                ({ display } = day);
                key = day.date;
                if (day.invalid) {
                  classes.push("invalid");
                }
                if (focus) {
                  classes.push(focus === "START" ? "focusStart" : "focusEnd");
                }
                if (day.selectedLeft || day.selectedRight) {
                  classes.push("selected");
                  if (day.selectedLeft) {
                    classes.push("selectedLeft");
                  } else {
                    classes.push("selectedRight");
                  }
                } else if (day.span) {
                  classes.push("span");
                }
                if (day.lastWasFirst) {
                  classes.push("lastWasFirst");
                }
                if (day.nextIsLast) {
                  classes.push("nextIsLast");
                }
              } else {
                classes.push("notCurrentMonth");
              }

              return day && day.invalid && maxDate && day.date > maxDate ? (
                <OverlayTrigger
                  placement={OverlayTrigger.PLACEMENTS.LEFT.CENTER}
                  overlay={<Tooltip id={day.date}>Data not yet available for these dates.</Tooltip>}
                  key={key}
                >
                  <div
                    key={key}
                    className={classes.join(" ")}
                    onClick={() => day && !day.invalid && onSelect(day.date)}
                  >
                    {display}
                  </div>
                </OverlayTrigger>
              ) : (
                <div
                  key={key}
                  className={classes.join(" ")}
                  onClick={() => day && !day.invalid && onSelect(day.date)}
                >
                  {display}
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default IndividualMonth;
