import "./OfflineInputs.scss";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import cn from "classnames";
import { MdDelete, MdEdit, MdSave } from "react-icons/md";
import * as uuid from "uuid";
import * as UserRedux from "../../redux/user";
import { Button, ButtonType, FilterBar, Spinner } from "../../Components";
import { DownloadToggle } from "../../Components/DownloadDropdown/DownloadToggle";
import InputTable from "./InputTable";
import {
  EntryEditsMap,
  NewTableEntriesMap,
  TableEditsMap,
  prepDeleteTableReq,
  prepCreateMediaTableReq,
  validateAndUpdateTables as validateAndUpdateTablesInternal,
  QueuedRequest,
} from "./OfflineInputsUtils";
import { HiOutlinePlus } from "react-icons/hi";
import { EmptyInputs } from "./EmptyInputs";
import {
  AddMediaInputArgs,
  OfflineInputTabs,
  OfflineInputsMediaEntry,
  OfflineInputsMediaTable,
} from "@blisspointmedia/bpm-types/dist/OfflineInputs";
import { useSelector } from "react-redux";
import AddMediaInputModal from "./AddMediaInputModal";
import {
  CommitSuccessDialog,
  DiscardConfirmationDialog,
  InstructionsDialog,
  ValidationErrorDialog,
} from "./OfflineInputsDialogs";
import { SetError } from "../../redux/modals";
import { format } from "date-fns";

interface MediaInputsProps {
  originalTables: OfflineInputsMediaTable[];
  updated: { by?: string; at?: string };
  fetching: boolean;
  refetch: () => void;
  editingUnlocked: boolean;
  setEditingUnlocked: React.Dispatch<React.SetStateAction<boolean>>;
  dispatchStateReset: boolean;
  setDispatchStateReset: React.Dispatch<React.SetStateAction<boolean>>;
  company: string;
  setError: SetError;
  isPaid?: boolean;
}

const MediaInputs: React.FC<MediaInputsProps> = ({
  originalTables,
  updated,
  fetching,
  refetch,
  editingUnlocked,
  setEditingUnlocked,
  dispatchStateReset,
  setDispatchStateReset,
  company,
  setError,
  isPaid = false,
}) => {
  const [tables, setTables] = useState<OfflineInputsMediaTable[]>(originalTables);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [tablesInEditMode, setTablesInEditMode] = useState<string[]>([]);
  const [newTableEntries, setNewTableEntries] = useState<NewTableEntriesMap>({});
  const [validationErrors, setValidationErrors] = useState<
    { tableId: string; errorDates: string[] }[]
  >([]);
  const [queuedRequests, setQueuedRequests] = useState<QueuedRequest[]>([]);
  const [deletedTables, setDeletedTables] = useState<OfflineInputsMediaTable[]>([]);
  const [showAddMediaInputModal, setShowAddMediaInputModal] = useState(false);
  const [showCommitSuccessDialog, setShowCommitSuccessDialog] = useState(false);
  const [showInstructionsDialog, setShowInstructionsDialog] = useState(false);
  const [showDiscardDialog, setShowDiscardDialog] = useState(false);
  const [tableEdits, setTableEdits] = useState<TableEditsMap>({});
  const [filter, setFilter] = useState<{ filter: (line: Record<string, any>) => boolean }>();
  const userFullName: string = useSelector(UserRedux.fullNameSelector) ?? "";

  const scrollToRow = (tableId, date) => {
    const idToFind = `${tableId}_${date.split("T")[0]}`;
    const foundEl = document.getElementById(idToFind);

    if (foundEl) {
      foundEl.scrollIntoView({ behavior: "smooth", block: "center" });
    } else {
      setTimeout(scrollToRow, 200, tableId, date);
    }
  };

  const startEditingTable = (tableId: string, blanks: OfflineInputsMediaEntry[]) => {
    setTablesInEditMode([...tablesInEditMode, tableId]);
    setTableEdits({ ...tableEdits, [tableId]: {} });
    newTableEntries[tableId] = blanks;
    setNewTableEntries({ ...newTableEntries });

    scrollToRow(tableId, blanks[blanks.length - 1].date);
  };

  const resetState = useCallback(
    (discard = false) => {
      if (discard) {
        // Restore deleted tables
        setTables([...originalTables, ...deletedTables]);
      }

      setEditingUnlocked(false);
      setNewTableEntries({});
      setTableEdits({});
      setTablesInEditMode([]);
      setDeletedTables([]);
    },
    [deletedTables, originalTables, setEditingUnlocked]
  );

  useEffect(() => {
    if (dispatchStateReset) {
      resetState(true);
      setDispatchStateReset(false);
    }
  }, [dispatchStateReset, resetState, setDispatchStateReset]);

  useEffect(() => {
    setTables(originalTables);
  }, [originalTables]);

  const onCommitSuccess = () => {
    setShowCommitSuccessDialog(true);
    refetch();
    resetState();
  };

  const validateAndUpdateTables = async () => {
    setSubmitting(true);
    await validateAndUpdateTablesInternal(
      company,
      userFullName,
      isPaid ? OfflineInputTabs.PAID_MEDIA_INPUTS : OfflineInputTabs.NON_PAID_MEDIA_INPUTS,
      tables.filter(table => tablesInEditMode.includes(table.tableId)),
      tableEdits,
      newTableEntries,
      setValidationErrors,
      setError,
      onCommitSuccess,
      queuedRequests,
      setQueuedRequests
    );
    setSubmitting(false);
  };

  const deleteTable = (tableId: string) => {
    prepDeleteTableReq(
      tableId,
      company,
      userFullName,
      isPaid ? OfflineInputTabs.PAID_MEDIA_INPUTS : OfflineInputTabs.NON_PAID_MEDIA_INPUTS,
      tables,
      setTables,
      deletedTables,
      setDeletedTables,
      queuedRequests,
      setQueuedRequests,
      newTableEntries,
      setNewTableEntries,
      tableEdits,
      setTableEdits
    );
  };

  const updatedLabel = useMemo(() => {
    const { by, at } = updated;

    try {
      const atDate = new Date(at ?? "");
      const atStrFormatted = format(atDate, "MM/dd/yyyy");

      return `Last updated on ${atStrFormatted} by ${by}`;
    } catch (e) {
      // If "at" cannot be parsed as a date, display nothing.
      return null;
    }
  }, [updated]);

  const addMediaLabel = useMemo(() => {
    return isPaid ? "Paid Media Input" : "Non-Paid Media Input";
  }, [isPaid]);

  return (
    <>
      <AddMediaInputModal
        show={showAddMediaInputModal}
        handleClose={() => setShowAddMediaInputModal(false)}
        createTable={(params, startDate) => {
          prepCreateMediaTableReq(
            company,
            userFullName,
            isPaid ? OfflineInputTabs.PAID_MEDIA_INPUTS : OfflineInputTabs.NON_PAID_MEDIA_INPUTS,
            startDate,
            {
              ...params,
              company,
              updatedBy: userFullName,
              tableId: uuid.v4(),
            } as AddMediaInputArgs,
            queuedRequests,
            setQueuedRequests,
            tables,
            setTables,
            tablesInEditMode,
            setTablesInEditMode,
            newTableEntries,
            setNewTableEntries,
            tableEdits,
            setTableEdits
          );
          setEditingUnlocked(true);
        }}
        isPaid={isPaid}
      />
      <CommitSuccessDialog
        show={showCommitSuccessDialog}
        onHide={() => setShowCommitSuccessDialog(false)}
      />
      <InstructionsDialog
        show={showInstructionsDialog}
        onHide={() => {
          setShowInstructionsDialog(false);
        }}
      />
      <DiscardConfirmationDialog
        show={showDiscardDialog}
        onDiscard={() => resetState(true)}
        onHide={() => setShowDiscardDialog(false)}
      />
      {validationErrors.length > 0 && (
        <ValidationErrorDialog
          errorDates={validationErrors[0].errorDates}
          onResolve={() => {
            validationErrors.shift();
            setValidationErrors([...validationErrors]);

            if (validationErrors.length === 0) {
              validateAndUpdateTables();
            }
          }}
          onReject={() => {
            setValidationErrors([]);
            setShowInstructionsDialog(true);
            scrollToRow(validationErrors[0].tableId, validationErrors[0].errorDates[0]);
          }}
          table={tables.find(table => table.tableId === validationErrors[0].tableId)}
          tableEdits={tableEdits[validationErrors[0].tableId]}
        />
      )}
      <div className="offlineInputsGroup">
        {fetching && <Spinner size={100} />}
        {!fetching && originalTables.length === 0 && deletedTables.length === 0 && (
          <EmptyInputs
            inputType={addMediaLabel}
            showAddMediaInputModal={() => setShowAddMediaInputModal(true)}
          />
        )}
        {!fetching && (tables.length > 0 || deletedTables.length > 0) && (
          <>
            <div className="tablesHeader">
              <span className="tablesHeaderUpdatedBy">{updatedLabel}</span>
              <span className="tablesActions">
                {editingUnlocked && (
                  <FilterBar
                    hasAdvanced={false}
                    onFilter={filter => {
                      setFilter({ filter });
                    }}
                    options={["date"]}
                  />
                )}
                {editingUnlocked ? (
                  <Button
                    type={ButtonType.FILLED}
                    design="secondary"
                    disabled={submitting}
                    onClick={() => setShowAddMediaInputModal(true)}
                  >
                    <HiOutlinePlus />
                    Add {addMediaLabel}
                  </Button>
                ) : (
                  <Button type={ButtonType.FILLED} onClick={() => setEditingUnlocked(true)}>
                    <MdEdit />
                    Edit
                  </Button>
                )}
                <div
                  // TODO: Implement download: https://tinuiti.atlassian.net/browse/STREAM-118
                  onClick={() => console.warn("Download not yet implemented")}
                >
                  <DownloadToggle design={editingUnlocked ? "secondary" : "primary"} />
                </div>
              </span>
            </div>
            <div className={cn("tablesContainer", { locked: !editingUnlocked })}>
              <div
                className="tablesContainerInner"
                style={{ minWidth: `${(625 + 16) * tables.length + 16 * 3}px` }}
              >
                {tables?.map(table => {
                  return (
                    <InputTable
                      table={table}
                      key={table.tableId}
                      deleteTable={deleteTable}
                      tablesInEditMode={tablesInEditMode}
                      startEditingTable={startEditingTable}
                      editingUnlocked={editingUnlocked}
                      edits={tableEdits[table.tableId] ?? {}}
                      setEdits={(edits: EntryEditsMap) =>
                        setTableEdits({ ...tableEdits, [table.tableId]: edits })
                      }
                      newEntries={newTableEntries[table.tableId] ?? []}
                      submitting={submitting}
                      setError={setError}
                      filter={filter ? filter.filter : undefined}
                    />
                  );
                })}
              </div>
            </div>
            {editingUnlocked && (
              <div className="tablesFooter">
                <div className="tablesActions">
                  <Button
                    type={ButtonType.FILLED}
                    design="secondary"
                    disabled={submitting}
                    onClick={() => setShowDiscardDialog(true)}
                  >
                    <MdDelete />
                    Discard Edits
                  </Button>
                  <Button
                    type={ButtonType.FILLED}
                    disabled={submitting}
                    onClick={validateAndUpdateTables}
                  >
                    {submitting ? (
                      <Spinner />
                    ) : (
                      <>
                        <MdSave />
                        Commit
                      </>
                    )}
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default MediaInputs;
