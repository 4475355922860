import React, { useState, useMemo, useCallback, useContext, useEffect } from "react";

import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";

import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import * as uuid from "uuid";

import { MdBuild, MdKeyboardTab, MdInfoOutline, MdContentPaste } from "react-icons/md";

import { Button, Form, Tooltip, Col, Alert, Toast } from "react-bootstrap";
import Select from "react-select";

import { useNetworkMap } from "../redux/networks";
import { useSetError, useSetAreYouSure } from "../redux/modals";

import { useExperimentFlag } from "../utils/experiments/experiment-utils";

import useLocation from "../utils/hooks/useLocation";

import { SuggestionInput, OverlayTrigger, DateRangePicker, TextToggleButton } from "../Components";

import {
  OrderViewContext,
  subFlightsToPropertyArray,
  toPrettyDate,
  toPrettySpend,
} from "./OrderView";

import MoveFlightModal from "./MoveFlightModal";

import InputIcon from "react-multi-date-picker/components/input_icon";

import "./OrderView.scss";

const DATE_FORMAT = "yyyy-MM-dd";
const CANCELED_DATE_FORMAT = "EE, M/d/yy @ h:mma";

const DEFAULT_IMPRESSIONS_WINDOW = 30;

const regex = /[<>,]/; // regex to check for illegal characters in description or additional field

export const NEW_VERSION_SEND = "new version";
export const RESEND = "resend";

const TODAY = Dfns.format(DATE_FORMAT, new Date());

const DEFAULT_AUDIO_BUY_TYPE_OPTIONS = [
  "Streaming Audio - DAI Impression",
  "Podcast - DAI Impression",
  "Podcast - DAI Episodic",
  "Podcast - Embedded",
];

const DEFAULT_RTB_AUDIO_BUY_TYPE_OPTIONS = [
  "Streaming Audio - DAI Impression",
  "Podcast - DAI Impression",
];

const SPOT_POSITION_OPTIONS = [
  { value: "Pre-Roll", label: "Pre-Roll" },
  { value: "Mid-Roll", label: "Mid-Roll" },
  { value: "Pre/Mid-Roll", label: "Pre/Mid-Roll" },
  { value: "Post-Roll", label: "Post-Roll" },
  { value: "Pre/Mid/Post-Roll", label: "Pre/Mid/Post-Roll" },
];

const IMPRESSIONS_WINDOW_OPTIONS = [
  { value: DEFAULT_IMPRESSIONS_WINDOW, label: `${DEFAULT_IMPRESSIONS_WINDOW} days` },
  { value: 42, label: "42 days" },
  { value: 60, label: "60 days" },
  { value: 90, label: "90 days" },
];

export const calculateImpressions = (spend, cpm) => {
  if (spend !== "" && cpm !== "") {
    let newSpend = parseFloat(spend);
    let newCPM = parseFloat(cpm);
    if (newCPM !== 0) {
      return Math.round((newSpend / newCPM) * 1000);
    }
  }
  return 0;
};

const calculatePodcastSpend = (spotRate, numOfSpots) => {
  if (spotRate !== "" && numOfSpots !== "") {
    let newSpotRate = parseFloat(spotRate);
    let newNumOfSpots = parseFloat(numOfSpots);
    return newSpotRate * newNumOfSpots;
  }
  return 0;
};

const parseNumber = num => parseFloat((`${num}` || "").replace(/[^\d.]/g, "") || 0);

const FlightForm = ({ selectedFlight }) => {
  const { company } = useLocation();
  const setAreYouSure = useSetAreYouSure(true);
  const setError = useSetError();

  const {
    flightChangeMap,
    networkBuyingOptions,
    order,
    network,
    networkInfo,
    isMultiProperty,
    setHasPendingFormChanges,
    saveFlightChange,
    closeFlightForm,
    refetchOrder,
    copiedFlight,
    sameTab,
    setSameTab,
    showTitleOptions,
    geoOptions,
  } = useContext(OrderViewContext);

  const enableStreamingBuyingUpdates =
    networkInfo?.enableTaxonomyUpdates || company === "blisspoint";
  const placementSpecsExperiment = useExperimentFlag("enablePlacementSpecs");
  const enablePlacementSpecs = useMemo(() => {
    if (R.prop("placementSpecsExperiment", order) === true) {
      return true;
    } else if (R.prop("placementSpecsExperiment", order) === false) {
      return false;
    } else {
      return placementSpecsExperiment;
    }
  }, [order, placementSpecsExperiment]);

  const networkMap = useNetworkMap();
  const requiresDescriptions = R.path([network, "requiresDescriptions"], networkMap);

  const shouldHaveImpressionsWindowOptions =
    network?.includes("VOX") || network?.includes("BARSTOOL");

  const [flight, isNew, isReallyNew] = useMemo(() => {
    const { flights } = order;
    let flight = R.find(flight => flight.id === selectedFlight, flights);
    if (!flight) {
      flight = R.pipe(
        R.values,
        R.filter(R.prop("isNew")),
        R.find(flight => flight.id === selectedFlight)
      )(flightChangeMap);
    }

    return [flight || {}, !flight || flight.isNew, !flight];
  }, [selectedFlight, order, flightChangeMap]);

  const timePeriod = useMemo(() => {
    if (isNew) {
      return "new";
    }
    if (R.prop("endDate", flight) < TODAY) {
      return "past";
    } else if (R.prop("startDate", flight) <= TODAY) {
      return "current";
    } else {
      return "future";
    }
  }, [isNew, flight]);

  const [localFlightChangeMap, setLocalFlightChangeMap] = useState({});

  // localFlightChangeMap has enableStreamingBuyingUpdates as a key by default so want to
  // check if change count is greater than 1
  const hasChanges = useMemo(() => R.pipe(R.keys, R.length)(localFlightChangeMap) > 1, [
    localFlightChangeMap,
  ]);
  useEffect(() => {
    setHasPendingFormChanges(hasChanges);
  }, [hasChanges, setHasPendingFormChanges]);
  const [prorated, setProrated] = useState(true);

  // If the selected flight changes, we should reset our local state
  useEffect(() => {
    setLocalFlightChangeMap({});
    setProrated(true);
  }, [selectedFlight]);

  const changeFlight = useCallback(
    (field, value) => {
      if (field === "impressions" || field === "spend") {
        setProrated(false);
      }
      setLocalFlightChangeMap(existing => ({ ...existing, [field]: value }));
    },
    [setLocalFlightChangeMap, setProrated]
  );

  const DEFAULT_NEW_FLIGHT = useMemo(
    () => ({
      description: "",
      content: "",
      additional: "",
      platform: "",
      impressions: "",
      spend: "",
      cpm: R.prop("cpm", networkBuyingOptions) || "",
      subFlights: [],
      audioBuyType: "",
      episodeAirDates: "",
      spotRate: "",
      numOfSpots: "",
      spotPosition: "",
      showTitle: "",
      isNewShowTitle: false,
      geo: "",
    }),
    [networkBuyingOptions]
  );

  const mergedFlight = useMemo(() => {
    let existingChangeObj = flightChangeMap[selectedFlight] || {};
    let existingLocalChangeObj = localFlightChangeMap || {};
    let mergedFlight = {
      ...DEFAULT_NEW_FLIGHT,
      ...flight,
      ...existingChangeObj,
      ...existingLocalChangeObj,
    };

    return mergedFlight;
  }, [DEFAULT_NEW_FLIGHT, flight, flightChangeMap, localFlightChangeMap, selectedFlight]);

  const {
    cpm,
    derivedNetwork,
    description,
    content,
    additional,
    dsp,
    endDate,
    impressions,
    platform,
    audioBuyType,
    spend,
    startDate,
    canceled,
    showTitle,
    spotPosition,
    episodeAirDates,
    numOfSpots,
    spotRate,
    geo,
    overrides,
    subFlights,
    useDescription,
  } = useMemo(() => {
    if (R.pipe(R.prop("platform"), R.defaultTo(""), R.includes("RTB"))(mergedFlight)) {
      mergedFlight.dsp = mergedFlight.dsp || "";
    }

    return mergedFlight;
  }, [mergedFlight]);

  const [flightFormVersion, setFlightFormVersion] = useState();
  useEffect(() => {
    const displayNewFlightFormVal = enableStreamingBuyingUpdates && !useDescription;
    setFlightFormVersion(displayNewFlightFormVal ? "standard" : "historical");
    changeFlight("enableStreamingBuyingUpdates", displayNewFlightFormVal);
  }, [changeFlight, enableStreamingBuyingUpdates, useDescription]);

  const impressionsWindow = useMemo(() => {
    if (mergedFlight.impressionsWindow) {
      return mergedFlight.impressionsWindow;
    } else if (
      shouldHaveImpressionsWindowOptions &&
      episodeAirDates &&
      episodeAirDates.length > 0
    ) {
      let episodeAirDatesArr = episodeAirDates.split(", ").filter(date => !date.includes("_C:"));
      if (episodeAirDatesArr.length === 0) {
        return DEFAULT_IMPRESSIONS_WINDOW;
      }
      let lastAirDate = new Date(episodeAirDatesArr[episodeAirDatesArr.length - 1]);
      let lastDate = new Date(endDate);

      return Math.ceil((lastDate.getTime() - lastAirDate.getTime()) / (1000 * 3600 * 24));
    } else {
      return DEFAULT_IMPRESSIONS_WINDOW;
    }
  }, [
    endDate,
    episodeAirDates,
    mergedFlight.impressionsWindow,
    shouldHaveImpressionsWindowOptions,
  ]);

  const isPodcast = useMemo(() => {
    return audioBuyType && audioBuyType.includes("Podcast");
  }, [audioBuyType]);

  const descriptionOptions = useMemo(() => {
    if (!networkBuyingOptions) {
      return [];
    }
    return R.pipe(
      R.prop("descriptions"),
      R.concat(R.pipe(R.values, R.pluck("description"))(flightChangeMap)),
      R.uniq,
      R.reject(R.not),
      R.map(description => ({ value: description, label: description }))
    )(networkBuyingOptions);
  }, [networkBuyingOptions, flightChangeMap]);

  const contentOptions = useMemo(() => {
    if (!networkBuyingOptions) {
      return [];
    }
    return R.pipe(
      R.prop("content"),
      R.map(content => ({ value: content, label: content }))
    )(networkBuyingOptions);
  }, [networkBuyingOptions]);

  const additionalOptions = useMemo(() => {
    if (!networkBuyingOptions) {
      return [];
    }
    return R.pipe(
      R.prop("additional"),
      R.concat(R.pipe(R.values, R.pluck("additional"))(flightChangeMap)),
      R.uniq,
      R.reject(R.not),
      R.map(additional => ({ value: additional, label: additional }))
    )(networkBuyingOptions);
  }, [networkBuyingOptions, flightChangeMap]);

  const platformOptions = useMemo(() => {
    if (!(networkMap && network)) {
      return [];
    }
    let ourPlatforms = [...(R.path([network, "platforms"], networkMap) || [])];

    if (R.any(R.test(/^Audio.*$/), ourPlatforms)) {
      ourPlatforms.push("Audio Bonus");
    }
    if (R.any(R.test(/^Audio RTB.*$/), ourPlatforms)) {
      ourPlatforms.push("Audio RTB Bonus");
    }
    if (R.any(R.test(/^Streaming.*$/), ourPlatforms)) {
      ourPlatforms.push("Streaming Bonus");
    }
    if (R.any(R.test(/^Streaming RTB.*$/), ourPlatforms)) {
      ourPlatforms.push("Streaming RTB Bonus");
    }
    if (R.any(R.test(/^Display.*$/), ourPlatforms)) {
      ourPlatforms.push("Display Bonus");
    }
    return ourPlatforms.map(platform => ({ value: platform, label: platform }));
  }, [networkMap, network]);

  const dspOptions = useMemo(() => {
    if (!networkBuyingOptions) {
      return [];
    }
    return R.pipe(
      R.defaultTo({}),
      R.prop("dsps"),
      R.map(dsp => ({ value: dsp, label: dsp }))
    )(networkBuyingOptions);
  }, [networkBuyingOptions]);

  const disabledBuyTypes = useMemo(() => {
    if (!(networkMap && network)) {
      return [];
    }

    return networkMap[network].disabledBuyTypes || {};
  }, [network, networkMap]);

  const audioBuyTypeOptions = useMemo(() => {
    if (!(networkMap && network)) {
      return [];
    }

    let disabledAudioBuyTypes = disabledBuyTypes.Audio;
    return DEFAULT_AUDIO_BUY_TYPE_OPTIONS.filter(buyType => {
      if (!disabledAudioBuyTypes) {
        return true;
      }
      return !disabledAudioBuyTypes.includes(buyType);
    }).map(buyType => {
      return { value: buyType, label: buyType };
    });
  }, [disabledBuyTypes.Audio, network, networkMap]);

  const rtbAudioBuyTypeOptions = useMemo(() => {
    if (!(networkMap && network)) {
      return [];
    }

    let disabledAudioBuyTypes = disabledBuyTypes["Audio RTB"];
    return DEFAULT_RTB_AUDIO_BUY_TYPE_OPTIONS.filter(buyType => {
      if (!disabledAudioBuyTypes) {
        return true;
      }

      return !disabledAudioBuyTypes.includes(buyType);
    }).map(buyType => {
      return { value: buyType, label: buyType };
    });
  }, [disabledBuyTypes, network, networkMap]);

  const currentProperties = useMemo(() => subFlightsToPropertyArray(subFlights), [subFlights]);

  const propertyOptions = useMemo(
    () =>
      R.pipe(
        R.path([network, "children"]),
        R.defaultTo([]),
        R.filter(
          shortCode => !R.includes(shortCode, currentProperties) && networkMap[shortCode]?.buyable
        ),
        R.map(child => ({
          label: `${R.path([child, "name"], networkMap)} (${child})`,
          value: child,
        })),
        R.sortBy(R.prop("label"))
      )(networkMap),
    [networkMap, network, currentProperties]
  );

  const isBonus = useMemo(() => (platform || "").includes("Bonus"), [platform]);

  const proratable = useMemo(
    () => !prorated && startDate && endDate && (isBonus ? impressions : spend),
    [prorated, startDate, endDate, isBonus, impressions, spend]
  );

  const prorate = useCallback(() => {
    if (!proratable) {
      return;
    }
    let numDays =
      Dfns.differenceInCalendarDays(Dfns.parseISO(startDate), Dfns.parseISO(endDate)) + 1;
    if (isBonus) {
      changeFlight("impressions", Math.round((impressions * numDays) / 7));
    } else {
      let newSpend = Math.round(((spend * numDays) / 7) * 100) / 100;
      changeFlight("spend", newSpend);
      changeFlight("impressions", calculateImpressions(newSpend, cpm));
    }
    setProrated(true);
  }, [impressions, spend, cpm, startDate, endDate, isBonus, proratable, changeFlight]);

  const flightWithoutLocalChanges = useMemo(() => {
    let existingChangeObj = flightChangeMap[selectedFlight] || {};
    let mergedFlightWithoutLocalChanges = {
      ...DEFAULT_NEW_FLIGHT,
      ...flight,
      ...existingChangeObj,
    };
    return mergedFlightWithoutLocalChanges;
  }, [flightChangeMap, selectedFlight, flight, DEFAULT_NEW_FLIGHT]);

  const onCancel = useCallback(async () => {
    try {
      if (hasChanges) {
        await setAreYouSure({
          title: "You have unsaved changes.",
          message:
            "You have unsaved changes. You are about to discard them. Do you want to continue?",
          cancelText: "Never mind",
          okayText: "Yes, I'm sure",
        });
      }
      closeFlightForm();
    } catch (e) {
      // noop. This is when set are you sure is a cancel
    }
  }, [closeFlightForm, setAreYouSure, hasChanges]);

  const [createAnother, setCreateAnother] = useState(false);

  const [showGeo, setShowGeo] = useState(!!geo);

  const onSave = useCallback(async () => {
    try {
      if (
        timePeriod === "current" &&
        localFlightChangeMap.startDate &&
        localFlightChangeMap.startDate !== flightWithoutLocalChanges.startDate
      ) {
        await setAreYouSure({
          title: "Change the start date on a running flight?",
          message:
            "You're about to change the start date on a currently running flight. This is VERY dangerous and probably unnecessary. You should not do this unless you're ABSOLUTELY CERTAIN this will not cause any problems. Are you sure you want to change the start date on this flight?",
          cancelText: "Never mind",
          okayText:
            "I understand the risks and I'm absolutely certain that I want to change the start date on this flight.",
          variant: "danger",
        });
      } else if (timePeriod === "past") {
        await setAreYouSure({
          title: "Change past flight?",
          message:
            "You're about to save changes to a flight that has already happened. This is VERY dangerous and there is almost no circumstance where you should do this. Are you sure you want to modify this already-ended flight?",
          cancelText: "Never mind",
          okayText:
            "I understand the risks and I'm sure I want to modify this already-finished flight.",
          variant: "danger",
        });
      }
    } catch (e) {
      return;
    }
    let changes = { ...localFlightChangeMap };
    const existingChanges = flightChangeMap[selectedFlight] || {};
    let fullNewFlight = {
      ...DEFAULT_NEW_FLIGHT,
      ...flight,
      ...existingChanges,
      ...localFlightChangeMap,
    };

    if (!fullNewFlight.id) {
      changes.id = uuid.v4();
      changes.isNew = true;
    }
    if (!fullNewFlight.platform) {
      setError({
        title: "No Platform",
        message: "Platform is required.",
      });
      return;
    }
    if (fullNewFlight.platform.includes("Audio") && fullNewFlight.audioBuyType === "") {
      setError({
        title: "No Audio Buy Type",
        message: "Audio Buy Type is required.",
      });
      return;
    }
    if (R.isNil(fullNewFlight.startDate) || R.isNil(fullNewFlight.endDate)) {
      setError({
        title: "No dates",
        message: "A date range is required.",
      });
      return;
    }
    if (fullNewFlight.platform.includes("RTB") && !fullNewFlight.dsp) {
      setError({
        title: "No DSP",
        message: "An RTB flight must have a DSP.",
      });
      return;
    }
    if (isPodcast && !fullNewFlight.showTitle) {
      setError({
        title: "No Show Title",
        message: "Show Title is required.",
      });
      return;
    }
    if (isPodcast && !fullNewFlight.spotPosition) {
      setError({
        title: "No Spot Position",
        message: "Spot Position is required.",
      });
      return;
    }
    if (showGeo && !fullNewFlight.geo && enablePlacementSpecs) {
      setError({
        title: "Geo Targeting Required",
        message: "Uncheck box if this flight shouldn't have Geo targeting.",
      });
      return;
    }
    if (isBonus) {
      if (R.isNil(fullNewFlight.impressions) || fullNewFlight.impressions === "") {
        setError({
          title: "No impressions",
          message: "For bonus flights, impressions are required.",
        });
        return;
      }
    } else {
      if (R.isNil(fullNewFlight.cpm) || fullNewFlight.cpm === "") {
        setError({
          title: "No CPM",
          message: "For non-bonus flights, CPM is required.",
        });
        return;
      }
      if (
        (fullNewFlight.audioBuyType?.includes("DAI Episodic") ||
          fullNewFlight.audioBuyType?.includes("Embedded")) &&
        fullNewFlight.spotRate === ""
      ) {
        setError({
          title: "No spot rate",
          message: "For non-bonus DAI episodic or Embedded flights, spot rate is required.",
        });
        return;
      }
      if (
        (fullNewFlight.audioBuyType?.includes("DAI Episodic") ||
          fullNewFlight.audioBuyType?.includes("Embedded")) &&
        fullNewFlight.numOfSpots === ""
      ) {
        setError({
          title: "No # of spots",
          message: "For non-bonus DAI Episodic or Embedded flights, # of spots is required.",
        });
        return;
      }
      if (R.isNil(fullNewFlight.spend) || fullNewFlight.spend === "") {
        setError({
          title: "No spend",
          message: "For non-bonus flights, spend is required.",
        });
        return;
      }
    }

    for (let [key, name] of [
      ["spend", "Spend"],
      ["cpm", "CPM"],
      ["impressions", "Impressions"],
    ]) {
      if (!R.isNil(localFlightChangeMap[key])) {
        let parsed = parseFloat(localFlightChangeMap[key]);
        if (localFlightChangeMap[key] === "" || isNaN(parsed)) {
          setError({
            title: `Invalid ${name}`,
            message: `${name} has to be a valid number.`,
          });
          return;
        }
        changes[key] = parsed;
      }
    }
    if (fullNewFlight.startDate > fullNewFlight.endDate) {
      setError({
        title: "Invalid Date Range",
        message: "Start date must occur before end date.",
      });
      return;
    }

    if (
      isMultiProperty &&
      !R.pipe(R.prop("subFlights"), subFlightsToPropertyArray, R.length)(fullNewFlight)
    ) {
      setError({
        title: "No Properties",
        message: "Multi-Property networks must have at least one property set.",
      });
      return;
    }

    let fullSpend = { ...fullNewFlight, ...changes }.spend;
    if (
      (!fullNewFlight.platform.includes("RTB") &&
        fullSpend < networkBuyingOptions.lineItemMinimum) ||
      0
    ) {
      try {
        await setAreYouSure({
          title: "Under Line Item Minimum",
          message: `Your spend (${toPrettySpend(
            fullSpend
          )}) is less than the line item minimum (${toPrettySpend(
            networkBuyingOptions.lineItemMinimum
          )}). Are you sure you want to save?`,
          okayText: "Yes, save anyway",
          cancelText: "Never mind",
        });
      } catch (e) {
        return;
      }
    }

    // If one override is set but not the other
    if (
      R.pipe(R.path(["overrides", "spend"]), R.isNil)(fullNewFlight) ^
      R.pipe(R.path(["overrides", "impressions"]), R.isNil)(fullNewFlight)
    ) {
      setError({
        title: "Incomplete Wrench",
        message: "You must set both (or neither) spend and impressions when wrenching.",
      });
      return;
    }

    if (flightFormVersion === "historical" && requiresDescriptions && !fullNewFlight.description) {
      setError({
        title: "Description Required",
        message: "This network requires all flights have descriptions.",
      });
      return;
    }

    if (
      fullNewFlight.description &&
      regex.test(fullNewFlight.description) &&
      !descriptionOptions.map(option => option.value).includes(fullNewFlight.description)
    ) {
      setError({
        title: "Illegal characters used for new Description",
        message: "Please replace the following if used:\n<\n>\n, (comma)",
      });
      return;
    }

    if (flightFormVersion === "standard" && !fullNewFlight.content && !isPodcast) {
      setError({
        title: "Content Required",
        message: "Content must be selected.",
      });
      return;
    }

    if (fullNewFlight.additional && regex.test(fullNewFlight.additional)) {
      setError({
        title: "Illegal characters used for Additional Details",
        message: "Please replace the following if used:\n<\n>\n, (comma)",
      });
      return;
    }

    let newFlightChangeObj = {};
    if (isNew) {
      newFlightChangeObj = { ...fullNewFlight, ...changes };
    } else {
      newFlightChangeObj = { ...existingChanges };
      let keys = R.keys({ ...existingChanges, ...changes });
      for (let key of keys) {
        let changeVal = changes[key];
        let existingVal = existingChanges[key];
        let flightVal = flight[key];
        if (key === "dsp") {
          // If we made no RTB-related changes, don't modify the dsp.
          if (R.isNil(changes.platform) && R.isNil(changes.dsp)) {
            continue;
          }
          let { platform } = fullNewFlight;
          // If everything resolves to and RTB flight...
          if (platform.includes("RTB")) {
            // If we have a new dsp value, use it. If not, but the previous changes had said to
            // delete the existing dsp (false), delete the key (meaning no change)
            if (changeVal) {
              newFlightChangeObj.dsp = changeVal;
            } else if (existingVal === false) {
              delete newFlightChangeObj.dsp;
            }
          } else if (flight.platform.includes("RTB")) {
            // If the fully resolved flight is not RTB, but the original is, then either these new
            // changes or pre-existing changes set the platform to non-RTB. Thus, the dsp needs to
            // be deleted from the flight (which used to be RTB). NOTE: it shouldn't be possible to
            // change the platform of an already-saved flight, but better safe than sorry.
            newFlightChangeObj.dsp = false;
          } else {
            // Otherwise, just set the dsp to have no change.
            delete newFlightChangeObj.dsp;
          }
          continue;
        }
        // If this key has no local change, just move on
        if (R.isNil(changeVal)) {
          continue;
        }

        if (key === "canceled") {
          // If the flight was previously canceled, but the user uncanceled and re-canceled, we want
          // the old date to remain (essentially delete this change and pretend it never happened).
          // If our local change agrees with the flight, then we should have no change. Either that
          // means we're saying cancel and already canceled flight, or uncancel a flight that's not
          // canceled, in which case we should not be registering a change. If they don't agree,
          // then use whatever this latest change is.
          if (changeVal ^ !!flightVal) {
            newFlightChangeObj.canceled = changeVal;
          } else {
            delete newFlightChangeObj.canceled;
          }
          continue;
        }

        // If our new value matches the flight value, just delete any change to that value, if
        // present. Otherwise, use our new value.
        if (changeVal === flightVal) {
          delete newFlightChangeObj[key];
        } else {
          newFlightChangeObj[key] = changeVal;
        }
      }
    }
    saveFlightChange(fullNewFlight.id || newFlightChangeObj.id, newFlightChangeObj, !createAnother);
  }, [
    localFlightChangeMap,
    flightChangeMap,
    selectedFlight,
    DEFAULT_NEW_FLIGHT,
    flight,
    isPodcast,
    showGeo,
    enablePlacementSpecs,
    isBonus,
    isMultiProperty,
    networkBuyingOptions,
    flightFormVersion,
    requiresDescriptions,
    descriptionOptions,
    isNew,
    saveFlightChange,
    createAnother,
    timePeriod,
    flightWithoutLocalChanges,
    setAreYouSure,
    setError,
  ]);

  const removeProperty = useCallback(
    shortCode => {
      let newSubFlights = [];
      for (let subFlight of subFlights) {
        // If this sub flight is the one we're removing
        if (subFlight.shortCode === shortCode) {
          // Tell it to end if it's existing. If it has no id, it's new, which means we just won't
          // add it back into the new array.
          if (subFlight.id) {
            newSubFlights.push({
              ...subFlight,
              ending: true,
            });
          }
        } else {
          newSubFlights.push(subFlight);
        }
      }
      changeFlight("subFlights", newSubFlights);
    },
    [changeFlight, subFlights]
  );

  const addProperty = useCallback(
    shortCode => {
      let newSubFlights = [];
      let found = false;
      for (let subFlight of subFlights) {
        // If there is already a sub flight set to end with the same short code, this action is
        // essentially just undoing that. So just remove that modification.
        if (subFlight.shortCode === shortCode && subFlight.ending) {
          found = true;
          newSubFlights.push(R.omit(["ending"], subFlight));
        } else {
          newSubFlights.push(subFlight);
        }
      }
      if (!found) {
        newSubFlights.push({
          shortCode,
        });
      }
      changeFlight("subFlights", newSubFlights);
    },
    [changeFlight, subFlights]
  );

  const canceledLabel = useMemo(() => {
    if (!canceled) {
      return;
    }
    return canceled === true
      ? "Just now"
      : R.pipe(Dfns.parseISO, Dfns.format(CANCELED_DATE_FORMAT))(canceled);
  }, [canceled]);

  const cancelFlight = useCallback(async () => {
    if (timePeriod === "future" && !canceled) {
      try {
        let resetValues =
          flight.audioBuyType?.includes("Episodic") || flight.audioBuyType?.includes("Embedded")
            ? "spot rate, # of spots, spend, CPM, and impressions"
            : "spend, CPM, and impressions";
        await setAreYouSure({
          title: "Canceling Flight",
          message: `You're about to cancel this flight. This will set the ${resetValues} to 0. Are you sure you want to continue?`,
          okayText: "Continue",
        });
      } catch (e) {
        return;
      }
      changeFlight("spend", 0);
      changeFlight("cpm", 0);
      changeFlight("impressions", 0);
      if (flight.audioBuyType?.includes("Episodic") || flight.audioBuyType?.includes("Embedded")) {
        changeFlight("spotRate", 0);
        changeFlight("numOfSpots", 0);
      }
    }
    changeFlight("canceled", !canceled);
  }, [timePeriod, setAreYouSure, changeFlight, canceled, flight]);

  const [showWrench, setShowWrench] = useState(false);

  const {
    wrenchSpend,
    wrenchImps,
    clearWrenching,
    changeWrenchSpend,
    changeWrenchImps,
    calculateWrenchSpend,
    calculateWrenchImps,
  } = useMemo(() => {
    const ourOverrides = overrides || {};
    const changeWrenchSpend = spend =>
      changeFlight("overrides", {
        ...overrides,
        spend,
      });
    const changeWrenchImps = impressions =>
      changeFlight("overrides", {
        ...overrides,
        impressions,
      });
    return {
      wrenchSpend: R.isNil(ourOverrides.spend) ? "" : ourOverrides.spend,
      wrenchImps: R.isNil(ourOverrides.impressions) ? "" : ourOverrides.impressions,
      clearWrenching: () => changeFlight("overrides", false),
      changeWrenchSpend,
      changeWrenchImps,
      calculateWrenchSpend: () =>
        changeWrenchSpend(
          // Do CPM math, but instead of dividing by 1000, divide by 10, round, then divide by 100
          // again, to keep it at max 2 decimal places
          Math.round(((cpm || 0) * (parseNumber(ourOverrides.impressions) || 0)) / 10) / 100
        ),
      calculateWrenchImps: () =>
        changeWrenchImps(cpm ? Math.round((1000 * parseNumber(ourOverrides.spend)) / cpm) : 0),
    };
  }, [changeFlight, overrides, cpm]);

  const [showMoveFlight, setShowMoveFlight] = useState(false);

  const [showCopiedToast, setShowCopiedToast] = useState(false);

  const [localCopiedFlight, setLocalCopiedFlight] = useState(copiedFlight);
  window.onstorage = ev => {
    let newCopiedFlight = JSON.parse(ev.newValue)?.copiedFlight;
    if (newCopiedFlight) {
      setSameTab(false);
      setLocalCopiedFlight(newCopiedFlight);
    }
  };

  const pasteFlight = useCallback(() => {
    const {
      copiedPlatform,
      copiedDescription,
      copiedContent,
      copiedAdditional,
      copiedDSP,
      copiedSpotPosition,
      copiedShowTitle,
      copiedCPM,
      copiedImpressions,
      copiedAudioBuyType,
      copiedSpend,
      copiedNumOfSpots,
      copiedSpotRate,
      copiedGeo,
    } = sameTab ? copiedFlight : localCopiedFlight;
    changeFlight("platform", copiedPlatform);
    changeFlight("description", copiedDescription);
    changeFlight("content", copiedContent);
    changeFlight("additional", copiedAdditional);
    changeFlight("dsp", copiedDSP);
    changeFlight("spotPosition", copiedSpotPosition);
    changeFlight("showTitle", copiedShowTitle);
    changeFlight("cpm", copiedCPM);
    changeFlight("impressions", copiedImpressions);
    changeFlight("audioBuyType", copiedAudioBuyType);
    changeFlight("spend", copiedSpend);
    changeFlight("numOfSpots", copiedNumOfSpots);
    changeFlight("spotRate", copiedSpotRate);
    changeFlight("geo", copiedGeo);
    if (copiedGeo) {
      setShowGeo(true);
    } else {
      setShowGeo(false);
    }
  }, [sameTab, copiedFlight, localCopiedFlight, changeFlight]);

  const defaultContent = networkInfo?.defaultContent || "";

  return (
    <div className="flightForm">
      <div className="header">
        <div className="title">{isReallyNew ? "New Flight" : "Modify Flight"}</div>
        {enableStreamingBuyingUpdates && !flight.id && (
          <TextToggleButton
            design="secondary"
            options={[
              { key: "historical", label: "Historical" },
              { key: "standard", label: "Standard" },
            ]}
            selectedOption={flightFormVersion}
            onChange={() => {
              if (flightFormVersion === "historical") {
                setFlightFormVersion("standard");
                changeFlight("useDescription:", false);
                changeFlight("description", "");
                changeFlight("useDescription", false);
                changeFlight("showTitle", "");
                changeFlight("enableStreamingBuyingUpdates", true);
                changeFlight("content", defaultContent);
              } else {
                setFlightFormVersion("historical");
                changeFlight("useDescription", true);
                changeFlight("content", "");
                changeFlight("additional", "");
                changeFlight("showTitle", "");
                changeFlight("enableStreamingBuyingUpdates", false);
              }
            }}
          />
        )}
        {enablePlacementSpecs &&
          isNew &&
          (Object.keys(localCopiedFlight).length > 0 || Object.keys(copiedFlight).length > 0) && (
            <div className="pasteButton">
              <OverlayTrigger
                overlay={<Tooltip>Paste Flight</Tooltip>}
                placement={OverlayTrigger.PLACEMENTS.LEFT.CENTER}
              >
                <Button size="sm" variant="dark" onClick={pasteFlight}>
                  <MdContentPaste />
                </Button>
              </OverlayTrigger>
            </div>
          )}
      </div>
      {timePeriod === "past" && (
        <Alert variant="danger">
          This flight has already ended. You should not make changes to it.
        </Alert>
      )}
      {timePeriod === "current" && (
        <Alert variant="info">
          This flight is currently running. Some fields shouldn't be changed mid-flight.
        </Alert>
      )}
      <Form>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Platform</Form.Label>
            <Select
              isDisabled={!isNew}
              isClearable
              placeholder="Platform"
              onChange={selection => {
                let resetDatesToUndefined = [];
                changeFlight("platform", selection ? selection.value : "");
                changeFlight("numOfSpots", "");
                changeFlight("spotRate", "");
                changeFlight("spend", selection?.value.includes("Bonus") ? 0 : "");
                changeFlight(
                  "cpm",
                  selection?.value.includes("Bonus") ? 0 : R.prop("cpm", networkBuyingOptions) || ""
                );
                changeFlight("audioBuyType", "");
                changeFlight("description", "");
                if (selection?.value.includes("Streaming") && flightFormVersion === "standard") {
                  changeFlight("content", defaultContent);
                } else {
                  changeFlight("content", "");
                }
                changeFlight("additional", "");
                changeFlight("spotPosition", "");
                changeFlight("episodeAirDates", "");
                changeFlight("dsp", "");
                changeFlight("showTitle", "");
                changeFlight("impressions", "");
                changeFlight("startDate", resetDatesToUndefined[0]);
                changeFlight("endDate", resetDatesToUndefined[0]);
                changeFlight("impressionsWindow", DEFAULT_IMPRESSIONS_WINDOW);
                changeFlight("geo", "");
                setShowGeo(false);
              }}
              options={platformOptions}
              value={platform ? { label: platform, value: platform } : null}
            />
          </Form.Group>
          {(platform || "").includes("RTB") && (
            <Form.Group as={Col} xs={6}>
              <Form.Label>DSP</Form.Label>
              <Select
                isDisabled={!isNew}
                isClearable
                placeholder="DSP"
                onChange={selection => {
                  changeFlight("dsp", selection ? selection.value : "");
                }}
                options={dspOptions}
                value={dsp ? { label: dsp, value: dsp } : null}
              />
            </Form.Group>
          )}
        </Form.Row>
        {platform && platform.includes("Audio") && audioBuyType !== null && (
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Audio Buy Type</Form.Label>
              <Select
                isDisabled={!isNew}
                isClearable
                placeholder="Audio Buy Type"
                onChange={selection => {
                  let resetDatesToUndefined = [];
                  changeFlight("audioBuyType", selection ? selection.value : "");
                  if (platform.includes("Bonus")) {
                    if (
                      selection?.value.includes("Episodic") ||
                      selection?.value.includes("Embedded")
                    ) {
                      changeFlight("numOfSpots", 0);
                      changeFlight("spotRate", 0);
                    } else {
                      changeFlight("numOfSpots", "");
                      changeFlight("spotRate", "");
                    }
                    changeFlight("spend", 0);
                    changeFlight("cpm", 0);
                  } else {
                    changeFlight("numOfSpots", "");
                    changeFlight("spotRate", "");
                    changeFlight("spend", "");
                    changeFlight("cpm", R.prop("cpm", networkBuyingOptions) || "");
                  }
                  changeFlight("description", "");
                  if (selection?.value === "Streaming Audio - DAI Impression") {
                    changeFlight("content", defaultContent);
                  } else {
                    changeFlight("content", "");
                  }
                  changeFlight("additional", "");
                  changeFlight("spotPosition", "");
                  changeFlight("episodeAirDates", "");
                  changeFlight("showTitle", "");
                  changeFlight("impressions", "");
                  changeFlight("startDate", resetDatesToUndefined[0]);
                  changeFlight("endDate", resetDatesToUndefined[0]);
                  changeFlight("impressionsWindow", DEFAULT_IMPRESSIONS_WINDOW);
                  changeFlight("geo", "");
                  setShowGeo(false);
                }}
                options={
                  platform.includes("Audio RTB") ? rtbAudioBuyTypeOptions : audioBuyTypeOptions
                }
                value={audioBuyType ? { label: audioBuyType, value: audioBuyType } : null}
              />
            </Form.Group>
          </Form.Row>
        )}
        {platform &&
          audioBuyType &&
          (audioBuyType.includes("Episodic") || audioBuyType.includes("Embedded")) && (
            <Form.Row>
              <Form.Group as={Col} className="formDatePicker">
                <Form.Label>Episode Air Dates</Form.Label>
                <OverlayTrigger
                  placement={OverlayTrigger.PLACEMENTS.RIGHT.TOP}
                  overlay={
                    <Tooltip>
                      <p>
                        Impressions are tracked from the first episode air date through{" "}
                        {DEFAULT_IMPRESSIONS_WINDOW} days past the last episode air date with the
                        exception of Vox and Barstool, which have more options for the window
                        length.
                      </p>
                      <p>
                        Note: You can view and modify currently selected dates in the Dates section
                        of the Calendar.
                      </p>
                    </Tooltip>
                  }
                >
                  <MdInfoOutline className="infoOutline" />
                </OverlayTrigger>
                <div className="multiDatePicker">
                  <DatePicker
                    multiple
                    render={<InputIcon />}
                    plugins={[<DatePanel />]}
                    value={episodeAirDates.split(", ").filter(date => !date.includes("_C:"))}
                    onChange={selection => {
                      let date_arr = [];
                      let savedSelection = !isNew ? flight.episodeAirDates?.split(", ") : [];
                      selection.forEach(element => {
                        let year = element.year.toString();
                        let month =
                          Math.floor((element.monthIndex + 1) / 10) === 0
                            ? "0".concat(element.monthIndex + 1)
                            : (element.monthIndex + 1).toString();
                        let day =
                          Math.floor(element.day / 10) === 0
                            ? "0".concat(element.day)
                            : element.day.toString();

                        let selectedDate = year.concat("-", month, "-", day);
                        // remove cancelled date if it is selected
                        if (!isNew && savedSelection.includes("_C:".concat(selectedDate))) {
                          savedSelection = savedSelection.filter(
                            date => !date.includes(selectedDate)
                          );
                        }
                        date_arr.push(selectedDate);
                      });

                      // add cancelled dates from savedSelection
                      let cancelledDates = [];
                      if (!isNew) {
                        savedSelection.forEach(date => {
                          if (date.includes("_C:")) {
                            cancelledDates.push(date.replace("_C:", ""));
                          } else if (!date_arr.includes(date)) {
                            cancelledDates.push(date);
                          }
                        });
                      }
                      date_arr.sort();

                      let newStartDate = date_arr[0];
                      // calculating value for endDate to be 30 days after last spot air date by default
                      let newEndDate;
                      if (selection.length > 0) {
                        newEndDate = new Date(date_arr[date_arr.length - 1].replace(/-/g, "/"));
                        // impressionsWindow will be 30 by default but may be changed for VOX and BARSTOOL before picking episodeAirDates
                        newEndDate.setDate(newEndDate.getDate() + impressionsWindow);
                      }

                      // want to add cancelled dates in after setting startDate and calculating endDate
                      if (!isNew && cancelledDates) {
                        cancelledDates.forEach(date => {
                          date_arr.push(date);
                        });
                        date_arr.sort();

                        for (let i = 0; i < date_arr.length; i++) {
                          let date = date_arr[i];
                          if (cancelledDates.includes(date)) {
                            date_arr[i] = "_C:".concat(date);
                          }
                        }
                      }

                      changeFlight("episodeAirDates", selection ? date_arr.join(", ") : "");
                      changeFlight("startDate", newStartDate);
                      changeFlight(
                        "endDate",
                        selection.length > 0 ? Dfns.format(DATE_FORMAT, newEndDate) : date_arr[0]
                      );
                    }}
                  />
                </div>
              </Form.Group>
              {shouldHaveImpressionsWindowOptions && (
                <Form.Group as={Col}>
                  <Form.Label>Impressions Window</Form.Label>
                  <Select
                    placeholder="Impressions Window"
                    onChange={selection => {
                      changeFlight("impressionsWindow", selection.value);
                      // only update endDate if there are valid episodeAirDates selected
                      let date_arr = episodeAirDates
                        .split(", ")
                        .filter(date => !date.includes("_C:"));
                      if (date_arr.length > 0) {
                        let lastAirDate = date_arr[date_arr.length - 1];
                        let newEndDate = new Date(lastAirDate.replace(/-/g, "/"));

                        newEndDate.setDate(newEndDate.getDate() + selection.value);
                        changeFlight("endDate", Dfns.format(DATE_FORMAT, newEndDate));
                      }
                    }}
                    options={IMPRESSIONS_WINDOW_OPTIONS}
                    value={
                      impressionsWindow
                        ? { value: impressionsWindow, label: `${impressionsWindow} days` }
                        : null
                    }
                  />
                </Form.Group>
              )}
            </Form.Row>
          )}
        {platform &&
          (!platform.includes("Audio") ||
            audioBuyType?.includes("Impression") ||
            audioBuyType?.includes("Old Audio Buy")) && (
            <Form.Row>
              <Form.Group as={Col} className="formDatePicker">
                <Form.Label>Dates</Form.Label>
                <div>
                  <DateRangePicker
                    small
                    regular
                    appendToBody
                    anchorRight
                    startDate={startDate}
                    endDate={endDate}
                    startDateId="streamingBuyingStartDate"
                    endDateId="streamingBuyingEndDate"
                    onChange={({ startDate, endDate }) => {
                      changeFlight("startDate", startDate);
                      changeFlight("endDate", endDate);
                    }}
                  />
                </div>
              </Form.Group>
            </Form.Row>
          )}
        {isPodcast && (
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Show Title</Form.Label>
              <SuggestionInput
                isDisabled={!isNew}
                placeholder="Show Title"
                formatCreateLabel={value => `New show title: ${value}`}
                onChange={async option => {
                  let trimmedOption = option.trim();
                  if (
                    trimmedOption &&
                    trimmedOption !== showTitle &&
                    R.none(({ value }) => value === trimmedOption, showTitleOptions)
                  ) {
                    try {
                      await setAreYouSure({
                        title: "Making New Show Title",
                        message:
                          "You are about to make a new show title that will be available for all clients. Make sure that it is not already listed in the drop-down menu.  Put any additional or client-specific information in the description field.",
                        okayText: "Use new show title",
                      });
                      changeFlight("isNewShowTitle", true);
                    } catch (e) {
                      changeFlight("isNewShowTitle", false);
                      return;
                    }
                  }

                  if (trimmedOption === "") {
                    changeFlight("isNewShowTitle", false);
                  }
                  changeFlight("showTitle", trimmedOption);
                }}
                options={R.sortBy(R.prop("label"), showTitleOptions)}
                value={showTitle}
              />
            </Form.Group>
          </Form.Row>
        )}
        {!enableStreamingBuyingUpdates &&
          flightFormVersion === "historical" &&
          platform &&
          (!platform.includes("Audio") || audioBuyType) && (
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Description</Form.Label>
                <SuggestionInput
                  isDisabled={!isNew}
                  placeholder="Description"
                  blankLabel="No description set"
                  formatCreateLabel={value => `New description: ${value}`}
                  onChange={async option => {
                    let trimmedOption = option.trim();
                    if (
                      trimmedOption &&
                      trimmedOption !== description &&
                      R.none(({ value }) => value === trimmedOption, descriptionOptions)
                    ) {
                      try {
                        await setAreYouSure({
                          title: "Making New Description",
                          message:
                            "You are about to make a new description. You must make sure you follow our best practices. If you're unsure if your new description is appropriate, please reach out to Justin or in the #ops_team slack channel.",
                          okayText: "Use new description",
                        });
                      } catch (e) {
                        return;
                      }
                    }

                    changeFlight("description", trimmedOption);
                  }}
                  options={descriptionOptions}
                  value={description}
                />
              </Form.Group>
            </Form.Row>
          )}
        {enableStreamingBuyingUpdates &&
          flightFormVersion === "historical" &&
          platform &&
          (!platform.includes("Audio") || audioBuyType) && (
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Description</Form.Label>
                <Select
                  isDisabled={!isNew}
                  isClearable
                  placeholder="Description"
                  blankLabel="No description set"
                  onChange={selection => {
                    changeFlight("description", selection ? selection.value : "");
                  }}
                  options={descriptionOptions}
                  value={description ? { label: description, value: description } : null}
                />
              </Form.Group>
            </Form.Row>
          )}
        {flightFormVersion === "standard" &&
          !isPodcast &&
          platform &&
          (!platform.includes("Audio") || audioBuyType) && (
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Content</Form.Label>
                <Select
                  isDisabled={!isNew}
                  isClearable
                  placeholder="Content"
                  onChange={selection => {
                    changeFlight("content", selection ? selection.value : "");
                  }}
                  options={contentOptions}
                  value={content ? { label: content, value: content } : null}
                />
              </Form.Group>
            </Form.Row>
          )}
        {flightFormVersion === "standard" &&
          platform &&
          (!platform.includes("Audio") || audioBuyType) && (
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Additional Details</Form.Label>
                <SuggestionInput
                  isDisabled={!isNew}
                  placeholder="Additional Details"
                  blankLabel="No additional details set"
                  formatCreateLabel={value => `New additional detail: ${value}`}
                  onChange={option => {
                    changeFlight("additional", option.trim());
                  }}
                  options={additionalOptions}
                  value={additional}
                />
              </Form.Group>
            </Form.Row>
          )}
        {isPodcast && (
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Spot Position</Form.Label>
              <Select
                isDisabled={!isNew}
                isClearable
                placeholder="Spot Position"
                onChange={selection => {
                  changeFlight("spotPosition", selection ? selection.value : "");
                }}
                options={SPOT_POSITION_OPTIONS}
                value={spotPosition ? { label: spotPosition, value: spotPosition } : null}
              />
            </Form.Group>
          </Form.Row>
        )}
        {enablePlacementSpecs &&
          isNew &&
          platform &&
          (!platform.includes("Audio") || audioBuyType) && (
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Specify Targeting</Form.Label>
                <div>
                  <Form.Check
                    inline
                    label="Geo"
                    type="checkbox"
                    checked={showGeo}
                    onChange={() => {
                      setShowGeo(!showGeo);
                      changeFlight("geo", "");
                    }}
                  ></Form.Check>
                </div>
              </Form.Group>
            </Form.Row>
          )}
        {enablePlacementSpecs &&
          showGeo &&
          platform &&
          (!platform.includes("Audio") || audioBuyType) && (
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Geo Targeting</Form.Label>
                <Select
                  isDisabled={!isNew}
                  isClearable
                  className="geoOptions"
                  placeholder="Select Geo Targeting"
                  value={geo ? { label: geo, value: geo } : null}
                  options={geoOptions}
                  onChange={selection => {
                    changeFlight("geo", selection ? selection.value : "");
                  }}
                  menuPlacement={"auto"}
                ></Select>
              </Form.Group>
            </Form.Row>
          )}
        {derivedNetwork && (
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Derived Network</Form.Label>
              <Form.Control
                readOnly
                className="derivedNetworkInput"
                type="text"
                defaultValue={derivedNetwork}
                onClick={() => {
                  /// https://developer.mozilla.org/en-US/docs/Mozilla/Add-ons/WebExtensions/Interact_with_the_clipboard
                  navigator.clipboard.writeText(`${company} ${derivedNetwork || ""}`);
                  setShowCopiedToast(true);
                }}
              />
            </Form.Group>
          </Form.Row>
        )}
        <Toast
          className="copiedToast"
          onClose={() => setShowCopiedToast(false)}
          show={showCopiedToast}
          delay={1000}
          autohide
        >
          <Toast.Body>Copied!</Toast.Body>
        </Toast>
        {platform && (audioBuyType?.includes("Episodic") || audioBuyType?.includes("Embedded")) && (
          <Form.Row>
            <Form.Group as={Col} xs={9}>
              <Form.Label>Spot Rate</Form.Label>
              <Form.Control
                readOnly={isBonus}
                type="number"
                value={spotRate}
                onChange={e => {
                  changeFlight("spotRate", e.target.value);
                  if (audioBuyType?.includes("Episodic") || audioBuyType?.includes("Embedded")) {
                    let podcastSpend = calculatePodcastSpend(e.target.value, numOfSpots);
                    changeFlight("spend", podcastSpend);
                    changeFlight("impressions", calculateImpressions(podcastSpend, cpm));
                  }
                }}
                onBlur={e => {
                  changeFlight(
                    "spotRate",
                    parseFloat((e.target.value || "").replace(/[^\d.]/g, "") || 0)
                  );
                }}
              />
            </Form.Group>
            <Form.Group as={Col} xs={3}>
              <Form.Label># of Spots</Form.Label>
              <Form.Control
                readOnly={isBonus}
                type="number"
                value={numOfSpots}
                onChange={e => {
                  changeFlight("numOfSpots", e.target.value);
                  if (audioBuyType?.includes("Episodic") || audioBuyType?.includes("Embedded")) {
                    let podcastSpend = calculatePodcastSpend(spotRate, e.target.value);
                    changeFlight("spend", podcastSpend);
                    changeFlight("impressions", calculateImpressions(podcastSpend, cpm));
                  }
                }}
                onBlur={e => {
                  changeFlight(
                    "numOfSpots",
                    parseFloat((e.target.value || "").replace(/[^\d.]/g, "") || 0)
                  );
                }}
              />
            </Form.Group>
          </Form.Row>
        )}
        {platform && (!platform.includes("Audio") || audioBuyType) && (
          <Form.Row>
            <Form.Group as={Col} xs={9}>
              <Form.Label>Spend</Form.Label>
              <Form.Control
                readOnly={
                  isBonus ||
                  audioBuyType?.includes("Episodic") ||
                  audioBuyType?.includes("Embedded")
                }
                type="number"
                value={spend}
                onChange={e => {
                  changeFlight("spend", e.target.value);
                  if (
                    !platform?.includes("Audio") ||
                    audioBuyType?.includes("Impression") ||
                    audioBuyType?.includes("Old Audio Buy")
                  ) {
                    changeFlight("impressions", calculateImpressions(e.target.value, cpm));
                  }
                }}
                onBlur={e => {
                  changeFlight(
                    "spend",
                    parseFloat((e.target.value || "").replace(/[^\d.]/g, "") || 0)
                  );
                }}
              />
            </Form.Group>
          </Form.Row>
        )}
        {platform && (!platform.includes("Audio") || audioBuyType) && (
          <Form.Row>
            <Form.Group as={Col} xs={3}>
              <Form.Label>CPM</Form.Label>
              <Form.Control
                readOnly={isBonus}
                type="number"
                value={cpm}
                onChange={e => {
                  changeFlight("cpm", e.target.value);
                  changeFlight("impressions", calculateImpressions(spend, e.target.value));
                }}
                onBlur={e => {
                  changeFlight(
                    "cpm",
                    parseFloat((e.target.value || "").replace(/[^\d.]/g, "") || 0)
                  );
                }}
              />
            </Form.Group>
          </Form.Row>
        )}
        {platform && (!platform.includes("Audio") || audioBuyType) && (
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Impressions</Form.Label>
              <Form.Control
                readOnly={
                  !isBonus ||
                  (!isBonus &&
                    !audioBuyType?.includes("Episodic") &&
                    !audioBuyType?.includes("Embedded"))
                }
                type="number"
                value={impressions}
                onChange={e => {
                  changeFlight("impressions", e.target.value);
                }}
                onBlur={e => {
                  changeFlight(
                    "impressions",
                    Math.round(parseFloat((e.target.value || "").replace(/[^\d.]/g, "") || 0))
                  );
                }}
              />
            </Form.Group>
            {(audioBuyType?.includes("Impression") ||
              audioBuyType?.includes("Old Audio Buy") ||
              !platform?.includes("Audio")) && (
              <Form.Group as={Col} xs="auto">
                <Form.Label>&nbsp;</Form.Label>
                <div className="buttonRow">
                  <OverlayTrigger
                    placement={OverlayTrigger.PLACEMENTS.LEFT.CENTER}
                    delay={500}
                    overlay={
                      <Tooltip>
                        When you hit this button, it takes the Spend, divides by 7, then multiplies
                        by the number of days in the flight's date range.
                      </Tooltip>
                    }
                  >
                    <div>
                      <Button className="prorate" disabled={!proratable} onClick={() => prorate()}>
                        Prorate
                      </Button>
                    </div>
                  </OverlayTrigger>
                  {!R.isNil(cpm) && (
                    <Button
                      variant={overrides ? "dark" : "outline-dark"}
                      onClick={() => setShowWrench(R.not)}
                    >
                      <MdBuild />
                    </Button>
                  )}
                </div>
              </Form.Group>
            )}
          </Form.Row>
        )}
        {showWrench &&
          (audioBuyType?.includes("Impression") ||
            audioBuyType?.includes("Old Audio Buy") ||
            !platform?.includes("Audio")) && (
            <div className="wrenchSection">
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>PDF Spend</Form.Label>
                  <Form.Control
                    size="sm"
                    type="number"
                    value={wrenchSpend}
                    onBlur={() => changeWrenchSpend(parseNumber(wrenchSpend))}
                    onChange={e => {
                      changeWrenchSpend(e.target.value);
                    }}
                  />
                </Form.Group>
                <Form.Group as={Col} xs="auto">
                  <Form.Label>&nbsp;</Form.Label>
                  <Button
                    block
                    size="sm"
                    disabled={R.isNil(wrenchImps) || wrenchImps === ""}
                    variant="primary"
                    onClick={calculateWrenchSpend}
                  >
                    Calculate
                  </Button>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>PDF Impressions</Form.Label>
                  <Form.Control
                    size="sm"
                    type="number"
                    value={wrenchImps}
                    onBlur={() => changeWrenchImps(parseNumber(wrenchImps))}
                    onChange={e => {
                      changeWrenchImps(e.target.value);
                    }}
                  />
                </Form.Group>
                <Form.Group as={Col} xs="auto">
                  <Form.Label>&nbsp;</Form.Label>
                  <Button
                    block
                    size="sm"
                    disabled={R.isNil(wrenchSpend) || wrenchSpend === ""}
                    variant="primary"
                    onClick={calculateWrenchImps}
                  >
                    Calculate
                  </Button>
                </Form.Group>
              </Form.Row>
              <div className="hint">
                When you press "Calculate", it will fill that box using the CPM and the value in the{" "}
                <em>other</em> box.
              </div>
              <Form.Row>
                <Form.Group as={Col}>
                  <Button size="sm" variant="outline-danger" onClick={clearWrenching}>
                    Clear Overrides
                  </Button>
                </Form.Group>
              </Form.Row>
            </div>
          )}
      </Form>
      {isMultiProperty && (
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Current Properties</Form.Label>
            <div className={`propertyList${timePeriod === "past" ? " past" : ""}`}>
              {currentProperties.length
                ? R.pipe(
                    R.sortBy(R.identity),
                    R.map(property => {
                      return (
                        <span
                          key={property}
                          onClick={() => timePeriod !== "past" && removeProperty(property)}
                        >
                          {property}
                        </span>
                      );
                    })
                  )(currentProperties)
                : "None"}
            </div>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Add Property</Form.Label>
            <Select
              placeholder="New Property"
              isDisabled={timePeriod === "past"}
              value={null}
              onChange={selection => addProperty(selection.value)}
              options={propertyOptions}
            />
          </Form.Group>
        </Form.Row>
      )}
      {!!(subFlights || []).length && (
        <div className="subFlightList">
          <div className="subFlight headers">
            <div className="property">Property</div>
            <div className="date">Start</div>
            <div className="date">End</div>
          </div>
          {R.pipe(
            R.sortWith([
              // TODO: play with this to make nulls and such sort nicely. I'm not sure at this
              // moment if I want them above or below and whether I want start/end dates
              // ascending/descending.
              R.descend(el => (el.id ? el.startDate || "" : "3")),
              R.descend(el => el.endDate || ""),
              R.ascend(R.prop("shortCode")),
            ]),
            R.map(({ id, shortCode, startDate, endDate, ending, zeroLength }) => {
              let isNewSubFlight = !id;
              let startDisp, endDisp;
              let current = true;
              let changed = false;
              if (isNewSubFlight) {
                changed = true;
                if (isNew) {
                  startDisp = <em>Same as flight</em>;
                } else {
                  startDisp = <strong>Today</strong>;
                }
                endDisp = <em>Same as flight</em>;
              } else {
                if (startDate) {
                  startDisp = toPrettyDate(startDate);
                  if (startDate > TODAY) {
                    current = false;
                  }
                } else {
                  startDisp = <em>Same as flight</em>;
                }
                if (ending && startDate < TODAY) {
                  changed = true;
                  endDisp = <strong>YESTERDAY</strong>;
                } else if (endDate) {
                  endDisp = toPrettyDate(endDate);
                  if (endDate < TODAY) {
                    current = false;
                  }
                } else {
                  endDisp = <em>Same as flight</em>;
                }
              }
              return (
                <div key={id || shortCode} className={`subFlight${current ? "" : " notCurrent"}`}>
                  <div className={`property${changed ? " changed" : ""}`}>
                    {shortCode} {zeroLength && <small>(0 len)</small>}
                  </div>
                  <div className="date">{startDisp}</div>
                  <div className="date">{endDisp}</div>
                </div>
              );
            })
          )(subFlights)}
        </div>
      )}
      <Form>
        <Form.Row>
          <Form.Group as={Col} className="canceledRow">
            <Button size="sm" variant="danger" onClick={cancelFlight}>
              {canceled ? "Un-cancel" : "Cancel Flight"}
            </Button>
            {canceled ? (
              <div className="label">Canceled {canceledLabel}</div>
            ) : (
              <div className="label" />
            )}
            {!isNew && (
              <OverlayTrigger
                overlay={<Tooltip>Move Flight</Tooltip>}
                placement={OverlayTrigger.PLACEMENTS.LEFT.CENTER}
              >
                <Button
                  variant="outline-primary"
                  size="sm"
                  disabled={hasChanges}
                  onClick={() => setShowMoveFlight(true)}
                >
                  <MdKeyboardTab />
                </Button>
              </OverlayTrigger>
            )}
          </Form.Group>
        </Form.Row>
      </Form>
      <div className="buttonRow">
        <Button variant={hasChanges ? "danger" : "dark"} onClick={onCancel}>
          {hasChanges ? "Discard Changes" : "Close"}
        </Button>
        <div className="saveContainer">
          {isReallyNew && (
            <Form.Check
              inline
              className="createAnother"
              // Though I hate using id attributes in React, the label on this checkbox doesn't work
              // properly without it
              id="createAnotherCheckbox"
              label="Create Another"
              type="checkbox"
              checked={createAnother}
              onChange={e => setCreateAnother(e.target.checked)}
            />
          )}
          <Button disabled={!hasChanges} onClick={onSave}>
            Save
          </Button>
        </div>
      </div>
      {showMoveFlight && (
        <MoveFlightModal
          flight={flight}
          onClose={() => setShowMoveFlight(false)}
          onSave={refetchOrder}
        />
      )}
    </div>
  );
};

export default FlightForm;
