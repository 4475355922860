import { useState, useMemo, useRef, useEffect } from "react";
import * as R from "ramda";
import ChartContainer from "../Components/ChartContainer";
import { CreativeMap, CreativeMapItem, useCreativeMap } from "../redux/creative";
import { getLiveCreatives } from "../LinearBuying/linearBuyingUtils";
import {
  Img,
  Skeleton,
  BarGraphSkeleton,
  AxesSkeleton,
  DropdownToggleType,
  Dropdown,
} from "../Components";
import AttributesChart from "../CreativeInsights/AttributesChart";
import { awaitJSON, CreativeLambdaFetch } from "../utils/fetch-utils";
import { getFillColors } from "../CreativeInsights/CreativeInsights";
import { StateSetter } from "../utils/types";
import {
  CreativeInsightsImportanceData,
  Importance,
} from "@blisspointmedia/bpm-types/dist/Creative";
import "./CreativeSnapshot.scss";

interface TitleToggleProps {
  activeTab: string;
  setActiveTab: StateSetter<string>;
}

interface CreativeAttributesProps {
  importanceData: Importance[];
  suppressedAttributes: Set<string>;
  kpi: string;
}

interface CreativeSnapshotProps {
  company: string;
  start: string;
  end: string;
  kpi: string;
}

interface LiveCreativesProps {
  company: string;
  start: string;
  end: string;
}

const MEDIA_TYPE_OPTIONS = [
  { label: "Streaming", value: "streaming" },
  { label: "Audio", value: "audio" },
  { label: "Display", value: "display" },
  { label: "Linear TV", value: "linear" },
];

const getLiveCreativesByMediaType = (
  creativeMap: CreativeMap | undefined,
  start: string,
  end: string
) => {
  if (!creativeMap || R.isEmpty(creativeMap)) {
    return;
  }
  const byMediaType = {};
  const liveCreatives = getLiveCreatives(creativeMap, start, end);
  for (let creativeItem of liveCreatives) {
    for (let mediaType of creativeItem.media_types) {
      byMediaType[mediaType] = [...(byMediaType[mediaType] || []), creativeItem];
    }
  }
  return byMediaType;
};

const TitleToggle: React.FC<TitleToggleProps> = ({ activeTab, setActiveTab }) => {
  return (
    <div className="creativeSnapshotToggle">
      <div
        className={`creativeSnapshotToggleButton${
          activeTab === "creativeInsights" ? " active" : ""
        }`}
        onClick={() => setActiveTab("creativeInsights")}
      >
        Creative Insights
      </div>
      <div
        className={`creativeSnapshotToggleButton${activeTab === "liveCreatives" ? " active" : ""}`}
        onClick={() => setActiveTab("liveCreatives")}
      >
        Live Creatives
      </div>
    </div>
  );
};

const CreativeAttributes: React.FC<CreativeAttributesProps> = ({
  importanceData,
  suppressedAttributes,
  kpi,
}) => {
  const fillColors = useMemo(() => {
    return getFillColors(importanceData, suppressedAttributes);
  }, [importanceData, suppressedAttributes]);

  return (
    <div className="homePageCreativeAttributes">
      {!R.isEmpty(importanceData) ? (
        <AttributesChart
          selectedAttribute={""}
          handleAttributeClick={() => {}}
          setSelectedTag={() => {}}
          fillColors={fillColors}
          attributeImportanceData={importanceData}
          suppressedAttributes={suppressedAttributes}
          kpi={kpi}
          showDownloadButton={false}
        />
      ) : (
        <Skeleton>
          <BarGraphSkeleton barWidth={14} gutter={3} verticalPadding={5} />
          <AxesSkeleton size={3} />
        </Skeleton>
      )}
    </div>
  );
};

const LiveCreatives: React.FC<LiveCreativesProps> = ({ company, start, end }) => {
  const [selectedMediaType, setSelectedMediaType] = useState("streaming");
  const liveCreativesRef = useRef<HTMLDivElement | null>(null);
  const { creativeMap } = useCreativeMap({
    company,
    mediaTypes: ["streaming", "audio", "linear", "display"],
  });

  const liveCreativesByMediaType = useMemo(() => {
    return getLiveCreativesByMediaType(creativeMap, start, end);
  }, [creativeMap, start, end]);
  const tableData = (liveCreativesByMediaType || {})[selectedMediaType] || [];

  return liveCreativesByMediaType ? (
    <div className="homePageLiveCreatives" ref={liveCreativesRef}>
      <div className="liveCreativesContainer">
        <div className="creativeSelect">
          <Dropdown
            type={DropdownToggleType.OUTLINED}
            design="secondary"
            size="sm"
            value={selectedMediaType}
            options={MEDIA_TYPE_OPTIONS}
            onChange={option => setSelectedMediaType(option)}
          />
        </div>
        {R.isEmpty(tableData) ? (
          <div className="noLiveCreatives">No live creatives for this media type.</div>
        ) : (
          <>
            {tableData.map(row => {
              const { isci, length, name, file } = row as CreativeMapItem;
              return (
                <div key={isci} className="liveCreativesItem">
                  <div className="thumbnail">
                    <Img
                      title={file}
                      src={`https://cdn.blisspointmedia.com/creatives/${isci}.png`}
                      unloader={<div className="creativeThumbnailUnloader">{isci}</div>}
                    />
                  </div>
                  <div className="nonImg">
                    <div className="creativeTitle">{name}</div>
                    <div className="creativeLength">
                      <strong>Length: </strong>
                      {length}s
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  ) : (
    <Skeleton>
      <BarGraphSkeleton barWidth={14} gutter={3} verticalPadding={5} />
      <AxesSkeleton size={3} />
    </Skeleton>
  );
};

const CreativeSnapshot: React.FC<CreativeSnapshotProps> = ({ company, start, end }) => {
  const [activeTab, setActiveTab] = useState("creativeInsights");
  const [kpi, setKpi] = useState<string>("");
  const [attributeImportanceData, setAttributeImportanceData] = useState<
    Record<string, Importance[]>
  >({});
  const [suppressedAttributes, setSuppressedAttributes] = useState<Set<string>>(new Set());

  useEffect(() => {
    if (company) {
      (async () => {
        try {
          let res = await CreativeLambdaFetch("/getInitialKpi", {
            params: {
              company,
            },
          });
          const kpi = await awaitJSON<string>(res);
          setKpi(kpi);
        } catch (e) {
          setKpi("");
          setActiveTab("liveCreatives");
        }
      })();
    }
  }, [company]);

  useEffect(() => {
    if (company && kpi) {
      (async () => {
        try {
          let res = await CreativeLambdaFetch("/getCreativeInsightsImportanceData", {
            params: {
              company,
              kpi: kpi,
            },
          });
          const parsedRes = await awaitJSON<{
            creativeInsights: CreativeInsightsImportanceData;
            attributeTypeBinomial: { [attribute: string]: boolean };
            lastModified: Date | null;
          }>(res);
          setAttributeImportanceData(current => ({
            ...current,
            [kpi]: parsedRes.creativeInsights.attributeImportanceData,
          }));
        } catch (e) {
          setAttributeImportanceData(current => ({ ...current }));
        }
      })();
    }
  }, [company, kpi]);

  useEffect(() => {
    (async () => {
      try {
        let res = await CreativeLambdaFetch("/getSuppressedAttributes", {
          params: { company },
        });

        let attributes = await awaitJSON<string>(res);
        let set = attributes !== null ? new Set<string>(attributes.split(",")) : new Set<string>();
        setSuppressedAttributes(set);
      } catch (e) {
        setSuppressedAttributes(new Set<string>());
      }
    })();
  }, [company]);

  const importanceData = attributeImportanceData[kpi] || [];

  return (
    <ChartContainer
      title={<TitleToggle activeTab={activeTab} setActiveTab={setActiveTab} />}
      leftActions={
        <div>
          <a
            className="creativeSnapshotLeftActions"
            href={
              activeTab === "creativeInsights"
                ? `https://app.blisspointmedia.com/${company}/creative_insights`
                : `https://app.blisspointmedia.com/${company}/creatives`
            }
          >
            {activeTab === "creativeInsights" ? "creative insights page" : "creatives page"}
          </a>
        </div>
      }
    >
      <div className="creativeSnapshot">
        {activeTab === "creativeInsights" ? (
          <CreativeAttributes
            importanceData={importanceData}
            suppressedAttributes={suppressedAttributes}
            kpi={kpi}
          />
        ) : (
          <LiveCreatives company={company} start={start} end={end} />
        )}
      </div>
    </ChartContainer>
  );
};

export default CreativeSnapshot;
