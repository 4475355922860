import React, { useMemo } from "react";
import "./DatePickerButton.scss";

import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import { DateRange } from "../../../utils/types";
import { MdCalendarToday } from "react-icons/md";
import { BiCaretDown } from "react-icons/bi";

const PRETTY_DATE_FORMAT = "M/d/yy";

const makePrettyDate = (date?: string) =>
  date ? R.pipe(Dfns.parseISO, Dfns.format(PRETTY_DATE_FORMAT))(date) : "___";

interface DatePickerButtonProps {
  reference?: React.RefObject<HTMLDivElement>;
  setShow?: (show: boolean, save?: boolean) => void;
  range?: DateRange;
  fullWeeksOnly?: boolean;
  setFocus?: React.Dispatch<React.SetStateAction<"START" | "END">>;
  className?: string;
}

export const DatePickerButton: React.FC<DatePickerButtonProps> = React.memo(
  ({ reference, setShow, fullWeeksOnly, range, setFocus, className }) => {
    const { start, end } = range || {};

    const resolvedClassName = useMemo(() => {
      let classes = ["DatePickerButton"];
      if (className) {
        classes.push(className);
      }
      if (fullWeeksOnly) {
        classes.push("fullWeeksOnly");
      }
      return classes.join(" ");
    }, [className, fullWeeksOnly]);

    const dateRangeDisplay = useMemo(
      () => (
        <>
          <span className="singleDate">{`${fullWeeksOnly ? "Week of " : ""}${makePrettyDate(
            start
          )}`}</span>{" "}
          –{" "}
          <span className="singleDate" onClick={() => (setFocus ? setFocus("END") : null)}>
            {`${fullWeeksOnly ? "Week of " : ""}${makePrettyDate(end)}`}
          </span>
        </>
      ),
      [fullWeeksOnly, start, end, setFocus]
    );

    return (
      <div
        ref={reference}
        className={resolvedClassName}
        onClick={() => (setShow ? setShow(true) : null)}
      >
        <div className="dpbIcon">
          <MdCalendarToday />
        </div>
        <div className="dpbRange">{dateRangeDisplay}</div>
        <div className="dpbCaret">
          <BiCaretDown />
        </div>
      </div>
    );
  }
);

export default DatePickerButton;
