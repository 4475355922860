import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./BudgetIntakeTool.scss";
import {
  ModalEditTable,
  Page,
  PendingChangesControls,
  PendingChangesPanel,
  Skeleton,
  TableSkeleton,
  ThreeDotsButton,
} from "../Components";
import { useSetError } from "../redux/modals";

import { ToolsLambdaFetch, awaitJSON } from "../utils/fetch-utils";
import * as R from "ramda";
import { useSelector } from "react-redux";
import { currentCompanySelector } from "../redux/company";
import { emailSelector } from "../redux/user";
import { Dropdown } from "react-bootstrap";

import ImportModal from "./Modals/ImportModal";
import { exportToExcel, processBulkImport } from "./excelUtils";

interface GetBudgetCustomSegmentsList {
  segmentNames: string[];
}

export interface GetBudgetIntakeRowsResponse {
  mappedBudgetEntries: any[];
}

interface BudgetIntakeRowInternal {
  id: number;
  company: string;
  start_date: string;
  end_date: string;
  [key: string]: string | number;
  goal_name: string;
  goal_value: number;
}

const BudgetIntakeTool = React.memo(
  (): JSX.Element => {
    const [tableData, setTableData] = useState<BudgetIntakeRowInternal[]>();
    const [originalTableData, setOriginalTableData] = useState<BudgetIntakeRowInternal[]>();
    const [originalTableDataMap, setOriginalTableDataMap] = useState<Record<string, any>>();
    const [customSegmentsList, setCustomSegmentsList] = useState<string[]>();
    const [budgetIntakeHeadersCustom, setBudgetIntakeHeadersCustom] = useState<any[]>([]);
    const [budgetIntakeSelectorOptionsPull, setBudgetIntakeSelectorOptions] = useState({});

    const company = useSelector(currentCompanySelector);
    const email: string = useSelector(emailSelector);
    const setError = useSetError();

    const [showTableData, setShowTableData] = useState(false);
    const [saving, setSaving] = useState(false);
    const [updatedRows, setUpdatedRows] = useState<BudgetIntakeRowInternal[]>([]);
    const [deletedRows, setDeletedRows] = useState<BudgetIntakeRowInternal[]>([]);
    const [deletedBudget, setDeletedBudget] = useState<BudgetIntakeRowInternal[]>([]);
    const [newRows, setNewRows] = useState<BudgetIntakeRowInternal[]>([]);

    const [showPendingChanges, setShowPendingChanges] = useState(false);
    const [showBulkImportModal, setShowBulkImportModal] = useState(false);
    const [invalidText, setInvalidText] = useState<string>();

    const getFreshBudgetIntakeData = useCallback(async () => {
      try {
        setShowTableData(false);

        let resCustom = await ToolsLambdaFetch("/getBudgetCustomMapping", {
          params: {
            company,
          },
        });
        let jsonResponseCustom = await awaitJSON<GetBudgetCustomSegmentsList>(resCustom);
        setCustomSegmentsList(jsonResponseCustom.segmentNames);

        let res = await ToolsLambdaFetch("/getBudgetIntakeData", {
          params: {
            company,
          },
        });
        let jsonResponse = await awaitJSON<GetBudgetIntakeRowsResponse>(res);
        setTableData(jsonResponse.mappedBudgetEntries);
        setOriginalTableData(jsonResponse.mappedBudgetEntries);

        let originalTableDataMap: Record<string, any> = {};
        for (let row of jsonResponse.mappedBudgetEntries) {
          originalTableDataMap[row.id] = row;
        }
        setOriginalTableDataMap(originalTableDataMap);
        setShowTableData(true);
        setDeletedRows([]);

        let resOptions = await ToolsLambdaFetch("/getBudgetIntakeLabelOptions", {
          params: {
            company,
          },
        });
        let resultsOptions = await awaitJSON(resOptions);

        let goalNameOptions = [
          { label: "Budget", value: "Budget" },
          { label: "Revenue", value: "Revenue" },
        ];
        let updatedOptions = {
          ...resultsOptions,
          goalNameOptions,
        };
        setBudgetIntakeSelectorOptions(updatedOptions);
      } catch (e) {
        const reportError = e as Error;
        setError({
          message: `Failed to get Budget Intake data. ERROR: ${reportError.message}`,
          reportError,
        });
      }
    }, [company, setError]);

    useEffect(() => {
      const dateHeaders = [
        {
          label: "Start Date",
          field: "start_date",
          type: "day",
          flex: 1,
          modalRow: 0,
          modalFlex: 1,
        },
        {
          label: "End Date",
          field: "end_date",
          type: "day",
          flex: 1,
          modalRow: 1,
          modalFlex: 1,
        },
      ];
      const goalHeaders = [
        {
          label: "Goal Name",
          field: "goal_name",
          type: "select",
          flex: 1,
          modalRow: 1,
          modalFlex: 1,
          options: "goalNameOptions",
        },
        {
          label: "Goal Value",
          field: "goal_value",
          type: "currency",
          flex: 1,
          modalRow: 1,
          modalFlex: 1,
        },
      ];
      const segments = customSegmentsList || [];
      const segmentHeaders = segments.map((segment, index) => ({
        label: segment,
        field: segment,
        type: "select",
        flex: 1,
        modalRow: index % 2,
        modalFlex: 1,
        options: segment,
      }));
      const combinedHeaders = [...dateHeaders, ...segmentHeaders, ...goalHeaders];
      setBudgetIntakeHeadersCustom(combinedHeaders);
    }, [
      setError,
      getFreshBudgetIntakeData,
      tableData,
      customSegmentsList,
      budgetIntakeSelectorOptionsPull,
    ]);

    useEffect(() => {
      if (!tableData) {
        (async () => {
          await getFreshBudgetIntakeData();
        })();
      }
    }, [setError, getFreshBudgetIntakeData, tableData]);

    useEffect(() => {
      if (tableData && originalTableDataMap && customSegmentsList) {
        let isSegmentUpdated = false;
        let isUpdated = (row: any) => {
          if (originalTableDataMap[row.id]) {
            let originalRow = originalTableDataMap[row.id];
            isSegmentUpdated = customSegmentsList.some(
              segment => row[segment] !== originalRow[segment]
            );

            return (
              row.id !== null &&
              (row.company !== originalRow.company ||
                row.start_date !== originalRow.start_date ||
                row.end_date !== originalRow.end_date ||
                isSegmentUpdated ||
                row.goal_name !== originalRow.goal_name ||
                row.goal_value !== originalRow.goal_value)
            );
          }
          return false;
        };
        let updatedRows = R.filter(isUpdated, tableData);
        setUpdatedRows(updatedRows);

        let newNewRows = R.filter(row => {
          if (
            row.id ||
            row.start_date === null ||
            row.end_date === null ||
            customSegmentsList.every(segment => row[segment] === null) ||
            row.goal_name === null ||
            row.goal_value === null
          ) {
            return false;
          }
          return true;
        }, tableData);

        setNewRows(newNewRows);

        let newDeletedRows = R.filter(row => {
          return !!row.id;
        }, deletedRows);
        setDeletedBudget(newDeletedRows);
      }
    }, [customSegmentsList, deletedRows, originalTableDataMap, tableData]);

    const hasPendingChanges: boolean = useMemo(() => {
      const hasPendingChangesResult =
        !!newRows.length || !!updatedRows.length || !!deletedBudget.length;

      if (!hasPendingChangesResult) {
        setShowPendingChanges(false);
      }

      return hasPendingChangesResult;
    }, [newRows, deletedBudget, updatedRows]);

    const save = useCallback(async () => {
      const getInternalData = (displayData: any[]): any[] => {
        return displayData.map(row => {
          let segments = {};
          customSegmentsList?.forEach(segment => {
            segments[segment] = row[segment];
          });

          return {
            id: row.id,
            company: company,
            start_date: row.start_date,
            end_date: row.end_date,
            ...segments,
            goal_name: row.goal_name,
            goal_value: row.goal_value,
            lastuser: email,
            lastmodified: row.lastmodified,
          };
        });
      };

      let convertedNewRows = getInternalData(newRows || []);
      let convertedUpdatedRows = getInternalData(updatedRows || []);
      let convertedDeletedRows = getInternalData(deletedBudget || []);

      const internalBody: any = {
        insert: convertedNewRows,
        update: convertedUpdatedRows,
        delete: convertedDeletedRows,
        company: company,
        customSegments: customSegmentsList,
      };

      try {
        setSaving(true);

        await ToolsLambdaFetch<any>("/setBudgetIntakeData", {
          method: "POST",
          body: JSON.stringify(internalBody),
        });

        await getFreshBudgetIntakeData();
        setSaving(false);
      } catch (e) {
        const reportError = e as Error;
        setError({
          message: `Failed to set Budget Intake data. \n ERROR: ${reportError.message}`,
          reportError,
        });
      }
    }, [
      newRows,
      updatedRows,
      deletedBudget,
      company,
      customSegmentsList,
      email,
      getFreshBudgetIntakeData,
      setError,
    ]);

    const clearAllChanges = useCallback(() => {
      setTableData(originalTableData);
      setDeletedRows([]);
      setNewRows([]);
      setUpdatedRows([]);
    }, [originalTableData]);

    const bulkImport = useCallback(
      async file => {
        try {
          let res = await ToolsLambdaFetch("/getBudgetIntakeData", {
            params: {
              company,
            },
          });
          let jsonResponse = await awaitJSON<GetBudgetIntakeRowsResponse>(res);
          const importedChanges = processBulkImport(
            file,
            jsonResponse.mappedBudgetEntries,
            company,
            customSegmentsList || []
          );

          setTableData(importedChanges);
        } catch (e) {
          setError({
            message: `Failed to import changes${e.message}`,
            reportError: e,
          });
        }
      },
      [company, customSegmentsList, setError]
    );

    const checkIsValid = (data: any) => {
      const { start_date, end_date, goal_name, goal_value } = data;
      if (
        !start_date ||
        !end_date ||
        !customSegmentsList?.some(segment => data[segment] != null) ||
        !goal_name ||
        !goal_value
      ) {
        setInvalidText("Start Date, End Date, segments, Goal Name and Goal Value cannot be empty");
        return false;
      }
      const startDate = new Date(start_date);
      const endDate = new Date(end_date);
      if (startDate >= endDate) {
        setInvalidText("End Date should be after start date");
        return false;
      }
      if (tableData) {
        for (let oldRow of tableData) {
          const isSegmentDuplicate = !customSegmentsList?.some(
            segment => oldRow[segment] !== data[segment]
          );

          if (
            oldRow.start_date === start_date &&
            oldRow.end_date === end_date &&
            isSegmentDuplicate &&
            oldRow.goal_name === goal_name
          ) {
            setInvalidText("Duplicate of existing Row");
            return false;
          }
        }
      }
      return true;
    };

    return (
      <Page
        title={
          <div className="budgetIntakeTitle">
            <div>Budget Intake Tool</div>
            <Dropdown className="optionsDropdown">
              <Dropdown.Toggle as={ThreeDotsButton} />
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setShowBulkImportModal(true)}>
                  Bulk Import
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    if (customSegmentsList) {
                      exportToExcel(tableData || [], company, customSegmentsList);
                    }
                  }}
                >
                  Export
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        }
        pageType="Budget Intake Tool"
        actions={
          <PendingChangesControls
            hasPendingChanges={hasPendingChanges}
            setShowPendingChanges={setShowPendingChanges}
            saveChanges={save}
            isSaving={saving}
            clearAllChanges={clearAllChanges}
          />
        }
      >
        <div className="budgetIntakeTool">
          {showTableData && tableData && budgetIntakeSelectorOptionsPull ? (
            <ModalEditTable
              className="budgetIntakeTool"
              filterBar
              headers={budgetIntakeHeadersCustom}
              tableData={tableData}
              setTableData={setTableData}
              deletedRows={deletedRows}
              setDeletedRows={setDeletedRows}
              selectorOptions={budgetIntakeSelectorOptionsPull}
              enableDelete={true}
              enableAdd={true}
              checkIsValid={checkIsValid}
              invalidText={invalidText}
              showModalOnAdd={true}
            ></ModalEditTable>
          ) : (
            <Skeleton>
              <TableSkeleton />
            </Skeleton>
          )}
          {showPendingChanges && (
            <PendingChangesPanel
              originalData={originalTableDataMap || {}}
              pendingChanges={{
                editedRows: updatedRows,
                newRows: newRows,
                deletedRows: deletedBudget,
              }}
              showPendingChanges={showPendingChanges}
              setShowPendingChanges={setShowPendingChanges}
              headers={budgetIntakeHeadersCustom}
            />
          )}
        </div>
        {showBulkImportModal && (
          <ImportModal
            importType="bulk"
            importFunction={bulkImport}
            onClose={() => setShowBulkImportModal(false)}
          />
        )}
      </Page>
    );
  }
);

export default BudgetIntakeTool;
