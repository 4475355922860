import React, { useCallback, useContext, useMemo, useRef, useState, useEffect } from "react";

import * as R from "ramda";
import * as Dfns from "date-fns/fp";

import cn from "classnames";

import AutoSizer from "react-virtualized-auto-sizer";
import { Form, Tooltip, InputGroup, Modal, Button } from "react-bootstrap";
import { MdClose, MdSearch } from "react-icons/md";

import {
  BASIC_COLUMN_TYPES as STREAMING_BASIC_COLUMN_TYPES,
  LagOption,
} from "@blisspointmedia/bpm-types/dist/StreamingPerformance";
import { BASIC_COLUMN_TYPES as LINEAR_BASIC_COLUMN_TYPES } from "@blisspointmedia/bpm-types/dist/LinearPerformance";

import {
  Preset,
  Column,
  PerformanceDataRow,
  ColumnHeader,
  PerformanceDimensionColumn as DimensionColumn,
  SortInfo,
  Dimension,
  GetKpiMetaDataResponse,
} from "@blisspointmedia/bpm-types/dist/Performance";

import { DateRange, StateSetter } from "../utils/types";
import { makeMemoizedGetter, mergeNumberObjects } from "../utils/data";

import {
  StickyTable,
  CornerLocation,
  Img,
  OverlayTrigger,
  SuperTopItemType,
  BPMButton,
  BPMPopover,
  BPMDateRange,
  Spinner,
} from "../Components";

import useLocation from "../utils/hooks/useLocation";
import { mixColors, RGB, rgbToHex } from "../utils/colors";
import { useScrollbarSizes } from "../utils/hooks/useDOMHelpers";
import { CreativeMap } from "../redux/creative";
import { useDerivedNetworkMap } from "../redux/networks";
import * as UserRedux from "../redux/user";
import { useSetError } from "../redux/modals";

import {
  adjustSupersAfterRemoval,
  resolveColumn,
  ROW_HEIGHT,
  PerformanceContext,
  RowWithTabularData,
  DimensionColumnMetaData,
  ColumnMetaData,
  PercentageDisplayMode,
} from "./performanceUtils";
import {
  performanceRowToTableRow as streamingPerformanceRowToTableRow,
  getColumnMetadataMap as getStreamingColumnMetadataMap,
  DIMENSION_COLUMN_METADATA_MAP as STREAMING_DIMENSION_COLUMN_METADATA_MAP,
  GLOSSARY_IP as STREAMING_GLOSSARY_IP,
  GLOSSARY_GRAPH as STREAMING_GLOSSARY_GRAPH,
} from "./StreamingPerformance/streamingPerformanceUtils";
import {
  performanceRowToTableRow as linearPerformanceRowToTableRow,
  TotalsMap,
  COLUMN_METADATA_MAP as LINEAR_COLUMN_METADATA_MAP,
  DIMENSION_COLUMN_METADATA_MAP as LINEAR_DIMENSION_COLUMN_METADATA_MAP,
  GLOSSARY as LINEAR_GLOSSARY,
} from "./LinearPerformance/linearPerformanceUtils";
import {
  performanceRowToTableRow as youtubePerformanceRowToTableRow,
  COLUMN_METADATA_MAP as YOUTUBE_COLUMN_METADATA_MAP,
  DIMENSION_COLUMN_METADATA_MAP as YOUTUBE_DIMENSION_COLUMN_METADATA_MAP,
  GLOSSARY as YOUTUBE_GLOSSARY,
} from "./YouTubePerformance/youtubePerformanceUtils";

import { useSelector } from "react-redux";

import { ReactComponent as Glossary } from "./icons/Glossary.svg";
import { TODAY } from "@blisspointmedia/bpm-types/dist/RelativeDatePicker";
import { awaitJSON, StreamingPerformanceLambdaFetch } from "../utils/fetch-utils";

import PercentAOIPlaceholder from "./PercentAOIPlaceholder";
import "./PerformanceGrid.scss";

const CDN = "https://cdn.blisspointmedia.com";

const TWO_DATES_BORDER_WIDTH = 2;
const TWO_DATES_PADDING = 16;
const HEADER_HEIGHT = 40;

const ABS_DELTA_COLOR_CUTOFF = 1000;
const ABS_DELTA_TEXT_CUTOFF = 99999;

const standardColorScheme: Record<"red" | "yellow" | "green", RGB> = {
  red: {
    r: 255,
    g: 99,
    b: 71,
  },
  yellow: {
    r: 255,
    g: 255,
    b: 102,
  },
  green: {
    r: 102,
    g: 255,
    b: 178,
  },
};

const lightColorScheme: Record<"red" | "yellow" | "green", RGB> = {
  red: {
    r: 255,
    g: 237,
    b: 237,
  },
  yellow: {
    r: 255,
    g: 255,
    b: 220,
  },
  green: {
    r: 229,
    g: 251,
    b: 240,
  },
};

const heatmapGreen: RGB = {
  r: 116,
  g: 184,
  b: 96,
};

interface HeaderProps<T> {
  data: T;
  style?: object;
  classes?: string[];
  columnIndex: number;
  rowIndex?: number;
}

interface TableCell {
  content: string;
  value: number;
  divider: boolean;
  pct?: number;
  backgroundColor?: string;
  other?: TableCell;
  delta?: TableCell;
  overlayText?: string;
}

interface HeatMapData {
  min: number;
  max: number;
  midpoint: number;
}

type IndexTotalsMap = Record<
  string,
  {
    totalRespondingImps: number;
    totalResidentialImps: number;
    totalRespondingImpsIP: number;
    totalResidentialImpsIP: number;
    totalRespondingImpsGraph: number;
    totalResidentialImpsGraph: number;
  }
>;

type DeltasHeatmapArgs = {
  nums: number[];
  sum: number;
}[];

const computeDelta = (
  val: number,
  otherVal: number,
  columnIndex: number,
  deltasHeatmapAggs?: DeltasHeatmapArgs
): TableCell => {
  let delta = Math.round((100 * (val - otherVal)) / otherVal);
  if (delta === -0) {
    delta = 0;
  }
  let content = `${delta.toLocaleString()}%`;
  let abs = Math.abs(delta);
  if (abs > ABS_DELTA_COLOR_CUTOFF) {
    if (abs > ABS_DELTA_TEXT_CUTOFF) {
      delta = ABS_DELTA_TEXT_CUTOFF;
      content = `${delta < 0 ? "<-" : ">"}${ABS_DELTA_TEXT_CUTOFF.toLocaleString()}%`;
    }
  } else if (deltasHeatmapAggs) {
    deltasHeatmapAggs[columnIndex].nums.push(delta);
    deltasHeatmapAggs[columnIndex].sum += delta;
  }

  return {
    value: delta,
    content,
    divider: false,
  };
};

export const useSortAdder = (
  setSorting: StateSetter<SortInfo[]>,
  disableMulti = false
): ((id: string) => void) =>
  useCallback(
    (id: string) => {
      //  If a user selects  an already-sorted column, it flips it if it's descending, and removes it otherwise.
      setSorting(sorting => {
        let newSorting: SortInfo[] = [];
        let sawSelf = false;
        for (const sortItem of sorting) {
          if (sortItem.id === id) {
            sawSelf = true;
            // If it's descending, then flip. Otherwise, skip.
            if (!sortItem.asc) {
              newSorting.push({ id, asc: true });
            }
          } else {
            newSorting.push(sortItem);
          }
        }
        if (!sawSelf) {
          if (disableMulti) {
            // If we pick an entirely new column to sort, we want to clear out
            // all the current sorting.
            newSorting = [{ id, asc: false }];
          } else {
            newSorting.push({ id, asc: false });
          }
        }
        return newSorting;
      });
    },
    [disableMulti, setSorting]
  );

interface PerformanceGridProps {
  creativeMap: CreativeMap;
  data: RowWithTabularData[];
  filterTerm: string;
  isLinear?: boolean;
  isSlides?: boolean;
  isYoutube?: boolean;
  onPathLoad?: (loaded: any) => void;
  otherDataRowMap?: Record<string, RowWithTabularData | undefined>;
  otherDates?: DateRange;
  percentAOIPlaceholder?: number;
  preset: Preset;
  setFilterTerm: StateSetter<string>;
  setOtherDates: StateSetter<DateRange | undefined>;
  slidesAudience?: string;
  slidesCompany?: string;
  slidesIsGraph?: boolean;
  slidesKpi?: string;
  slidesKpiMetaData?: GetKpiMetaDataResponse;
  slidesLag?: LagOption;
}

const PerformanceGrid: React.FC<PerformanceGridProps> = ({
  creativeMap,
  data,
  filterTerm,
  isLinear = false,
  isSlides = false,
  isYoutube = false,
  onPathLoad,
  otherDataRowMap,
  otherDates,
  percentAOIPlaceholder,
  preset,
  setFilterTerm,
  setOtherDates,
  slidesAudience,
  slidesCompany,
  slidesIsGraph,
  slidesKpi,
  slidesKpiMetaData,
  slidesLag,
}) => {
  const { company: companyLocationResult } = useLocation();
  const company = isSlides ? slidesCompany : companyLocationResult;
  const {
    kpiMetaData: kpiMetaDataPage,
    globalKpi,
    globalLag,
    globalAudience,
    isGraph: isGraphPage,
  } = useContext(PerformanceContext);
  const kpiMetaData = isSlides ? slidesKpiMetaData : kpiMetaDataPage;
  const kpi = isSlides ? slidesKpi : globalKpi;
  const lag = isSlides ? slidesLag : globalLag;
  const audience = isSlides ? slidesAudience : globalAudience;
  const isGraph = isSlides ? slidesIsGraph : isGraphPage;

  const setError = useSetError();

  const derivedNetworkMap = useDerivedNetworkMap(R.defaultTo(companyLocationResult, company));
  const isInternal = useSelector(UserRedux.isInternalSelector);

  const sortedGlossary = R.sortBy(
    elem => elem[0],
    isYoutube
      ? YOUTUBE_GLOSSARY
      : isLinear
      ? LINEAR_GLOSSARY
      : isGraph
      ? STREAMING_GLOSSARY_GRAPH
      : STREAMING_GLOSSARY_IP
  );

  const [columnMetaDataMap, dimensionColumnMetaDataMap]: [
    Record<string, ColumnMetaData>,
    Record<string, DimensionColumnMetaData>
  ] = useMemo(() => {
    return isLinear
      ? [LINEAR_COLUMN_METADATA_MAP, LINEAR_DIMENSION_COLUMN_METADATA_MAP]
      : isYoutube
      ? [YOUTUBE_COLUMN_METADATA_MAP, YOUTUBE_DIMENSION_COLUMN_METADATA_MAP]
      : [
          isSlides || slidesIsGraph
            ? {
                ...getStreamingColumnMetadataMap(true),

                ...getStreamingColumnMetadataMap(false),
              }
            : getStreamingColumnMetadataMap(isGraph),
          STREAMING_DIMENSION_COLUMN_METADATA_MAP,
        ];
  }, [isLinear, isYoutube, isSlides, slidesIsGraph, isGraph]);

  const [twoDateMode, setTwoDateMode] = useState(false);
  const otherDateState = useMemo(() => {
    if (!twoDateMode) {
      return "none" as const;
    }
    if (R.keys(otherDataRowMap).length) {
      return "ready" as const;
    }
    if (otherDates) {
      return "loading" as const;
    }
    return "waiting" as const;
  }, [twoDateMode, otherDataRowMap, otherDates]);

  const [youtubeConversionDate, setYoutubeConversionDate] = useState<string>();

  const performanceGridCreativeMap = useMemo(() => {
    let res = {};
    if (isLinear || isYoutube) {
      return creativeMap;
    }
    if (creativeMap) {
      for (let key of R.keys(creativeMap)) {
        const { name } = creativeMap[key];
        if (R.isNil(res[name]) || !res[name].file) {
          res[name] = creativeMap[key];
        }
      }
    }
    return res;
  }, [creativeMap, isLinear, isYoutube]);

  useEffect(() => {
    if (company) {
      (async () => {
        try {
          let res = await StreamingPerformanceLambdaFetch("/youtubeConversionDate", {
            params: {
              company,
            },
          });
          const youtubeDate = await awaitJSON<string>(res);
          if (youtubeDate) {
            setYoutubeConversionDate(Dfns.format("MM-dd-yyyy", Dfns.parseISO(youtubeDate)));
          }
        } catch (e) {
          let error = e as Error;
          setError({
            message: error.message,
            reportError: error,
          });
        }
      })();
    }
  }, [youtubeConversionDate, company, setError]);

  const [processedColumns, processedHeaders] = useMemo(() => {
    let columns: Column[] = [];
    let headers: ColumnHeader[] = [...preset.headers];

    for (let i = 0; i < preset.columns.length; ++i) {
      let column = preset.columns[i];
      if (!isInternal && column.adminOnly) {
        headers = adjustSupersAfterRemoval(headers, i);
        continue;
      }
      columns.push(column);
    }
    return [columns, headers];
  }, [preset.headers, preset.columns, isInternal]);

  const [processedDimensionColumns] = useMemo(() => {
    let cols: DimensionColumn[] = [];
    let map: Record<string, DimensionColumn> = {};
    for (let dimCol of R.defaultTo([], preset.performanceDimensionColumns)) {
      if (isInternal || !dimCol.adminOnly) {
        cols.push(dimCol);
        map[dimCol.id] = dimCol;
      }
    }
    return [cols];
  }, [preset, isInternal]);

  const dividerMap = useMemo(() => {
    let map: Record<string, true> = {};
    for (let i = 0; i < processedColumns.length; ++i) {
      if (processedColumns[i].divider) {
        map[`${i}`] = true;
      }
    }
    return map;
  }, [processedColumns]);

  const topData = useMemo(() => {
    return processedColumns.map((column, i) => ({
      ...resolveColumn(
        column,
        R.defaultTo(kpiMetaDataPage, kpiMetaData),
        R.defaultTo(globalKpi, kpi),
        columnMetaDataMap,
        isLinear,
        isYoutube
      ),
      divider: !!dividerMap[i],
    }));
  }, [
    columnMetaDataMap,
    dividerMap,
    globalKpi,
    isLinear,
    isYoutube,
    kpi,
    kpiMetaData,
    kpiMetaDataPage,
    processedColumns,
  ]);

  const [sorting, setSorting] = useState<SortInfo[]>(preset.defaultSorting || []);

  useEffect(() => {
    setSorting(preset.defaultSorting || []);
  }, [preset]);

  const addSorting = useSortAdder(setSorting, !isSlides);
  const sortRows = useCallback(
    (rows: RowWithTabularData[], sorting: SortInfo[]) => {
      return R.sortWith(
        sorting.reduce((sorts, { id, asc }) => {
          let sorter = (() => {
            let func = asc ? R.ascend : R.descend;
            let dimensionCol = R.find(col => col.id === id, processedDimensionColumns);
            if (dimensionCol) {
              let getter: (row: PerformanceDataRow) => string;
              if (!isYoutube && !isLinear && dimensionCol.dimension === "Network") {
                if (dimensionCol.type !== "Derived ID" && derivedNetworkMap) {
                  getter = row => {
                    let derivedID = row.dimensions.Network || "";
                    let derivedInfo = derivedNetworkMap[derivedID] || {};
                    return (
                      derivedInfo[
                        dimensionCol?.type === "Network Logo" ? "network" : "description"
                      ] || ""
                    );
                  };
                } else {
                  getter = row => row.dimensions.Network || "";
                }
              } else if (dimensionCol.dimension === "Creative") {
                if (dimensionCol.type === "Creative" && performanceGridCreativeMap) {
                  getter = row =>
                    performanceGridCreativeMap[row.dimensions.Creative || ""]?.name || "";
                } else {
                  getter = row => row.dimensions.Creative || "";
                }
              } else {
                getter = row => row.dimensions[dimensionCol?.dimension || ""] || "";
              }
              return func(getter);
            }
            let i = R.findIndex(col => col.id === id, processedColumns) || 0;
            let column = topData[i];
            if (!column) {
              return undefined;
            }
            let metadata = columnMetaDataMap[column.type];
            if (metadata.zerosSortHigh) {
              return (rowA: RowWithTabularData, rowB: RowWithTabularData): number => {
                const a = rowA.tabularRow[i];
                const b = rowB.tabularRow[i];
                if (a === b) {
                  return 0;
                }
                let res = 0;
                // If zeros sort high (and b isn't also 0, as we know from if statement above), then
                // if a is 0, then b must be smaller.
                if (a === 0) {
                  res = 1;
                } else if (b === 0) {
                  res = -1;
                } else {
                  res = a < b ? -1 : 1;
                }
                if (asc) {
                  res *= -1;
                }
                return res;
              };
            }
            return func((row: RowWithTabularData) => row.tabularRow[i]);
          })();
          if (sorter) {
            return [...sorts, sorter];
          }
          return sorts;
        }, [] as ((a: RowWithTabularData, b: RowWithTabularData) => number)[]),
        rows
      );
    },
    [
      columnMetaDataMap,
      derivedNetworkMap,
      isLinear,
      isYoutube,
      performanceGridCreativeMap,
      processedColumns,
      processedDimensionColumns,
      topData,
    ]
  );

  const sortedRows = useMemo(() => {
    let ourSorting = sorting;
    if (!sorting.length) {
      ourSorting = processedDimensionColumns.map(column => ({ id: column.id, asc: true }));
    }

    return sortRows(data, ourSorting);
  }, [sorting, sortRows, data, processedDimensionColumns]);

  const heatmapInfo = useMemo(() => {
    const heatmapInfo: (HeatMapData | null)[] = [];
    for (let i = 0; i < topData.length; ++i) {
      const column = topData[i];
      if (column.heatMapping) {
        let min = Infinity;
        let max = -Infinity;
        let sum = 0;
        let medianNumbers: number[] = [];
        for (let row of data) {
          let val = row.tabularRow[i];
          const { contentReplacement } = columnMetaDataMap[column.type];
          if (
            (column.heatMapping.excludeZeros && val === 0) ||
            (contentReplacement && val <= contentReplacement.threshold)
          ) {
            continue;
          }
          if (val < min) {
            min = val;
          }
          if (val > max) {
            max = val;
          }
          sum += val;
          medianNumbers.push(val);
        }
        // If the config minimum is bigger than the actual minimum, use that one
        if (!R.isNil(column.heatMapping.min) && column.heatMapping.min > min) {
          ({ min } = column.heatMapping);
        }
        // If the config maximum is smaller than the actual maximum, use that one
        if (!R.isNil(column.heatMapping.max) && column.heatMapping.max < max) {
          ({ max } = column.heatMapping);
        }
        let mean = sum / medianNumbers.length;
        let stdClip = R.isNil(column.heatMapping.stdevClipping)
          ? 1
          : column.heatMapping.stdevClipping;
        if (stdClip > 0) {
          let stdevCalc = 0;
          for (let item of data) {
            if (!column.heatMapping.excludeZeros || item.tabularRow[i] !== 0) {
              stdevCalc += (item.tabularRow[i] - mean) ** 2;
            }
          }
          let stdev = Math.sqrt(stdevCalc / medianNumbers.length);
          min = Math.max(min, mean - stdev * stdClip);
          max = Math.min(max, mean + stdev * stdClip);
        }
        let midpoint: number;
        switch (column.heatMapping.midpoint) {
          case "MEDIAN":
            midpoint = medianNumbers.length
              ? medianNumbers.sort()[Math.floor(medianNumbers.length / 2)]
              : 0;
            break;
          case "MEAN":
            midpoint = mean;
            break;
          case "CUSTOM":
            midpoint = column.heatMapping?.customMidpoint || 0;
            break;
          default:
            midpoint = (max - min) / 2 + min;
            break;
        }
        heatmapInfo.push({
          midpoint,
          min,
          max,
        });
      } else {
        heatmapInfo.push(null);
      }
    }
    return heatmapInfo;
  }, [topData, data, columnMetaDataMap]);

  const leftData = useMemo(() => R.pluck("dimensions", sortedRows), [sortedRows]);
  const [subtotalItems, otherSubtotalItems, bottomData] = useMemo(() => {
    let fakeRow: PerformanceDataRow = {
      dimensions: {},
      fetches: {},
    };

    let otherFakeRow: PerformanceDataRow = {
      dimensions: {},
      fetches: {},
    };

    let weightedData: any = R.map((row: any) => {
      let weightedRow = row;
      for (let key of R.keys(row.fetches)) {
        // Scale weighted by impressions
        weightedRow.fetches[key].avgFreqWeighted =
          row.fetches[key].avgFreq && row.fetches[key].imps
            ? row.fetches[key].avgFreq * row.fetches[key].imps
            : 0;
        weightedRow.fetches[key].medianFreqWeighted =
          row.fetches[key].medianFreq && row.fetches[key].imps
            ? row.fetches[key].medianFreq * row.fetches[key].imps
            : 0;
        weightedRow.fetches[key].stddevFreqPerIpWeighted =
          row.fetches[key].stddevFreqPerIp && row.fetches[key].imps
            ? row.fetches[key].stddevFreqPerIp * row.fetches[key].imps
            : 0;

        // Youtube weighting
        weightedRow.fetches[key].liftProbabilityWeighted =
          row.fetches[key].liftProbability && row.fetches[key].impressions
            ? row.fetches[key].liftProbability * row.fetches[key].impressions
            : 0;
        weightedRow.fetches[key].relativeLiftWeighted =
          row.fetches[key].relativeLift && row.fetches[key].impressions
            ? row.fetches[key].relativeLift * row.fetches[key].impressions
            : 0;
      }
      return weightedRow;
    }, data);

    for (let row of weightedData) {
      fakeRow.fetches = mergeNumberObjects(fakeRow.fetches, row.fetches);
      if (otherDataRowMap) {
        let otherRow = otherDataRowMap[JSON.stringify(row.dimensions)];
        if (otherRow) {
          otherFakeRow.fetches = mergeNumberObjects(otherFakeRow.fetches, otherRow.fetches);
        }
      }
    }
    let indexTotalsMap = isLinear ? ({} as TotalsMap) : ({} as IndexTotalsMap);
    let otherIndexTotalsMap = isLinear ? ({} as TotalsMap) : ({} as IndexTotalsMap);

    for (let [ourFakeRow, ourIndexTotalsMap] of [
      [fakeRow, indexTotalsMap],
      [otherFakeRow, otherIndexTotalsMap],
    ] as const) {
      for (let fetchKey of R.keys(ourFakeRow.fetches)) {
        let fetch = ourFakeRow.fetches[fetchKey];
        if (isLinear) {
          ourIndexTotalsMap[fetchKey] = {
            impressions: fetch.impressions || 0,
            ratedAdRawEffect: fetch.ratedAdRawEffect || 0,
          };
        } else {
          // FYI: response index in the overall will always work out to 1, which makes sense
          ourIndexTotalsMap[fetchKey] = {
            totalRespondingImps: fetch.respImps || 0,
            totalResidentialImps: fetch.residImps || 0,
            totalRespondingImpsIP: fetch.respImpsIP || 0,
            totalResidentialImpsIP: fetch.residImpsIP || 0,
            totalRespondingImpsGraph: fetch.respImpsGraph || 0,
            totalResidentialImpsGraph: fetch.residImpsGraph || 0,
          };
        }

        let {
          avgFreqWeighted,
          medianFreqWeighted,
          stddevFreqPerIpWeighted,
          imps,

          // Youtube Weighting
          impressions,
          liftProbabilityWeighted,
          relativeLiftWeighted,
        } = ourFakeRow.fetches[fetchKey];
        if (avgFreqWeighted) {
          ourFakeRow.fetches[fetchKey].avgFreq = imps ? avgFreqWeighted / imps : 0;
        }
        if (medianFreqWeighted) {
          ourFakeRow.fetches[fetchKey].medianFreq = imps ? medianFreqWeighted / imps : 0;
        }
        if (stddevFreqPerIpWeighted) {
          ourFakeRow.fetches[fetchKey].stddevFreqPerIp = imps ? stddevFreqPerIpWeighted / imps : 0;
        }
        if (liftProbabilityWeighted) {
          ourFakeRow.fetches[fetchKey].liftProbability = impressions
            ? liftProbabilityWeighted / impressions
            : 0;
        }
        if (relativeLiftWeighted) {
          ourFakeRow.fetches[fetchKey].relativeLift = impressions
            ? relativeLiftWeighted / impressions
            : 0;
        }
      }
    }

    let dataRow = isYoutube
      ? youtubePerformanceRowToTableRow(
          fakeRow,
          processedColumns,
          indexTotalsMap as any,
          R.defaultTo(companyLocationResult, company),
          R.defaultTo(globalKpi, kpi),
          isInternal
        )
      : isLinear
      ? linearPerformanceRowToTableRow(
          fakeRow,
          processedColumns,
          indexTotalsMap as TotalsMap,
          R.defaultTo(globalKpi, kpi),
          R.defaultTo(kpiMetaDataPage, kpiMetaData),
          audience || "HH",
          isInternal
        )
      : streamingPerformanceRowToTableRow(
          fakeRow,
          processedColumns,
          indexTotalsMap as IndexTotalsMap,
          R.defaultTo(companyLocationResult, company),
          R.defaultTo(globalKpi, kpi),
          R.defaultTo(globalLag, lag),
          isInternal
        );

    let otherDataRow = isYoutube
      ? youtubePerformanceRowToTableRow(
          otherFakeRow,
          processedColumns,
          otherIndexTotalsMap as any,
          R.defaultTo(companyLocationResult, company),
          R.defaultTo(globalKpi, kpi),
          isInternal
        )
      : isLinear
      ? linearPerformanceRowToTableRow(
          otherFakeRow,
          processedColumns,
          otherIndexTotalsMap as TotalsMap,
          R.defaultTo(globalKpi, kpi),
          R.defaultTo(kpiMetaDataPage, kpiMetaData),
          audience || "HH",
          isInternal
        )
      : streamingPerformanceRowToTableRow(
          otherFakeRow,
          processedColumns,
          otherIndexTotalsMap as IndexTotalsMap,
          R.defaultTo(companyLocationResult, company),
          R.defaultTo(globalKpi, kpi),
          R.defaultTo(globalLag, lag),
          isInternal
        );

    return [
      dataRow,
      otherDataRow,
      dataRow.map((item, i) => {
        let col = topData[i];
        let metadata = columnMetaDataMap[col.type];
        let otherData = otherDataRow[i];

        let delta = computeDelta(item, otherData, i);

        delta.backgroundColor = "white";
        if (delta.value && topData[i].heatMapping) {
          let positive = delta.value > 0;
          let { minIsBest } = columnMetaDataMap[topData[i].type];

          delta.backgroundColor = rgbToHex(
            positive === !minIsBest ? lightColorScheme.green : lightColorScheme.red
          );
        }
        let text = metadata.formatter(item, col.decimals);
        let otherText = metadata.formatter(otherData, col.decimals);
        if (col.type === "percentIncremental") {
          text = `${metadata.formatter(item * 100, col.decimals)}%`;
          otherText = `${metadata.formatter(otherData * 100, col.decimals)}%`;
        } else if (col.type === "completionsRate") {
          text = `${metadata.formatter(item, col.decimals)}%`;
          otherText = `${metadata.formatter(otherData * 100, col.decimals)}%`;
        } else if (col.type.toLowerCase().includes("percent")) {
          text = `${Math.round(item * 100)}%`;
          otherText = `${Math.round(otherData * 100)}%`;
        } else if (col.type.includes("liftProbability") || col.type.includes("relativeLift")) {
          text = `${metadata.formatter(item, col.decimals)}%`;
          otherText = `${metadata.formatter(otherData, col.decimals)}%`;
        } else if (col.type.toLowerCase().includes("rate")) {
          text = `${metadata.formatter(item, col.decimals)}%`;
          otherText = `${metadata.formatter(otherData, col.decimals)}%`;
        }
        return {
          text,
          otherText,
          delta,
          divider: !!dividerMap[i],
        };
      }),
    ];
  }, [
    audience,
    columnMetaDataMap,
    company,
    companyLocationResult,
    data,
    dividerMap,
    globalKpi,
    globalLag,
    isInternal,
    isLinear,
    isYoutube,
    kpi,
    kpiMetaData,
    kpiMetaDataPage,
    lag,
    otherDataRowMap,
    processedColumns,
    topData,
  ]);

  const [tableData, deltasHeatmapInfo] = useMemo(() => {
    let rows: TableCell[][] = [];

    let hasOtherData = otherDataRowMap && !!R.keys(otherDataRowMap).length;
    const deltasHeatmapAggs: { sum: number; nums: number[] }[] = [];
    for (let i = 0; i < topData.length; ++i) {
      deltasHeatmapAggs.push({
        sum: 0,
        nums: [],
      });
    }

    for (let dataRow of sortedRows) {
      let otherData: number[] | undefined;
      if (otherDataRowMap && hasOtherData) {
        let key = JSON.stringify(dataRow.dimensions);
        otherData = otherDataRowMap[key]?.tabularRow;
      }
      let row: TableCell[] = [];

      for (let i = 0; i < topData.length; ++i) {
        const column = topData[i];
        const cellData = dataRow.tabularRow[i];
        const metaData = columnMetaDataMap[column.type];

        let shouldReplaceContent =
          metaData.contentReplacement && cellData <= metaData.contentReplacement.threshold;

        let content;
        let overlayText;
        if (shouldReplaceContent) {
          content = metaData.contentReplacement?.replacementString;
          overlayText = metaData.contentReplacement?.overlayText || "";
        } else if (column.type === "percentIncremental") {
          content = `${metaData.formatter(cellData * 100, column.decimals)}%`;
        } else if (column.type === "completionsRate") {
          content = `${metaData.formatter(cellData, column.decimals)}%`;
        } else if (column.type.toLowerCase().includes("percent")) {
          content = `${Math.round(cellData * 100)}%`;
        } else if (column.type.toLowerCase().includes("rate")) {
          content = `${metaData.formatter(cellData, column.decimals)}%`;
        } else if (
          column.type.includes("liftProbability") ||
          column.type.includes("relativeLift")
        ) {
          content = `${metaData.formatter(cellData, column.decimals)}%`;
        } else {
          content = metaData.formatter(cellData, column.decimals);
        }

        const cell: TableCell = {
          value: cellData,
          content,
          overlayText,
          divider: !!dividerMap[i],
        };

        let hasPct =
          !shouldReplaceContent &&
          (R.isNil(column.pct)
            ? metaData.percentageDisplayMode === PercentageDisplayMode.SHOW
            : column.pct);
        if (hasPct) {
          cell.pct = Math.round((subtotalItems[i] ? cellData / subtotalItems[i] : 0) * 100);
        }

        if (otherData) {
          let val = otherData[i];
          let shouldReplaceContent =
            metaData.contentReplacement && val <= metaData.contentReplacement.threshold;

          cell.other = {
            value: val,
            content: shouldReplaceContent
              ? (metaData.contentReplacement?.replacementString as string)
              : metaData.formatter(val, column.decimals),
            overlayText: shouldReplaceContent
              ? (metaData.contentReplacement?.overlayText as string)
              : "",
            divider: false,
          };
          if (!shouldReplaceContent) {
            if (hasPct) {
              cell.other.pct = Math.round(
                (otherSubtotalItems[i] ? val / otherSubtotalItems[i] : 0) * 100
              );
            }
            if (val > 0) {
              cell.delta = computeDelta(cellData, val, i, deltasHeatmapAggs);
            }
          }
        }

        row.push(cell);
      }
      rows.push(row);
    }

    const deltaHeatmapInfo: HeatMapData[] = [];

    if (hasOtherData) {
      for (let heatmapInfo of deltasHeatmapAggs) {
        let mean = heatmapInfo.sum / heatmapInfo.nums.length;
        let stdevCalc = 0;
        for (let val of heatmapInfo.nums) {
          stdevCalc += (val - mean) ** 2;
        }
        let stdev = Math.sqrt(stdevCalc / heatmapInfo.nums.length);

        let sortedVals = R.sortBy(R.identity, heatmapInfo.nums);
        let median = sortedVals[Math.floor(sortedVals.length / 2)];

        deltaHeatmapInfo.push({
          midpoint: median,
          max: Math.min(median + 2 * stdev, sortedVals[sortedVals.length - 1]),
          min: Math.max(median - 2 * stdev, sortedVals[0]),
        });
      }
    }

    return [rows, deltaHeatmapInfo];
  }, [
    columnMetaDataMap,
    dividerMap,
    otherDataRowMap,
    otherSubtotalItems,
    sortedRows,
    subtotalItems,
    topData,
  ]);

  const getValueThresholdColor = useMemo(
    () =>
      makeMemoizedGetter({
        calculate: ({ column, value }: { column: number; value: number }) => {
          const { valueThreshold } = topData[column];
          if (
            valueThreshold &&
            ((valueThreshold.max && value > valueThreshold.max) ||
              (valueThreshold.min && value < valueThreshold.min))
          ) {
            return valueThreshold.color
              ? rgbToHex(valueThreshold.color)
              : rgbToHex(standardColorScheme.red);
          }
          return undefined;
        },
      }),
    [topData]
  );

  const getHeatMapColor = useMemo(
    () =>
      makeMemoizedGetter({
        calculate: ({
          column,
          value,
          deltas,
        }: {
          column: number;
          value: number;
          deltas?: boolean;
        }) => {
          let heatmap = heatmapInfo[column];
          // deltas have their own heatmap data. However, if this column doesn't use heatmapping, we
          // ignore
          if (heatmap && deltas) {
            heatmap = deltasHeatmapInfo[column];
          }

          let col = topData[column];

          const heatmapMeta = col.heatMapping || {};

          if (!(heatmap && heatmapMeta)) {
            return "";
          }
          let { min, max, midpoint } = heatmap;
          let { colorScheme, excludeZeros } = heatmapMeta;
          let { minIsBest: columnMinIsBest, contentReplacement } = columnMetaDataMap[
            topData[column].type
          ];
          const presetMinIsBest =
            topData && topData[column] && topData[column].heatMapping
              ? (topData[column].heatMapping as any).minIsBest
              : undefined;
          const minIsBest = R.defaultTo(columnMinIsBest, presetMinIsBest);
          if (
            min === max ||
            (excludeZeros && value === 0) ||
            (contentReplacement && value <= contentReplacement.threshold)
          ) {
            return "#FFFFFF";
          }
          let left: RGB, right: RGB;
          let pct = 100;
          if (!deltas && colorScheme === "green") {
            left = {
              r: 255,
              g: 255,
              b: 255,
            };
            right = heatmapGreen;
            pct = (value - min) / (max - min);
          } else {
            let ourColors =
              deltas || colorScheme === "light" ? lightColorScheme : standardColorScheme;
            let up = ourColors.green;
            let down = ourColors.red;
            if (minIsBest) {
              [up, down] = [down, up];
            }
            left = down;
            right = ourColors.yellow;
            let denom = midpoint - min;
            if (denom !== 0) {
              pct = (value - min) / denom;
            }
            if (value > midpoint) {
              left = ourColors.yellow;
              right = up;
              let denom = max - midpoint;
              if (denom !== 0) {
                pct = 1 - (max - value) / denom;
              } else {
                pct = 100;
              }
            }
          }
          pct = Math.max(0, Math.min(1, pct));
          return rgbToHex(mixColors(left, right, pct));
        },
      }),
    [heatmapInfo, topData, deltasHeatmapInfo, columnMetaDataMap]
  );

  const tableHeaderRenderer = useMemo(
    () => ({ data, style = {}, classes = [] }: HeaderProps<Column & { divider: boolean }>) => {
      const mySorting = R.find(({ id }) => id === data.id, sorting);
      let content = (
        <div className="content">
          {data.label}
          {!!isInternal &&
            (data.type === "percentIncremental" || data.type === "percentDirectIncremental") &&
            (!!percentAOIPlaceholder || percentAOIPlaceholder === 0) && (
              <PercentAOIPlaceholder percentage={percentAOIPlaceholder} />
            )}
          {mySorting && <div className="arrow">{mySorting.asc ? "▴" : "▾"}</div>}
        </div>
      );
      let kpiIndex = R.defaultTo(globalKpi, kpi);
      let kpiInfo = R.defaultTo(kpiMetaDataPage, kpiMetaData)[data.kpi || kpiIndex];
      let differentKPI = data.kpi && data.kpi !== kpi;
      let differentLag = data.lag && data.lag !== lag;
      let differentAudience = data.audience && data.audience !== audience;
      if (
        !STREAMING_BASIC_COLUMN_TYPES.includes(
          data.type as typeof STREAMING_BASIC_COLUMN_TYPES[number]
        ) &&
        !LINEAR_BASIC_COLUMN_TYPES.includes(
          data.type as typeof LINEAR_BASIC_COLUMN_TYPES[number]
        ) &&
        kpiInfo &&
        (differentKPI || differentLag)
      ) {
        let textParts: string[] = [];
        if (differentKPI) {
          textParts.push(kpiInfo.name);
        }
        if (differentLag) {
          textParts.push(`${data.lag} lag`);
        }
        if (differentAudience) {
          textParts.push(data.audience || "HH");
        }
        let hoverText = textParts.join(", ");
        if (hoverText) {
          content = (
            <OverlayTrigger overlay={<BPMPopover>{hoverText}</BPMPopover>}>
              {content}
            </OverlayTrigger>
          );
        }
      }
      return (
        <div
          style={style}
          className={cn(...classes, "tableHeader", { dividerCell: data.divider, twoDateMode })}
          onClick={() => addSorting(data.id)}
        >
          {content}
          {twoDateMode && (
            <>
              <div className="content">Comparison</div>
              <div className="content">% Increase</div>
            </>
          )}
        </div>
      );
    },
    [
      addSorting,
      audience,
      globalKpi,
      kpi,
      kpiMetaData,
      kpiMetaDataPage,
      lag,
      sorting,
      twoDateMode,
      percentAOIPlaceholder,
      isInternal,
    ]
  );

  const innerCellRenderer = (innerCellContent, overlayText) => {
    return overlayText ? (
      <OverlayTrigger
        placement="right center"
        overlay={<Tooltip id={overlayText}>{overlayText}</Tooltip>}
      >
        {innerCellContent}
      </OverlayTrigger>
    ) : (
      innerCellContent
    );
  };

  const tableCellRenderer = useMemo(
    () => ({
      data,
      style = {},
      classes = [],
      columnIndex = 0,
      rowIndex = 0,
    }: HeaderProps<TableCell>) => {
      let ourClasses = [...classes, "bodyCell"];
      if (columnIndex === 0) {
        ourClasses.push("start");
      }
      if (columnIndex === topData.length - 1) {
        ourClasses.push("end");
      }
      if (rowIndex === tableData.length - 1) {
        ourClasses.push("lastRow");
      }

      if (data.divider) {
        ourClasses.push("dividerCell");
      }
      if (twoDateMode) {
        ourClasses.push("twoDateMode");
      }

      return (
        <div style={style} className={ourClasses.join(" ")}>
          <div className="cellWrapper">
            {innerCellRenderer(
              <div
                className="innerCell"
                style={{
                  backgroundColor:
                    data.backgroundColor ||
                    getValueThresholdColor({ column: columnIndex, value: data.value }) ||
                    getHeatMapColor({ column: columnIndex, value: data.value }),
                }}
              >
                <div className="content">{data.content}</div>
                {R.isNil(data.pct) ? null : <div className="pct">({data.pct}%)</div>}
              </div>,
              data.overlayText
            )}
          </div>
          {twoDateMode &&
            (otherDateState === "ready" ? (
              <>
                <div className="otherDateWrapper">
                  {innerCellRenderer(
                    <div className="innerCell">
                      <div className="content">{data.other?.content || "-"}</div>
                      {R.isNil(data.other?.pct) ? null : (
                        <div className="pct">({data.other?.pct}%)</div>
                      )}
                    </div>,
                    data.other?.overlayText
                  )}
                </div>
                <div className="otherDateWrapper">
                  {innerCellRenderer(
                    <div
                      className="innerCell"
                      style={{
                        backgroundColor: data.delta?.content
                          ? data.backgroundColor ||
                            getHeatMapColor({
                              column: columnIndex,
                              value: data.delta?.value || 0,
                              deltas: true,
                            })
                          : "white",
                      }}
                    >
                      <div className="content">{data.delta?.content || "-"}</div>
                    </div>,
                    data.delta?.overlayText
                  )}
                </div>
              </>
            ) : (
              <div className="otherDateWrapper notReady">
                {otherDateState === "loading" ? <Spinner /> : "Pick dates to compare"}
              </div>
            ))}
        </div>
      );
    },
    [getValueThresholdColor, getHeatMapColor, tableData, topData, twoDateMode, otherDateState]
  );

  // TODO: this can probably be pulled out into its own component.
  const subtotalCellRenderer = useMemo(
    () => ({
      data,
      style = {},
      classes = [],
      columnIndex,
    }: HeaderProps<{ text: string; otherText: string; delta?: TableCell; divider: boolean }>) => {
      let ourClasses = [...classes, "subtotalCell", "bodyCell"];
      if (columnIndex === 0) {
        ourClasses.push("start");
      }
      if (columnIndex === topData.length - 1) {
        ourClasses.push("end");
      }
      if (data.divider) {
        ourClasses.push("dividerCell");
      }
      if (twoDateMode) {
        ourClasses.push("twoDateMode");
      }

      return (
        <div style={style} className={ourClasses.join(" ")}>
          <div className="cellWrapper">
            <div className="content innerCell">{data.text}</div>
          </div>
          {twoDateMode &&
            (otherDateState === "ready" ? (
              <>
                <div className="otherDateWrapper">
                  <div className="innerCell">
                    <div className="content">{data.otherText || "-"}</div>
                  </div>
                </div>
                <div className="otherDateWrapper">
                  <div
                    className="innerCell"
                    style={{
                      backgroundColor: data.delta?.backgroundColor || "white",
                    }}
                  >
                    <div className="content">{data.delta?.content || "-"}</div>
                  </div>
                </div>
              </>
            ) : (
              <div className="otherDateWrapper notReady">
                {otherDateState === "loading" ? <Spinner /> : "Pick dates to compare"}
              </div>
            ))}
        </div>
      );
    },
    [topData, otherDateState, twoDateMode]
  );

  const leftWidth = useMemo(() => {
    let sum = 0;
    for (let col of processedDimensionColumns) {
      sum += col.minWidth || dimensionColumnMetaDataMap[col.type].minWidth || 0;
    }
    return sum;
  }, [processedDimensionColumns, dimensionColumnMetaDataMap]);

  const leftRenderer = useMemo(
    () => ({
      data,
      style = {},
      classes = [],
      rowIndex,
    }: HeaderProps<Partial<Record<Dimension, string>>>) => {
      let columns: JSX.Element[] = [];
      for (let column of processedDimensionColumns) {
        let key = `${rowIndex}_${column.id}`;
        if (column.dimension === "Network") {
          if (isLinear) {
            if (column.type === "Network Logo") {
              columns.push(
                <div
                  key={key}
                  className="networkLogo"
                  style={{
                    width: column.minWidth || dimensionColumnMetaDataMap["Network Logo"].minWidth,
                  }}
                >
                  {data.Network && (
                    <OverlayTrigger
                      placement="right center"
                      overlay={<Tooltip id={key}>{data.Network}</Tooltip>}
                    >
                      <Img
                        alt={data.Network}
                        src={`${CDN}/networks/${data.Network}.png`}
                        unloader={
                          <strong className="description">{data.Network.toUpperCase()}</strong>
                        }
                      />
                    </OverlayTrigger>
                  )}
                </div>
              );
            } else if (column.type === "Network Name") {
              columns.push(
                <div
                  key={key}
                  className="content description"
                  style={{
                    width: column.minWidth || dimensionColumnMetaDataMap["Network Name"].minWidth,
                  }}
                >
                  {data.Network}
                </div>
              );
            }
          } else {
            const derivedNetwork = (derivedNetworkMap || {})[data.Network || ""];
            if (derivedNetwork) {
              if (column.type === "Network Logo") {
                columns.push(
                  <div
                    key={key}
                    className="networkLogo"
                    style={{
                      width: column.minWidth || dimensionColumnMetaDataMap["Network Logo"].minWidth,
                    }}
                  >
                    {derivedNetwork.network && (
                      <OverlayTrigger
                        placement="right center"
                        overlay={
                          <Tooltip id={key}>
                            {derivedNetwork.network === "YOUTUBE" ? (
                              <div>
                                <div>{derivedNetwork.network}</div>
                                {youtubeConversionDate && (
                                  <div>{`Performance data complete through ${youtubeConversionDate}`}</div>
                                )}
                              </div>
                            ) : (
                              derivedNetwork.network
                            )}
                          </Tooltip>
                        }
                      >
                        <Img
                          alt={derivedNetwork.network}
                          src={`${CDN}/networks/${derivedNetwork.network}.png`}
                        />
                      </OverlayTrigger>
                    )}
                  </div>
                );
              } else if (column.type === "Derived ID") {
                columns.push(
                  <div
                    key={key}
                    className="content description"
                    style={{
                      width: column.minWidth || dimensionColumnMetaDataMap["Derived ID"].minWidth,
                    }}
                  >
                    {data.Network}
                  </div>
                );
              } else if (column.type === "Network Name") {
                columns.push(
                  <div
                    key={key}
                    className="content description"
                    style={{
                      width: column.minWidth || dimensionColumnMetaDataMap["Network Name"].minWidth,
                    }}
                  >
                    {derivedNetwork.network || data.Network}
                  </div>
                );
              } else {
                columns.push(
                  <div
                    key={key}
                    className="content description"
                    style={{
                      width: column.minWidth || dimensionColumnMetaDataMap.Description.minWidth,
                    }}
                  >
                    {derivedNetwork.description}
                  </div>
                );
              }
              continue;
            }

            columns.push(<div key={key}>{data.Network || ""}</div>);
          }
        } else if (column.dimension === "Network Group") {
          if (!isLinear) {
            if (column.type === "Network Group Logo") {
              columns.push(
                <div
                  key={key}
                  className="networkLogo"
                  style={{
                    width:
                      column.minWidth || dimensionColumnMetaDataMap["Network Group Logo"].minWidth,
                  }}
                >
                  {data["Network Group"] && (
                    <OverlayTrigger
                      placement="right center"
                      overlay={<Tooltip id={key}>{data["Network Group"]}</Tooltip>}
                    >
                      <Img
                        alt={data["Network Group"]}
                        src={`${CDN}/networks/${data["Network Group"].toUpperCase()}.png`}
                        unloader={
                          <strong className="description">
                            {data["Network Group"].toUpperCase()}
                          </strong>
                        }
                      />
                    </OverlayTrigger>
                  )}
                </div>
              );
            } else if (column.type === "Network Group Name") {
              columns.push(
                <div
                  key={key}
                  className="content description"
                  style={{
                    width:
                      column.minWidth || dimensionColumnMetaDataMap["Network Group Name"].minWidth,
                  }}
                >
                  {data["Network Group"]}
                </div>
              );
            }
          }
        } else if (column.dimension === "Creative") {
          let creative = (performanceGridCreativeMap || {})[data.Creative || ""];
          if (column.type === "Creative") {
            if (creative) {
              columns.push(
                <div
                  key={key}
                  className="content description"
                  style={{
                    width: column.minWidth || dimensionColumnMetaDataMap.Creative.minWidth,
                  }}
                >
                  {creative.name}
                </div>
              );
            } else {
              columns.push(
                <div
                  key={key}
                  className="content description"
                  style={{
                    width: column.minWidth || dimensionColumnMetaDataMap.Creative.minWidth,
                  }}
                >
                  Unknown
                </div>
              );
            }
          } else if (column.type === "Creative Thumbnail") {
            if (creative) {
              columns.push(
                <div
                  key={key}
                  className="networkLogo"
                  style={{
                    width:
                      column.minWidth || dimensionColumnMetaDataMap["Creative Thumbnail"].minWidth,
                  }}
                >
                  {creative.file && (
                    <Img alt={creative.file} src={`${CDN}/creatives/${creative.file}.png`} />
                  )}
                </div>
              );
            } else {
              // Add thumbnail div for formatting even though the creative is unknown.
              columns.push(
                <div
                  key={key}
                  className="networkLogo"
                  style={{
                    width:
                      column.minWidth || dimensionColumnMetaDataMap["Creative Thumbnail"].minWidth,
                  }}
                ></div>
              );
            }
          }
          continue;
        } else if (column.dimension === "Length") {
          columns.push(
            <div
              key={key}
              className="content"
              style={{ width: column.minWidth || dimensionColumnMetaDataMap.Length.minWidth }}
            >
              {data[column.dimension] || ""}
              {isLinear ? "" : "s"}
            </div>
          );
        } else if (column.dimension === "Size") {
          columns.push(
            <div
              key={key}
              className="content description"
              style={{ width: column.minWidth || dimensionColumnMetaDataMap.Size.minWidth }}
            >
              {data[column.dimension] || "Native"}
            </div>
          );
        } else if (column.dimension === "DeviceOS") {
          let [device, os] = (data[column.dimension] || "").toUpperCase().split("_");
          const isDevice = column.type === "Device" || column.type === "Device Logo";
          const isLogo = column.type === "Device Logo" || column.type === "OS Logo";
          const datum = ((isDevice ? device : os) || "").toUpperCase();
          if (isLogo) {
            columns.push(
              <div
                key={key}
                className="networkLogo"
                style={{
                  width: column.minWidth || dimensionColumnMetaDataMap[column.type].minWidth,
                }}
              >
                {datum && (
                  <OverlayTrigger
                    placement="right center"
                    overlay={<Tooltip id={key}>{datum}</Tooltip>}
                  >
                    <Img alt={datum} src={`${CDN}/networks/${datum}.png`} />
                  </OverlayTrigger>
                )}
              </div>
            );
          } else {
            columns.push(
              <div
                key={key}
                className="content"
                style={{
                  width: column.minWidth || dimensionColumnMetaDataMap[column.type].minWidth,
                }}
              >
                {datum}
              </div>
            );
          }
        } else if (column.dimension === "Device") {
          let textToRender = data[column.dimension];
          columns.push(
            <div
              key={key}
              className="content description"
              style={{
                width: column.minWidth || dimensionColumnMetaDataMap[column.type].minWidth,
              }}
            >
              {textToRender}
            </div>
          );
        } else if (column.dimension === "Campaign") {
          const campaign: string =
            column && column.dimension && data[column.dimension]
              ? R.defaultTo("", data[column.dimension])
              : "";
          const textToRender =
            campaign.includes("|") && campaign.split("|") && campaign.split("|").length > 1
              ? campaign.split("|")[1]
              : data[column.dimension];
          columns.push(
            <div
              key={key}
              className="content description"
              style={{
                width: column.minWidth || dimensionColumnMetaDataMap[column.type].minWidth,
              }}
            >
              {textToRender}
            </div>
          );
        } else if (column.dimension === "Age") {
          const age = R.defaultTo("", data[column.dimension])
            .replace("AGE_RANGE_", "")
            .replace("_", "-");
          columns.push(
            <div
              key={key}
              className="content description"
              style={{
                width: column.minWidth || dimensionColumnMetaDataMap[column.type].minWidth,
              }}
            >
              {age}
            </div>
          );
        } else if (
          ["Daypart", "Avail", "Gender", "Device", "Ad Group", "Ad"].includes(column.dimension)
        ) {
          let textToRender = data[column.dimension];
          if (textToRender === "L") {
            textToRender = "Local";
          }
          if (textToRender === "N") {
            textToRender = "National";
          }
          columns.push(
            <div
              key={key}
              className="content description"
              style={{
                width: column.minWidth || dimensionColumnMetaDataMap[column.type].minWidth,
              }}
            >
              {textToRender}
            </div>
          );
        } else {
          columns.push(<div key={key} />);
        }
      }

      return (
        <div style={style} className={[...classes, "leftCell"].join(" ")}>
          {columns}
        </div>
      );
    },
    [
      processedDimensionColumns,
      isLinear,
      dimensionColumnMetaDataMap,
      derivedNetworkMap,
      youtubeConversionDate,
      performanceGridCreativeMap,
    ]
  );

  const containerRef = useRef<HTMLDivElement>(null);
  const scrollbarSizes = useScrollbarSizes(containerRef);

  const colWidth = useMemo(() => {
    // This is copied from the CSS. This is the extra padding needed on the first/last columns
    const EDGE_PADDING = 4;
    const widthMap: { [width: number]: number[] | undefined } = {};
    return (width: number, i: number) => {
      let widths = widthMap[width];
      if (!widths) {
        widths = [];
        let minSum = 0;
        for (let i = 0; i < topData.length; ++i) {
          let column = topData[i];
          let minSize = column.minWidth || columnMetaDataMap[column.type].minWidth;
          if (twoDateMode) {
            minSize = minSize * 3 + (TWO_DATES_BORDER_WIDTH + TWO_DATES_PADDING) * 2;
          }
          if (i === 0) {
            minSize += EDGE_PADDING;
          }
          if (i === topData.length - 1) {
            minSize += EDGE_PADDING;
          }
          minSum += minSize;
          widths.push(minSize);
        }

        let availWidth = width - leftWidth - scrollbarSizes.width - 1;
        if (minSum < availWidth) {
          let extraSpace = Math.floor((availWidth - minSum) / topData.length);
          let extraExtraSpace = availWidth - minSum - extraSpace * topData.length - 1;

          for (let i = 0; i < widths.length; ++i) {
            widths[i] = widths[i] + extraSpace;
            if (extraExtraSpace > 0) {
              widths[i]++;
              extraExtraSpace--;
            }
          }
        }
        widthMap[width] = widths;
        return widths[i];
      }
      return widths[i];
    };
  }, [topData, scrollbarSizes, leftWidth, twoDateMode, columnMetaDataMap]);

  const superHeaders = useMemo(() => {
    if (!processedHeaders.length) {
      return undefined;
    }
    let superHeaders: SuperTopItemType<{
      text: string;
      leftDivider: boolean;
      rightDivider: boolean;
    }>[] = [];
    let lastIndex = -1;
    // ASSUMPTION: the headers will be in order, and they won't overlap
    for (let i = 0; i < processedHeaders.length; ++i) {
      let header = processedHeaders[i];
      if (header.start > lastIndex + 1) {
        superHeaders.push({
          span: header.start - lastIndex - 1,
          data: {
            text: "",
            leftDivider: false,
            rightDivider: false,
          },
        });
      }
      let nextHeader = processedHeaders[i + 1];
      superHeaders.push({
        span: header.end - header.start + 1,
        data: {
          text: header.text,
          leftDivider: header.start !== 0,
          rightDivider: !nextHeader || nextHeader.start !== header.end + 1,
        },
      });
      lastIndex = header.end;
    }
    if (lastIndex < topData.length - 1) {
      superHeaders.push({
        span: topData.length - lastIndex - 1,
        data: {
          text: "",
          leftDivider: false,
          rightDivider: false,
        },
      });
    }
    return superHeaders;
  }, [processedHeaders, topData]);

  // TODO: this can probably be pulled out into its own component.
  const superHeaderRenderer = useMemo(
    () => ({
      data,
      style = {},
      classes = [],
      columnIndex,
    }: HeaderProps<{ text: string; leftDivider: boolean; rightDivider: boolean }>) => {
      let ourClasses = [...classes, "superHeader"];
      if (data.leftDivider) {
        ourClasses.push("leftDivider");
      }
      if (data.rightDivider) {
        ourClasses.push("rightDivider");
      }
      if (twoDateMode) {
        ourClasses.push("twoDateMode");
      }
      return (
        <div style={style} className={ourClasses.join(" ")}>
          <div className="content innerCell">{data.text}</div>
        </div>
      );
    },
    [twoDateMode]
  );

  const cornerRenderer = useMemo(
    () => (corner: CornerLocation) => {
      if (corner === "nw") {
        let classes = ["dimensionCorner"];
        if (superHeaders || twoDateMode) {
          classes.push("withSuperHeaders");
        }
        return (
          <div className={classes.join(" ")}>
            {processedDimensionColumns.map(column => {
              const ourSorting = R.find(({ id }) => id === column.id, sorting);
              const classes = ["tableHeader"];
              const unsorted = column.type === "Creative Thumbnail";
              if (unsorted) {
                classes.push("unsorted");
              }
              let metadata = dimensionColumnMetaDataMap[column.type];
              if (metadata.centerHeader) {
                classes.push("centered");
              }
              return (
                <div
                  className={classes.join(" ")}
                  style={{
                    width: column.minWidth || metadata.minWidth,
                  }}
                  key={column.id}
                  onClick={() => !unsorted && addSorting(column.id)}
                >
                  <div className="content">
                    {column.label || dimensionColumnMetaDataMap[column.type].label || column.type}
                    {ourSorting && !unsorted && (
                      <div className="arrow">{ourSorting.asc ? "▴" : "▾"}</div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        );
      } else if (corner === "sw") {
        return <div>Overall</div>;
      } else {
        return <div />;
      }
    },
    [
      processedDimensionColumns,
      addSorting,
      sorting,
      superHeaders,
      twoDateMode,
      dimensionColumnMetaDataMap,
    ]
  );

  const [showGlossary, setShowGlossary] = useState(false);

  useEffect(() => {
    if (onPathLoad) {
      onPathLoad(null);
    }
    let subTotalCheck = false;
    for (let item of subtotalItems) {
      if (item > 0) {
        subTotalCheck = true;
      }
    }
    for (let item of otherSubtotalItems) {
      if (item > 0) {
        subTotalCheck = true;
      }
    }
    const bottomDataCheck = !!bottomData && subTotalCheck;
    let isSorted = false;
    if (preset) {
      isSorted =
        preset.defaultSorting && preset.defaultSorting.length > 0 ? sorting.length > 0 : true;
    }
    if (
      onPathLoad &&
      topData &&
      tableData &&
      bottomDataCheck &&
      heatmapInfo &&
      leftData &&
      kpiMetaData &&
      isSorted &&
      kpi &&
      company &&
      ((isLinear && audience) || (!isLinear && lag) || (isYoutube && kpi))
    ) {
      const onPathLoadValues = {
        topData,
        tableData,
        bottomData,
        heatmapInfo,
        leftData,
      };
      onPathLoad(onPathLoadValues);
    }
  }, [
    audience,
    bottomData,
    company,
    heatmapInfo,
    isLinear,
    isYoutube,
    kpi,
    kpiMetaData,
    lag,
    leftData,
    onPathLoad,
    otherSubtotalItems,
    preset,
    sorting,
    subtotalItems,
    tableData,
    topData,
  ]);

  return (
    <div className="gridBox">
      <div className="gridControls">
        <InputGroup className="searchGroup">
          <InputGroup.Prepend>
            <div className="searchIcon">
              <MdSearch />
            </div>
          </InputGroup.Prepend>
          <Form.Control
            placeholder="Search"
            className="search"
            value={filterTerm}
            onChange={e => setFilterTerm(e.currentTarget.value)}
          />
        </InputGroup>
        <BPMButton
          variant="outline-primary"
          icon={<Glossary />}
          className="glossaryButton"
          onClick={() => setShowGlossary(R.not)}
        >
          Glossary
        </BPMButton>
        {twoDateMode ? (
          <>
            <small className="compareTo">Comparing to</small>
            <BPMDateRange
              currentMonthSecond
              range={otherDates}
              onChange={dates => setOtherDates(dates)}
              isDayBlocked={
                isLinear
                  ? date => date >= TODAY || !R.pipe(Dfns.parseISO, Dfns.isMonday)(date)
                  : date => date >= TODAY
              }
              fullWeeksOnly={isLinear}
            />
            <Button variant="light" size="sm" onClick={() => setTwoDateMode(false)}>
              <MdClose />
            </Button>
          </>
        ) : (
          <Button size="sm" variant="link" className="compare" onClick={() => setTwoDateMode(true)}>
            Compare another date range
          </Button>
        )}
        {showGlossary && (
          <Modal
            show
            centered
            size="lg"
            onHide={() => setShowGlossary(false)}
            className="performance2GlossaryModal"
          >
            <Modal.Header closeButton>
              <Modal.Title>Glossary</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {sortedGlossary.map(([header, text]) => (
                <div key={header} className="section">
                  <div className="header">{header}</div>
                  <p>{text}</p>
                </div>
              ))}
            </Modal.Body>
          </Modal>
        )}
      </div>
      <div className="gridContainer" ref={containerRef}>
        <AutoSizer>
          {({ width, height }) => (
            <StickyTable
              width={width}
              height={height}
              leftData={leftData}
              topData={topData}
              data={tableData}
              superTopData={superHeaders}
              superTopRenderer={superHeaderRenderer}
              topRenderer={tableHeaderRenderer}
              cellRenderer={tableCellRenderer}
              leftRenderer={leftRenderer}
              rowHeight={ROW_HEIGHT}
              topHeight={HEADER_HEIGHT}
              superTopHeight={HEADER_HEIGHT}
              columnWidth={i => colWidth(width, i)}
              leftWidth={leftWidth}
              cornerRenderer={cornerRenderer}
              bottomData={bottomData}
              bottomRenderer={subtotalCellRenderer}
            />
          )}
        </AutoSizer>
      </div>
    </div>
  );
};

export default PerformanceGrid;
