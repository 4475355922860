import "./SingleChannelDeliveryChart.scss";
import { abbreviateNumber } from "../../utils/data";
import { Bar, CartesianGrid, Cell, ComposedChart, Line, XAxis, YAxis } from "recharts";
import { CDN, toPrettyCPX, toPrettyNumber, toPrettySpend } from "../MetricsTable/metricsTableUtils";
import { DateRange, StateSetter } from "../../utils/types";
import { downloadPNG, exportToExcel } from "../../utils/download-utils";
import { formatDateLabel, getGroupingValue } from "../../TVADCrossChannel/homePageUtils";
import { getChannelSeriesColor, shouldUsePrimaryPalette } from "../../utils/colors";
import {
  MdOutlineTableRows,
  MdShowChart,
  MdClose,
  MdArrowBackIos,
  MdArrowForwardIos,
} from "react-icons/md";
import * as R from "ramda";
import AutoSizer from "react-virtualized-auto-sizer";
import ChartContainer from "../../Components/ChartContainer";
import PercentChange from "../PercentChange";
import React, { useState, useMemo, useCallback, useEffect } from "react";
import {
  BPMTable,
  DownloadDropdown,
  Dropdown,
  DropdownToggleType,
  IconToggleButton,
  Img,
} from "../../Components";
import {
  CARTESIAN_GRID_STROKE_WIDTH,
  CARTESIAN_GRID_STROKE,
  DATE_GROUPING_OPTIONS,
  DateGroupingKey,
  TICK_STYLE,
  X_AXIS_PADDING,
} from "../../TVADCrossChannel/homePageConstants";
import {
  CreativeKeyInfo,
  DailyDeliveryData,
  NetworkKeyInfo,
} from "../../StreamingDelivery/StreamingDelivery";

export interface DeliveryMetricOption {
  isSpend?: boolean;
  label: string;
  value: string;
}

export const DEFAULT_DELIVERY_METRICS_OPTIONS: DeliveryMetricOption[] = [
  { label: "Spend", isSpend: true, value: "cost" },
  { label: "Impressions", value: "count" },
];
const GREYED_OUT_GREY = "#C7C7C7";
const LEFT_Y_AXIS_ID = 0;
const RIGHT_Y_AXIS_ID = 1;
const PRIMARY_STACK_ID = 0;
const COMPARISON_STACK_ID = 1;

/**
 * Depending on the date grouping selected (Day, Week, Month, etc.), sum up the metrics for each date.
 */
const deliveryTotalsByDateIncrement = (
  data: any,
  dateGrouping: DateGroupingKey,
  deliveryMetricOptions: DeliveryMetricOption[]
): Record<string, Record<string, any>> => {
  const totalsByDate: Record<string, Record<string, any>> = {};
  const smallestDataIncrement =
    !R.isNil(data.daily) && R.isEmpty(data.daily) && !R.isEmpty(data.weekly)
      ? data.weekly
      : data.daily;
  for (const date of R.keys(smallestDataIncrement)) {
    const dateToUse = getGroupingValue(date, dateGrouping);
    totalsByDate[dateToUse] = R.defaultTo({}, totalsByDate[dateToUse]);
    for (const dim of R.keys(smallestDataIncrement[date])) {
      totalsByDate[dateToUse][dim] = R.isNil(totalsByDate[dateToUse][dim])
        ? {}
        : totalsByDate[dateToUse][dim];
      for (const metric of deliveryMetricOptions) {
        totalsByDate[dateToUse][dim][metric.value] =
          R.defaultTo(0, totalsByDate[dateToUse][dim][metric.value]) +
          R.defaultTo(0, smallestDataIncrement[date][dim][metric.value]);
      }
    }
  }
  return totalsByDate;
};

const joinDeliveryData = (
  data: any,
  otherData: any,
  keys: string[],
  includeOtherDateRange: boolean,
  compareDateRange: boolean,
  emptyCell: Record<string, number>
) => {
  const res: any[] = [];
  const dates = R.sort((a, b) => a.localeCompare(b), R.keys(data));
  const otherDates = R.sort((a, b) => a.localeCompare(b), R.keys(otherData));
  const allDates = R.sort(
    (a, b) => a.localeCompare(b),
    R.uniq(includeOtherDateRange ? R.uniq([...dates, ...otherDates]) : dates)
  );
  if (compareDateRange) {
    const longerRange = dates.length > otherDates.length ? dates : otherDates;
    for (let dateIndex = 0; dateIndex < longerRange.length; dateIndex++) {
      const dateToUse = longerRange[dateIndex];
      const date = dates[dateIndex];
      const otherDate = otherDates[dateIndex];
      const joinedData = { date: dateToUse, datePrimary: date, otherDate, data: {}, otherData: {} };
      for (const key of keys) {
        joinedData.data[key] = date && data[date] && data[date][key] ? data[date][key] : emptyCell;
        joinedData.otherData[key] =
          otherData[otherDate] && otherData[otherDate][key] ? otherData[otherDate][key] : emptyCell;
      }
      res.push(joinedData);
    }
  } else {
    for (const date of allDates) {
      const joinedData = { date, data: {}, otherData: {} };
      for (const key of keys) {
        joinedData.data[key] = data[date] && data[date][key] ? data[date][key] : emptyCell;
        joinedData.otherData[key] =
          otherData[date] && otherData[date][key] ? otherData[date][key] : emptyCell;
      }
      res.push(joinedData);
    }
  }
  return res;
};

interface SingleChannelDeliveryChartProps {
  company: string;
  data: DailyDeliveryData;
  dates: DateRange;
  defaultDateGroupingKey?: DateGroupingKey;
  defaultMetric?: string;
  deliveryMetricOptions?: DeliveryMetricOption[];
  dimension: string;
  dimensionOptions: string[];
  otherData: DailyDeliveryData;
  otherDates?: DateRange;
  setDimension: StateSetter<string>;
}

const SingleChannelDeliveryChart: React.FC<SingleChannelDeliveryChartProps> = React.memo(
  ({
    company,
    data,
    dates,
    defaultDateGroupingKey = "Day",
    defaultMetric,
    deliveryMetricOptions = DEFAULT_DELIVERY_METRICS_OPTIONS,
    dimension,
    dimensionOptions,
    otherData,
    otherDates,
    setDimension,
  }) => {
    const [absolute, setAbsolute] = useState(true);
    const [graphWidth, setGraphWidth] = useState<number>(0);
    const [groupingKey, setGroupingKey] = useState(defaultDateGroupingKey);
    const [hoveredDateIndex, setHoveredDateIndex] = useState<number | undefined>();
    const [hoveredLegendItemIndex, setHoveredLegendItemIndex] = useState<number | undefined>();
    const [includeOtherDateRange, setIncludeOtherDateRange] = useState<boolean>(false);
    const [metric, setMetric] = useState<DeliveryMetricOption>(
      defaultMetric && R.find(option => option.label === defaultMetric, deliveryMetricOptions)
        ? {
            label: defaultMetric,
            value: (R.find(
              option => option.label === defaultMetric,
              deliveryMetricOptions
            ) as DeliveryMetricOption).value,
          }
        : deliveryMetricOptions[0]
    );
    const [selectedDateIndex, setSelectedDateIndex] = useState<number | undefined>();
    const [showAddOtherDateRange, setShowAddOtherDateRange] = useState(false);
    const [selectedLegendItemIndexes, setSelectedLegendItemIndexes] = useState<
      number[] | undefined
    >();
    const [selectedToggleOption, setSelectedToggleOption] = useState<string>("showGraph");
    const [showPercentChange, setShowPercentChange] = useState(false);

    const dateGroupingOptions = useMemo(() => {
      if (
        data &&
        data.daily &&
        otherData &&
        otherData.daily &&
        (!R.isEmpty(data.daily) || (R.isEmpty(data.daily) && R.isEmpty(data.weekly))) &&
        (!R.isEmpty(otherData.daily) || (R.isEmpty(otherData.daily) && R.isEmpty(otherData.weekly)))
      ) {
        return DATE_GROUPING_OPTIONS;
      } else if (data && data.weekly && otherData && otherData.weekly) {
        return DATE_GROUPING_OPTIONS.filter(option => option.value !== "Day");
      } else {
        return [];
      }
    }, [data, otherData]);

    const sortedDimensionKeys = useMemo(
      () =>
        R.uniq([
          ...R.defaultTo([], R.keys(data.keys)).sort(),
          ...(otherData && otherData.keys && includeOtherDateRange
            ? R.keys(otherData.keys)
            : []
          ).sort(),
        ]),
      [data, otherData, includeOtherDateRange]
    );

    const activeKeyCount = sortedDimensionKeys.length;

    const usePrimaryPalette = shouldUsePrimaryPalette(activeKeyCount);

    const dataGroupedByDate = useMemo(
      () =>
        R.isNil(data)
          ? undefined
          : deliveryTotalsByDateIncrement(data, groupingKey, deliveryMetricOptions),
      [data, deliveryMetricOptions, groupingKey]
    );
    const otherDateDataGroupedByDate = useMemo(
      () =>
        R.isNil(otherData)
          ? undefined
          : deliveryTotalsByDateIncrement(otherData, groupingKey, deliveryMetricOptions),
      [deliveryMetricOptions, groupingKey, otherData]
    );

    const joinedDeliveryData = useMemo(() => {
      if (dataGroupedByDate && otherDateDataGroupedByDate) {
        const emptyCell = {};
        for (const option of deliveryMetricOptions) {
          emptyCell[option.value] = 0;
        }
        return joinDeliveryData(
          dataGroupedByDate,
          otherDateDataGroupedByDate,
          sortedDimensionKeys,
          includeOtherDateRange,
          includeOtherDateRange && !R.isNil(selectedLegendItemIndexes),
          emptyCell
        );
      }
      return [];
    }, [
      dataGroupedByDate,
      deliveryMetricOptions,
      includeOtherDateRange,
      otherDateDataGroupedByDate,
      selectedLegendItemIndexes,
      sortedDimensionKeys,
    ]);
    // When we have an active legend item, we only want to show the data for that item. The graph
    // data should never be affected by selected dates however.
    const filteredGraphDeliveryData = useMemo(() => {
      if (R.isNil(selectedLegendItemIndexes)) {
        return joinedDeliveryData;
      } else {
        const filteredGraphDeliveryData: any = [];
        for (const row of joinedDeliveryData) {
          const newRow = {
            date: row.data,
            data: {},
            otherData: {},
          };
          for (const index of selectedLegendItemIndexes) {
            const activeDataKey = sortedDimensionKeys[index];
            newRow.data = {
              ...newRow.data,
              [activeDataKey]: row.data[activeDataKey],
            };
            newRow.otherData = {
              ...newRow.otherData,
              [activeDataKey]: row.otherData[activeDataKey],
            };
          }
          filteredGraphDeliveryData.push(newRow);
        }
        return filteredGraphDeliveryData;
      }
    }, [joinedDeliveryData, selectedLegendItemIndexes, sortedDimensionKeys]);

    const [overviewData, overviewByDimensionData] = useMemo(() => {
      const overviewData: Record<string, { primary: number; comparison: number }> = {
        CPM: {
          primary: 0,
          comparison: 0,
        },
      };
      for (const option of deliveryMetricOptions) {
        overviewData[option.label] = {
          primary: 0,
          comparison: 0,
        };
      }
      const overviewByDimensionData = {};
      if (joinedDeliveryData) {
        // If we hover over or select a specific date in the cart, we need to update the legend
        // data. The graph data should not be affected by this, only greyed out.
        const filterIndex = R.defaultTo(hoveredDateIndex, selectedDateIndex);
        const slicedJoinedDeliveryData = R.isNil(filterIndex)
          ? joinedDeliveryData
          : R.slice(filterIndex, filterIndex + 1, joinedDeliveryData);
        for (const row of slicedJoinedDeliveryData) {
          if (row.data) {
            for (const key of R.keys(row.data)) {
              overviewByDimensionData[key] = R.defaultTo({}, overviewByDimensionData[key]);
              for (const option of deliveryMetricOptions) {
                overviewData[option.label].primary += row.data[key][option.value];
                if (overviewByDimensionData[key][option.label]) {
                  overviewByDimensionData[key][option.label].primary += row.data[key][option.value];
                } else {
                  overviewByDimensionData[key] = {
                    ...overviewByDimensionData[key],
                    [option.label]: {
                      primary: row.data[key][option.value],
                      comparison: 0,
                    },
                  };
                }
              }
            }
          }
          if (row.otherData) {
            for (const key of R.keys(row.otherData)) {
              overviewByDimensionData[key] = R.defaultTo({}, overviewByDimensionData[key]);
              for (const option of deliveryMetricOptions) {
                overviewData[option.label].comparison += row.otherData[key][option.value];
                if (overviewByDimensionData[key]) {
                  overviewByDimensionData[key][option.label].comparison +=
                    row.otherData[key][option.value];
                } else {
                  overviewByDimensionData[key] = {
                    ...overviewByDimensionData[key],
                    [option.label]: {
                      primary: 0,
                      comparison: row.otherData[key][option.value],
                    },
                  };
                }
              }
            }
          }
        }
        if (overviewData.Impressions.primary > 0) {
          overviewData.CPM.primary =
            (overviewData.Spend.primary * 1000) / overviewData.Impressions.primary;
        }
        if (overviewData.Impressions.comparison > 0) {
          overviewData.CPM.comparison =
            (overviewData.Spend.comparison * 1000) / overviewData.Impressions.comparison;
        }
      }
      return [overviewData, overviewByDimensionData];
    }, [deliveryMetricOptions, hoveredDateIndex, joinedDeliveryData, selectedDateIndex]);

    ///////////////
    // Graph
    ///////////////
    /* Chart */
    const grid = (
      <CartesianGrid
        stroke={CARTESIAN_GRID_STROKE}
        strokeWidth={CARTESIAN_GRID_STROKE_WIDTH}
        vertical={false}
      />
    );
    const yAxes = useMemo(() => {
      return [
        <YAxis
          axisLine={false}
          scale="linear"
          tick={TICK_STYLE}
          tickFormatter={number =>
            `${metric.isSpend && absolute ? "$" : ""}${
              absolute ? abbreviateNumber(number) : number * 100.0
            }${absolute ? "" : "%"}`
          }
          tickLine={false}
          yAxisId={LEFT_Y_AXIS_ID}
        />,
        <YAxis
          axisLine={false}
          orientation="right"
          scale="linear"
          tick={TICK_STYLE}
          tickFormatter={number => `$${abbreviateNumber(number)}`}
          tickLine={false}
          yAxisId={RIGHT_Y_AXIS_ID}
        />,
      ];
    }, [absolute, metric]);
    const graphYAxisTitles = !showPercentChange && (
      <div className="dc-aboveGraphContainer">
        <div className="dc-aboveGraph" style={{ width: graphWidth }}>
          <div className="dc-leftTitle">{metric.label}</div>
          <div className="dc-rightTitle">CPM</div>
        </div>
        <div className="border"></div>
      </div>
    );
    const xAxis = (
      <XAxis
        axisLine={false}
        dataKey={"date"}
        padding={X_AXIS_PADDING}
        tick={TICK_STYLE}
        tickFormatter={date => formatDateLabel(date, groupingKey)}
      />
    );

    const bars = useMemo(() => {
      if (joinedDeliveryData) {
        const bars: JSX.Element[] = [];
        if (includeOtherDateRange && !R.isNil(selectedLegendItemIndexes)) {
          const dates = R.sort((a, b) => a.localeCompare(b), R.keys(dataGroupedByDate));
          const otherDates = R.sort(
            (a, b) => a.localeCompare(b),
            R.keys(otherDateDataGroupedByDate)
          );
          const longerRange = dates.length > otherDates.length ? dates : otherDates;
          for (let dateIndex = 0; dateIndex < longerRange.length; dateIndex++) {
            bars.push(
              <Bar
                fill={getChannelSeriesColor(selectedLegendItemIndexes[0], usePrimaryPalette)}
                isAnimationActive={false}
                key={`${dateIndex} ${PRIMARY_STACK_ID}`}
                stackId={PRIMARY_STACK_ID}
                yAxisId={LEFT_Y_AXIS_ID}
                dataKey={val => {
                  return val &&
                    val.data &&
                    val.data[sortedDimensionKeys[selectedLegendItemIndexes[0]]] &&
                    val.data[sortedDimensionKeys[selectedLegendItemIndexes[0]]][metric.value]
                    ? val.data[sortedDimensionKeys[selectedLegendItemIndexes[0]]][metric.value]
                    : 0;
                }}
              />
            );
            bars.push(
              <Bar
                fill={GREYED_OUT_GREY}
                isAnimationActive={false}
                key={`${dateIndex} ${COMPARISON_STACK_ID}`}
                stackId={COMPARISON_STACK_ID}
                yAxisId={LEFT_Y_AXIS_ID}
                dataKey={val => {
                  return val &&
                    val.otherData &&
                    val.otherData[sortedDimensionKeys[selectedLegendItemIndexes[0]]] &&
                    val.otherData[sortedDimensionKeys[selectedLegendItemIndexes[0]]][metric.value]
                    ? val.otherData[sortedDimensionKeys[selectedLegendItemIndexes[0]]][metric.value]
                    : 0;
                }}
              />
            );
          }
          return bars;
        }
        for (let dimensionKeyIndex = 0; dimensionKeyIndex < activeKeyCount; ++dimensionKeyIndex) {
          const cells: JSX.Element[] = [];
          const fill =
            (!R.isNil(selectedLegendItemIndexes) &&
              !selectedLegendItemIndexes.includes(dimensionKeyIndex)) ||
            (R.isNil(selectedLegendItemIndexes) &&
              !R.isNil(hoveredLegendItemIndex) &&
              hoveredLegendItemIndex !== dimensionKeyIndex)
              ? GREYED_OUT_GREY
              : getChannelSeriesColor(dimensionKeyIndex, usePrimaryPalette);
          for (let dateIndex = 0; dateIndex < joinedDeliveryData.length; ++dateIndex) {
            cells.push(
              <Cell
                fill={fill}
                key={dateIndex}
                opacity={
                  (!R.isNil(selectedDateIndex) && selectedDateIndex !== dateIndex) ||
                  (R.isNil(selectedDateIndex) &&
                    !R.isNil(hoveredDateIndex) &&
                    hoveredDateIndex !== dateIndex)
                    ? 0.3
                    : 1
                }
                onClick={() => {
                  const newDateIndex =
                    R.isNil(selectedDateIndex) || dateIndex !== selectedDateIndex
                      ? dateIndex
                      : undefined;
                  setSelectedDateIndex(newDateIndex);
                }}
                onMouseLeave={() => setHoveredDateIndex(undefined)}
                onMouseOver={() => {
                  if (!hoveredDateIndex && R.isNil(selectedDateIndex)) {
                    setHoveredDateIndex(dateIndex);
                  }
                }}
              />
            );
          }
          bars.push(
            <Bar
              isAnimationActive={false}
              key={dimensionKeyIndex}
              stackId={PRIMARY_STACK_ID}
              yAxisId={LEFT_Y_AXIS_ID}
              dataKey={val => {
                const primaryVal =
                  val &&
                  val.data &&
                  val.data[sortedDimensionKeys[dimensionKeyIndex]] &&
                  val.data[sortedDimensionKeys[dimensionKeyIndex]][metric.value]
                    ? val.data[sortedDimensionKeys[dimensionKeyIndex]][metric.value]
                    : 0;
                const comparisonVal =
                  includeOtherDateRange &&
                  val &&
                  val.otherData &&
                  val.otherData[sortedDimensionKeys[dimensionKeyIndex]] &&
                  val.otherData[sortedDimensionKeys[dimensionKeyIndex]][metric.value]
                    ? val.otherData[sortedDimensionKeys[dimensionKeyIndex]][metric.value]
                    : 0;
                return primaryVal ? primaryVal : comparisonVal;
              }}
            >
              {cells}
            </Bar>
          );
        }
        return bars;
      }
    }, [
      activeKeyCount,
      dataGroupedByDate,
      hoveredDateIndex,
      hoveredLegendItemIndex,
      includeOtherDateRange,
      joinedDeliveryData,
      metric,
      otherDateDataGroupedByDate,
      selectedDateIndex,
      selectedLegendItemIndexes,
      sortedDimensionKeys,
      usePrimaryPalette,
    ]);
    const CPMLine = useMemo(
      () => (
        <Line
          dot={false}
          isAnimationActive={false}
          name="CPM"
          stroke={"#000000"}
          strokeWidth={3}
          scale="linear"
          type="monotone"
          yAxisId={RIGHT_Y_AXIS_ID}
          dataKey={val => {
            const spendKey = R.defaultTo(
              { value: "cost" },
              R.find(option => option.label === "Spend", deliveryMetricOptions)
            ).value;
            const impressionsKey = R.defaultTo(
              { value: "count" },
              R.find(option => option.label === "Impressions", deliveryMetricOptions)
            ).value;
            let spend = 0;
            let impressions = 0;
            let otherSpend = 0;
            let otherImpressions = 0;
            if (val && val.data) {
              for (const dimensionKey of R.keys(val.data)) {
                spend += val.data[dimensionKey][spendKey];
                impressions += val.data[dimensionKey][impressionsKey];
              }
            }
            const CPM = spend && impressions ? (spend * 1000) / impressions : 0;
            if (CPM === 0 && val && val.otherData && includeOtherDateRange) {
              for (const dimensionKey of R.keys(val.otherData)) {
                otherSpend += val.otherData[dimensionKey][spendKey];
                otherImpressions += val.otherData[dimensionKey][impressionsKey];
              }
            }
            const otherCPM =
              otherSpend && otherImpressions ? (otherSpend * 1000) / otherImpressions : 0;
            return CPM ? CPM : otherCPM;
          }}
        />
      ),
      [deliveryMetricOptions, includeOtherDateRange]
    );

    /* Legend */
    const legendDateRange = useMemo(() => {
      if (joinedDeliveryData && (!R.isNil(selectedDateIndex) || !R.isNil(hoveredDateIndex))) {
        const dateIndex = R.defaultTo(hoveredDateIndex, selectedDateIndex) as number;
        const dateRange = (
          <div className="legendDateRange">
            <MdArrowBackIos
              onClick={() => {
                if (dateIndex > 0) {
                  setSelectedDateIndex(R.defaultTo(0, dateIndex - 1));
                }
              }}
              style={{
                opacity: dateIndex > 0 ? 1 : 0,
              }}
            />
            <div className="primary">
              {formatDateLabel(joinedDeliveryData[dateIndex].date, groupingKey)}
            </div>
            <MdArrowForwardIos
              onClick={() => {
                if (dateIndex < joinedDeliveryData.length - 1) {
                  setSelectedDateIndex(R.defaultTo(0, dateIndex + 1));
                }
              }}
              style={{
                opacity: dateIndex < joinedDeliveryData.length - 1 ? 1 : 0,
              }}
            />
          </div>
        );
        return <div className="legendDateRangeContainer">{dateRange}</div>;
      }
      const dateRange = (
        <div className="legendDateRange">
          <div className="primary">{`${formatDateLabel(dates.start)} – ${formatDateLabel(
            dates.end
          )}`}</div>
          {includeOtherDateRange && <div className="vs">vs</div>}
          {includeOtherDateRange && otherDates && (
            <div className="comparison">{`${formatDateLabel(otherDates.start)} – ${formatDateLabel(
              otherDates.end
            )}`}</div>
          )}
        </div>
      );
      return <div className="legendDateRangeContainer">{dateRange}</div>;
    }, [
      dates,
      groupingKey,
      hoveredDateIndex,
      includeOtherDateRange,
      joinedDeliveryData,
      otherDates,
      selectedDateIndex,
    ]);
    const legendCPMContainer = useMemo(() => {
      let metricTotal = overviewData[metric.label].primary;
      let totalCPM = overviewData.CPM.primary;
      let metricPercentageChange =
        overviewData[metric.label].primary && overviewData[metric.label].comparison
          ? ((overviewData[metric.label].primary - overviewData[metric.label].comparison) /
              overviewData[metric.label].comparison) *
            100
          : 0;
      let CPMPercentageChange =
        overviewData.CPM.primary && overviewData.CPM.comparison
          ? ((overviewData.CPM.primary - overviewData.CPM.comparison) /
              overviewData.CPM.comparison) *
            100
          : 0;
      let isTotal = R.isNil(hoveredDateIndex) && R.isNil(selectedDateIndex);
      if (!R.isNil(selectedLegendItemIndexes) || !R.isNil(hoveredLegendItemIndex)) {
        isTotal = false;
        const indexes: number[] = R.isNil(selectedLegendItemIndexes)
          ? [hoveredLegendItemIndex as number]
          : selectedLegendItemIndexes;
        metricTotal = R.sum(
          indexes.map(
            index => overviewByDimensionData[sortedDimensionKeys[index]][metric.label].primary
          )
        );
        totalCPM = R.sum(
          indexes.map(
            index =>
              (overviewByDimensionData[sortedDimensionKeys[index]].Spend.primary * 1000) /
              overviewByDimensionData[sortedDimensionKeys[index]].Impressions.primary
          )
        );
      }
      return (
        <div className="legendCPMContainer">
          <div className="spendImp">
            <div className="iconContainer">
              <div className="icon" />
            </div>
            <div className="label">
              {isTotal ? "Total " : ""}
              {metric.label}
              {!isTotal && <b style={{ opacity: 0 }}>{"Total "}</b>}
            </div>
            <div className="valueContainer">
              <div className="value">
                {metric.isSpend ? toPrettySpend(metricTotal) : toPrettyNumber(metricTotal)}
              </div>
              {includeOtherDateRange && (
                <div>
                  <PercentChange percentChange={metricPercentageChange} size={"sm"} />
                </div>
              )}
            </div>
          </div>
          <div className="CPM">
            <div className="iconContainer">
              <div className="icon" />
            </div>
            <div className="label">
              {isTotal ? "Overall CPM" : "CPM"}
              {!isTotal && <b style={{ opacity: 0 }}>{"Overall "}</b>}
            </div>
            <div className="valueContainer">
              <div className="value">{toPrettyCPX(totalCPM)}</div>
              {includeOtherDateRange && (
                <div className="percentChangeContainer">
                  <PercentChange percentChange={CPMPercentageChange} size={"sm"} />
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }, [
      hoveredDateIndex,
      hoveredLegendItemIndex,
      includeOtherDateRange,
      metric,
      overviewByDimensionData,
      overviewData,
      selectedDateIndex,
      selectedLegendItemIndexes,
      sortedDimensionKeys,
    ]);
    const legendItems = useMemo(() => {
      const legendItems: JSX.Element[] = [];
      for (let itemIndex = 0; itemIndex < sortedDimensionKeys.length; itemIndex++) {
        const key = sortedDimensionKeys[itemIndex];
        if (overviewByDimensionData[key]) {
          const percentChange =
            overviewByDimensionData[key][metric.label].primary &&
            overviewByDimensionData[key][metric.label].comparison
              ? ((overviewByDimensionData[key][metric.label].primary -
                  overviewByDimensionData[key][metric.label].comparison) /
                  overviewByDimensionData[key][metric.label].comparison) *
                100
              : 0;
          const compareItem =
            includeOtherDateRange &&
            otherDates &&
            selectedLegendItemIndexes &&
            selectedLegendItemIndexes.includes(itemIndex);
          const colorContainer = (
            <div className="colorContainer">
              <div
                className="color"
                style={{
                  backgroundColor: getChannelSeriesColor(itemIndex, usePrimaryPalette),
                }}
              />
            </div>
          );
          const isci =
            data && data.keys && data.keys[key]
              ? (data.keys[key] as CreativeKeyInfo).isci
              : undefined;
          const network =
            data && data.keys && data.keys[key]
              ? (data.keys[key] as NetworkKeyInfo).network
              : undefined;
          const url = isci
            ? `${CDN}/creatives/${isci}.png`
            : `${CDN}/networks/${dimension === "Network Group" ? key.toUpperCase() : network}.png`;
          const iconContainer = key &&
            data &&
            data.keys &&
            (isci || dimension === "Network Group" || network) && (
              <div className="iconContainer">
                <Img className="icon" src={url} />
              </div>
            );
          const labelContainer = (
            <div className="labelContainer">
              {colorContainer}
              {iconContainer}
              <div className="label">{key}</div>
            </div>
          );
          const percentageChangeContainer = (
            <div className="percentChangeContainer">
              <PercentChange percentChange={percentChange} size={"sm"} />
            </div>
          );
          const percentOfTotal =
            overviewData[metric.label].primary && overviewData[metric.label].primary > 0
              ? (overviewByDimensionData[key][metric.label].primary /
                  overviewData[metric.label].primary) *
                100
              : 0;
          const valueContainer = (
            <div className="valueContainer">
              <div className="value">
                {!absolute
                  ? `${
                      percentOfTotal > 1 || percentOfTotal === 0
                        ? percentOfTotal.toFixed(0)
                        : percentOfTotal.toFixed(1)
                    }%`
                  : metric.isSpend
                  ? toPrettySpend(overviewByDimensionData[key][metric.label].primary)
                  : toPrettyNumber(overviewByDimensionData[key][metric.label].primary)}
              </div>
              {includeOtherDateRange && percentageChangeContainer}
            </div>
          );
          const comparisonContainer = includeOtherDateRange &&
            otherDates &&
            selectedLegendItemIndexes &&
            selectedLegendItemIndexes.includes(itemIndex) && (
              <div
                className="comparisonContainer"
                style={{
                  border: `1px solid ${getChannelSeriesColor(itemIndex, usePrimaryPalette)}`,
                }}
              >
                <div className="itemDescription">
                  <div className="labelContainer">
                    {iconContainer}
                    <div className="label">{key}</div>
                  </div>
                  <div className="valueContainer">{percentageChangeContainer}</div>
                </div>

                <div className="dateContainer">
                  {colorContainer}
                  <div className="dateRange">{`${formatDateLabel(dates.start)} – ${formatDateLabel(
                    dates.end
                  )}`}</div>
                  <div className="value">
                    <div>
                      {metric.isSpend
                        ? toPrettySpend(overviewByDimensionData[key][metric.label].primary)
                        : toPrettyNumber(overviewByDimensionData[key][metric.label].primary)}
                    </div>
                  </div>
                </div>
                <div className="dateContainer">
                  <div className="colorContainer">
                    <div
                      className="color"
                      style={{
                        backgroundColor: GREYED_OUT_GREY,
                      }}
                    />
                  </div>
                  <div className="dateRange">{`${formatDateLabel(
                    otherDates.start
                  )} – ${formatDateLabel(otherDates.end)}`}</div>
                  <div className="value">
                    {metric.isSpend
                      ? toPrettySpend(overviewByDimensionData[key][metric.label].comparison)
                      : toPrettyNumber(overviewByDimensionData[key][metric.label].comparison)}
                  </div>
                </div>
              </div>
            );
          legendItems.push(
            <div
              className={`legendItemContainer ${
                !R.isNil(selectedLegendItemIndexes) &&
                !selectedLegendItemIndexes.includes(itemIndex)
                  ? "greyedOut"
                  : ""
              }`}
              onClick={() => {
                if (
                  R.isNil(selectedLegendItemIndexes) ||
                  (includeOtherDateRange && selectedLegendItemIndexes[0] !== itemIndex)
                ) {
                  setSelectedLegendItemIndexes([itemIndex]);
                } else if (!R.isNil(selectedLegendItemIndexes)) {
                  const isNew = !selectedLegendItemIndexes.includes(itemIndex);
                  if (isNew && !includeOtherDateRange) {
                    setSelectedLegendItemIndexes(
                      R.sort((a, b) => (a > b ? 1 : -1), [...selectedLegendItemIndexes, itemIndex])
                    );
                  } else {
                    const newIndexes = R.filter(
                      index => index !== itemIndex,
                      selectedLegendItemIndexes
                    );
                    setSelectedLegendItemIndexes(newIndexes.length > 0 ? newIndexes : undefined);
                  }
                }
              }}
              onMouseLeave={() => setHoveredLegendItemIndex(undefined)}
              onMouseOver={() => {
                if (!hoveredLegendItemIndex && R.isNil(selectedLegendItemIndexes)) {
                  setHoveredLegendItemIndex(itemIndex);
                }
              }}
            >
              {!compareItem && labelContainer}
              {!compareItem && valueContainer}
              {comparisonContainer}
            </div>
          );
        }
      }
      return <div className="legendItems">{legendItems}</div>;
    }, [
      absolute,
      data,
      dates,
      dimension,
      hoveredLegendItemIndex,
      includeOtherDateRange,
      metric,
      otherDates,
      overviewByDimensionData,
      overviewData,
      selectedLegendItemIndexes,
      sortedDimensionKeys,
      usePrimaryPalette,
    ]);
    const legendSelectAll = !R.isNil(selectedLegendItemIndexes) && (
      <div className="selectedAllLabel" onClick={() => setSelectedLegendItemIndexes(undefined)}>
        Select All
      </div>
    );
    const legend = (
      <div className="dc-Legend">
        {legendDateRange}
        {legendCPMContainer}
        {<div className="legendDivider" />}
        {legendSelectAll}
        {legendItems}
      </div>
    );

    /* Graph */
    const graph = (
      <div className="dc-Graph">
        {graphYAxisTitles}
        <div className="dc-GraphContents">
          <div id="dc-Graph" className="dc-Graph">
            <AutoSizer>
              {({ width, height }) => {
                setGraphWidth(width);
                return (
                  <ComposedChart
                    data={filteredGraphDeliveryData}
                    height={height}
                    margin={{ bottom: -10 }}
                    stackOffset={absolute ? "none" : "expand"}
                    width={width}
                  >
                    {grid}
                    {bars}
                    {CPMLine}
                    {xAxis}
                    {yAxes}
                  </ComposedChart>
                );
              }}
            </AutoSizer>
          </div>
          <div className="rightOfChart">{legend}</div>
        </div>
      </div>
    );

    const tableData = useMemo(() => {
      const tableData: {
        data: Record<string, number>;
        date: string;
        dimensionValue: string;
        otherData: Record<string, number>;
        otherDate: string;
      }[] = [];
      if (dataGroupedByDate && otherDateDataGroupedByDate && filteredGraphDeliveryData) {
        const sortedDataDateKeys = R.sort((a, b) => a.localeCompare(b), R.keys(dataGroupedByDate));
        const sortedOtherDataDateKeys = R.sort(
          (a, b) => a.localeCompare(b),
          R.keys(otherDateDataGroupedByDate)
        );
        const length =
          sortedOtherDataDateKeys.length > sortedDataDateKeys.length
            ? sortedOtherDataDateKeys.length
            : sortedDataDateKeys.length;
        const dataKeysMap: Record<string, string> = {};
        for (const row of filteredGraphDeliveryData) {
          for (const key of R.keys(row.data)) {
            dataKeysMap[key] = key;
          }
          for (const key of R.keys(row.otherData)) {
            dataKeysMap[key] = key;
          }
        }
        const combinedKeys = R.keys(dataKeysMap);
        for (let i = 0; i < length; i++) {
          for (const dimensionValue of combinedKeys) {
            const date = sortedDataDateKeys[i];
            const data =
              sortedDataDateKeys[i] &&
              dataGroupedByDate[sortedDataDateKeys[i]] &&
              dataGroupedByDate[sortedDataDateKeys[i]][dimensionValue]
                ? dataGroupedByDate[sortedDataDateKeys[i]][dimensionValue]
                : {};
            const otherData =
              sortedOtherDataDateKeys[i] &&
              otherDateDataGroupedByDate[sortedOtherDataDateKeys[i]] &&
              otherDateDataGroupedByDate[sortedOtherDataDateKeys[i]][dimensionValue]
                ? otherDateDataGroupedByDate[sortedOtherDataDateKeys[i]][dimensionValue]
                : {};
            const otherDate = sortedOtherDataDateKeys[i];
            tableData.push({
              data,
              date,
              dimensionValue,
              otherData,
              otherDate,
            });
          }
        }
      }
      return tableData;
    }, [dataGroupedByDate, filteredGraphDeliveryData, otherDateDataGroupedByDate]);

    // Table Logic
    const [headers, superHeaders] = useMemo(() => {
      let superHeaders;
      const headers: any[] = [
        {
          flex: 1,
          label: dimension,
          name: "dimensionValue",
          nonInteractive: true,
          renderer: (item: any) => item.dimensionValue || "-",
        },
        {
          label: "Date",
          name: "date",
          nonInteractive: true,
          width: 80,
          renderer: (item: any) => formatDateLabel(item.date, "", true) || "--",
        },
      ];
      for (const option of deliveryMetricOptions) {
        headers.push({
          flex: 1,
          label: option.label,
          name: option.value,
          nonInteractive: true,
          renderer: (item: any) =>
            option.isSpend
              ? toPrettySpend(R.defaultTo(0, item.data[option.value]))
              : toPrettyNumber(R.defaultTo(0, item.data[option.value])),
        });
      }
      if (includeOtherDateRange) {
        headers.push({
          label: "Comparison Date",
          name: "otherDate",
          nonInteractive: true,
          width: 80,
          renderer: (item: any) => formatDateLabel(item.otherDate, "", true) || "--",
        });
        for (const option of deliveryMetricOptions) {
          headers.push({
            flex: 1,
            label: option.label,
            name: option.value,
            nonInteractive: true,
            renderer: (item: any) =>
              option.isSpend
                ? toPrettySpend(R.defaultTo(0, item.otherData[option.value]))
                : toPrettyNumber(R.defaultTo(0, item.otherData[option.value])),
          });
        }
        const superHeaderSpan = deliveryMetricOptions.length + 1;
        superHeaders = [
          { span: 1, data: "" },
          { span: superHeaderSpan, data: "Primary Period" },
          { span: superHeaderSpan, data: "Comparison Period" },
        ];
      }
      return [headers, superHeaders];
    }, [deliveryMetricOptions, dimension, includeOtherDateRange]);

    const excelDownload = useCallback(() => {
      const unionData = [
        ...R.map(row => {
          const impressions = R.sum(R.map(key => row.data[key].impressions, sortedDimensionKeys));
          const otherImpressions =
            impressions || !includeOtherDateRange
              ? 0
              : R.sum(R.map(key => row.otherData[key].impressions, sortedDimensionKeys));
          const spend = R.sum(R.map(key => row.data[key].spend, sortedDimensionKeys));
          const otherSpend =
            spend || !includeOtherDateRange
              ? 0
              : R.sum(R.map(key => row.otherData[key].spend, sortedDimensionKeys));
          return {
            date: row.date,
            impressions: impressions ? impressions : otherImpressions,
            spend: spend ? spend : otherSpend,
          };
        }, joinedDeliveryData),
      ];
      exportToExcel(unionData, `${company}_Compare_Metrics`);
    }, [company, includeOtherDateRange, joinedDeliveryData, sortedDimensionKeys]);

    const pngDownload = useCallback(async () => {
      await downloadPNG(".dc-Graph", `${company}_Compare_Metrics`);
    }, [company]);

    const table = (
      <BPMTable
        data={tableData}
        filterBar={false}
        headerHeight={30}
        headers={headers}
        superHeaderHeight={30}
        superHeaders={superHeaders}
      />
    );

    // Title Pickers
    const dateGroupingPicker = (
      <Dropdown
        type={DropdownToggleType.WIDGET_TITLE}
        value={groupingKey}
        options={dateGroupingOptions}
        onChange={option => {
          setGroupingKey(option as DateGroupingKey);
          setSelectedDateIndex(undefined);
          setHoveredDateIndex(undefined);
        }}
      />
    );
    const metricPicker = (
      <Dropdown
        type={DropdownToggleType.WIDGET_TITLE}
        value={metric.value}
        options={deliveryMetricOptions}
        onChange={option => {
          const newOption = R.find(R.propEq("value", option), deliveryMetricOptions);
          setMetric(R.defaultTo(deliveryMetricOptions[0], newOption));
        }}
      />
    );

    const dimensionPicker = (
      <Dropdown
        className="singleChannelDimensionDropdown"
        onChange={dimension => setDimension(dimension)}
        options={dimensionOptions}
        type={DropdownToggleType.WIDGET_TITLE}
        value={dimension}
      />
    );

    // No Data Logic
    const emptyDeliveryData = (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flex: 1,
          fontSize: "26px",
          justifyContent: "center",
        }}
      >
        No data to show
      </div>
    );

    // Chart Container Logic
    const chartTitle = (
      <>
        {dateGroupingPicker}
        {metricPicker}
        <div style={{ fontSize: 22 }}>by</div>
        {dimensionPicker}
        <div className="titleDivider"></div>
        <div className="primaryDateRange">{`${formatDateLabel(dates.start)} – ${formatDateLabel(
          dates.end
        )}`}</div>
        {!includeOtherDateRange &&
          showAddOtherDateRange &&
          (R.isNil(selectedLegendItemIndexes) || selectedLegendItemIndexes.length < 2) && (
            <div
              className="addOtherDateRange"
              onClick={() => {
                setIncludeOtherDateRange(true);
                const dates = R.sort((a, b) => a.localeCompare(b), R.keys(dataGroupedByDate));
                const otherDates = R.sort(
                  (a, b) => a.localeCompare(b),
                  R.keys(otherDateDataGroupedByDate)
                );
                const allDates = R.sort(
                  (a, b) => a.localeCompare(b),
                  R.uniq([...dates, ...otherDates])
                );
                if (selectedDateIndex) {
                  const selectedDate = joinedDeliveryData[selectedDateIndex].date;
                  for (let dateIndex = 0; dateIndex < allDates.length; dateIndex++) {
                    if (selectedDate === allDates[dateIndex]) {
                      setSelectedDateIndex(dateIndex);
                      break;
                    }
                  }
                }
              }}
            >
              +
            </div>
          )}
        {includeOtherDateRange && otherDates && (
          <>
            <div className="otherDateRange">{`vs ${formatDateLabel(
              otherDates.start
            )} – ${formatDateLabel(otherDates.end)}`}</div>
            {showAddOtherDateRange && (
              <div
                className="removeButton"
                onClick={() => {
                  if (!R.isNil(selectedDateIndex)) {
                    const selectedDate = joinedDeliveryData[selectedDateIndex].date;
                    const start = new Date(dates.start).getTime();
                    const end = new Date(dates.end).getTime();
                    const selected = new Date(selectedDate).getTime();
                    if (selected < start || selected > end) {
                      setSelectedDateIndex(undefined);
                    }
                  }
                  setIncludeOtherDateRange(false);
                  setShowPercentChange(false);
                }}
              >
                <MdClose />
              </div>
            )}
          </>
        )}
      </>
    );

    const rightActions = (
      <>
        <Dropdown
          background="light"
          design="secondary"
          onChange={value => setAbsolute(value === "Absolute")}
          options={[
            { label: "Absolute", value: "Absolute" },
            { label: "Relative", value: "Relative" },
          ]}
          size="sm"
          type={DropdownToggleType.FILLED}
          value={absolute ? "Absolute" : "Relative"}
        />
        <IconToggleButton
          size="sm"
          options={[
            { key: "showTable", icon: <MdOutlineTableRows />, label: "table view" },
            { key: "showGraph", icon: <MdShowChart />, label: "graph view" },
          ]}
          selectedOption={selectedToggleOption}
          onChange={setSelectedToggleOption}
        />
        <DownloadDropdown
          size="sm"
          onClickOptions={[excelDownload, pngDownload]}
          disabledMenuOptions={
            selectedToggleOption === "showGraph"
              ? { XLSX: false, PNG: false }
              : { XLSX: false, PNG: true }
          }
        />
      </>
    );

    useEffect(() => {
      if (!R.map(R.prop("value"), dateGroupingOptions).includes(groupingKey)) {
        setGroupingKey(dateGroupingOptions[0].value);
      }
    }, [dateGroupingOptions, groupingKey]);

    useEffect(() => {
      const handleEsc = event => {
        if (event.key === "Escape") {
          setSelectedDateIndex(undefined);
          setSelectedLegendItemIndexes(undefined);
        }
      };
      window.addEventListener("keydown", handleEsc);
      return () => {
        window.removeEventListener("keydown", handleEsc);
      };
    }, []);

    return (
      <div
        className="singleChannelDeliveryChartContainer"
        onMouseLeave={() => setShowAddOtherDateRange(false)}
        onMouseOver={() => setShowAddOtherDateRange(true)}
      >
        <ChartContainer enableHoverDesign rightActions={rightActions} title={chartTitle}>
          {R.isEmpty(joinedDeliveryData)
            ? emptyDeliveryData
            : selectedToggleOption === "showGraph"
            ? graph
            : table}
        </ChartContainer>
      </div>
    );
  }
);

export default SingleChannelDeliveryChart;
