// import DerivedIDDescriptionUpdater from "./StreamingTools/DerivedIDDescriptionUpdater";
import "./AdOps.scss";
import { Button, Modal } from "react-bootstrap";
import { Page } from "../Components";
import { SetError, useSetError } from "../redux/modals";
import { useExperimentFlag } from "../utils/experiments/experiment-utils";
import Bonuser from "./StreamingTools/Bonuser";
import ExtremeReachCampaignAdder from "./StreamingTools/ExtremeReachCampaignAdder";
import FlashtalkingCampaignAdder from "./StreamingTools/FlashtalkingCampaignAdder";
import FlashtalkingSiteAdder from "./StreamingTools/FlashtalkingSiteAdder";
import LinearIngestions from "./LinearTools/LinearIngestions";
import LogUpdates from "./LinearTools/LogUpdates";
import NexxenAdvertiserAdder from "./StreamingTools/NexxenAdvertiserAdder";
import React, { useState } from "react";
import TransferByMethod from "./StreamingTools/TransferByMethod";
import TransferSpendAndImpressions from "./StreamingTools/TransferSpendAndImpressions";

type Platform = "Linear" | "Streaming";

export interface AdOpsToolProps {
  utils: AdOpsUtilFunctions;
}

export interface AdOpsUtilFunctions {
  setError: SetError;
  setModalMessage: React.Dispatch<React.SetStateAction<string>>;
  setShowModalConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
}

interface AdOpsProps {
  platform: Platform;
}

const AdOps: React.FC<AdOpsProps> = ({ platform }) => {
  const setError = useSetError();
  const [modalMessage, setModalMessage] = useState("");
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const shouldEnableBonuserTools = useExperimentFlag("enableBonuserTools");

  const utils = { setError, setModalMessage, setShowModalConfirmation };

  const ModalConfirmation = ({ show }) => {
    return (
      <Modal size="lg" keyboard={false} show={show} onHide={() => setShowModalConfirmation(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{modalMessage}</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => {
              setShowModalConfirmation(false);
            }}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <Page title={`${platform} AdOps Tools`} pageType={`${platform} AdOps Tools`}>
      <div className="adOpsContainer">
        {platform === "Linear" && (
          <div className="adOpsRow">
            <LinearIngestions utils={utils} />
            <LogUpdates utils={utils} />
          </div>
        )}
        {platform === "Streaming" && (
          <div>
            <div className="adOpsRow">
              <ExtremeReachCampaignAdder utils={utils} />
              <NexxenAdvertiserAdder utils={utils} />
              {shouldEnableBonuserTools && <Bonuser utils={utils} />}
              {/* <DerivedIDDescriptionUpdater utils={utils} /> */}
              <FlashtalkingCampaignAdder utils={utils} />
              <FlashtalkingSiteAdder utils={utils} />
            </div>
            <div className="adOpsRow">
              {shouldEnableBonuserTools && <TransferSpendAndImpressions utils={utils} />}
              {shouldEnableBonuserTools && <TransferByMethod utils={utils} />}
            </div>
          </div>
        )}
      </div>
      <ModalConfirmation show={showModalConfirmation} />
    </Page>
  );
};

export default AdOps;
