import "./EditKpiMapping.scss";
import React, { useState, useCallback, useContext, useMemo } from "react";
import { useSetAreYouSure, useSetError } from "../redux/modals";
import * as R from "ramda";
import * as uuid from "uuid";
import { MdDelete, MdSave, MdAdd } from "react-icons/md";
import { Button, ButtonType, Spinner } from "../Components";
import { KpiMappingsContext } from "./KpiMapping";
import { CrossChannelLambdaFetch } from "../utils/fetch-utils";
import SourceColumn from "./SourceColumn";
import CrossChannelKpiColumn from "./CrossChannelKpiColumn";
import { KpiMappings, EditsMap, NewSourceKpis } from "@blisspointmedia/bpm-types/dist/KpiMapping";
import { ButtonFrameworkVariant } from "../Components/ButtonFramework";

export interface HandleKpiEditInput {
  kpiId: number;
  kpiLabel: string;
  isCrossChannelKpi?: boolean;
}

export interface HandleNewSourceKpiInput {
  crossChannelKpiId: number;
  source: string;
  sourceId: number;
  kpiLabel: string;
  accountId: string;
}

export interface HandleNewRowInput {
  crossChannelKpiId: string;
  kpiLabel: string;
  sourceLabel: string;
  accountId?: string;
  isCrossChannelKpi: boolean;
}

interface CombinedCrossChannelKpi {
  crossChannelKpiLabel: string;
  crossChannelKpiId: string | number;
  isNewRow?: boolean;
}

export type CombinedCrossChannelKpis = CombinedCrossChannelKpi[];

interface EditKpiMappingProps {
  company: string;
  data: KpiMappings;
  setEditMode: (editMode: boolean) => void;
  setKpiMappings: (kpiMappings: KpiMappings | undefined) => void;
}

export const makeNewSourceKpiKey = (
  crossChannelKpiId: number | string,
  source: string,
  accountId: string
): string => `${crossChannelKpiId}_${source}_${accountId}`;

const EditKpiMapping: React.FC<EditKpiMappingProps> = ({
  company,
  data,
  setEditMode,
  setKpiMappings,
}) => {
  const setError = useSetError();
  const setAreYouSure = useSetAreYouSure(true);

  const { sourcesWithKpis, sourceIdMap, accountInfoBySource } = useContext(KpiMappingsContext);

  const [editsMap, setEditsMap] = useState<EditsMap>({}); // Edit existing cross channel KPIs or source KPIs
  const [newSourceKpis, setNewSourceKpis] = useState<NewSourceKpis>({}); // Add new source KPI values for existing cross channel KPIs
  const [newRows, setNewRows] = useState<KpiMappings>({}); // Add new cross channel KPIs and source KPIs
  const [saving, setSaving] = useState(false);

  // Edit the name of an existing Cross Channel KPI name or edit a Source KPI for the existing Cross Channel KPI
  const handleKpiEdit = useCallback(
    ({ kpiId, kpiLabel, isCrossChannelKpi = false }: HandleKpiEditInput) => {
      const key = `${kpiId}`;

      setEditsMap(prevEdits => ({
        ...prevEdits,
        [key]: {
          id: kpiId,
          newKpi: kpiLabel,
          isCrossChannelKpi,
        },
      }));
    },
    []
  );

  // Add a new Source KPI for an existing Cross Channel KPI
  const handleNewSourceKpi = useCallback(
    ({ crossChannelKpiId, source, sourceId, kpiLabel, accountId }: HandleNewSourceKpiInput) => {
      // We need to map the new KPI to the cross channel KPI ID
      // and it ends up being an insert into kpi_mappings instead of an update
      const key = makeNewSourceKpiKey(crossChannelKpiId, source, accountId);

      setNewSourceKpis(prevEdits => ({
        ...prevEdits,
        [key]: {
          newKpi: kpiLabel,
          crossChannelKpiId,
          source,
          id: sourceId,
          accountId,
        },
      }));
    },
    []
  );

  // Add a new Cross Channel KPI and Source KPIs for the new Cross Channel KPI
  const handleNewRow = useCallback(
    ({
      crossChannelKpiId,
      kpiLabel,
      sourceLabel,
      accountId,
      isCrossChannelKpi,
    }: HandleNewRowInput) => {
      const updateItemKpiLabel = (sourceData, accountId, newKpiLabel) => {
        const newValue = { kpi: newKpiLabel };
        let merged: any[] = [];
        for (let item of sourceData) {
          if (item.accountId === accountId) {
            merged.push({ ...item, ...newValue });
          } else {
            merged.push(item);
          }
        }
        return merged;
      };

      if (isCrossChannelKpi) {
        setNewRows(prevNewRows => ({
          ...prevNewRows,
          [crossChannelKpiId]: {
            ...prevNewRows[crossChannelKpiId],
            crossChannelKpiLabel: kpiLabel,
          },
        }));
      } else {
        //@ts-ignore
        setNewRows(prevNewRows => ({
          ...prevNewRows,
          [crossChannelKpiId]: {
            ...prevNewRows[crossChannelKpiId],
            mappings: {
              ...R.path([crossChannelKpiId, "mappings"], prevNewRows),
              [sourceLabel]: updateItemKpiLabel(
                R.path([crossChannelKpiId, "mappings", sourceLabel], prevNewRows),
                accountId,
                kpiLabel
              ),
            },
          },
        }));
      }
    },
    []
  );

  // Add a new default row as a placeholder for brand new Cross Channel KPI and Source KPIs for it
  const addNewRow = useCallback(() => {
    const newRowKey = uuid.v4();

    let initSourceMappings = {};
    for (let source of sourcesWithKpis) {
      let accounts = accountInfoBySource[source];
      if (!accounts || !accounts.length) {
        // @ts-ignore
        accounts = [{ accountId: null, accountName: null }];
      }
      for (let accountInfo of accounts) {
        const { accountId } = accountInfo;
        initSourceMappings = R.mergeDeepRight(initSourceMappings, {
          [source]: [
            ...R.pathOr([], [source], initSourceMappings),
            { id: sourceIdMap[source], source, accountId, kpi: "" },
          ],
        });
      }
    }

    setNewRows(prevNewRows => ({
      ...prevNewRows,
      [`${newRowKey}`]: {
        isNewRow: true,
        crossChannelKpiId: newRowKey,
        crossChannelKpiLabel: "",
        mappings: initSourceMappings,
      },
    }));
  }, [accountInfoBySource, sourcesWithKpis, sourceIdMap]);

  const deleteNewRow = useCallback(id => {
    setNewRows(prevNewRows => {
      const newRowsCopy = { ...prevNewRows };
      delete newRowsCopy[id];
      return newRowsCopy;
    });
  }, []);

  const saveChanges = useCallback(async () => {
    try {
      setSaving(true);
      try {
        await setAreYouSure({
          title: "Are you sure?",
          message: "Are you sure you want to save these changes?",
          okayText: "Save",
        });
      } catch (e) {
        setSaving(false);
        return;
      }

      await CrossChannelLambdaFetch("/setKpiMappings", {
        method: "POST",
        body: {
          editsMap: R.values(editsMap),
          newSourceKpis: R.values(newSourceKpis),
          newRows: R.values(newRows),
          company,
        },
      });
      setSaving(false);
      setEditMode(false);
      setEditsMap({});
      setNewSourceKpis({});
      setNewRows({});
      setKpiMappings(undefined);
    } catch (e) {
      setSaving(false);
      setError({
        message: `Failed to save KPI mapping edits. Error: ${e.message}`,
        reportError: e,
      });
    }
  }, [
    company,
    editsMap,
    newSourceKpis,
    newRows,
    setEditMode,
    setKpiMappings,
    setAreYouSure,
    setError,
  ]);

  const clearEdits = useCallback(() => {
    setEditsMap({});
    setNewSourceKpis({});
    setNewRows({});
    setEditMode(false);
  }, [setEditMode]);

  const combinedCrossChannelKpis: CombinedCrossChannelKpis = useMemo(() => {
    const existingCrossChannelKpis = R.values(data)
      .map(kpiObj => R.pick(["crossChannelKpiLabel", "crossChannelKpiId", "isNewRow"], kpiObj))
      //@ts-ignore
      .sort((a, b) => a.crossChannelKpiId - b.crossChannelKpiId); // ID is a number for existing

    const newCrossChannelKpis = R.values(newRows)
      .map(kpiObj => R.pick(["crossChannelKpiLabel", "crossChannelKpiId", "isNewRow"], kpiObj))
      //@ts-ignore
      .sort((a, b) => a.crossChannelKpiId.localeCompare(b.crossChannelKpiId)); // ID is a UUID for new

    return [...existingCrossChannelKpis, ...newCrossChannelKpis];
  }, [data, newRows]);

  const combinedKpiMappings = useMemo(() => ({ ...data, ...newRows }), [data, newRows]);

  return (
    <div className="editKpiMappingContainer">
      <div className="kpiMappingEditControls">
        <Button
          type={ButtonType.FILLED}
          variant={ButtonFrameworkVariant.LEADING_ICON}
          icon={<MdAdd />}
          onClick={addNewRow}
          disabled={saving}
        >
          Add KPI
        </Button>
      </div>
      <div className="editSection">
        <div className="crossChannelKpisColumn">
          <CrossChannelKpiColumn
            crossChannelKpis={combinedCrossChannelKpis}
            editsMap={editsMap}
            handleKpiEdit={handleKpiEdit}
            handleNewRow={handleNewRow}
            deleteNewRow={deleteNewRow}
          />
        </div>
        <div className="scrollableSection">
          {sourcesWithKpis.map(source => {
            return (
              <SourceColumn
                combinedKpiMappings={combinedKpiMappings}
                source={source}
                crossChannelKpis={combinedCrossChannelKpis}
                editsMap={editsMap}
                newSourceKpis={newSourceKpis}
                accounts={accountInfoBySource[source] || []}
                handleKpiEdit={handleKpiEdit}
                handleNewSourceKpi={handleNewSourceKpi}
                handleNewRow={handleNewRow}
              />
            );
          })}
        </div>
      </div>
      <div className="footerControls">
        <div className="rightControls">
          {saving ? (
            <Spinner size={40} color="#8254FF" />
          ) : (
            <>
              <Button
                type={ButtonType.OUTLINED}
                variant={ButtonFrameworkVariant.LEADING_ICON}
                icon={<MdDelete style={{ color: "#8254FF" }} />}
                onClick={clearEdits}
              >
                Discard Edits
              </Button>
              <Button
                type={ButtonType.FILLED}
                variant={ButtonFrameworkVariant.LEADING_ICON}
                icon={<MdSave />}
                onClick={saveChanges}
                disabled={R.isEmpty(editsMap) && R.isEmpty(newSourceKpis) && R.isEmpty(newRows)}
              >
                Commit
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditKpiMapping;
