// Stage Two MM

// If opted into this experiment, you'll see the "Effective or Raw" at the top of MMM
const stageTwoMMMUsers: string[] = [
  "katya.villano@tinuiti.com",
  "carling.sugarman@tinuiti.com",
  "nicole.yu@tinuiti.com",
  "vinnie.jaidar@tinuiti.com",
  // Add users here!
];

export const shouldEnablestageTwoMMM = (user: string): boolean => {
  return stageTwoMMMUsers.includes(user);
};
