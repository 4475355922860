import * as Dfns from "date-fns/fp";
import { formatInTimeZone } from "date-fns-tz";
import { PacingDataInfo } from "@blisspointmedia/bpm-types/src/Pacing";
import _ from "lodash";

interface AggregatedResultGraph {
  date: string;
  type: string;
  actualDeliveryActual: number;
  actualDeliveryProjected: number;
  idealDeliveryActual: number;
  idealDeliveryProjected: number;
  goalName: string;
  goalValue: number;
}

export const sortData = (pacingData: any[], sortOption: string, currentSegment: string): any[] => {
  let sortedData: any;
  switch (sortOption) {
    case "Abc":
      sortedData = pacingData.sort((a, b) => a[currentSegment] - b[currentSegment]);
      return sortedData;
    case "goalValue":
      sortedData = pacingData.sort((a, b) => b.goalValue - a.goalValue);
      return sortedData;
    default:
      return pacingData;
  }
};

export const pacingSortByOptions: {
  value: string;
  label: string;
}[] = [
  { value: "Abc", label: "Abc" },
  { value: "goalValue", label: "Goal Value" },
];

export const calculateCumulativeData = (data: any[]): any[] => {
  let cumulativeClearedSimpleSpend = 0;
  let cumulativeProjectedSimpleSpend = 0;
  let cumulativeClearedSimpleIdealSpend = 0;
  let cumulativeProjectedAdjustedSpend = 0;

  const cumulativeData: any[] = [];

  data.forEach(entry => {
    cumulativeClearedSimpleSpend += entry.cleared.simple.spend;
    cumulativeProjectedSimpleSpend += entry.projected.simple.spend;
    cumulativeClearedSimpleIdealSpend += entry.cleared.simple.idealSpend;
    cumulativeProjectedAdjustedSpend += entry.projected.adjusted.spend;

    cumulativeData.push({
      date: entry.date,
      cumulativeClearedSimpleSpend,
      cumulativeProjectedSimpleSpend,
      cumulativeClearedSimpleIdealSpend,
      cumulativeProjectedAdjustedSpend,
      goalValue: entry.goalValue,
    });
  });

  return cumulativeData;
};

export const unnestPacingData = (data: any[]): any[] => {
  return data.map(entry => {
    return {
      date: entry.date,
      Channel: entry.Channel,
      Platform: entry.Platform,
      Presence: entry.Presence,
      Tactic: entry.Tactic,
      revenue: entry.revenue,
      spend: entry.spend,
      booked_simple_revenue: entry.booked.simple.revenue,
      booked_simple_spend: entry.booked.simple.spend,
      booked_adjusted_revenue: entry.booked.adjusted.revenue,
      booked_adjusted_spend: entry.booked.adjusted.spend,
      cleared_simple_revenue: entry.cleared.simple.revenue,
      cleared_simple_spend: entry.cleared.simple.spend,
      cleared_simple_idealRevenue: entry.cleared.simple.idealRevenue,
      cleared_simple_idealSpend: entry.cleared.simple.idealSpend,
      cleared_adjusted_revenue: entry.cleared.adjusted.revenue,
      cleared_adjusted_spend: entry.cleared.adjusted.spend,
      cleared_adjusted_idealRevenue: entry.cleared.adjusted.idealRevenue,
      cleared_adjusted_idealSpend: entry.cleared.adjusted.idealSpend,
      projected_simple_revenue: entry.projected.simple.revenue,
      projected_simple_spend: entry.projected.simple.spend,
      projected_adjusted_revenue: entry.projected.adjusted.revenue,
      projected_adjusted_spend: entry.projected.adjusted.spend,
      goalName: entry.goalName,
      goalValue: entry.goalValue,
    };
  });
};

export const aggregatePacingDataGraph = (
  pacingData: PacingDataInfo[],
  segment: string,
  activeDimensions: string[],
  goalTypeValue: string,
  total: boolean
): AggregatedResultGraph[] => {
  let graphData: PacingDataInfo[] = [];
  let firstGo: PacingDataInfo[] = [];
  if (activeDimensions.length === 1) {
    graphData = pacingData.filter(
      item => item[activeDimensions[0].toLocaleLowerCase()] === segment
    );
  }
  if (activeDimensions.length === 2) {
    firstGo = pacingData.filter(
      item => item[activeDimensions[0].toLocaleLowerCase()] === segment.split(" ")[0]
    );
    graphData = firstGo.filter(
      item => item[activeDimensions[1].toLocaleLowerCase()] === segment.split(" ")[1]
    );
  }
  let groupedResults: { [key: string]: AggregatedResultGraph } = {};
  let finalData = total ? pacingData : graphData;
  finalData.forEach(pacing => {
    let key = pacing.date;
    let valueType = goalTypeValue === "Spend" ? "spend" : "revenue";

    if (!groupedResults[key]) {
      groupedResults[key] = {
        date: key,
        type: valueType,
        actualDeliveryActual: 0,
        actualDeliveryProjected: 0,
        idealDeliveryActual: 0,
        idealDeliveryProjected: 0,
        goalName: pacing.goalName,
        goalValue: pacing.goalValue,
      };
    }
    let simpleCleared = pacing.cleared.simple[valueType];
    let simpleProjected = pacing.projected.simple[valueType];
    let simpleIdealCleared = pacing.cleared.simple[valueType];
    let adjustedProjected = pacing.projected.simple[valueType];

    groupedResults[key].actualDeliveryActual += parseFloat(simpleCleared);
    groupedResults[key].actualDeliveryProjected += parseFloat(simpleProjected);
    groupedResults[key].idealDeliveryActual += parseFloat(simpleIdealCleared);
    groupedResults[key].idealDeliveryProjected += parseFloat(adjustedProjected);
  });
  const nonSorted = Object.values(groupedResults);
  const sortedData = nonSorted.sort(
    (a, b) => Dfns.parseISO(a.date).valueOf() - Dfns.parseISO(b.date).valueOf()
  );
  return sortedData;
};

interface AggregatedResult {
  segments: string;
  type: string;
  totalCleared: number;
  totalProjected: number;
  totalRemainder: number;
  goalName: string;
  goalValue: number;
}

export const aggregateTotalData = (
  pacingData: any[],
  simplicity: string,
  goalTypeValue: string,
  endDate: string
): any => {
  let groupedResults: { [key: string]: AggregatedResult } = {};
  const today = formatInTimeZone(new Date(), "America/Los_Angeles", "yyyy-MM-dd");
  pacingData.forEach(pacing => {
    let key = "total";
    let metricType = simplicity === "Simple" ? "simple" : "adjusted";
    let valueType = goalTypeValue === "Spend" ? "spend" : "revenue";
    if (!groupedResults[key]) {
      groupedResults[key] = {
        segments: key,
        type: valueType,
        totalCleared: 0,
        totalProjected: 0,
        totalRemainder: 0,
        goalName: pacing.goalName,
        goalValue: pacing.goalValue,
      };
    }
    let cleared = pacing.cleared[metricType][valueType];
    let projected = pacing.projected[metricType][valueType];

    groupedResults[key].totalCleared += parseFloat(cleared);
    groupedResults[key].totalProjected += parseFloat(projected);
  });
  const results = Object.values(groupedResults);
  for (let res of results) {
    res.totalRemainder = res.goalValue - res.totalCleared;
    if (new Date(endDate) < new Date(today)) {
      res.totalProjected = 0;
    }
  }
  return Object.values(groupedResults);
};

export const filterAggregateData = (
  pacingData: PacingDataInfo[],
  segment: string,
  activeDimensions: string[],
  simplicity: string,
  goalTypeValue: string,
  endDate: string
): any => {
  console.log("RANFilter", new Date().getMilliseconds);
  let barChartData: PacingDataInfo[] = [];
  let firstGo: PacingDataInfo[] = [];
  const today = formatInTimeZone(new Date(), "America/Los_Angeles", "yyyy-MM-dd");
  if (activeDimensions.length === 1) {
    barChartData = pacingData.filter(
      item => item[activeDimensions[0].toLocaleLowerCase()] === segment
    );
  }
  if (activeDimensions.length === 2) {
    firstGo = pacingData.filter(
      item => item[activeDimensions[0].toLocaleLowerCase()] === segment.split(" ")[0]
    );
    barChartData = firstGo.filter(
      item => item[activeDimensions[1].toLocaleLowerCase()] === segment.split(" ")[1]
    );
  }

  let groupedResults: { [key: string]: AggregatedResult } = {};
  barChartData.forEach(pacing => {
    let key = "";
    for (let dim of activeDimensions) {
      if (dim === "Channel") {
        key = `${key + pacing.channel} `;
      } else if (dim === "Platform") {
        key = `${key + pacing.platform} `;
      } else if (dim === "Geo") {
        key = `${key + pacing.geo} `;
      }
    }

    let metricType = simplicity === "Simple" ? "simple" : "adjusted";
    let valueType = goalTypeValue === "Spend" ? "spend" : "revenue";
    if (!groupedResults[key]) {
      groupedResults[key] = {
        segments: key,
        type: valueType,
        totalCleared: 0,
        totalProjected: 0,
        totalRemainder: 0,
        goalName: pacing.goalName,
        goalValue: pacing.goalValue,
      };
    }
    let cleared = pacing.cleared[metricType][valueType];
    let projected = pacing.projected[metricType][valueType];

    groupedResults[key].totalCleared += parseFloat(cleared);
    groupedResults[key].totalProjected += parseFloat(projected);
  });
  const results = Object.values(groupedResults);
  for (let res of results) {
    res.totalRemainder = res.goalValue - res.totalCleared;
    if (new Date(endDate) < new Date(today)) {
      res.totalProjected = 0;
    }
  }
  return Object.values(groupedResults);
};

const sumNested = (acc, item, keys) => {
  keys.forEach(key => {
    if (typeof item[key] === "object") {
      acc[key] = acc[key] || {};
      sumNested(acc[key], item[key], Object.keys(item[key]));
    } else {
      acc[key] = (acc[key] || 0) + (parseFloat(item[key]) || 0);
    }
  });
};

export const groupByAndSum = (array: any[], properties: any[]): any => {
  const grouped = _.groupBy(array, item => {
    return properties.map(prop => item[prop]).join("-");
  });

  return _.map(grouped, items => {
    return items.reduce(
      (acc, item) => {
        acc.date = item.date;
        acc.goalName = item.goalName;
        acc.goalValue = item.goalValue;
        properties.forEach(prop => {
          acc[prop] = item[prop];
        });
        sumNested(acc, item, ["revenue", "spend", "booked", "cleared", "projected"]);
        return acc;
      },
      {
        revenue: 0,
        spend: 0,
        booked: {
          simple: { revenue: 0, spend: 0 },
          adjusted: { revenue: 0, spend: 0 },
        },
        cleared: {
          simple: { revenue: 0, spend: 0, idealRevenue: 0, idealSpend: 0 },
          adjusted: { revenue: 0, spend: 0, idealRevenue: 0, idealSpend: 0 },
        },
        projected: {
          simple: { revenue: 0, spend: 0 },
          adjusted: { revenue: 0, spend: 0 },
        },
      }
    );
  });
};
