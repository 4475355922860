import { useMemo } from "react";
import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import { MdDeleteForever } from "react-icons/md";
import { BPMButton } from "../../Components";
import { PlanRow, TYPES_TO_NAMES } from "@blisspointmedia/bpm-types/dist/LinearBuying";
import { formatMoney } from "../../utils/format-utils";
import { SHORT_DATE_FORMAT } from "../../utils/date-utils";
import { convert24hrTo12hr } from "../../utils/time-utils";

const WeeksForNewRow = ({ weekToKey, deleteNewRow }) => {
  return (
    <div className="weeksForNewRow">
      {R.keys(weekToKey).map(week => {
        return (
          <div key={week} className="weekContainer">
            <BPMButton
              className="deleteButton"
              size="sm"
              variant="outline-danger"
              onClick={() => deleteNewRow(weekToKey[week])}
            >
              <MdDeleteForever />
            </BPMButton>
            <div>{R.pipe(Dfns.parseISO, Dfns.format(SHORT_DATE_FORMAT))(week)}</div>
          </div>
        );
      })}
    </div>
  );
};

interface PendingNewRowProps {
  rows: PlanRow[];
  deleteNewRow: (key: string) => void;
}

const PendingNewRow: React.FC<PendingNewRowProps> = ({ rows, deleteNewRow }) => {
  const {
    avail,
    rotation,
    daypart_start,
    daypart_end,
    dow,
    type,
    length,
    count,
    cost,
    notes,
    creatives,
  } = rows[0];

  const weekToKey = useMemo(() => {
    return rows.reduce((prev, current) => ({ ...prev, [current.week]: current.key }), {});
  }, [rows]);

  return (
    <div className="newRowContainer">
      <div className="newRowTitle">
        <WeeksForNewRow weekToKey={weekToKey} deleteNewRow={deleteNewRow} />
      </div>
      <div className="rowDetails">
        <div>
          <span className="type">Avail: </span>
          <span className="value">{avail}</span>
        </div>
        <div>
          <span className="type">Rotation Label: </span>
          <span className="value">{rotation}</span>
        </div>
        <div>
          <span className="type">Start time: </span>
          <span className="value">{convert24hrTo12hr(daypart_start)}</span>
        </div>
        <div>
          <span className="type">End time: </span>
          <span className="value">{convert24hrTo12hr(daypart_end)}</span>
        </div>
        <div>
          <span className="type">Days: </span>
          <span className="value">{dow.join(", ")}</span>
        </div>
        <div>
          <span className="type">Type: </span>
          <span className="value">{TYPES_TO_NAMES[`${type}`]}</span>
        </div>
        <div>
          <span className="type">Length: </span>
          <span className="value">{length}</span>
        </div>
        <div>
          <span className="type">Spots: </span>
          <span className="value">{count}</span>
        </div>
        <div>
          <span className="type">Rate: </span>
          <span className="value">{formatMoney(cost, 0)}</span>
        </div>
        <div>
          <span className="type">Spend: </span>
          <span className="value">{formatMoney(cost * count, 0)}</span>
        </div>
        <div>
          <span className="type">Notes: </span>
          <span className="value">{notes}</span>
        </div>
        {creatives && (
          <div>
            <span className="type">Creatives: </span>
            <span className="value">
              {creatives.map(creative => {
                const { isci, percent } = creative;
                return <span key={isci}>{`${isci} - ${percent}% `}</span>;
              })}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default PendingNewRow;
